.notification-card {
  &_webinar {
    background: #EFF4F8;
  }

  &__inner {
    display: block;
    width: 100%;
    color: #181818;
    padding: 20px;

    &:hover {
      color: #0d4a75;
    }
    @media (min-width: 768px) {
      padding: 15px 33px 15px 33px;
      display: flex;
      align-items: center;
    }
    @media (min-width: 1199px) {
      padding-right: 75px;
    }
    @media (min-width: 1400px) {
      padding: 19px 33px 19px 33px;
    }
  }

  &__type {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
    display: block;

    @media (min-width: 576px) {
      font-size: 15px;
    }
    @media (min-width: 768px) {
      margin-bottom: 0;
      margin-right: 43px;
      flex-shrink: 0;
    }
    @media (min-width: 1400px) {
      font-size: 20px;
    }
  }

  &__icon {
    display: block;
    margin: 0 !important;
  }

  &__icon-wrap {
    margin-right: 43px;
    flex-shrink: 0;
    display: none;
    @media (min-width: 992px) {
      display: block;
    }
  }

  &__text {
    margin-bottom: 10px;
    width: 100%;

    @media (min-width: 768px) {
      margin-bottom: 0;
      margin-right: auto;
    }
  }

  &__date {
    font-size: 10px;
    line-height: 1.5;

    @media (min-width: 576px) {
      font-size: 15px;
    }
    @media (min-width: 768px) {
      margin-bottom: 7px;
    }
    @media (min-width: 1400px) {
      font-size: 16px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;

    @media (min-width: 576px) {
      font-size: 15px;
    }
    @media (min-width: 1400px) {
      font-size: 18px;
    }
  }

  &__title-popover {
    font-size: 12px;
    line-height: 1.5;
    width: 100%;
    display: block;
    height: 100%;
    background: #eff4f8;
    top: 0;
    position: absolute;
    left: 0;
    animation: popover 10s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;

    @media (min-width: 576px) {
      font-size: 18px;
    }
  }

  &__title-wrap {
    overflow: hidden;
    position: relative;
  }

  &__link {
    @media (min-width: 768px) {
      flex-shrink: 0;
      margin-left: 30px;
    }
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }
}

@keyframes popover {
  0% {
    transform: translateY(-100%);
  }
  40% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0);
  }
  90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
