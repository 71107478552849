.s-story-toggle {
  &__wrap {
    position: relative;
    z-index: 10;
    background-color: #ffffff;
    padding: 25px 16px;
    padding-bottom: 60px;
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.1);
  }

  &__title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 17px;
    font-weight: bold;
  }

  &__link-list {
    position: relative;
    margin-bottom: 26px;
    padding-bottom: 10px;
  }

  &__link-list::before {
    position: absolute;
    content: '';
    height: 2px;
    right: 0;
    left: 0;
    background-color: #d2d8db;
    bottom: 0;
  }

  &__link-item {
    width: 100%;
    margin-bottom: 8px;
    margin-left: -10px;
  }

  &__link {
    padding: 3px 10px;
    padding-bottom: 7px;
    font-size: 16px;
  }

  &__link.active {
    background-color: #e6e6e6;
    color: #000000;
  }

  &__subtitle {
    display: block;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: bold;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  &__description:last-of-type {
    margin-bottom: 46px;
  }

  &__wrap-list {
    padding-bottom: 20px;
  }

  &__wrap-list .s-story__item {
    margin-bottom: 0;
  }

  &__wrap-text {
    margin-bottom: 17px;
  }

  &__quote {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }

  &__position {
    display: flex;
    align-items: flex-start;
    padding: 39px 0;
  }

  &__position-img-wrap {
    padding-right: 17px;
  }

  &__position-img-wrap img {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    box-shadow: 0 3px 24px 0 rgba(0,0,0,.16);
  }

  &__position-text b {
    font-size: 14px;
    line-height: 22px;
    display: block;
  }

  &__position-text p {
    font-size: 14px;
    line-height: 22px;
    display: block;
    margin-bottom: 0;
  }

  &__slide-wrap img {
    width: 100%;
    height: auto;
    margin-bottom: 14px;
    border: 1px solid #dee2e6;
  }

  &__slide-wrap figcaption {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (min-width: $desktop-view) {
  .s-story-toggle {
    margin-left: -20px;
    margin-right: -20px;

    &__wrap {
      padding: 38px 67px;
      padding-right: 50px;
      padding-bottom: 60px;
    }

    &__title {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 18px;
    }

    &__link-list {
      display: flex;
      align-items: flex-start;
      padding-bottom: 6px;
    }

    &__link-list::before {
      display: none;
    }

    &__link-item {
      margin-right: 14px;
      width: auto;
    }

    &__link {
      font-size: 18px;
      line-height: 22px;
    }

    &__wrap-text {
      width: 44%;
    }

    &__slide-wrap {
      width: 580px;
      padding-top: 9px;
      max-width: 50%;
    }

    &__slide-wrap img {
      width: 580px;
      height: auto;
    }

    &__slide-wrap figcaption {
      font-size: 16px;
      line-height: 26px;
    }

    &__subtitle {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 12px;
    }

    &__description {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 6px;
    }

    &__description:last-of-type {
      margin-bottom: 50px;
    }

    &__wrap-list {
      padding-bottom: 14px;
    }

    &__author {
      padding: 8px 0;
    }

    &__quote {
      font-size: 18px;
      line-height: 30px;
    }

    &__position {
      padding-top: 28px;
      padding-bottom: 0;
    }

    &__position-img-wrap {
      padding-right: 23px;
      flex-shrink: 0;
    }

    &__position-img-wrap img {
      width: 60px;
      height: 60px;
    }

    &__position-text b {
      font-size: 16px;
      line-height: 26px;
    }

    &__position-text p {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .s-story-toggle--sadco {
    .s-story-toggle {
      &__wrap {
        margin-bottom: 101px;
      }
    }
  }
}
