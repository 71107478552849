.search {
  position: relative;
  &__input {
    width: 100%;
  }
  &__clear {
    position: absolute;
    right: 0;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    transition: 0.2s ease-in-out;
    &:hover {
      opacity: 0.6;
    }
  }
}