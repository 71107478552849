.enterprise-head {
  padding-top: 25px;
}

.enterprise-head .enterprise-head__title {
  margin-bottom: 22px;
}

.enterprise-head__box-content {
  margin-left: -35px;
  width: 815px;
  margin-bottom: 78px;
}

.enterprise-head__box-content h1 {
  padding-left: 35px;
}

.enterprise-head__box-content p {
  padding-left: 35px;
  padding-bottom: 17px;
}

.enterprise-decision {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}

.enterprise-decision .enterprise-decision__title {
  margin-bottom: 12px;
}

.enterprise-decision__subtitle {
  font-size: 16px;
  line-height: 22px;
}

.enterprise-decision__description,
.enterprise-decision__subscription {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 7px;
}

.enterprise-decision__description {
  margin-bottom: 7px;
}

.enterprise-decision__subscription {
  margin-bottom: 17px;
}

.enterprise-decision__wrap-content {
  width: 480px;
  @media (min-width: 1400px) {
    width: 597px;
  }
}

.enterprise-decision__wrap-image {
  width: 450px;
  position: relative;
  padding-top: 5px;
  @media (min-width: 1400px) {
    width: 591px;
  }
}

.enterprise-decision__image {
  -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
}

.enterprise-decision__caption-image {
  position: absolute;
  background-color: white;
  padding: 20px 25px;
  width: 359px;
  bottom: -62px;
  left: 48px;
  @media (min-width: 1400px) {
    width: 479px;
  }
}

.enterprise-decision__caption-title {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 12px;
  font-weight: bold;
}

.enterprise-decision__caption-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.enterprise-technologies {
  padding: 35px 67px;
  padding-bottom: 54px;
  margin-left: -35px;
  margin-right: -35px;
  margin-bottom: 67px;
  background-image: url('/img/enterprise/technologies__bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.enterprise-technologies .enterprise-technologies__title {
  width: 452px;
  margin-bottom: 16px;
}

.enterprise-technologies__description {
  font-size: 16px;
  line-height: 26px;
  width: 554px;
  margin-bottom: 6px;
  @media (min-width: 1400px) {
    width: 624px;
  }
}

.enterprise-integration {
  margin-bottom: 35px;
}

.enterprise-integration__list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 36px;
}

.enterprise-integration__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.enterprise-integration__item:not(:first-of-type) {
  padding-top: 60px;
}

.enterprise-integration__item:not(:last-of-type) {
  border-bottom: 1px solid #d3d9db;
  padding-bottom: 133px;
}

.enterprise-integration__item:last-of-type .enterprise-integration__text-column {
  width: 480px;
  @media (min-width: 1400px) {
    width: 609px;
  }
}

.enterprise-integration__wrap-image {
  width: 450px;
  position: relative;
  @media (min-width: 1400px) {
    width: 591px;
  }
}

.enterprise-integration__image {
  -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
}

.enterprise-integration__text-column {
  width: 480px;
  @media (min-width: 1400px) {
    width: 589px;
  }
}

.enterprise-integration .enterprise-integration__subtitle {
  font-size: 30px;
  line-height: 42px;
  margin-bottom: 10px;
  padding-top: 5px;
}

.enterprise-integration__description {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;
}

.enterprise-integration__caption {
  position: absolute;
  background-color: white;
  padding: 20px 25px;
  width: 359px;
  bottom: -72px;
  left: 48px;
  @media (min-width: 1400px) {
    width: 479px;
  }
}

.enterprise-integration__caption-title {
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}

.enterprise-integration__caption-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.enterprise-integration__base-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 12px;
}

.enterprise-integration__link-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.enterprise-integration__link-item {
  margin-right: 35px;
}

@media (max-width: 420px) {
  .enterprise-head__box-content {
    width: auto;
    margin-left: -16px;
    margin-right: -16px;
    margin-top: auto;
    margin-bottom: 0;
    background: rgba(0, 0, 0, 0.8);
  }

  .enterprise-head__box-content h1 {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    padding-left: 16px;
  }

  .enterprise-head__box-content p {
    padding-left: 16px;
  }

  .enterprise-decision__wrap-image,
  .enterprise-integration__wrap-image {
    width: auto;
  }

  .enterprise-decision__image,
  .enterprise-integration__image {
    width: 100%;
  }

  .enterprise-decision__caption-image,
  .enterprise-integration__caption {
    position: static;
    width: auto;
    padding: 16px 16px;
  }

  .enterprise-technologies {
    padding: 35px 16px;
    width: auto;
    margin-left: -16px;
    margin-right: -16px;
  }

  .enterprise-technologies .enterprise-technologies__title {
    width: 100%;
  }

  .enterprise-technologies__description {
    width: 100%;
  }

  .enterprise-integration__item:not(:first-of-type) {
    padding-top: 15px;
  }

  .enterprise-integration__item:not(:last-of-type) {
    padding-bottom: 33px;
  }
}
