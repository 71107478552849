.dd-item {
  &__input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }
  &__select-icon {
    display: inline-block;
    margin-right: 8px;
    vertical-align: baseline;
  }
}