// categories styles
.categories {
  display: flex;
  flex-flow: column wrap;

  @media (min-width: 576px) {
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 0 -0.625rem;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    & + & {
      margin-top: .5rem;
    }
  }

  &__item {
    & + & {
      margin-top: .5rem;
    }
  }

  &__link {
    padding: 0.3rem 0.625rem;

    &--active {
      background-color: #fff;
      color: $black;

      &:hover {
        text-decoration: none;
        color: $black;
      }
    }
  }

}

.awards-container {
  display: flex;
  justify-content: space-between;
  list-style: none;
  width: 100%;
  padding: 0;
}

.awards-item {
  padding-right: 3%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.awards-item:last-child {
  padding-right: 0;
}

.main-description {
  margin-top: 10px;
  font-size: 16px;
}

.awards-img {
  height: 70px;
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .awards-container {
    padding-left: 0;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .main-description {
    font-size: 14px;
  }
  .awards-container li {
    width: 48%;
  }
  .awards-img {
    margin-top: 10px;
  }
}

.awards-section a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.index-awards-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.g2-item {
  background-image: url('/image/big-g2.svg');
  background-repeat: no-repeat;
  background-position: 95% 80%;
  padding: 40px 0px 38px 40px;
  border: 1px solid rgba(207, 207, 207, 0.767);
  width: 49%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.g2-item:hover {
  box-shadow: 0px 20px 34px -10px rgba(0, 0, 0, 0.1);
}

.quadro-item {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dual-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 48%;
}

.awards-img__item {
  height: 70px;
  display: flex;
  align-items: center;
}

.bordered {
  padding: 20px;
  border: 1px solid rgba(207, 207, 207, 0.767);
  width: 49%;
}

.bordered:hover {
  box-shadow: 0px 20px 34px -10px rgba(0, 0, 0, 0.1);
}

.item-tdviser img {
  height: 35px;
}

.item-cnews img {
  height: 28px;
}

@media (max-width: 991px) {
  .index-awards-container {
    flex-direction: column;
  }

  .g2-item {
    padding: 25px;
    width: 100%;
    margin-bottom: 15px;
    align-items: start;
    background-position: 85% 50%;
    background-size: 30%;
  }

  .quadro-item {
    width: 100%;
  }

  .bordered {
    width: 49%;
  }
}

@media (max-width: 767px) {
  .dual-item {
    flex-direction: column;
    align-items: center;
  }

  .bordered {
    width: 100%;
    margin-bottom: 10px;
  }

  .g2-item {
    background-image: none;
  }
}

.slider-bordered {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.slider-image {
  display: flex;
  align-items: center;
}

.owl-carousel .owl-item .slider-image img {
  display: revert;
  height: 50px;
}

.owl-carousel .owl-item .item-cnews  .slider-image img {
  margin-bottom: 10px;
  margin-top: 10px;
  height: 30px;
}

.owl-carousel .owl-item .g2-img {
  height: 110px;
}

.owl-carousel .owl-item .g2-img img{
  height: 100px;
}

.owl-item .item div{
  margin-bottom: 10px;
  justify-content: flex-start;
}

.awards-slider {
  font-size: 12px;
}

.awards-slider a {
  text-decoration: none;
  color: #000000;
}

.none-border {
  border: none;
}

.owl-carousel .owl-item .item img {
  width: auto;
}

@media (max-width: 426px) {
  .awards-section {
    display: none;
  }
}
@media (min-width: 427px) {
  .container-awards-slider {
    display: none;
  }

}
.tadviser-img {
  height: 40px;
}
.cnews-img {
  height: 28px;
}


.testing-access__section {
  background-color: #253252;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 43px 16px 32px;
}

@media(max-width: 769px) {
  .testing-access__section {
    padding-right: 60px;
  }

}

@media(min-width: 768px) {
  .testing-access__section:before {
    content: '';
    position: absolute;
    left: 0;
    top:50%;
    transform: translateY(-25%);
    background: url('/image/pixel-arrow.svg') no-repeat;
    width: 5%;
    height: 100%;
  }

  .testing-access__section:after {
    content: '';
    position: absolute;
    right: 0;
    top:50%;
    transform: translateY(-25%) scale(-1, 1);
    background: url('/image/pixel-arrow.svg') no-repeat;
    width: 5%;
    height: 100%;
  }
}

@media(max-width: 400px) {
  .testing-access__buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .testing-access__button-try-free {
    margin-bottom: 20px;
  }
}

.testing-access__title {
  color: #FFFFFF;
  margin-bottom: 30px;
  font-size:20px;
  font-weight: bold;
  text-align: center;
}

.testing-access__subtitle {
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.testing-access__button-try-free {
  background-color: #f8bd01;
  color: #253252;
  padding: 11px 15px 11px 15px;
  font-weight: 700;
  margin-right: 5px;
}

.testing-access__button-to-site {
  background-color: #253252;
  color: white;
  padding: 10px 15px 10px 15px;
  font-weight: 700;
  border: 1px solid white;
  margin-left: 5px;
  box-sizing: border-box;
}

.testing-access__buttons {
  margin-bottom: 20px;
}

.test-access-section-image {
  width: 100%;
  background-color: #253252;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 25px;
  margin-top: 50px;
}

.test-access-section-image__left-block {
  padding-top: 20px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;
}

.access-button {
  display: inline-block;
}

.title-big {
  font-size: 30px;
  line-height: 39px;
}

.test-access__section-small {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 25px;
  background-color: #253252;
}

.testing-access__buttons-small {
  display: flex;
  justify-content: center;
}

.test-access__section-small-left {
  padding-top: 30px;
}

.small-title {
  font-size: 18px;
  line-height: 23px;
}

@media (max-width: 670px) {
  .testing-access__buttons-small {
    flex-direction: column;
    align-items: center;
  }

  .testing-access__buttons-small a {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  .test-access__section-small {
    padding-right: 80px;
  }

  .test-access__image {
    display: none;
  }

}

.testing-access__buttons-big {
  display: flex;
  align-items: center;
}

@media (max-width: 890px) {
  .test-access-section-image__right-block {
    display: none;
  }

  .test-access-section-image__left-block {
    margin-right: 0;
    align-items: center
  }
}

@media (max-width: 450px) {
  .testing-access__buttons-big {
    flex-direction: column;
  }

  .testing-access__buttons-big a{
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }
}
