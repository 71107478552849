.projects-for-general {
  //max-width: 800px;
}

.projects-for-general__large-publication {
  height: 450px;
}

@media (max-width: $mobile-view) {
  .projects-for-general__large-publication {
    height: 50vw;
  }
}
