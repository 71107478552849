.case-author {
  &__wrap {
    border-top: 1px solid #d1d1d1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 60px 0;
    margin-bottom: 14px;
  }

  &__wrap.b2b {
    margin-bottom: 5px;
    padding-top: 48px;
  }

  &__title {
    font-size: 30px;
    line-height: 40px;
    width: 50%;
    margin-bottom: 17px;
  }

  &__description {
    width: 78%;
    font-size: 18px;
    line-height: 35px;
    margin-bottom: 19px;
    @media (min-width: 1400px) {
      font-size: 20px;
    }
  }

  &__wrap-img--che &__avatar {
    margin-left: 33%;
    margin-bottom: 22px;
  }

  &__wrap-img--che &__position {
    width: 100%;
    margin-left: 31%;
    font-size: 16px;
    line-height: 26px;
  }

  &__wrap-img--che &__position b {
    font-size: 18px;
    line-height: 30px;
  }

  &__wrap-img {
    width: 30%;
  }

  &__wrap-img--che {
    width: 30%;
    padding-top: 14px;
  }

  &__avatar {
    border-radius: 50%;
    margin-left: 34%;
  }

  &__avatar-mobile {
    display: none;
  }

  &__wrap-description {
    width: 70%;
    padding-left: 55px;
    padding-top: 12px;
    box-sizing: border-box;
  }

  &__wrap-description.b2b &__description {
    font-size: 18px;
    line-height: 30px;
  }

  &__wrap-description.b2b &__description:not(:last-of-type) {
    margin-bottom: 30px;
  }

  &__wrap-description li span {
    font-size: 18px;
    line-height: 26px;
  }

  &__position {
    font-size: 18px;
    line-height: 30px;
  }
}

.case-author--all-border {
  .case-author {
    &__wrap {
      border-top: 1px solid #d1d1d1;
      border-bottom: 1px solid #d1d1d1;
    }
  }
}

.case-author--contracts {
  .case-author__title {
    width: 75%;
  }

  .case-author__description {
    width: 84%;
  }
}

.case-author--pay {
  margin-bottom: 80px;

  .case-author__title {
    width: 60%;
  }
}

.case-author--ivoice {
  margin-bottom: 129px;

  .case-author {
    &__wrap {
      padding: 55px 0;
    }

    &__wrap-description {
      padding-top: 0;
      padding-left: 45px;
    }

    &__wrap-img img {
      margin-top: 24px;
      margin-left: 35px;

    }

    &__title {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 11px;
      font-weight: bold;
      width: 85%;
    }

    &__description {
      margin-bottom: 0;
      width: 80%;
    }
  }
}

.case-author--travel {
  .case-author {
    &__wrap {
      margin-bottom: 79px;
      padding: 43px 0;
      padding-bottom: 63px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__description {
      width: 86%;
      font-size: 18px;
      line-height: 30px;
    }

    &__description:not(:last-of-type) {
      margin-bottom: 23px;
    }
  }
}

@media (max-width: $mobile-view) {
  .case-author {
    & &__wrap {
      padding: 30px 36px;
    }

    &__wrap-img {
      display: none;
    }

    & &__title {
      width: 100%;
      font-size: 24px;
      line-height: 32px;
    }

    &__wrap-description {
      width: 100%;
      padding-left: 0;
      margin-right: 0;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
    }

    &__position {
      font-size: 14px;
      line-height: 22px;
    }

    &__avatar-mobile {
      display: block;
      border-radius: 50%;
      float: left;
      margin-right: 17px;
    }

    &__wrap-description li span {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .case-author--ivoice {
    margin-bottom: 80px;

    .case-author {
      &__wrap-img {
        width: 100%;
        display: block;
      }

      &__wrap {
        padding: 42px 16px;
        padding-bottom: 60px;
      }

      &__wrap-img img {
        margin-top: 0;
        margin-left: 16px;
        width: 246px;
        height: 79px;
        margin-bottom: 53px;
      }

      &__wrap-description {
        padding-top: 0;
      }

      &__title {
        font-weight: bold;
        width: 100%;
      }

      &__description {
        margin-bottom: 0;
        width: 100%;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}
