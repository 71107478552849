textarea:focus, input:focus{
  outline: none;
}

#content {
  max-width: 1250px;
  margin: 0 auto;
}

.wrap-main {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 15px;
  @media (max-width: 1399px) {
    max-width: 1010px;
  }
}

#content {
  width: auto;
}

.clr-blue {
  color: #0D4A75;
}

.clr-grey {
  color: #B2B2B2;
}

.clr-white {
  color: #FFFFFF;
}

.clr-yellow {
  color: #FABE00;
}

#carousel-webinars {
  margin: 20px 0;
}

.text-inherit {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin-bottom: 0;
}

@media (max-width: $mobile-view) {
  .wrap-main {
    width: 100%;
    padding: 0 16px;
  }

  .wrap-main--wide {
    padding: 0;
  }
}
