.webinars {
  padding-top: 25px;

  &__wrap {
    position: relative;
    padding-top: 11px;
    margin-bottom: 34px;
    min-height: 440px;
  }

  & &__title {
    margin-bottom: 19px;
  }

  &__menu {
    position: absolute;
    left: 0;
    top: 20px;
    width: 243px;
    @media (min-width: 1400px) {
      top: 52px;
      width: 413px;
    }
  }

  &__menu .second-nav .second-mobile-nav__item {
    margin-right: 0;
  }

  &__menu-list {
    padding: 0;
    margin: 0;
    margin-left: -10px;
    margin-bottom: 24px;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  &__menu-item {
    padding: 4px 10px;
    margin-right: 4px;
  }

  &__menu-item--active {
    background-color: #ffffff;
    color: black;
  }

  &__filter-list {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: -10px;
  }

  &__filter-item {
    margin-bottom: 0;
  }

  &__filter-link {
    display: inline-block;
    padding: 4px 10px;
  }

  &__filter-link--active {
    background-color: #ffffff;
    color: black;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;

    &--author-page  {
      .webinars__items:first-of-type {
        margin-right: 20px;
        width: 310px;
        margin-bottom: 32px;
        margin-left: 0;

        @media (min-width: 1400px) {
          width: 380px;
          margin-right: 40px;

          .webinars__wrap-image {
            width: 380px;
            height: 214px;
          }
        }
      }


      .webinars__items:nth-of-type(3n + 4) {
        margin-right: 20px;
      }
      @media (min-width: 1400px) {
        .webinars__items:nth-of-type(3n + 4) {
          margin-right: 40px;
        }
      }

      .webinars__items:nth-of-type(3n) {
        margin-right: 0;
      }
      
      
    }
  }

  &__items {
    width: 310px;
    margin-right: 20px;
    margin-bottom: 32px;
    @media (min-width: 1400px) {
      width: 380px;
      margin-right: 40px;
    }
  }

  &__wrap-image {
    width: 380px;
    max-width: 100%;
    height: auto;
    margin-bottom: 12px;
    -webkit-box-shadow: inset 0 0 0 2px rgba(120, 120, 120, 0.2), rgba(0, 0, 0, 0.2) 0 20px 40px;
    box-shadow: inset 0 0 0 2px rgba(120, 120, 120, 0.2), rgba(0, 0, 0, 0.2) 0 20px 40px;
    @media (min-width: 1400px) {
      height: 214px;
    }
  }

  &__wrap-image img {
    width: 100%;
    height: 100%;
  }

  &__items:first-of-type {
    width: 640px;
    margin-left: 330px;
    margin-right: 0;
    @media (min-width: 1400px) {
      width: 800px;
      margin-left: 420px;
    }
  }

  &__items:first-of-type .webinars__wrap-image {
    width: 800px;
    height: auto;
    @media (min-width: 1400px) {
      height: 450px;
    }
  }

  &__items:nth-of-type(3n + 4) {
    margin-right: 0;
  }

  &__wrap-link {
    margin-bottom: 6px;
  }

  &__link {
    position: relative;
  }

  &__active &__link::before {
    position: absolute;
    content: "Открыта запись";
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    top: 50%;
    left: 50%;
    opacity: 0.93;
    background-color: #ffcc00;
    margin-left: -37px;
    margin-top: -37px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: black;
    animation-name: scale;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    z-index: 0;
  }

  &__active &__link:hover::before {
    color: black;
  }

  &__date {
    font-size: 14px;
    color: rgb(120, 120, 120);
    margin-bottom: 0;

  }

  &__pagination-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  &__pagination-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  &__pagination-item {
    margin-right: 8px;
  }

  &__pagination-item:nth-of-type(6) {
    margin-left: 7px;
    margin-right: 18px;
  }

  &__pagination-link {
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
  }

  &__pagination-link:hover {
    color: #f0523e;
  }

  &__pagination-link--active {
    background-color: white;
  }

  &__left-button {
    margin-left: -5px;
    width: 22px;
    margin-top: -1px;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;
  }

  &__left-button:after {
    position: absolute;
    content: "";
    top: 10.5px;
    left: 3px;
    width: 8.5px;
    height: 1px;
    background-color: #048ad3;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &__left-button:before {
    position: absolute;
    content: "";
    top: 16px;
    left: 3px;
    width: 8.5px;
    height: 1px;
    background-color: #048ad3;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &__right-button {
    margin-right: 13px;
    width: 22px;
    margin-top: -1px;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;
  }

  &__right-button:before {
    position: absolute;
    content: "";
    top: 10.2px;
    right: 3px;
    width: 8.5px;
    height: 1px;
    background-color: #048ad3;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &__right-button:after {
    position: absolute;
    content: "";
    top: 16px;
    right: 3px;
    width: 8.5px;
    height: 1px;
    background-color: #048ad3;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &__right-button:hover::after, &__right-button:hover::before,
  &__left-button:hover::before, &__left-button:hover::after {
    background-color: #f0523e;
  }
}

@media (max-width: $mobile-view) {
  .webinars {
    &__menu {
      width: 100%;
      position: static;
    }

    &__menu-item {
      width: 100%;;
      margin-right: 0;
    }

    &__menu-link {
      padding-left: 16px;
      padding-right: 16px;
    }

    &__filter-list {
      margin-bottom: 25px;
      display: none;
    }

    &__items:first-of-type {
      width: 100%;
      margin-left: 0;
    }

    &__items:first-of-type &__wrap-image {
      width: 100%;
      height: auto;
    }

    &__items {
      width: 100%;
      margin-right: 0;
    }

    &__wrap-image {
      width: 100%;
      height: auto;
    }

    &__list {
      &--author-page  {
        .webinars__items:first-of-type {
          width: 100%;
          margin-right: 0;
  
          .webinars__wrap-image {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
