// header styles

.navbar-expand-md {
  .navbar-nav {
    .nav-link {
      @media (min-width: 576px) {
        padding: 0.9375rem;
      }
    }
  }
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  background-color: #fff;
}

.navbar-nav .nav-item:hover {
  .nav-link {
    background-color: #fff;
    border: 0;
  }
}

.navbar {
//  padding: 0 1rem .5rem;
  padding-top: 0;
  padding-bottom: .5rem;
}