.mobile-filter {
  position: absolute;
  top: -45px;
  right: -10px;
  z-index: 20;

  &__button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    width: 30px;
    height: 30px;
    background: 0;
    border: 0;
    padding: 0;
  }

  &__button svg {
    display: none;
  }

  &__button.collapsed svg {
    display: block;
  }

  &__button::before {
    position: absolute;
    content: '';
    width: 17px;
    height: 2px;
    background-color: #048ad3;
    top: 14px;
    left: 7px;
    transform: rotate(45deg);
  }

  &__button::after {
    position: absolute;
    content: '';
    width: 17px;
    height: 2px;
    background-color: #048ad3;
    top: 14px;
    left: 7px;
    transform: rotate(-45deg);
  }

  &__button.collapsed::before {
    display: none;
  }

  &__button.collapsed::after {
    display: none;
  }


  &__list {
    border: none;
    border-radius: 0;
  }

  &__link {
    display: inline-block;
    white-space: normal;
  }

  &__link:active {
    background-color: #f8f9fa;
  }

  &__link--active {
    color: #1b8ac7;
    background-color: #eeeeee;
  }
}
