.three-columns {
  &__wrap {
    padding: 72px 0;
    padding-left: 11px;
    padding-bottom: 43px;
    border-top: 1px solid #d1d1d1;
    margin: auto;
  }

  &__title {
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 13px;
  }

  &__description {
    font-size: 18px;
    line-height: 30px;
    width: 63%;
    margin-bottom: 25px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 94.5%;
  }

  &__item {
    width: 27%;
    font-size: 18px;
    line-height: 30px;
    margin-right: 83px;
    @media (min-width: 1400px) {
      margin-right: 103px;
    }
  }

  &__item:last-of-type {
    margin-right: 0;
  }

  &__item b {
    display: block;
    margin-bottom: 5px;
  }

  &__item p {
    font-size: 18px;
    line-height: 30px;
  }

  &--last &__wrap {
    border-top: none;
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 72px;
  }
}

.three-columns--border-all {
  .three-columns {
    &__wrap {
      border-top: 1px solid #d1d1d1;
      border-bottom: 1px solid #d1d1d1;
    }
  }
}

.three-columns--interaction {
  @media (min-width: 1400px) {
    .three-columns {
      &__list {
        width: 96.5%;
      }

      &__item:nth-of-type(1) {
        width: 29%;
        margin-right: 72px;
      }

      &__item:nth-of-type(2) {
        width: 31%;
        margin-right: 50px;
      }

      &__item:nth-of-type(3) {
        width: 29%;
      }
    }
  }
}

.three-columns--stocks {
  @media (min-width: 1400px) {
    .three-columns {
      &__list {
        width: 96.5%;
      }

      &__item:nth-of-type(1) {
        width: 33%;
        margin-right: 24px;
      }

      &__item:nth-of-type(2) {
        width: 31%;
        margin-right: 50px;
      }

      &__item:nth-of-type(3) {
        width: 29%;
      }
    }
  }
}

.three-columns--meeting {
  .three-columns {
    &__wrap {
      border-bottom: 1px solid #d1d1d1;
      padding-bottom: 6px;
      margin-bottom: 70px;
    }
  }
}

.three-columns--ivoice {
  margin-left: 105px;

  .three-columns {
    &__item {
      width: 41.7%;
      margin-right: 82px;
    }

    &__item:last-of-type {
      margin-right: 0;
      width: 37%;
    }

    &__item b {
      margin-bottom: 11px;
    }
  }
}

.three-columns--travel {
  margin-bottom: 78px;

  .three-columns {
    &__description {
      width: 67%;
      margin-bottom: 22px;
    }

    &__item:not(:last-of-type) {
      width: 27.5%;
      margin-right: 98px;
    }

    &__item b {
      margin-bottom: 1px;
    }
  }
}

@media (max-width: $mobile-view) {
  .three-columns {
    &__wrap {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 36px;
      padding-bottom: 16px;
    }

    &__title {
      width: 100%;
      font-size: 24px;
      line-height: 32px;
    }

    &__description {
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }

    &__item {
      width: 100%;
      margin-right: 0;
      font-size: 16px;
      line-height: 24px;
    }

    &__item b,
    &__item p {
      font-size: inherit;
    }
  }

  .three-columns--stocks {
    .three-columns {
      &__list {
        width: 100%;
      }

      &__item:nth-of-type(1) {
        width: 100%;
        margin-right: 0;
      }

      &__item:nth-of-type(2) {
        width: 100%;
        margin-right: 0;
      }

      &__item:nth-of-type(3) {
        width: 100%;
      }
    }
  }

  .three-columns--interaction {
    .three-columns {
      &__list {
        width: 100%;
      }

      &__item:nth-of-type(1) {
        width: 100%;
        margin-right: 0;
      }

      &__item:nth-of-type(2) {
        width: 100%;
        margin-right: 0;
      }

      &__item:nth-of-type(3) {
        width: 100%;
      }
    }
  }

  .three-columns--ivoice {
    margin-bottom: 48px;

    .three-columns {
      &__list {
        padding: 0 16px;
      }

      &__item {
        width: 100%;
        padding: 0;
      }

      &__item:last-of-type {
        width: 100%;
      }
    }
  }


  .three-columns--travel {
    margin-bottom: 48px;

    .three-columns {
      &__description {
        width: 100%;
      }

      &__list {
        padding: 0 0;
      }

      &__item:not(:last-of-type) {
        width: 100%;
        padding: 0;
        margin-right: 0;
      }

      &__item:last-of-type {
        width: 100%;
      }
    }
  }

  .three-columns--ivoice {
    margin-left: 0;

    .three-columns {
      &__item {
        width: 100%;
        margin-right: 0;
        font-size: 16px;
        line-height: 24px;
      }

      &__item:last-of-type {
        margin-right: 0;
        width: 100%;
      }

      &__item b {
        margin-bottom: 11px;
      }
    }
  }
}
