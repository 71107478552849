.project {

  &__title {
    margin-left: 35px;
  }

  &__description {
    padding-left: 35px;
    padding-bottom: 28px;
    margin-bottom: 15px;
  }

  &__button-primary {
    margin-left: 35px;
    margin-bottom: 32px;
    padding: 12px 35px;
  }
}

.what-elma-project {
  margin-bottom: 2px;

  &__wrap-text {
    width: 530px;
    @media (min-width: 1400px) {
      width: 700px;
    }
  }

  &__aside-wrap {
    width: 320px;
    iframe {
      max-width: 100%;
    }
    @media (min-width: 1400px) {
      width: 380px;
    }
  }

  & &__title {
    margin-bottom: 14px;
  }

  &__description {
    margin-bottom: 5px;
  }

  &__crm-plus {
    margin-bottom: 71px;
  }

  &__aside-wrap {
    margin-right: 0;
    margin-top: 5px;
  }

  &__help {
    background: white;
    padding: 25px 30px;
    padding-bottom: 10px;
    -webkit-box-shadow: 0 0 48px 2px rgba(190, 190, 190, 0.5);
    box-shadow: 0 0 48px 2px rgba(190, 190, 190, 0.5);
  }

  &__help-title {
    margin-bottom: 15px;
    margin-right: 30px;
  }

  &__help-text {
    margin-bottom: 6px;
  }

  &__help-item {
    padding-bottom: 20px;
    margin-bottom: 18px;
  }

  &__help-item:nth-of-type(3) {
    padding-bottom: 20px;
    margin-bottom: 18px;
    margin-right: 10px;
  }
}

@media (max-width: $mobile-view) {
  .what-elma-project__aside-wrap {
    margin-right: 0;
    margin-top: 22px;
    width: 100%;
  }

  .what-elma-project__help {
    margin-bottom: 0;
  }

  .what-elma-project__crm-plus {
    margin-bottom: 30px;
  }
}

.project-conveyor {
  margin-bottom: 72px;

  & &__title {
    margin-bottom: 15px;
  }

  &__description {
    width: 769px;
  }

  &__text-wrap {
    margin-bottom: 32px;
  }

  &__subtitle {
    margin-bottom: 5px;
  }

  &__text-wrap &__description {
    margin-bottom: 28px;
  }

  &__text-wrap figure {
    font-size: 14px;
    line-height: 22px;
    color: #636363;
  }
}
@media (max-width: $mobile-view) {
  .project-conveyor {
    margin-bottom: 32px;
  }

  .project-conveyor__description {
    width: 100%;
  }

  .project-conveyor img {
    width: 100%;
    margin-bottom: 15px;
  }
}

.business-process-work {
  margin-bottom: 73px;

  &__text-wrap {
    width: 776px;
  }

  &__webinar-wrap {
    width: 380px;
    margin-top: 5px;
  }

  &__webinar-wrap iframe {
    margin-bottom: 9px;
  }

  & &__title {
    margin-bottom: 15px;
  }

  &__description {
    margin-bottom: 16px;
  }
}

@media (max-width: $mobile-view) {
  .business-process-work {
    margin-bottom: 32px;
  }

  .business-process-work__webinar-wrap {
    width: 100%;
  }

  .business-process-work__webinar-wrap iframe {
    width: 100%;
  }

  .business-process-work__link-wrap {
    margin-bottom: 15px;
  }
}

@media (max-width: $mobile-view) {
  .project__title {
    margin-left: 16px;
  }

  .project__description {
    padding-left: 16px;
    padding-bottom: 10px;
    margin-bottom: 12px;
  }

  .project__button-primary {
    margin-left: 16px;
    margin-bottom: 32px;
    padding: 12px 35px;
  }

  .elma-projects-img img {
    max-width: 320px;
  }
}
