.navbar-subnav--new {
  max-width: 969px;
  padding-bottom: 35px;
  -webkit-box-shadow: 0 1rem 1rem 0 rgba(0,0,0,.1);
  box-shadow: 0 1rem 1rem 0 rgba(0,0,0,.1);
}

.navbar-subnav .navbar-subnav__group--new {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 562px;
  flex: 0 1 562px;
}

.navbar-subnav__group-title--new {
  margin-bottom: 6px;
}

.navbar-subnav__group-title--services {
  margin-top: 25px!important;
}

.navbar-subnav__section {
  padding: 5px 15px;
  margin-left: -15px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  margin-top: 0!important;
  margin-bottom: 4px;
}

.navbar-subnav__section--hover:hover {
  background-color: #F5F5F5;
}


.navbar-subnav__section--hover:hover .navbar-subnav__link {
  color: #f0523e;
}

.navbar-subnav__section--mbbottom {
  margin-bottom: 8px;
}

.navbar-subnav__link {
  color: black;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.navbar-subnav__link--first {
  font-size: 14px;
  line-height: 22px;
  color: black;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.navbar-subnav__link--new {
  font-size: 16px;
  line-height: 24px;
  color: black;
}
.navbar-subnav__link--title {
  font-weight: bold;
}

.navbar-subnav__wrap-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 562px;
}

.navbar-subnav__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 343px;
}

.navbar-subnav__col--services {
  width: 100%;
  min-height: auto;
}

.navbar-subnav__list--new .navbar-subnav__item {
  margin-top: 0;
  margin-bottom: 9px;
}

//Выпадающее меню

.subnav-list {
  position: absolute;
  display: none;
  list-style: none;
  padding: 0;
  z-index: 100;
  background-color: white;
  padding-left: 15px;
  padding-top: 21px;
  padding-bottom: 11px;
  padding-right: 28px;
  top: 100%;
  -webkit-box-shadow: 0 1rem 1rem 0 rgba(0,0,0,.1);
  box-shadow: 0 1rem 1rem 0 rgba(0,0,0,.1);
}

.subnav-item {
  margin-bottom: 5px;
}

.subnav-list .subnav-link {
  color: black;
}

.subnav-list .subnav-link:hover,
.subnav-list .subnav-link:focus {
  color: #f0523e;
}

.subnav-list--case {
  width: 685px;
  left: 17.6%;
  padding: 17px 32px;
  box-sizing: border-box;
}

.subnav-list--case .navbar-subnav__column {
  min-height: 200px;
}

.subnav-list--case .navbar-subnav__column.right {
  width: 42%;
}

.subnav-list--case .navbar-subnav__column.left {
  width: 54%;
}

.nav-item:hover .subnav-list {
  display: block;
}

.nav-item:nth-last-of-type(3) .subnav-list {
  width: 616px;
}

.navbar-nav__try-button {
  padding: 6px 19px;
  margin-left: 8px;
  font-size: 13px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border-radius: 1px;
}

.navbar-top {
  background-color: #ededed;
  padding: 0;

  &__list {
    width: 1250px;
    margin: auto;
    padding: 5px 0;
  }

  &__item {
    margin-left: 28px;
  }

  &__link {
    font-size: 13px;
    line-height: 18px;
  }
}

@media (max-width: $mobile-view) {
  .navbar-subnav--new {
    position: static;
    display: block;
    padding: 0;
    padding-left: 40px;
    width: 100%;
  }

  .navbar-subnav__group {
    display: block;
  }

  .navbar-subnav__item,
  .navbar-subnav__total,
  .navbar-subnav__group-title--new,
  .navbar-subnav__list--new,
  .navbar-subnav__col {
    display: none;
  }


  .navbar-subnav--new {
    position: static;
    background-color: #f5f5f5;
  }

  .navbar-subnav__column,
  .navbar-subnav__wrap-col {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    min-height: auto;
    width: 100%;
  }

  .navbar-subnav__section {
    width: 100%;
    border-bottom: 1px solid #dee2e6;
    margin-left: 0;
    padding-right: 0;
  }

  .navbar-subnav__link--title {
    font-weight: normal;
  }

  .nav-item:hover .subnav-list {
    display: none;
  }

  .nav-item:hover .navbar-subnav {
    display: block;
  }

  .navbar-light .navbar-nav .nav-link {
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 1px solid #dee2e6;
  }

  .navbar-collapse.show {
    min-height: auto;
  }

  .navbar-nav {
    padding-bottom: 43px;
  }

  .navbar-nav:nth-of-type(1) .nav-item:nth-last-of-type(1) {
    position: absolute;
    bottom: 35px;
    left: 20px;
  }

  .navbar-nav:nth-of-type(2) .nav-item:nth-last-of-type(1) {
    position: absolute;
    bottom: 35px;
    right: 20%;
  }

  .navbar-nav:nth-last-of-type(1) .nav-item:nth-last-of-type(1) a {
    color: #048ad3;
    border-bottom: none;
  }

  .navbar-nav:nth-last-of-type(2) .nav-item:nth-last-of-type(1) .navbar-nav__try-button {
    padding: 8px 41px;
  }

  .navbar-collapse {
    background-color: rgb(245, 245, 245);
    position: absolute;
    top: 42px;
    right: 0;
    z-index: 100;
    -webkit-box-shadow: rgba(0, 0, 0, 0.28) 0 19px 36px 0;
    box-shadow: rgba(0, 0, 0, 0.28) 0 19px 36px 0;
  }

  .navbar-subnav--new {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
