.case-action {
  &__wrap {
    margin-left: -20px;
    margin-right: -20px;
    background-color: #fff;
    padding: 39px 68px;
    padding-bottom: 68px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__wrap-text {
    width: 42%;
    padding-right: 7%;
    box-sizing: border-box;
  }

  &__title {
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 12px;
    font-weight: bold;
  }

  &__description {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 23px;
  }

  &__wrap-img {
    padding-top: 5px;
    width: 58%;
  }

  &__wrap-img figcaption {
    font-size: 16px;
    line-height: 26px;
    color: #636363;
  }

  &__img {
    border: 1px solid #dee2e6;
    box-sizing: content-box;
    margin-bottom: 14px;
  }

  &__list li {
    margin-bottom: 23px;
    font-size: 18px;
    line-height: 30px;
  }

  &__list b {
    display: block;
    font-size: 18px;
    line-height: 30px;
  }
}

.case-action--contracts {
  margin-bottom: 48px;

  .case-action__wrap {
    display: block;
    background-color: #ebe7dd;
  }

  .case-action__wrap-fill {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .case-action__wrap-img {
    order: 0;
    width: 52%;
  }

  .case-action__wrap-text {
    order: 1;
    padding-right: 0;
    width: 44%;
  }
}

.case-action--b2b {
  margin-bottom: 76px;

  .case-action__wrap-text {
   width: 51.5%;
  }

  .case-action__wrap-img {
    padding-top: 65px;
    width: 48.5%;
  }
}

.case-action--chairs {
  .case-action__wrap {
    display: block;
    min-height: 975px;
    margin-bottom: 50px;
    background-color: #566373;
    background-image: url("/img/assets/bg_chairs.jpg");
    background-repeat: no-repeat;
    background-position: 100% 10%;
    padding: 45px 68px;
    padding-bottom: 82px;
  }

  .case-action__title {
    color: #FFFFFF;
    width: 55%;
    margin-bottom: 21px;
  }

  .case-action__description {
    color: #FFFFFF;
    width: 50%;
    margin-bottom: 39px;
    font-size: 18px;
    line-height: 30px;
  }

  .case-action__list {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .case-action__item {
    width: 45%;
    margin-bottom: 39px;
  }

  .case-action__item:first-of-type {
    margin-right: 55%;
  }

  .case-action__item:last-of-type,
  .case-action__item:nth-last-of-type(2) {
    margin-bottom: 0;
  }

  .case-action__item-title {
    color: #edc14c;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 26px;
  }

  .case-action__item-text {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 26px;
  }
}

.case-action--travel {
  .case-action {
    &__wrap {
      background-color: #e9ebef;
      padding-bottom: 107px;
      margin-bottom: 81px;
    }

    &__description {
      width: 76%;
      margin-bottom: 49px;
    }

    &__wrap-fill {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__wrap-ad-list {
      width: 47.5%;
    }

    &__wrap-ad-list b {
      display: block;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 28px;
    }

    &__ad-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &__ad-item:not(:last-of-type) {
      position: relative;
      margin-bottom: 28px;
      padding-bottom: 30px;
    }

    &__ad-item:not(:last-of-type)::before {
      position: absolute;
      content: '';
      right: 0;
      left: 0;
      bottom: 0;
      height: 2px;
      background-color: #ffffff;
    }

    &__ad-list p:first-of-type {
      margin-bottom: 7px;
    }

    &__ad-list p {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 15px;
    }

    &__ad-list p > span {
      font-size: 24px;
      line-height: 30px;
      color: #048ad3;
    }

    &__wrap-video {
      width: 39.5%;
      padding-right: 179px;
      padding-top: 91px;
    }

    &__wrap-video iframe {
      box-shadow: inset 0 0 0 1px gray;
      margin-bottom: 7px;
    }

    &__wrap-video a {
      font-size: 18px;
      line-height: 30px;
    }

    &__figcaption {
      margin-bottom: 23px;
      font-size: 16px;
      line-height: 26px;
      color: #636363;
    }

    &__app-list {
      list-style: none;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      padding: 25px 0;
    }

    &__app-item:not(:last-of-type) {
      margin-right: 37px;
      margin-bottom: 15px;
    }
  }
}

.case-action--travel-il {
  .case-action {
    &__wrap {
      background-color: #e9ebef;
      padding-bottom: 95px;
      margin-bottom: 78px;
    }

    &__wrap-fill {
      width: 100%;
    }

    &__description {
      width: 71%;
      margin-bottom: 32px;
    }

    &__wrap-fill a {
      font-size: 18px;
      line-height: 30px;
    }

    &__img-list {
      list-style: none;
      margin: 0;
      margin-bottom: 21px;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__img-item img {
      border: solid 1px #d3d9db;
      margin-bottom: 11px;
    }

    &__img-item figcaption {
      font-size: 16px;
      line-height: 30px;
      color: #636363;
    }
  }
}

// b2b
.case-action--chairs.b2b .case-action__wrap {
  min-height: auto;
}

.case-action--chairs.b2b .case-action__description {
  width: 61%;
  margin-bottom: 38px;
}

.case-action--chairs.b2b .case-action__wrap {
  background-position: 96% 10%;
  background-size: 38%;
}

.case-action__list.three .case-action__item {
  width: 30%;
}

.case-action__list.three .case-action__item:first-of-type {
  margin-right: 0;
}

.case-action__list.three .case-action__item:nth-of-type(2) {
  margin-right: 35%;
}

//Travel-automation
.case-action--chairs.travel .case-action__wrap {
  min-height: auto;
}

.case-action--chairs.travel .case-action__description {
  width: 61%;
  margin-bottom: 38px;
}

.case-action--chairs.travel .case-action__wrap {
  background-position: 96% 10%;
  background-size: 38%;
}

.case-action__list.travel {
  width: 71%;
}

.case-action__list.travel .case-action__item {
  width: 44%;
}

.case-action__list.travel .case-action__item:first-of-type {
  margin-right: 0;
}

// payment management


.case-action--chairs.pay .case-action__wrap {
  min-height: auto;
  background-color: #e6ebf0;
  background-image: url('/upload/case/case_pay_action.png');
  background-position: 90% 16%;
  background-size: 480px;
  padding: 58px 68px;
  padding-bottom: 20px;
  @media (min-width: 1400px) {
    background-size: inherit;
    background-position: 85% 16%;
  }
}

.case-action--chairs.pay .case-action__title {
  color: #272727;
  width: 45%;
  margin-bottom: 18px;
}

.case-action--chairs.pay .case-action__description {
  width: 40%;
  margin-bottom: 14px;
  color: #272727;
}

.case-action--chairs.pay a {
  font-size: 18px;
  line-height: 26px;
}

.case-action__list.pay {
  width: 97.5%;
  padding-top: 91px;
  padding-bottom: 32px;
}

.case-action__list.pay .case-action__item {
  width: 28%;
  margin-bottom: 62px;
}

.case-action__list.pay .case-action__item-title {
  color: #272727;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 12px;
}

.case-action__list.pay .case-action__item-text {
  color: #272727;
  font-size: 16px;
  line-height: 1.63;
}

.case-action__list.pay .case-action__item:first-of-type {
  margin-right: 0;
}

.case-action__list.pay .case-action__item:nth-of-type(2) {
  margin-right: 0;
}

@media (max-width: $mobile-view) {
  .case-action {
    &__wrap {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      padding-left: 16px;
      padding-right: 16px;
    }

    &__wrap-text,
    &__wrap-img {
      width: 100%;
    }

    &__wrap-img img {
      width: 100%;
      height: auto;
    }

    &__title {
      font-size: 20px;
      line-height: 26px;
    }

    &__description {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .case-action__wrap-fill {
    .case-action__wrap-img {
      width: 100%;
      margin-bottom: 25px;
    }

    .case-action__wrap-text {
      padding-right: 0;
      width: 100%;
    }
  };

  .case-action--b2b {
    .case-action__wrap-text {
      width: 100%;
    }

    .case-action__wrap-img {
      width: 100%;
    }
  }

  .case-action--chairs {
    .case-action {
      &__wrap {
        padding-bottom: 74vw;
        background-position: 100% 100%;
        background-size: 100%;
        padding-right: 16px;
        padding-left: 16px;
      }

      &__title {
        font-size: 24px;
        line-height: 30px;
        width: 100%;
      }

      &__description {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
      }

      &__item:first-of-type {
        margin-right: 0;
      }

      &__item {
        width: 100%;
      }
    }
  }

  .case-action--travel {
    .case-action {
      &__wrap-ad-list {
        width: 100%;
      }

      &__wrap-video {
        width: 100%;
      }

      &__wrap-ad-list {
        width: 100%;
      }

      &__wrap-video {
        padding: 0;
        margin-left: 36px;
      }
    }
  }

  .case-action--travel-il {
    .case-action {
      &__img-item {
        width: 100%;
      }

      &__img-item img {
        width: 100%;
        height: auto;
      }

      &__description {
        width: 100%;
      }
    }
  }

  // b2b
  .case-action--chairs.b2b .case-action__wrap {
    background-position: 100% 102%;
    background-size: 100%;
  }

  .case-action--chairs.b2b .case-action__description {
    width: 100%;
  }

  .case-action__list.three .case-action__item {
    width: 100%;
  }

  .case-action__list.three .case-action__item:first-of-type {
    margin-right: 0;
  }

  .case-action__list.three .case-action__item:nth-of-type(2) {
    margin-right: 0;
  }

  //tarvel-automation

  .case-action--chairs.travel .case-action__wrap {
    background-position: 100% 102%;
    background-size: 100%;
  }

  .case-action--chairs.travel .case-action__description {
    width: 100%;
  }

  .case-action__list.travel {
    width: 100%;
  }

  .case-action__list.travel .case-action__item {
    width: 100%;
  }

  .case-action__list.travel .case-action__item:first-of-type {
    margin-right: 0;
  }

  .case-action__list.travel .case-action__item:nth-of-type(2) {
    margin-right: 0;
  }

  // payment-management

  .case-action--chairs.pay .case-action__wrap {
    background-position: 50% 25%;
    background-size: 80%;
  }

  .case-action--chairs.pay .case-action__description {
    width: 100%;
  }

  .case-action--chairs.pay .case-action__title {
    width: 100%;
  }

  .case-action__list.pay {
    padding-top: 54vw;
    padding-bottom: 20px;
  }

  .case-action__list.pay .case-action__item {
    width: 100%;
  }
}
