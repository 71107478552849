.wrap-call-to-action {
  margin-top: 32px;
  margin-bottom: 72px;
}

.call-to-action {
  //width: 1290px;
  margin-left: -35px;
  margin-right: -35px;
  padding: 25px 35px;
  -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);

  &__font-button {
    font-size: 16px;
    line-height: 32px;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  & a {
    font-weight: bold;
    padding: 11px 33px;
    margin-right: 15px;
  }
}

@media (max-width: $mobile-view) {
  .call-to-action {
    width: 100%;
    margin: 32px 0;
    padding: 26px 20px;
  }

  .call-to-action h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .call-to-action a {
    width: 100%;
    padding: 11px 18px;
    display: flex;
    font-size: 14px;
  }
}
