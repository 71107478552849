.purchases {
  margin-bottom: 50px;

  &__wrap {
    margin: auto;
  }
}

.purchases-head {
  position: relative;
  z-index: 0;
  margin-left: 40px;
  margin-right: 40px;
  padding: 35px 47px;
  padding-bottom: 9px;
  display: flex;
  flex-wrap: wrap;
  background-color: #ebe7dd;
  @media (min-width: 1400px) {
    margin-left: 58px;
    margin-right: 58px;
  }

  &__left-column,
  &__right-column{
    width: 50%;
    box-sizing: border-box;
  }

  &__left-column {
    padding-right: 5%;
  }

  &__right-column {
    padding-left: 5.5%;
  }

  &__title {
    display: block;
    font-size: 24px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  &__description {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  & .three-columns__list {
    width: 100%;
  }

  & .three-columns__item {
    width: 30%;
    margin-right: 5%;
  }
}

.purchases-head--bpms {
  width: 1084px;
  padding: 49px 47px;
  padding-right: 109px;
  padding-bottom: 38px;
  margin-left: 57px;
  margin-bottom: 50px;

  .case-bpms-nlp {
    &__wrap {
      position: relative;
      padding-bottom: 78px;
      margin-bottom: 51px;
    }

    &__wrap::before {
      position: absolute;
      content: '';
      height: 2px;
      right: 0;
      left: 0;
      background-color: #fff;
      bottom: 0;
    }

    &__title {
      font-size: 24px;
      line-height: 26px;
      font-weight: bold;
      margin-bottom: 17px;
    }

    &__description {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 12px;
      width: 75%;
    }

    &__description:last-of-type {
      margin-bottom: 29px;
    }

    &__wrap-list {
      padding-bottom: 31px;
    }

    &__wrap-list ul {
      margin-bottom: 0;
    }

    &__wrap-list li {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 0;
    }

    &__fill {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      margin-bottom: 13px;
      width: 100%;
      height: auto;
      padding: 41px 25px;
      padding-bottom: 33px;
    }

    &__fill img {
      width: 100%;
      height: auto;
    }

    &__slide-wrap figcaption {
      font-size: 16px;
      line-height: 26px;
      color: #636363;
    }

    &__slide-wrap {
      margin-bottom: 56px;
    }

    &__wrap-list-orange b {
      display: block;
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 18px;
    }

    &__wrap-list-orange li {
      width: 31%;
    }
  }

  .case-voice-robots {
    &__wrap {
      width: 100%;
    }

    &__title {
      font-size: 24px;
      line-height: 36px;
      width: 54%;
      margin-bottom: 13px;
    }

    &__advantages-list {
      width: 841px;
      margin-bottom: 50px;
    }
  }
}

.purchases-head.blue-silver {
  background-color: #e9ebef;
}

.purchases-head--pale-gray {
  background-color: #e9ebef;
}

.purchases-head--meeting {
  padding-bottom: 0;

  .purchases-head {

    &__title {
      font-size: 30px;
      line-height: 42px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    &__description {
      width: 684px;
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.purchases-head--travel {
  padding: 40px 47px;
  padding-bottom: 10px;

  .three-columns__list {
    width: 95%;
  }

  .purchases-head {
    &__title {
      font-size: 24px;
      line-height: 36px;
      font-weight: 600;
      margin-bottom: 29px;
    }

    &__description {
      width: 684px;
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.purchases-head--pay {
  padding: 40px 47px;
  padding-bottom: 10px;

  .three-columns__list {
    width: 100%;
    padding-bottom: 23px;
  }

  .three-columns__item {
    width: 28%;
    margin-right: 5.2%;
  }

  .three-columns__item:last-of-type {
    width: 32%;
  }
}

.purchases-head--mriya {
  position: relative;
  background-image: url("/upload/success_story/mriya/notebook_bg.png");
  background-position: bottom right;
  background-repeat: no-repeat;

  .change-easy {
    position: absolute;
    top: 7%;
    right: 20%;
    padding: 5px 18px;
    background: #FFE601;
    border-radius: 2px;
    font-size: 18px;
    line-height: 25px;
    transform: rotate(-12.73deg);
  }

  .s-story__list {
    padding-left: 28px;
    width: 50%;

    .s-story__item::before {
      background-color: transparent;
      top: 9px;
      left: -25px;
      width: 19px;
      height: 16px;
      background-image: url("/upload/success_story/mriya/list_check_icon.svg");
    }
  }
}

.purchases-main {
  position: relative;
  z-index: 10;
}

.purchases-main--b2b {
  margin-bottom: 36px;
}

.purchases-foo {
  position: relative;
  z-index: 0;
  margin-left: 58px;
  margin-right: 58px;
  padding: 42px 47px;
  padding-bottom: 62px;
  background-color: #ebe7dd;

  &__title {
    font-size: 30px;
    line-height: 46px;
    margin-bottom: 19px;
  }

  &__results-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid #636363;
    padding-bottom: 45px;
    margin-bottom: 7px;
    @media (min-width: 1400px) {
      width: 85%;
    }
  }

  &__results-item {
    width: 25%;
    margin-right: 62px;
  }

  &__results-item:last-of-type {
    width: 32%;
    margin-right: 0;
  }

  &__results-number {
    font-size: 70px;
    line-height: 82px;
    color: #edc14c;
    margin-bottom: 3px;
  }

  &__results-number span {
    font-size: 36px;
    line-height: 42px;
    color: inherit;
    margin-left: -15px;
  }

  &__results-text {
    font-size: 18px;
    line-height: 26px;
    margin: 0;
  }

  &__subscription {
    font-size: 14px;
    line-height: 30px;
    color: #636363;
  }
}

.purchases-foo--contracts {
  .purchases-foo__results-list {
    width: 93%;
  }

  .purchases-foo__results-item:last-of-type {
    width: 30%;
  }
}

.purchases-foo--b2b {
  padding: 26px 47px;
  padding-bottom: 44px;

  .purchases-foo__title {
    margin-bottom: 13px;
  }

  .purchases-foo__results-list {
    width: 100%;
    @media (min-width: 1400px) {
      width: 92%;
    }
  }

  .purchases-foo__results-item {
    width: 25%;
  }


  .purchases-foo__results-item:not(:last-of-type) {
    width: 26%;
    margin-right: 79px;
    @media (min-width: 1400px) {
      margin-right: 89px;
    }
  }

  .purchases-foo__results-item:nth-of-type(2) {
    width: 26%;
  }
}

.purchases-foo--travel {
  padding: 29px 47px;
  padding-bottom: 44px;

  .purchases-foo__title {
    margin-bottom: 13px;
  }

  .purchases-foo__results-list {
    width: 98%;
  }

  .purchases-foo__results-item:not(:last-of-type) {
    width: 25.5%;
    margin-right: 77px;
    @media (min-width: 1400px) {
      margin-right: 89px;
    }
  }

  .purchases-foo__results-item:nth-of-type(2) {
    width: 25%;
  }

  .purchases-foo__results-item:last-of-type {
    width: 29%;
  }
}

.purchases-foo.dark {
  background-color: #566373;
}

.purchases-foo.dark .purchases-foo__title {
  color: #ffffff;
}

.purchases-foo.dark .purchases-foo__results-text {
  color: #FFFFFF;
}

.purchases-foo.dark .purchases-foo__results-list {
  border-bottom: 1px solid #dee2e6;
}

.purchases-foo.dark .purchases-foo__subscription {
  color: #dee2e6;
}

.purchases-foo.blue-silver {
  background-color: #e9ebef;
}

.case-step {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 15px;

  &__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-position: 43% 27%;
    background-repeat: no-repeat;
    background-size: 19%;
    border-bottom: 2px solid white;
    padding-top: 34px;
    padding-bottom: 36px;
  }

  &__item:last-of-type {
    border: none;
  }

  .bg-item-one {
    background-image: url("/upload/ill_1/png5d31b3d10cdb24/05731168/ill_1.png");
  }

  .bg-item-two {
    background-image: url("/upload/ill_2/png5d31b5435e96d5/25629642/ill_2.png");
  }

  .bg-item-three {
    background-image: url("/upload/ill_3/png5d31b55ca31083/22119947/ill_3.png");
    background-position-y: 19%;
  }

  .bg-item-four {
    background-image: url("/upload/ill_4/png5d31b5677a7482/96799724/ill_4.png");
    background-position-y: 22%;
  }

  .bg-item-five {
    background-image: url("/upload/obninsk_ss/pics/Group 1878_2.svg");
  }

  .bg-item-six {
    background-image: url("/upload/obninsk_ss/pics/Group 1879_2.svg");
  }

  .bg-item-seven {
    background-image: url("/upload/obninsk_ss/pics/Group 1880_2.svg");
  }

  .bg-item-eight {
    background-image: url("/upload/obninsk_ss/pics/Group 1881_2.svg");
  }

  .bg-item-nine {
    background-image: url("/upload/obninsk_ss/pics/Group 1882_2.svg");
  }

  .bg-item-ten {
    background-image: url("/upload/obninsk_ss/pics/Group 1883_2.svg");
  }

  .bg-item-eleven {
    background-image: url("/upload/obninsk_ss/pics/Group 1884_2.svg");
  }

  .bg-item-twelve {
    background-image: url("/upload/projects/icon_problem_1.svg");
  }

  .bg-item-thirteen {
    background-image: url("/upload/projects/icon_problem_2.svg");
  }

  .bg-item-fourteen {
    background-image: url("/upload/projects/icon_problem_3.svg");
  }

  .bg-item-fifteen {
    background-image: url("/upload/projects/icon_problem_4.svg");
  }

  .bg-item-sixteen {
    background-image: url("/upload/projects/icon_problem_5.svg");
  }

  &__wrap-item {
    padding-top: 5px;
    width: 32%;
  }

  &__title {
    font-size: 20px;
    line-height: 26px;
    color: #048ad3;
    margin-bottom: 12px;
    @media (min-width: 1400px) {
      font-size: 24px;
    }
  }

  &__title-black {
    font-size: 20px;
    line-height: 26px;
    color: #272727;
    margin-bottom: 12px;
    @media (min-width: 1400px) {
      font-size: 24px;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 1.5;
    @media (min-width: 1400px) {
      font-size: 18px;
      line-height: 30px;
    }
  }

  &__wrap-text {
    position: relative;
    width: 42%;
    box-sizing: border-box;
    @media (min-width: 1400px) {
      padding-right: 6%;
      width: 44.5%;
    }
  }
}

.elma-project--problems {
  .case-step__item {
    padding-bottom: 15px;
    padding-top: 15px;
    border: none;
  }

  .case-step__wrap-item {
    width: 34%;
  }

  .case-step__wrap-text {
    width: 44.5%;
    padding-right: 0;
  }
}

@media (max-width: $mobile-view) {
  .purchases {
    margin-bottom: 25px;

    &__wrap {
      width: 100%;
    }
  }

  .purchases-head {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-right: 16px;
    padding-left: 16px;

    &__left-column,
    &__right-column {
      width: 100%;
      box-sizing: border-box;
    }

    &__left-column {
      margin-bottom: 35px;
    }

    &__right-column {
      padding-left: 0;
    }

    & &__title {
      font-size: 18px;
      line-height: 22px;
    }

    & &__description {
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .purchases-head--pay {
    .three-columns__item:last-of-type {
      width: 100%!important;
    }
  }

  .purchases-head .three-columns__item  {
    width: 100%;
  }

  .purchases-head--mriya {
    background-image: none;

    .s-story__list {
      width: 100%;
    }
  }

  .purchases-head--mriya-icons {
    .case-step__item {
      background-size: 39%;
      background-position: 20% 53%;
    }
  }

  .purchases-head--bpms {
    .case-bpms-nlp {
      &__title {
        font-size: 20px;
        line-height: 30px;
        width: 100%;
      }

      &__description {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
      }

      &__fill {

        padding: 16px;
      }

      &__wrap-list-orange li {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
      }

      &__wrap-list li {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .purchases-head--mriya {
    .change-easy {
      display: block;
      margin-bottom: 20px;
      position: static;
      transform: rotate(-5.36deg);
    }
  }

  .purchases-foo {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 16px;
    padding-right: 16px;

    &__results-item {
      width: 100%;
      display: flex;
      margin-right: 0;
    }

    &__results-item:nth-of-type(2) .purchases-foo__results-number  {
      padding-right: 94px;
    }

    &__results-item:last-of-type {
      width: 100%;
    }

    &__title {
      font-size: 20px;
      line-height: 26px;
    }

    &__results-list {
      width: 100%;
    }

    &__results-item:not(:last-of-type) {
      margin-bottom: 40px;
      align-items: center;
    }

    &__results-number {
      position: relative;
      padding-right: 50px;
      font-size: 60px;
      line-height: 82px;
    }

    &__results-number span {
      position: absolute;
      top: 30px;
      right: 15px;
    }

    &__results-text {
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }

    &__subscription {
      font-size: 12px;
    }
  }

  .purchases-foo--contracts {
    .purchases-foo {
      &__results-item:last-of-type {
        width: 100%;
      }

      &__results-item:last-of-type .purchases-foo__results-number {
        padding-right: 90px;
      }
    }
  }

  .purchases-foo--b2b {
    .purchases-foo__results-item:not(:last-of-type) {
      width: 100%;
      margin-right: 0
    }

    .purchases-foo__results-item:nth-of-type(2) {
      width: 100%;
    }
  }

  .case-step {
    &__item {
      background-size: 51%;
      background-position: 20% 42%;
    }

    &__wrap-item {
      width: 100%;
      margin-bottom: 185px;
    }

    &__wrap-text {
      width: 100%;
    }

    &__title {
      font-size: 20px;
      line-height: 26px;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
    }

    .bg-item-one {
      background-image: url("/upload/1/png5d412bad663124/70189381/1.png");
    }

    .bg-item-two {
      background-image: url("/upload/2/png5d412bbfbdc024/60559366/2.png");
      background-position-y: 52%;
    }

    .bg-item-three {
      background-image: url("/upload/3/png5d412bcbbc5ea1/60649329/3.png");
      background-position-y: 57%;
    }

    .bg-item-four {
      background-image: url("/upload/4/png5d412bd690ec77/96603657/4.png");
      background-position-y: 48%;
    }

    .bg-item-five {
      background-image: url("/upload/obninsk_ss/pics/mobile_1.svg");
    }

    .bg-item-six {
      background-image: url("/upload/obninsk_ss/pics/mobile_2.svg");
    }

    .bg-item-seven {
      background-image: url("/upload/obninsk_ss/pics/mobile_3.svg");
    }

    .bg-item-eight {
      background-image: url("/upload/obninsk_ss/pics/mobile_4.svg");
    }

    .bg-item-nine {
      background-image: url("/upload/obninsk_ss/pics/mobile_5.svg");
    }

    .bg-item-ten {
      background-image: url("/upload/obninsk_ss/pics/mobile_6.svg");
    }

    .bg-item-eleven {
      background-image: url("/upload/obninsk_ss/pics/mobile_7.svg");
    }

    .bg-item-twelve {
      background-image: none;
    }

    .bg-item-thirteen {
      background-image: none;
    }

    .bg-item-fourteen {
      background-image: none;
    }

    .bg-item-fifteen {
      background-image: none;
    }

    .bg-item-sixteen {
      background-image: none;
    }
  }

  .purchases-foo--travel {
    .purchases-foo__results-list {
      width: 100%;
    }

    .purchases-foo__results-item:not(:last-of-type) {
      width: 1000%;
      margin-right: 0;
    }

    .purchases-foo__results-item:nth-of-type(2) {
      width: 100%;
    }

    .purchases-foo__results-item:last-of-type {
      width: 100%;
    }
  }

  .elma-project--problems {
    .case-step__item {
      padding-bottom: 0;
    }

    .case-step__wrap-item {
      width: 100%;
      margin-bottom: 0;
    }

    .case-step__wrap-text {
      width: 100%;
    }
  }
}

