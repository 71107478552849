.products-background__general-transform-cover {
  background-image: url('/img/products/circle.png');
  background-size: 72%;
  background-repeat: no-repeat;
  background-position: 55% 58%
}

.products {
  &__title {
    padding-left: 35px;
  }

  &__description {
    padding-left: 35px;
    padding-bottom: 32px;
    margin-bottom: 11px;
  }

  &__button-primary {
    margin-left: 35px;
    margin-bottom: 32px;
    padding: 12px 35px;
  }

}

.version-elma {
  margin-bottom: 40px;
}

.version-elma .version-elma__title {
  margin-bottom: 14px;
}

.version-elma__description {
  max-width: 761px;
  margin-bottom: 14px;
}

.version-elma__description:last-of-type {
  margin-bottom: 36px;
}

.version-elma__list {
  max-width: 1290px;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: -30px;
  margin-right: -30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.version-elma__item {
  width: 330px;
  min-height: 703px;
  padding: 27px 29px;
  padding-bottom: 47px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  @media (min-width: 1400px) {
    width: 410px;
  }
}

.version-elma__item:not(:first-of-type) {
  color: white;
}

.version-elma__item--white {
  background-color: #ffffff;
}

.version-elma__item--dodgerblue {
  background-color: rgba(53, 98, 134, 0.9);
}

.version-elma__item--blue {
  background-color: #35627c;
}

.version-elma__subtitle {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 18px;
}

.version-elma__content {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}

.version-elma__content:first-of-type {
  margin-bottom: 25px;
}

.version-elma__content:last-of-type {
  margin-top: auto;
  margin-bottom: 25px;
}

.version-elma__wrap-link {
  margin-top: auto;
}

.version-elma__wrap-link .version-elma__link {
  padding: 8px 0;
  background-color: #048ad3;
  color: white;
  font-size: 16px;
  line-height: 30px;
  font-weight: bold;
  width: 216px;
  display: inline-block;
  text-align: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.version-elma__wrap-link .version-elma__link:hover {
  color: white;
  background-color: #007abc;
}


@media (max-width: $mobile-view) {
  .products__title {
    padding-left: 16px;
  }

  .products__description {
    padding-left: 16px;
    padding-bottom: 12px;
    margin-bottom: 0;
  }

  .products__button-primary {
    margin-left: 16px;
  }

  .products-background__general-transform-cover {
    background-size: 187%;
    background-position: 49% 58%;
  }

  .products-background__bpm-transform {
    margin-left: -181px;
    margin-top: -650px;
  }

  .version-elma__item {
    width: 100%;
  }
}
