.carousel {
  height: 350px;
  margin-bottom: 85px;
  margin-top: 28px;
}

.carousel .carousel-inner {
  height: 100%;
}

.carousel-item img {
  margin: 0 auto;
}

.carousel-indicators {
  bottom: -35px;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #cccccc;
  border-radius: 50%;

}

.carousel-indicators li:hover {
  background-color: #f0523e;
}

.carousel-indicators .active {
  background-color: #048ad3;
}

.carousel-features .carousel-item {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.carousel-features .carousel-item.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.carousel--main {
  height: auto;
  margin-left: -35px;
  margin-right: -35px;
  margin-bottom: 45px;

  & .carousel-item {
    padding: 0 35px;
  }

  & .carousel-indicators {
    bottom: 10px;
  }

  & .carousel-indicators li {
    background-color: transparent;
    border: 1px solid white;
  }

  & .carousel-indicators li:hover {
    background-color: white;
  }

  & .carousel-indicators li.active {
    background-color: white;
  }
}

@media (max-width: $mobile-view) {
  .carousel--main {
    margin-left: -16px;
    margin-right: -16px;

    & .carousel-item {
      padding: 0 16px;
    }
  }
}
