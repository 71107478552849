.s-story-quote-c {
  padding: 0 16px;

  &__wrap {
    background-color: #e9ebef;
    background-image: url('/upload/DSC07062_5/png5d7a347de407e5/82303181/DSC07062_5.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 179%;
    padding-top: 66vw;
    padding-bottom: 24px;
  }

  &__wrap-text {
    padding: 34px 28px;
  }

  &__wrap-text p {
    font-size: 16px;
    line-height: 24px;
  }

  &__wrap-author {
    order: 2;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 20px;
  }

  &__position {
    padding-right: 17px;
  }

  &__wrap-author img {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  }

  &__author-description b {
    font-size: 14px;
    line-height: 22px;
    display: block;
  }

  &__author-description p {
    font-size: 14px;
    line-height: 22px;
    display: block;
    margin-bottom: 0;
  }
}

.s-story-quote-c--sadco {
  .s-story-quote-c {
    &__wrap {
      background-image: url('/upload/success_story/sadco/quote_c_mob.png');
      background-size: 100%;
    }
  }
}

.s-story-quote-c--obninsk {
  margin-top: 50px;
  margin-bottom: 50px;
  .s-story-quote-c {
    &__wrap {
      background-image: url('/upload/obninsk_ss/final_quote_mobile.png');
      background-size: 100%;
    }
  }
}

.s-story-quote-c--mriya {
  .s-story-quote-c {
    &__wrap {
      padding-bottom: 0;
      padding-top: 150px;
      background-color: #D8DDE7;;
      background-image: url('/upload/success_story/mriya/quote_img.png');
      background-size: 100%;
      background-position: left bottom;

      &-text p {
        font-size: 18px;
        line-height: 30px;
      }

      &-text p:nth-child(1) {
        position: relative;
        top: -40px;
      }

      &-author {
        position: relative;
        top: -30px;
      }
    }
  }
}

@media (min-width: $desktop-view) {
  .s-story-quote-c {
    padding: 0;

    &__wrap {
      margin-left: -20px;
      margin-right: -20px;
      background-size: cover;
      background-position: center;
      padding-top: 87px;
      padding-bottom: 173px;
    }

    &__wrap-text {
      width: 49.3%;
      padding: 0;
      padding-right: 7%;
      margin-left: auto;
    }

    &__wrap-text p {
      font-size: 20px;
      line-height: 35px;
      margin-bottom: 11px;
    }

    &__wrap-author {
      justify-content: flex-start;
    }

    &__position {
      padding-right: 0;
    }

    &__wrap-author img {
      display: none;
    }

    &__author-description b {
      font-size: 16px;
      line-height: 26px;
    }

    &__author-description p {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .s-story-quote-c--sadco {
    .s-story-quote-c {
      &__wrap {
        background-image: url('/upload/success_story/sadco/quote_c.jpg');
        background-size: cover;
        padding-top: 54px;
        padding-bottom: 101px;
      }

      &__wrap-text {
        width: 43.3%;
        padding: 0;
        margin-left: 67px;
      }

      &__wrap-text p {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  .s-story-quote-c--obninsk {
    .s-story-quote-c {
      &__wrap {
        background-image: url('/upload/obninsk_ss/final_quote_web.png');
        background-size: cover;
        padding-top: 54px;
        padding-bottom: 101px;
      }

      &__wrap-text {
        width: 43.3%;
        padding: 0;
        margin-left: 67px;
      }

      &__wrap-text p {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}


@media (min-width: 1400px) {
  .s-story-quote-c {
    &__wrap-text {
      padding-right: 13%;
    }
  }
}