.personal-cabinet__layer {
    position: relative;
    background-color: white;
    max-width: 840px;
    margin: 0;
    margin-right: 30px;
    padding: 34px 27px;
    padding-left: 35px;
}

.personal-cabinet {
  padding-top: 24px;

  &__wrap-head {
    position: relative;
    margin-bottom: 34px;
  }

  &__wrap-head::before {
    position: absolute;
    content: "";
    top: -34px;
    left: -35px;
    right: -27px;
    bottom: 0;
    z-index: 0;
  }

  &__wrap-head--pale-gray::before {
    background-color: #ecf4f8;
  }

  &__wrap-head--off-white::before {
    background-color: #fdf9e2;
  }

  &__wrap-head--ligh-green::before {
    background-color: #e4ffe2;
  }

  &__wrap-head--primary::before {
    background-color: #f5f5f5;
  }

  &__title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 22px;
  }

  &__title-h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
  }

  &__title-h3 {
    font-size: 24px;
    line-height: 34px;
  }

  &__subtitle {
    margin-bottom: 10px;
  }

  &__new-version {
    font-size: 36px;
    line-height: 27px;
    color: #048ad3;
    margin-bottom: 35px;
  }

  &__wrap-link {
    margin-bottom: 16px;
  }

  &__icon-download {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 12px;
    background-image: url('/upload/icon-download/png5c9360bd271b22/58250174/icon-download.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__icon-link {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 12px;
    background-image: url('/upload/icon-link/png5c93602f4ee5e8/52697372/icon-link.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__manager-info-wrap {
    margin-bottom: 25px;
  }

  &__manager-info-wrap p {
    margin: 0;
  }

  & .news__title {
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  & .news__list {
    width: 100%;
    box-sizing: border-box;
  }

  &__activation-item {
    padding: 30px 35px;
    background-color: #f5f5f5;
    margin-bottom: 16px;
  }

  &__button {
    padding: 12px 24px;
  }

  &__module-item {
    position: relative;
    padding-left: 22px;
    margin-bottom: 8px;
  }

  &__module-item::before {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    background-color: #c1c1c1;
    bottom: 50%;
    margin-bottom: -6px;
    left: 0;
  }

  li a {
    display: inline-block;
    line-height: 1.4;
    margin-bottom: .3rem;
  }

}
