.try-popup {

  &__hide-thx-text {
    display: none;
  }

  &__close {
    top: 57px;
    right: 57px;
    z-index: 12;

    &::before, &::after {
      @media (min-width: 1024px) {
        background-color: #FFFFFF;
      }

      height: 1px;
    }
  }

  &__tab {
    display: none;
  }

  &__info {
    display: none;
    height: 100%;
  }

  &__thx {
    display: none;
  }

  &__main {
    &_thx {
      .try-popup__forms-wrap {
        display: none;
      }
      .try-popup__thx {
        display: block;
      }
    }
  }
}
