.s-story-quote-b {
  &__wrap {
    margin-bottom: 18px;
  }

  &__wrap-content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 29px 36px;
    padding-bottom: 40px;
  }

  &__wrap-content::before,
  &__wrap-content::after {
    position: absolute;
    content: '';
    height: 1px;
    right: 16px;
    left: 16px;
    background-color: #d3d9db;
  }

  &__wrap-content::before {
    top: 0;
  }

  &__wrap-content::after {
    bottom: 0;
  }

  &__quote {
    padding-left: 8px;
    order: 1;
  }

  &__quote p {
    font-size: 16px;
    line-height: 24px;
  }

  &__quote p b {
    font-weight: 400;
  }

  &__wrap-author {
    order: 2;
    display: flex;
    align-items: flex-start;
    padding-top: 24px;
  }

  &__position {
    padding-right: 17px;
  }

  &__wrap-author img {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  }

  &__author-description b {
    font-size: 14px;
    line-height: 22px;
    display: block;
  }

  &__author-description p {
    font-size: 14px;
    line-height: 22px;
    display: block;
    margin-bottom: 0;
  }

  &__wrap-list {
    padding: 38px 43px;
  }
}

.s-story-quote-b--top {
  .s-story-quote-b {
    &__wrap-content::before {
      display: none;
    }
  }
}

@media (min-width: $desktop-view) {
  .s-story-quote-b {
    &__wrap {
      display: flex;
      justify-content: space-between;
      margin-bottom: 118px;
    }

    &__wrap-content {
      width: 59.5%;
      padding: 40px 0;
      padding-bottom: 77px;
      padding-left: 40px;
    }

    &__quote {
      order: 2;
      padding-right: 10%;
    }

    &__wrap-author {
      order: 1;
      padding-bottom: 59px;
      padding-left: 6px;
    }

    &__wrap-list {
      width: 32%;
      padding-top: 51px;
      padding-right: 8%;
      padding-left: 0;
    }

    &__quote p {
      font-size: 20px;
      line-height: 35px;
      padding-left: 0;
    }

    &__quote p b {
      font-weight: bold;
    }

    &__position {
      padding-right: 30px;
      flex-shrink: 0;
    }

    &__wrap-author img {
      width: 140px;
      height: 140px;
    }

    &__author-description {
      padding-top: 47px;
    }

    &__author-description b {
      font-size: 16px;
      line-height: 26px;
    }

    &__author-description p {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 0;
      padding-right: 30%;
    }

    &__wrap-list .s-story__target-item {
      margin-bottom: 7px;
    }

    &__wrap-list .s-story__target-text {
      font-size: 16px;
      line-height: 26px;
    }

    &__wrap-list .s-story__target-mark {
      margin-bottom: -5px;
    }
  }

  .s-story-quote-b--sadco {
    .s-story-quote-b {
      &__wrap-content {
        width: 100%;
      }
    }
  }

  .s-story-quote-b--right {
    .s-story-quote-b {
      &__wrap-content {
        padding-left: 313px;
      }
    }
  }
}


@media (min-width: 1400px) {
  .s-story-quote-b {
    &__position {
      padding-right: 60px;
    }
    &__wrap-content {
      padding-left: 107px;
    }
  }
}