.error-404 {
  &__title {
    position: relative;
    display: block;
    width: 394px;
    margin: 0 auto;
    padding-top: 23px;
    font-size: 188px;
    line-height: 228px;
    color: white;
    font-weight: bold;
    text-align: center;
    text-shadow: 0 0 44px rgba(0, 0, 0, 0.1);
  }

  &__title-ups {
    position: absolute;
    display: inline-block;
    top: 18px;
    right: -62px;
    padding: 6px 14px;
    background-color: white;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    color: #c5c5c5;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 44px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 44px 1px rgba(0, 0, 0, 0.1);
  }

  &__title-ups::before {
    position: absolute;
    content: '';
    bottom: -17px;
    right: 10px;
    border: 10px solid white;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;

  }

  &__description {
    text-align: left;
    margin-bottom: 25px;
  }
  &__description--no-search {
    font-size: 40px;
    line-height: 52px;
    color: white;
    font-weight: bold;
    text-align: center;
    text-shadow: 0 0 44px rgba(0, 0, 0, 0.17);
    margin-top: -30px;
    margin-bottom: 50px;
  }

  &__link-item {
    margin-bottom: 8px;
  }
}

@media (max-width: $mobile-view) {
  .error-404 {
    &__title {
      width: auto;
      display: inline-block;
      font-size: 131px;
      line-height: 189px;
      margin: 0;
      text-align: left;
    }

    &__title-ups {
      right: -10px;
    }

    &__description {
      text-align: left;
      font-size: 26px;
      line-height: 34px;
      margin-bottom: 17px;
    }
  }
}
