.bpm {
  &__title {
    padding-left: 35px;
  }

  &__description {
    padding-left: 35px;
  }

  &__button-primary {
    margin-left: 35px;
    margin-bottom: 32px;
    padding: 12px 35px;
  }
}

.bpm-system__description {
  max-width: 663px;
}

.work-elma-bpm {
  margin-top: 80px;
}

.bpm-circle__wrap {
  margin-bottom: 118px;
  max-width: 1112px;
  margin-left: 38px;
  min-height: 345px;
  margin-top: 31px;
}

.work-elma-bpm__wrap-text {
  max-width: 805px;
}

.bpm-circle__link {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -92px;
  margin-top: -17px;
  width: 109px;
  text-align: center;
}

.business-process {
  max-width: 761px;
}

.business-process__title {
  margin-right: 50px;
  margin-bottom: 15px;
}

.elma-bpm-combination {
  background-image: url('/img/bpm/bpm-combination.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 1290px;
  min-height: 723px;
  margin-left: -35px;
  margin-right: -35px;
  margin-bottom: 75px;
  padding: 55px 67px;

  &__text-wrap {
    max-width: 562px;
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    color: white;
    font-weight: 400;
    margin-bottom: 14px;
  }

  &__description {
    font-size: 16px;
    line-height: 26px;
    color: white;
    font-weight: 400;
  }

  &__low-code {
    margin-bottom: 71px;
  }

  &__low-code &__description {
    max-width: 491px;
  }
}

.bpm-through {
  margin-bottom: 50px;

  &__text-wrap {
    width: 557px;
    @media (min-width: 1400px) {
      width: 707px;
    }
  }

  &__text-wrap .bpm-through__title {
    margin-bottom: 16px;
  }

  &__webinar-wrap {
    width: 380px;
    padding-top: 5px;
  }

  &__webinar-wrap iframe {
    margin-bottom: 9px;
  }
}

.products-background__bpm-transform-cover {
  background-image: url('/img/products/circle.png');
  background-size: 72%;
  background-repeat: no-repeat;
  background-position: 50% 58%
}

.bpm__transform {
  margin-bottom: 80px;
}

.bpm__container-transform {
  width: 1150px;
  margin: 0 auto;
  padding: 0;
  padding-bottom: 60px;
}

.bpm__container-transform--fill {
  width: 1145px;
  margin: 0 auto;
  padding: 0;
}

.bpm__container-transform--wrap {
  width: 1290px;
  margin-left: -35px;
  margin-bottom: 60px;
  padding-top: 40px;
  padding-bottom: 60px;
}

.bpm-benefits__wrap-text {
  max-width: 620px;
}

.bpm-benefits {
  min-height: 928px;
  margin-bottom: 35px;
}

.bpm-benefits__container {
  margin-bottom: 22px;
}

.products-background__transform {
  top: 15%;
  left: 50%;
  width: 1200px;
  height: 919px;
  margin-left: -600px;
  z-index: -1;
}

.what-bpm__container {
  width: 96%;
  margin: auto;
}

@media (max-width: $mobile-view) {
  .bpm {
    &__title {
      padding-left: 16px;
    }
  }

  .bpm__description {
    padding-left: 16px;
  }

  .bpm__button-primary {
    margin-left: 16px;
  }

  .bpm-circle__wrap {
    margin-left: 20px;
    margin-bottom: 0;
  }

  .elma-bpm-combination {
    margin-right: -16px;
    margin-left: -16px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 36px;
  }

  .bpm-benefits {
    background-position: 58px 1428px;
  }

  .bpm-circle__link {
    position: relative;
    display: inline-block;
    width: 202px;
    left: 0;
    top: 0;
    text-align: left;
    margin: 0;
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .bpm-circle__wrap {
    margin-bottom: 58px;
  }

  .bpm__description {
    padding-left: 16px;
  }

  .bpm__button-primary {
    margin-left: 16px;
  }
}
