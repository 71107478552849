.prices {
  padding-top: 25px;

  & &__title {
    margin-bottom: 22px;
  }

  &__description {
    font-size: 16px;
    line-height: 26px;
    margin-top: 22px;
    margin-bottom: 34px;
    width: 764px;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: -35px;
    margin-right: -35px;
    margin-bottom: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &__item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 334px;
    min-height: 840px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;
    padding-left: 35px;
    padding-right: 31px;
    padding-bottom: 44px;
    padding-top: 161px;
    -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    @media (min-width: 1400px) {
      width: 404px;
    }
  }

  &__item--white {
    background-image: url("/img/index/home_Community_Edition.jpg");
    background-color: white;
  }

  &__item--dodgerblue {
    background-image: url("/img/products/products_internal_portal.jpg");
    background-color: white;
  }

  &__item--blue {
    background-image: url("/img/enterprise.jpg");
    background-color: #004163;
    color: white;
  }

  &__subtitle {
    display: inline-block;
    font-size: 20px;
    padding: 7px 35px;
    padding-right: 14px;
    margin-left: -35px;
    margin-bottom: 25px;
    color: white;
    background: rgba(0, 0, 0, 0.8);
    @media (min-width: 1400px) {
      font-size: 24px;
    }
  }

  &__free {
    position: absolute;
    font-size: 16px;
    line-height: 22px;
    left: 24px;
    top: 20px;
    background-color: #ffcc00;
    color: black;
    padding: 0 10px;
  }

  &__content {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  &__content--silver {
    background-color: #f5f5f5;
    min-height: 104px;
    margin-left: -35px;
    margin-right: -31px;
    padding-left: 35px;
    padding-right: 30px;
    padding-top: 14px;
    padding-bottom: 18px;
    margin-bottom: 15px;
  }

  &__content--blue {
    background-color: #084f74;
    margin-left: -35px;
    margin-right: -31px;
    padding-left: 35px;
    padding-right: 30px;
    padding-top: 14px;
    padding-bottom: 18px;
    margin-bottom: 15px;
  }

  &__wrap-link .prices__link {
    padding: 8px 0;
    background-color: #048ad3;
    color: white;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    width: 100%;
    display: inline-block;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  &__wrap-link {
    margin-top: auto;
    width: 100%;
  }

  &__wrap-link .prices__link:hover {
    color: white;
    background-color: #007abc;
  }
}
