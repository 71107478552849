.about-company {
  padding-top: 25px;
  margin-bottom: 109px;

  & &__title {
    margin-bottom: 23px;
  }

  &__slogan-wrap {
    min-height: 523px;
    padding: 62px 67px;
    margin-bottom: 50px;
    margin-top: 23px;
    margin-left: -35px;
    margin-right: -35px;
    background-color: #566373;
    background-image: url('/img/about-company.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__slogan {
    font-size: 30px;
    line-height: 42px;
    color: white;
    width: 700px;
  }

  &__description {
    max-width: 807px;
    margin-bottom: 15px;
    letter-spacing: -0.07px;
  }

  &__description:last-of-type {
    margin-bottom: 76px;
  }

  &__info {
    width: 888px;
  }

  &__info-list {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &__info-item {
    width: 172px;
  }

  &__info-item:nth-of-type(2) {
    width: 310px;
  }

  &__info-item:nth-of-type(3) {
    width: 258px;
  }

  &__info-title {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: -3px;
  }

  &__info-description {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
  }
}

.our-clients,
.partners-elma {
  padding: 30px 0;
  padding-bottom: 96px;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
}

.our-clients {
  margin-bottom: -1px;
}

.our-clients .our-clients__title,
.partners-elma .partners-elma__title {
  margin-bottom: 13px;
}

.our-clients__description,
.partners-elma__description {
  width: 690px;
  margin-bottom: 34px;
}

.our-clients__clients-list,
.partners-elma__partners-list {
  list-style: none;
  padding: 0;
  margin-bottom: 49px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.our-clients__clients-item,
.partners-elma__partners-item {
  position: relative;
  width: 119px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 55px;
  margin-bottom: 20px;
}

.partners-elma__partners-item:not(:last-of-type) {
  width: 88px;
  margin-right: 84px;
}

.our-priorities {
  padding: 32px 0;
  margin-bottom: 14px;

  & &__title {
    margin-bottom: 0;
  }

  &__item {
    padding: 27px 0;
    padding-bottom: 78px;
    width: 832px;
    border-bottom: 1px solid #c4c4c4;
  }

  &__item:last-of-type {
    border-bottom: none;
  }

  &__description {
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 48px;
  }

  &__wrap-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &__wrap-img {
    width: 178px;
    height: 178px;
    -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  }

  &__wrap-img img {
    width: 100%;
    height: 100%;
  }

  &__quote {
    width: 622px;
    margin-bottom: 15px;
  }

  &__speaker {
    display: block;
    margin-top: 15px;
    margin-bottom: 0;
  }
}

.carer-in-elma {
  margin-left: -35px;
  margin-right: -35px;
  padding: 40px 67px;
  padding-bottom: 124px;
  margin-bottom: 74px;
  background-color: #566373;
  background-image: url('/img/assets/bg_chairs.jpg');
  background-repeat: no-repeat;
  background-position: 696px 16px;
  background-size: 40%;

  & &__title {
    margin-bottom: 12px;
    color: white;
  }

  &__description {
    width: 647px;
    margin-bottom: 25px;
    color: white;
  }

  &__info-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 643px;
    list-style: none;
    padding: 0;
    margin-bottom: 10px;
    color: white;
  }

  &__info-item {
    width: 195px;
  }

  &__info-item:nth-of-type(2) {
    width: 132px;
  }

  &__info-item:nth-of-type(3) {
    width: 192px;
  }

  &__info-title {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 2px;
  }

  &__info-item sup {
    font-size: 24px;
  }
}

@media (max-width: 420px) {
  .about-company {
    width: auto;
    margin-bottom: 36px;

    &__slogan-wrap {
      width: auto;
      padding: 35px 16px;
      margin-left: -16px;
      margin-right: -16px;
    }

    &__slogan {
      width: 100%;
    }

    &__info {
      width: auto;
    }

    &__info-item {
      width: 100%;
      margin-bottom: 15px;
    }

    &__description:last-of-type {
      margin-bottom: 25px;
    }
  }

  .our-clients {
    width: auto;
    padding-bottom: 36px;

    &__title,
    &__description {
      width: 100%;
    }

    &__clients-list {
      width: 100%;
    }

    &__clients-item {
      width: 150px;
      height: 82px;
      margin-right: 0;
    }
  }

  .partners-elma {
    width: auto;
    padding-bottom: 36px;

    &__title,
    &__description {
      width: 100%;
    }

    &__partners-list {
      width: 100%;
    }

    &__partners-item:not(:last-of-type) {
      width: 156px;
      height: 57px;
      margin-right: 0;
    }

    &__partners-item:last-of-type {
      width: 156px;
      height: 57px;
      margin-right: 0;
    }
  }

  .our-priorities {
    width: auto;

    &__title,
    &__description,
    &__item {
      width: 100%;
    }

    &__item {
      padding-bottom: 27px;
    }

    &__wrap-img {
      margin-bottom: 35px;
    }
  }

  .carer-in-elma {
    width: auto;
    margin-left: -16px;
    margin-right: -16px;
    padding: 35px 16px;

    &__title,
    &__description,
    &__info-list,
    &__info-item {
      width: 100%;
    }

    &__info-item:nth-of-type(2),
    &__info-item:nth-of-type(3) {
      width: 100%;
    }
  }
}
