.face-block{
  display: flex;
  align-items: start;
  &__photo{
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    &-wrap{
      flex-shrink: 0;
      border-radius: 50%;
      position: relative;
      display: block;
      overflow: hidden;
      width: 48px;
      margin-right: 15px;
      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }
  }
  &__content{
    font-size: 12px;

    @media (min-width: 576px) {
      font-size: 14px;
    }
  }
  &__name{
    font-style: normal;
    font-weight: 600;
  }
}