.news-detail {
  position: relative;
  background-color: white;
  max-width: 750px;
  -webkit-box-shadow: 0 0 64px 1px rgba(190, 190, 190, 0.5);
  box-shadow: 0 0 64px 1px rgba(190, 190, 190, 0.5);
  margin: 0;
  margin-right: 30px;
  padding: 34px 72px;
  padding-left: 35px;
  flex-shrink: 0;
  @media (min-width: 1400px) {
    max-width: 840px;
  }
}

.news-detail table {
  width: 100%;
}

.news-detail img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin-top: 28px;
  margin-bottom: 20px;
  @media (min-width: 1400px) {
    max-width: 733px;
  }
}

.news-detail h2 {
  padding: 0;
  margin-bottom: 16px;
  margin-top: 32px;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
}

.news-detail h1 {
  padding: 0;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: -1.2px;
  margin-bottom: 23px;
  font-weight: bold;
}

.news-detail h3 {
  padding: 0;
}

.news-detail .news-detail__subscription {
  padding: 0;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 45px;
}

.news-detail p {
  padding: 0;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;
}

.news-detail table {
  margin-bottom: 25px;
}

.news-detail table thead td {
  font-weight: bold;
}

.news-detail table td {
  padding: 4px 10px;
}

.news-detail__wrap-paragraph {
  margin-bottom: 32px;
}

.news-detail figure {
  padding: 0;
}

.news-detail figcaption {
  font-size: 14px;
  line-height: 22px;
  color: #636363;
  margin-bottom: 50px;
}

.news-detail__wrap-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
}

.news-detail__wrap-image figure {
  width: 48%;
}

.news-detail__wrap-image img {
  width: 100%;
}

.news-detail__next-read-wrap {
  padding: 0;
  margin-bottom: 19px;
  margin-top: 67px;
}

.news-detail__next-read {
  display: block;
  font-size: 16px;
  margin-bottom: 3px;
}

.news-detail-features {
  padding-top: 353px;
  background-image: url('/img/mask_group_20.jpg');
  background-repeat: no-repeat;
}

.news-detail-features .news-detail-features__main-title {
  margin-left: -35px;
  padding-left: 35px;
  padding-bottom: 6px;
  color: white;
  background-color: rgba(20, 29, 45, 0.6);
  display: inline-block;
  margin-bottom: 52px;
  max-width: 617px;
}

.function {
  margin: 0;
  padding: 0;
}

.news-detail-features .news-detail-features__title {
  margin-left: -35px;
  padding-left: 35px;
  padding-right: 12px;
  padding-bottom: 2px;
  color: white;
  background-color: rgba(20, 29, 45, 0.6);
  display: inline-block;
}

.news-detail-features .news-detail-features__subtitle {
  font-size: 24px;
  line-height: 32px;
}

.news-detail-features .news-detail-features__subtitle-level-four {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.news-detail-features .news-detail-features__wrap-img {
  margin-bottom: 48px;
}

.news-detail-nav {
  margin-left: -12px;
  position: -webkit-sticky;
  position: sticky;
  top: 8px;
  height: 600px;
}

.news-detail-nav .news-detail-nav__item {
  margin-bottom: 15px;
}

.news-detail-nav .news-detail-nav__main-link {
  position: relative;
  font-weight: bold;
  padding: 5px 12px;
  cursor: pointer;
  display: inline-block;
}

.news-detail-nav .news-detail-nav__main-link-active {
  color: black;
  background-color: white;
}

.collapsed.news-detail-nav__main-link::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.collapsed.news-detail-nav__main-link::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.news-detail-nav .news-detail-nav__main-link-active {
  color: black;
  background-color: white;
}

.news-detail-nav__main-link::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 2px;
  top: 17px;
  right: -4px;
  background-color: #048ad3;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: .1s;
  -o-transition: .1s;
  transition: .1s;
}

.news-detail-nav__main-link::after {
  position: absolute;
  content: "";
  width: 8px;
  height: 2px;
  top: 17px;
  right: -9px;
  background-color: #048ad3;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: .1s;
  -o-transition: .1s;
  transition: .1s;
}

.news-detail-nav__secondary-link {
  padding: 5px 12px;
}

.regular__wrap {
  position: relative;
  max-width: 1290px;
  margin-left: -35px;
}

.regular__wrap .menu a:hover {
  border: none;
  color: #007abc;
}

.regular-wrap__button-up {
  display: block;
  bottom: 15%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  z-index: 100;
  -webkit-box-shadow: 0 0 24px 1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 24px 1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  opacity: 0;
}

.regular-wrap__button-up::before {
  content: "";
  position: absolute;
  top: 17px;
  right: 11px;
  width: 11px;
  height: 2px;
  background: silver;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.regular-wrap__button-up::after {
  content: "";
  position: absolute;
  top: 17px;
  left: 11px;
  width: 11px;
  height: 2px;
  background: silver;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.regular-wrap__button-up--show {
  opacity: 1;
}

.regular-wrap__button-up--fixed {
  position: fixed;
}

.regular-wrap__button-up--absolute {
  position: absolute;
  bottom: 0;
}

.regular-container__article {
  position: relative;
}

.regular-container__fill {
  position: absolute;
  top: 2px;
  right: -388px;
  width: 275px;
  min-height: 160px;
  @media (min-width: 1400px) {
    right: -475px;
    width: 365px;
  }
}

.regular-container__fill .h3 {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 11px;
}

.regular-container__fill img {
  margin: 0;
}

.regular-container__fill span {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.regular-container__fill a {
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
}

.regular-container__fill h3 {
  margin-bottom: 18px;
}

.news-detail .article__img h1 {
  background-color: rgba(20, 29, 45, 0.6);
  padding: 8px;
  display: inline-block;
  margin: 0;
  margin-bottom: 5px;
  margin-left: -8px;
}

.news-detail .article__img p {
  background-color: rgba(20, 29, 45, 0.6);
  padding: 8px;
  display: inline-block;
  margin: 0;
  margin-left: -8px;
}

@media (max-width: $mobile-view) {
  .regular__wrap {
    position: relative;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -16px;
    background-color: #F5F5F5;
  }

  .news-detail {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0;
    margin-right: 0;
    background-color: #F5F5F5;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
  }

  .news-detail img {
    width: 100%;
  }

  .regular-container__article {
    display: none;
  }

  .news-detail__wrap-image {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .news-detail__wrap-image figure {
    width: 100%;
  }

  .regular__wrap .menu {
    margin-left: 12px;
  }

  .call-to-action-small__wrap {
    margin-left: -16px;
    margin-right: -16px;
  }

  .call-to-action-small__wrap-image {
    margin-bottom: 15px;
  }
}

.call-to-action-small__wrap {
  margin-right: -72px;
}


.quote-avatar {
  width: 142px;
  height: 142px;
  border-radius: 50%;
  overflow: hidden;
  @media (min-width: 1400px) {
    width: 172px;
    height: 172px;
  }
}
