@import "./block/variables";

@mixin max-xs {
  @media screen and (max-width: $mobile-view) {
    @content;
  }
}

@mixin max-sm {
  @media screen and (max-width: $tablet-view - 1) {
    @content;
  }
}

@mixin max-md {
  @media screen and (max-width: $desktop-view - 1) {
    @content;
  }
}


@mixin xs {
  @media screen and (max-width: $mobile-view) {
    @content;
  }
}

@mixin min-xs {
  @media screen and (min-width: $mobile-view + 1) {
    @content;
  }
}

@mixin sm {
  @media screen and (max-width: $tablet-view - 1) {
    @content
  }
};

@mixin min-sm {
  @media screen and (min-width: $tablet-view) {
    @content
  }
};

@mixin between-md {
  @media screen and (max-width: $desktop-view - 1) {
    @content
  }
}

@mixin md {
  @media screen and (min-width: $tablet-view ) and (max-width: $lg-desktop-view - 1) {
    @content
  }
};
@mixin min-md {
  @media screen and (min-width: $tablet-view ) {
    @content
  }
};

@mixin md-lg {
  @media screen and (min-width: $tablet-view ) {
    @content
  }
};

@mixin lg {
  @media screen and (min-width: $lg-desktop-view) {
    @content
  }
}