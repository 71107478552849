@media (min-width: 1200px) {
  .page-footer {
    &__contacts-col {
      padding: 0;
    }
  }
}
.page-footer {
  max-width: 1010px;
  padding: 0 15px;
  margin: 0 auto;
  @media (min-width: 1400px) {
    max-width: 1250px;
  }

  &__separator {
    margin-top: 50px;
    width: 100%;
  }
  &__main {
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
    padding-top: 38px;
    padding-bottom: 55px;
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__title {
    margin-bottom: 34px;
  }
  &__links-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &__link-wrap {
    margin-bottom: 8px;
  }
  &__contacts-wrap {
    padding: 0 15px;
    margin-bottom: 0;
    list-style: none;
  }
  &__soc-wrap {
    display: flex;
    align-items: flex-start;
  }
  &__soc {
    margin-left: 8px;
    display: block;
    @media (min-width: 768px) {
      margin-right: 16px;
      margin-left: 0;
    }
    img {
      display: block;
    }
  }
  &__contacts-title {
    margin-bottom: 6px;
  }
  &__contacts-item {
    padding: 0;
    margin-bottom: 12px;
  }

  &__bottom {
    padding: 15px 0;
    font-size: 14px;
    &-n1 {
      max-width: 100%;
    }
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }

  &__mobile {

    border-bottom: 1px solid #EEEEEE;
    padding-bottom: 15px;

    @media (max-width: 575px) {
      font-size: 12px;
    }
    @media (min-width: 768px) {
      display: none;
    }
  }

  &__copyright-flex {
    @media (max-width: 767px) {
      padding-top: 10px;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #EEEEEE;
    }
  }
  &__copyright-wrap {
    @media (max-width: 767px) {
      order: 3;
    }
  }

  &__sitemap-link {
    padding-bottom: 13px;
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 18px;
    display: block;
    padding-right: 25px;
    color: #394149;
    line-height: 30px;
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 7px;
      background-image: url("../image/link-arr.svg");
      background-position: center right;
      background-repeat: no-repeat;
      right: 0;
      top: calc(50% - 3px);
    }
  }
  &__mobile-contact {
    margin-bottom: 5px;
  }

}
