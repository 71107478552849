$header-breakpoint-max: 1084px;
$header-breakpoint-min: 1085px;

$header-xs-height: 52px;
$header-padding: 24px 0;
$header-padding_xs: 12px 0;

$header-card-min-height: 248px;

$logo-width: 125px;
$logo-width_xs: 109px;
$logo-margin: 20px;

$nav-link-padding: 0 15px;
$nav-link-size: 16px;

$right-link-padding: 0 20px;
$right-link-padding-xs: 16px;


.header {
  width: 100%;
  background-color: #ffffff;
  z-index: 101;
  position: sticky;
  top: 0;
  padding: $header-padding_xs;
  transition: .1s;

  &.active {
    border-bottom: 1px solid #E1E1E1;
  }

  @media (max-width: $header-breakpoint-max) {
    height: $header-xs-height;
  }

  @media (min-width: $header-breakpoint-min) {
    padding: $header-padding;
  }

  &_open-search {
    .header__search-switcher {
      display: none;
    }
    .header__search-wrap {
      width: 100%;
      justify-content: flex-end;
    }
    .header__search {
      visibility: visible;height: auto;
      width: 100%;
    }
    .header__right {
      width: 100%;
    }
  }

  &__container {
    position: static;
    display: flex;
    justify-content: space-between;
    @media (min-width: $header-breakpoint-min) {
      position: relative;
    }
  }

  &__logo {
    max-width: $logo-width_xs;
    align-self: center;
    flex-shrink: 0;
    margin-right: $logo-margin;
    @media (min-width: $header-breakpoint-min) {
      max-width: 110px;
    }
    @media (min-width: 1400px) {
      max-width: $logo-width;
    }
  }

  @media (max-width: $header-breakpoint-max) {
    &__nav {
      position: absolute;
      left: 0;
      top: 100%;
      background-color: #fff;
      width: 100%;
      height: calc(100vh - 52px);
      overflow: auto;
      padding: 12px 20px;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: 0.3s ease-in-out;
    }
    &_opened &__nav {
      opacity: 1;
      visibility: visible;
      pointer-events: inherit;
    }
  }

  &__nav-list {
    margin: 0;
    height: 100%;
    flex-direction: column;
    @media (min-width: $header-breakpoint-min) {
      flex-direction: row;
    }
  }

  &__link-wrap {
    @media (min-width: $header-breakpoint-min) {
      padding: 0;
      display: flex;
      align-items: center;
    }
  }

  &__link {
    font-size: $nav-link-size;
    line-height: 1.5;
    color: #272727;
    border-bottom: 1px solid #EEEEEE;
    padding: 16px 0;
    width: 100%;
    display: block;
    @media (min-width: $header-breakpoint-min) {
      font-size: 14px;
      border: none;
      padding: 0 10px;
      width: auto;
      display: inline;
      position: relative;
      transition: 0.2s ease-in-out;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 2px;
        top: calc(50% - 2px);
        width: 5px;
        height: 5px;
        transform: rotate(45deg);
        pointer-events: none;
        transition: 0.2s ease-in-out;
        background-color: #0d4a75;
        opacity: 0;
      }
      &:hover {
        color: #0d4a75;
        &::before {
          opacity: 1;
        }
      }
    }

    @media (min-width: 1400px) {
      font-size: 16px;
      padding: $nav-link-padding;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
  }

  &__right-link-wrap {
    position: relative;
    flex-shrink: 0;
    padding-left: $right-link-padding-xs;
    @media (min-width: $header-breakpoint-min) {
      padding: 0 12px;
    }
    @media (min-width: 1400px) {
      padding: $right-link-padding;
    }
  }

  &__right > &__right-link-wrap:last-child {
    padding-right: 0;
  }

  &__search {
    visibility: hidden;
    width: 0%;
    transition: width 0.3s ease-in-out;
    &-wrap {
      width: 32px;
      height: 28px;
      display: flex;
      align-items: center;
      flex-shrink: inherit;
      @media (min-width: $header-breakpoint-min) {
        width: 56px;
      }
      @include md {
        width: 40px
      }

    }

    &-switcher {
      cursor: pointer;
      display: block;
      transition: 0.2s ease-in-out;
      position: relative;
      z-index: 2;
      background-color: #FFFFFF;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__login {
    position: relative;
    display: flex;
    align-items: center;
    color: #272727;
    &:hover {
      color: #272727;
      opacity: 1;
    }
    svg, img {
      display: block;
      margin-right: 8px;
    }
    &:hover::before {
      content: "";
      width: 100%;
      height: 32px;
      position: absolute;
      top: 100%;
    }
    &-username {
      @include md {
        display: none;
      }
    }
  }

  &__try-btn {
    padding: 8px 15px;
    border: 1px solid #0d4a75;
    @include md {
      padding: 8px 8px;
    }
  }
  &__login:hover + &__user-dropdown,
  &__user-dropdown:hover {
    pointer-events: unset;
    height: 78px;
    opacity: 1;
    transform: translateY(22px);
    padding: 6px 0;
  }
  &__user-dropdown {
    position: absolute;
    top: 50%;
    left: 0;
    background-color: #FFFFFF;
    border-radius: 2px;
    width: 167px;
    border: 1px solid #F0F0F0;
    //border-radius: 2px;
    z-index: 100;
    overflow: hidden;
    box-shadow: 0px 0px 9px rgb(0 0 0 / 10%);
    pointer-events: none;
    line-height: 32px;
    height: 0;
    opacity: 0;
    transition: .3s;
    padding-left: 0;

    li {
      list-style: none;
    }
    a {
      display: flex;
      padding: 0 12px;
      transition: .3s;
      @include lg {
        padding: 0 20px;
      }
      &:hover {

        background-color: #F7F7F7;
      }
    }
    .header__login-profile {
      @include md {
        display: none;
      }
    }
    .header__login-username {
      display: none;
      @include md {
        display: block;
      }
    }
    &:hover::before {
      content: "";
      top: -22px;
      height: 22px;
      position: absolute;
    }
  }

  &__dropdown {
    padding: $header-padding;
    &-link {
      height: 100%;
      @media (min-width: $header-breakpoint-min) {
        display: flex;
        align-items: center;
      }
    }
    @media (max-width: $header-breakpoint-max) {
      padding: 0;
    }
    &-content {
      @media (min-width: $header-breakpoint-min) {
        border-top: 1px solid #e1e1e1;
        background-color: #FFFFFF;
        box-shadow: 0 24px 34px rgba(0, 0, 0, 0.1);
      }
    }
    &-row {
      margin: 0;
      &_inside {
        @media (min-width: $header-breakpoint-min) {
          margin: -20px;
        }
        @media (min-width: 1400px) {
          margin: -30px;
        }
      }
    }
  }

  &__row-col {
    position: relative;

    @media (min-width: $header-breakpoint-min) {
      padding: 20px;
      &_r-15 {
        padding-right: 15px;
      }
      &_l-15 {
        padding-left: 15px;
      }
      &_b-r {
        border-right: 1px solid #EEEEEE;
      }
      &_b-b {
        border-bottom: 1px solid #EEEEEE;
      }
      &_min-h {
        min-height: $header-card-min-height;
      }
      &_dark {
        background-color: #062543;
      }
    }
    @media (min-width: 1400px) {
      padding: 30px;
    }

    &_mobile-hide {
      display: none !important;
      @media (min-width: $header-breakpoint-min) {
        display: flex !important;
      }
    }

    &_desktop-hide {
      display: flex !important;
      @media (min-width: $header-breakpoint-min) {
        display: none !important;
      }
    }

    &_p-r-0 {
      padding-right: 0;
    }

    &_p-l-0 {
      padding-right: 0;
    }

    &_p-0 {
      padding: 0;
    }

    &_b-l {
      border-left: 1px solid #EEEEEE;
    }

    &_h-50 {
      height: 50%;
    }

    &-img {
      margin-bottom: 20px;
      flex: 1 0 auto;
      @media (min-width: 1400px) {
        margin-bottom: 30px;
      }
      &_max-h {
        @media (min-width: 1400px) {
          max-height: 218px;
        }
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &_link {
      color: inherit;
      transition: 0.3s ease-in-out;
      &:hover {
        color: inherit;
        @media (min-width: $header-breakpoint-min) {
          background-color: #f3f5f6;
        }
      }
    }
  }

  &__dark-item {
    display: block;
    margin-left: -20px;
    margin-right: -20px;
    padding: 17px 20px;
    background-color: initial;
    transition: 0.3s ease-in-out;
    @media (min-width: 1400px) {
      margin-left: -30px;
      margin-right: -30px;
      padding: 17px 30px;
    }

    &:hover {
      background-color: #011D38;
    }
    &_flex {
      display: flex;
      align-items: flex-start;
    }
    &-img-wrap {
      margin-left: 12px;
      width: 100px;
      height: 65px;
      flex-shrink: 0;
      @media (min-width: 1400px) {
        margin-left: 20px;
        width: 126px;
        height: 76px;
      }
    }
  }

  &__dark-list {
    &_gap {
      margin-top: -17px;
      margin-bottom: -17px;
    }
  }

  &__projects-text {
    font-size: 14px;
  }

  &__company-img-wrap {
    height: 84px;
    margin: 30px 0;
    display: flex;
    align-items: center;
  }

  &__company-img {
    display: block;
    max-height: 100%;
  }

  &__company-txt {
    margin-bottom: 30px;
  }

  .header__dropdown-title {
    margin-bottom: 25px;
  }

  &__btn-xs {
    margin: 0;
    margin-top: 11px;
  }

  &__btns-xs-wrap {
    margin-bottom: 14px;
  }

  &__release-soon-img {
    position: absolute;
    display: block;
    bottom: 30px;
    right: 70px;
  }

  &__journal {
    &-card-border-bottom {
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: 13px;
        margin-bottom: 13px;
    }
    &-card-last {
      margin-bottom: 23px;
    }
    &-card-img {
      margin-bottom: 24px;
    }
    &-card-title {
      margin-bottom: 8px;
      display: inline-block;
    }
    &-card-description {
      margin-top: 0;
      &_big-mrg {
        margin-bottom: 22px;
      }
      &_small-mrg {
        margin-bottom: 8px;
      }
    }
    &-card-date {
      margin: 0;
    }
  }

  &__mobile-block {
    @media (min-width: $header-breakpoint-min) {
      display: none;
    }
  }

  &__desktop-block {
    @media (max-width: $header-breakpoint-max) {
      display: none;
    }
  }

  @media (max-width: $header-breakpoint-max) {
    &__dropdown-row *:not(.header__mobile-el) {
      display: none !important;
    }
    &__mobile-el {
      margin: 0 !important;
    }
    &__dropdown-row:not(.header__dropdown-row_inside) {
      width: 100%;
      padding-left: 20px;
    }
    &__mobile-el_link-wrap {
      padding: 16px 0;
      border-bottom: 1px solid #EEEEEE;
      display: flex;
      flex-direction: row !important;
      align-items: baseline !important;
      width: 100%;
    }
    &__link {
      font-size: 14px;
      &_not-mobile {
        @media (max-width: $header-breakpoint-max) {
          display: none;
        }
      }
    }
    &__mobile-el_link {
      font-size: 14px;
      line-height: 1.5;
      display: block;
      width: 100%;
      margin: 0;
      color: #272727;
      font-weight: normal;
    }
    &__mobile-el_no-padding {
      padding: 0;
    }
  }
}
