.projects {
  padding-top: 25px;

  &__wrap {
    position: relative;
    padding-top: 11px;
    margin-bottom: 34px;
    min-height: 700px;
  }

  & &__title {
    margin-bottom: 17px;
  }

  &__button-primary {
    margin-left: 35px;
    margin-bottom: 32px;
    padding: 12px 35px;
  }

  &__menu {
    position: absolute;
    left: 0;
    top: 0;
    width: 400px;
  }

  &__menu-list {
    padding: 0;
    margin: 0;
    margin-left: -10px;
    margin-bottom: 18px;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  &__menu-item {
    padding: 4px 10px;
    margin-right: 6px;
  }

  &__menu-item--active {
    background-color: #ffffff;
    color: black;
  }

  &__block-info {
    margin-top: 36px;
    margin-bottom: 36px;
    @media (min-width: 768px) {
      display: flex;
      align-items: flex-end;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      margin-bottom: 0;
      width: 50%;
      padding-right: 70px;
    }
  }

  &__filter-list {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: -10px;
  }

  &__filter-item {
    margin-bottom: 2px;
  }

  &__filter-link {
    display: inline-block;
    padding: 4px 10px;
  }

  &__filter-link--active {
    background-color: #ffffff;
    color: black;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    @media (min-width: 768px) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
  }

  &__item{
    width: 100%;

    @media (min-width: 768px) {
      /* стили для маленьких блоков */
      flex: 1 1 calc(33% - 20px);
      margin-right: 20px;
      &:nth-of-type(5n+5){
        margin-right: 0;
      }
      /* стили для двух больших блоков */
      &:nth-of-type(5n+1){
        flex: 1 1 calc(50% - 20px);
        margin-right: 20px;
      }
      &:nth-of-type(5n+2){
        flex: 1 1 calc(50% - 20px);
        margin-right: 0;
      }
    }
  }

  &__items {
    width: 380px;
    margin-right: 40px;
    margin-bottom: 32px;
  }

  &__subjects {
    @media (min-width: 768px) {
      margin-left: 20px;
      width: 50%;
    }
  }

  &__subjects-form {
    padding-left:10px;
    width: 100%;
  }

  &__subjects-selector {
    width: 100%;
  }

  &__subject-block{
    position: absolute;
    left: 16px;
    top: 16px;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    z-index: 1;
  }

  &__subject-successstory {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 5px;
    width: fit-content;
    background-color: rgba(244, 244, 244, 0.7);
    border-radius: 5px;
    font-size: 14px;
    line-height: 180%;
    color: rgba(0, 0, 0, 0.35);
  }

  &__wrap-image {
    overflow: hidden;
    display: block;
    position: relative;
    margin-bottom: 15px;
  }

  &__wrap-image::before {
    content: '';
    display: block;
    padding-bottom: 57%;
  }

  &__wrap-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: .3s;
  }

  &__wrap-image img:hover {
    transform: scale(1.05);
  }

  &__items:first-of-type {
    width: 800px;
    margin-left: 420px;
    margin-right: 0;
  }

  &__items:first-of-type .projects__wrap-image {
    width: 800px;
    height: 450px;
  }

  &__items:nth-of-type(3n + 3) {
    margin-right: 0;
  }

  &__items:nth-of-type(2) {
    margin-left: 420px;
  }

  &__items:nth-of-type(3) {
    margin-right: 0;
  }

  &__name-company {
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__wrap-link {
    margin-top: 15px;
    margin-bottom: 55px;
  }

  &__type-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  &__type-items {
    margin-right: 23px;
  }

  &__pagination-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 310px;
  }

  &__pagination-list {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 256px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  &__pagination-item {
    margin-right: 8px;
  }

  &__pagination-item:nth-of-type(6) {
    margin-left: 7px;
    margin-right: 18px;
  }

  &__pagination-link {
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
  }

  &__pagination-link:hover {
    color: #f0523e;
  }

  &__pagination-link--active {
    background-color: white;
  }

  &__left-button {
    margin-left: -5px;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;
  }

  &__left-button:after {
    position: absolute;
    content: "";
    top: 10.5px;
    left: 3px;
    width: 8.5px;
    height: 1px;
    background-color: #048ad3;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &__left-button:before {
    position: absolute;
    content: "";
    top: 16px;
    left: 3px;
    width: 8.5px;
    height: 1px;
    background-color: #048ad3;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &__right-button {
    margin-right: 13px;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;
  }

  &__right-button:before {
    position: absolute;
    content: "";
    top: 10.2px;
    right: 3px;
    width: 8.5px;
    height: 1px;
    background-color: #048ad3;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &__right-button:after {
    position: absolute;
    content: "";
    top: 16px;
    right: 3px;
    width: 8.5px;
    height: 1px;
    background-color: #048ad3;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &__right-button:hover::after, &__right-button:hover::before,
  &__left-button:hover::before, &__left-button:hover::after {
    background-color: #f0523e;
  }
}

@media (max-width: $mobile-view) {
  .projects__menu {
    position: static;
    width: 100%;
  }

  .projects__menu-item {
    width: 100%;
    margin-right: 0;
  }

  .projects__menu-link {
    padding-left: 16px;
    padding-right: 16px;
  }

  .projects__filter-list {
    display: none;
  }

  .projects__items:first-of-type,
  .projects__items:nth-of-type(2) {
    margin-left: 0;
    width: 100%;
  }

  .projects__items {
    margin-right: 0;
    width: 100%;
  }

  .projects__items:first-of-type .projects__wrap-image {
    width: 100%;
    height: auto;
  }

  .projects__wrap-image {
    width: 100%;
    height: auto;
  }
}
