.clients {
  background: #233255;
  padding-top: 35px;
  padding-bottom: 44px;
  margin-bottom: 160px;

  @media (min-width: 576px) {
    padding-top: 66px;
  }

  &__title {
    font-size: 16px;
    line-height: 1.8;
    color: #FFFFFF;
    @media (min-width: 576px) {
      font-size: 20px;
    }
    @media (min-width: 992px) {
      font-size: 22px;
    }
    @media (min-width: 1400px) {
      font-size: 24px;
    }
  }

  &__icons {
    margin: 35px 0 0;
    max-width: 100%;
    display: block;
    @media (min-width: 768px) {
      margin: 50px 0;
    }
  }

  &__photo-wrap {
    width: 100%;
    height: 170px;
    overflow: hidden;
    position: relative;
    margin-top: 75px;

    @media (min-width: 576px) {
      height: 220px;
    }
    @media (min-width: 768px) {
      margin-top: 0;
    }
    @media (min-width: 992px) {
      height: 322px;
    }
  }

  &__photo {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    position: absolute;
    object-fit: cover;
    object-position: center;
  }

  &__comment {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 20px 34px -10px rgba(0, 0, 0, 0.1);
    padding: 32px 23px;
    margin-bottom: -156px;
    &-text {
      font-size: 12px;
      line-height: 1.8;
      margin-bottom: 24px;
      @media (min-width: 576px) {
        font-size: 14px;
      }
      @media (min-width: 768px) {
        margin-bottom: 32px;
      }
    }
    @media (min-width: 768px) {
      padding: 20px;
      margin-bottom: -105px;
    }
    @media (min-width: 992px) {
      padding: 32px;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__link {
    @media (max-width: 767px) {
      margin-top: -26px;
      margin-left: auto;
    }
    @media (max-width: 575px) {
      font-size: 12px;
      margin-top: -18px;
    }
  }
}