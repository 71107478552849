.person {
    margin: 10px -20px 135px -20px;
    padding: 178px 25px 76px 103px;
    background-color: #ececec;

    &__inner {
        display: flex;
        flex-direction: row;
    }

    &__image-container {
        width: 300px;
        height: 300px;
        position: relative;
        z-index: 2;
        margin-right: 144px;

        &::after {
            position: absolute;
            z-index: -1;
            bottom: -32px;
            right: -32px;
            content: "";
            width: 151px;
            height: 153px;
            background: url('/upload/author-page/dots-bg.svg') no-repeat center center;
        }
    }

    &__image {
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    &__name {
        font-weight: bold;
        font-size: 48px;
        line-height: 58px;
        color: #201E1E;
        margin-bottom: 10px;
    }

    &__jobposition {
        margin-bottom: 25px;
        font-size: 18px;
        line-height: 27px;
        color: #A1A1A1;
    }

    &__core-competence {
        margin-bottom: 23px;
        font-size: 24px;
        line-height: 36px;
        color: #201E1E;
    }

    &__biography {
        font-size: 21px;
        line-height: 31px;
        color: #201E1E;
    }

    &__socials {
        margin: 0;
        padding: 0;
        margin-left: auto;
        display: flex;
        align-items: center;
        width: 145px;
        padding-top: 12px;
        border-top: 1px solid #000000;
        list-style: none;
    
    }

    &__social + &__social {
        margin-left: 16px;
    }
}

.person-articles {
    &__title {
        margin-bottom: 35px;
        margin-top: 65px;
        font-weight: bold;
        font-size: 36px;
        line-height: 43px;
        color: #212529;
    }
}

@media (max-width: 768px) {
    .person {
        padding: 50px 15px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 40px;

        &__inner {
            flex-direction: column;
        }

        &__image-container {
            margin-right: 0;
        }

        &__info {
            margin-top: 40px;
        }
    }

    .person-articles {
        padding-left: 15px;
        padding-right: 15px;

        &__title {
            margin-top: 20px;
        }
    }
}