.other-solutions {
  &__wrap {
    margin: auto;
  }

  &__title {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 28px;
    font-weight: bold;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    justify-content: space-between;
  }

  &__item {
    width: 31.4%;
    border: 1px solid #d3d9db;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item .elma-link--arrow {
    margin-right: 24px;
    margin-left: 24px;
    margin-bottom: 43px;
    margin-top: auto;
  }

  &__preview {
    margin-bottom: 28px;
    @media (max-width: 1399px) {
      height: auto;
    }
  }

  &__wrap-text {
    padding-left: 24px;
    padding-right: 24px;
  }

  &__subtitle {
    font-size: 18px;
    line-height: 22px;
  }

  &__description {

  }
}

.other-solutions--case-hub {
  margin-bottom: 80px;

  .other-solutions {
    &__title {
      font-size: 32px;
      line-height: 38px;
    }

    &__list {
      margin-left: auto;
      margin-right: auto;
      flex-wrap: wrap;
    }

    &__item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      border: none;
    }

    &__item:not(:first-of-type) {
      border-bottom: 2px solid #d3d9db;
      padding-bottom: 60px;
      padding-top: 48px;
    }

    &__problem {
      width: 27%;
    }

    &__problem p {
      font-size: 30px;
      line-height: 36px;
      color: #048ad3;
    }

    &__problem--head p {
      width: 100%;
      color: #000000;
      border-bottom: 6px solid #edc14c;
      padding-bottom: 14px;
      margin-bottom: -0px;
    }

    &__problem--mobile-only {
      display: none;
    }

    &__case-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 69%;
      @media (min-width: 1400px) {
        width: 65%;
      }
    }

    &__case-content--head p {
      width: 100%;
      border-bottom: 6px solid #edc14c;
      padding-bottom: 14px;
      margin-bottom: 0;
    }

    &__subtitle {
      font-size: 20px;
      line-height: 22px;
    }

    &__description {
      font-size: 18px;
      line-height: 30px;
    }

    &__wrap-text {
      width: 359px;
      padding: 0;
    }

    &__wrap-text a {
      font-size: 18px;
      line-height: 26px;
    }

    &__preview {
      width: 290px;
      height: 180px;
      @media (min-width: 1400px) {
        width: 380px;
        height: 214px;
      }
    }

    &__subtitle,
    &__description {
      color: #000000;
    }
  }
}


@media (max-width: $mobile-view) {
  .other-solutions {
    &__wrap {
      width: 100%;
      padding: 0 16px;
      box-sizing: border-box;
    }


    &__list {
     width: 100%;
      flex-wrap: wrap;
      margin: 0;
      padding: 0 16px;
      box-sizing: border-box;
    }

    &__item {
      width: 100%;
      margin-bottom: 36px;
    }

    &__item:last-of-type {
      margin-bottom: 0;
    }

    &__preview {
      width: 100%;
      height: auto;
    }
  }

  .other-solutions--case-hub {
    .other-solutions {
      &__title {
        font-size: 20px;
        line-height: 24px;
      }

      &__list {
        width: 100%;
        padding: 0;
      }

      &__preview {
        width: 100%;
        height: auto;
      }

      &__subtitle,
      &__description {
        color: #000000;
      }

      &__item {
        margin-bottom: 0;
      }

      &__item:not(:first-of-type) {
        padding-bottom: 48px;
        padding-top: 32px;
      }

      &__problem {
        width: 100%;
      }

      &__case-content {
        width: 100%;
      }

      &__case-content img {
        order: 1;
        margin-bottom: 24px;
      }

      &__case-content .other-solutions__wrap-text {
        order: 2;
      }

      &__subtitle {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 16px;
      }

      &__description {
        font-size: 16px;
        line-height: 26px;
      }

      &__case-content--head {
        display: none;
      }

      &__problem--head {
        display: none;
      }

      &__problem--mobile-only {
        display: block;
      }

      &__problem.other-solutions__problem--mobile-only p {
        color: #000000;
        padding-bottom: 13px;
        margin-bottom: 0;
      }

      &__wrap-text,
      &__preview {
        width: 100%;
      }

      &__problem p {
        font-size: 20px;
        line-height: 28px;
        padding-right: 30%;
        margin-bottom: 24px;
      }

      &__wrap-text a {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
