.s-story-task {
  &__wrap {
    position: relative;
    padding: 30px 16px;
    background-color: #e9ebef;
    padding-bottom: 47px;
  }

  &__wrap::before {
    position: absolute;
    content: '';
    height: 2px;
    right: 16px;
    left: 16px;
    background-color: #ffffff;
    bottom: 0;
  }

  &__title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 9px;
    font-weight: bold;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 25px;
  }
}

.s-story-task--sadco {
  .s-story-task {
    &__wrap {
      background: none;
    }

    &__wrap::before {
      display: none;
    }

    &__title {
      margin-bottom: 15px;
    }

    &__list {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      margin-top: 0;
    }

    &__item {
      border-top: 6px solid #edc14c;
      margin-bottom: 50px;
    }

    &__item p {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 15px;
      padding-top: 10px;
    }

    &__item ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      margin-top: 0;
    }

    &__item li {
      font-size: 16px;
      line-height: 24px;
    }

    &__list-i {
      list-style: none;
      margin-top: 0;
      margin-right: 0;
      padding-left: 0;
      border-bottom: 2px solid #ffffff;
      margin-bottom: 36px;
    }

    &__list-i b {
      display: block;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    &__list-i > li {
      margin-bottom: 26px;
    }
  }
}

.s-story-task--sadco-gray {
  margin-bottom: 36px;
  .s-story-task {
    &__wrap {
      background-color: #ebe7dd;
    }
  }
}

// #ebe7dd

@media (min-width: $desktop-view) {
  .s-story-task {
    &__wrap {
      margin-left: 40px;
      margin-right: 40px;
      padding: 60px 47px;
    }

    &__title {
      font-size: 30px;
      line-height: 26px;
      margin-bottom: 20px;
    }

    &__description {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 20px;
      width: 75%;
    }

    &__list-wrap {
      width: 60%;
    }

    &__wrap::before {
      right: 65px;
      left: 47px;
    }
  }

  .s-story-task--sadco {
    .s-story-task {
      &__title {
        margin-bottom: 36px;
      }

      &__list {
        display: flex;
        justify-content: space-between;
      }

      &__item {
        width: 405px;
      }

      &__item div {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      &__item ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 45%;
      }

      &__item ul li {
        width: 100%;
        font-size: 18px;
        line-height: 28px;
      }

      &__item p {
        padding-top: 24px;
        font-size: 24px;
        line-height: 36px;
      }

      &__list-i .s-story__item {
        font-size: 18px;
        line-height: 36px;
        margin-bottom: 10px;
      }

      &__list-i {
        display: flex;
        flex-wrap: wrap;
      }

      &__list-i li {
        margin-bottom: 55px;
      }

      &__list-i li b {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 14px;
      }


      &__list-i li {
        width: 312px;
        padding-right: 50px;
      }

      &__list-i li:nth-of-type(1) {
        order: 0;
      }

      &__list-i li:nth-of-type(2) {
        order: 4;
      }

      &__list-i li:nth-of-type(3) {
        order: 2;
      }

      &__list-i li:nth-of-type(4) {
        order: 1;
      }

      &__list-i li:nth-of-type(5) {
        order: 3;
      }
    }

    .s-story-task__list-wrap {
      width: 95%;
    }
  }

  .s-story-task--sadco-mt  {
    .s-story-task {
      &__wrap {
        padding-top: 140px;
        padding-bottom: 160px;
      }
    }
  }

  .s-story-task--sadco-gray {
    margin-bottom: 36px;
    .s-story-task {
      &__wrap {
        width: 100%;
        background-color: #ebe7dd;
        margin-left: 0;
        padding-top: 56px;
        padding-left: 105px;
        margin-bottom: 100px;
      }
    }
  }
}


@media (min-width: 1400px) {

  .s-story-task {
    &__wrap {
      margin-left: 68px;
      margin-right: 68px;
    }
  }
}