.banner-notation {
  background: #C4C4C4;
  border-radius: 48px;
  width: 63px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 12.1px;
  color: #000000;
  cursor: pointer;
}

.banner-notation-wrap {
  position: absolute;
  top: 23px;
  right: 39px;
}

.slide-img-fix {
  @media (max-width: 1400px) {
    width: 300px;
  }
}

.banner-notation-wrap:hover {
  .banner-notation_info {
    display: block;
    position: absolute;
    top: 30px;
    right: 0;
  }
}

.banner-notation_info {
  display: none;
  background: #FFFFFF;
  border-radius: 13px;
  padding: 20px 10px;

  p {
    font-size: 10px;
    color: #000000;
    line-height: 12.1px;
  }
}

.pos-relative {
  position: relative;
}

.single-article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 24px;

  &__wrap {
    max-width: 630px;
    @media (min-width: 1400px) {
      max-width: 750px;
    }
  }

  &__title {
    font-size: 30px;
    line-height: 42px;
    font-weight: bold;
    margin-bottom: 9px;
  }

  & &__date {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
    display: block;
  }

  & &__description {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 54px;
  }

  & img {
    max-width: 100%;
    margin-bottom: 27px;
  }

  & figcaption {
    font-size: 14px;
    line-height: 22px;
    color: #636363;
    margin-bottom: 12px;
  }

  & p {
    margin-bottom: 14px;
  }

  &__aside {
    width: 289px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    @media (min-width: 1400px) {
      max-width: 379px;
    }
  }

  &__aside-block:first-of-type {
    margin-top: 8px;
    margin-bottom: 50px;
    padding-right: 150px;
  }

  &__aside-block:last-of-type {
    margin-top: auto;
  }

  &__aside-block--link {
    display: block;
    text-decoration: none;
    color: #212529;
  }

  &__wrap-img {
    width: 180px;
    /*    height: 180px; */
    /*    margin-bottom: 14px; */
  }

  & &__aside-name {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  & &__aside-position {
    font-size: 16px;
    line-height: 24px;
    color: #272727;
  }

  &__aside-title {
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 13px;
  }


  &__list {
    list-style: none;
    padding: 0;
  }

  &__item {
    padding: 12px 0;
    border-bottom: 1px solid #c4c4c4;
  }

  &__item:last-child{
    border-bottom: 0;
  }

  &__link {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
  }

  & .regular-container__fill {
    right: -455px;
  }
}

@media (max-width: $mobile-view) {
  .single-article {
    & img {
      height: auto;
    }
  }
}
