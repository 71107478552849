
.banner {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  margin-top: 2em;
  margin-bottom: 2em;
  overflow: hidden;
  padding: 15px;

  @media (max-width: 991px){
    align-items: center;
  }
  @media (min-width: 992px) {
    padding: 14px 20px;
  }
  @media (min-width: 1240px) {
    padding: 16px 24px;
  }
  &.elmaday {
    padding: 0;
  }
  &__desktop {
    display: none;
    @include min-sm {
      display: block;
    }
  }
  &__tablet {
    display: none;
    width: 100%;
    @include min-xs {
      display: block;
    }
    @include min-sm {
      display: none;
    }
  }
  &__mobile {
    width: 100%;
    display: block;
    @include min-xs {
      display: none;
    }
  }

  &.elmaday {
    align-items: center;
    .banner__desktop-title {
      display: none;
      @include md {
        display: block;
      }
    }
    .banner__mobile-title {
      display: block;
      @include md {
        display: none;
      }
    }
    .banner__img {
      @media (max-width: 380px) {
        display: none;
      }
      @include md {
        margin-left: 64px;
      }
      &-bg {
        @include md {
          width: 72px;
          height: 72px;
          left: -38%;
        }
      }
    }
    .banner__upper-title {
      @include max-sm {
        font-size: 10px;
      }
      font-size: 18px;
      display: flex;
      color: #0a141e;
      margin-bottom: 6px;
      @include md {
        margin-bottom: 10px;
      }
      div {
        margin-right: 8px;
        @include min-md {
          margin-right: 16px;
        }
      }
    }
    .banner__title {
      @include md {
        font-size: 20px;
      }
    }
  }

  &_blue {
    background-color: #bbd2ff;

    .banner__img {
      @media (max-width: 1239px) {
        background-size: 70px;
      }
    }
    .banner__img-bg {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-color: #5082E6;
      @media (min-width: 992px) {
        width: 114px;
        height: 114px;
        right: 50%;
      }
      &_1, &_2, &_3 {
        background-color: #5082E6;
      }
    }
  }

  &_yellow {
    background-color: #FFF4D1;
    .banner__img {
      @media (max-width: 1239px) {
        background-size: 70px;
      }
    }
    .banner__img-bg {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #FABE00;
      @media (min-width: 992px) {
        width: 152px;
        height: 162px;
        right: 50%;
      }
      &_1, &_2, &_3 {
        background-color: #FABE00;
      }
    }
    .banner__title {
      @media (min-width: 992px) {
        margin-left: 12px;
      }
    }
  }

  &__img {
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    max-width: 75px;

    @media (max-width: 991px) {
      justify-content: center;
    }
    @media (min-width: 992px) {
      max-width: 74px;
    }
    @media (min-width: 1440px) {
      max-width: 105px;
      width: 100%;
      height: auto;
    }
    img {
      z-index: 1;
      width: auto;
      height: 20px;
      @media (min-width: 992px) {
        height: 24px;
      }
      @media (min-width: 1440px) {
        height: auto;
      }
    }
  }

  &__img-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    &_1, &_2, &_3 {
      position: absolute;
      animation: wave 3s ease-out infinite;
      border-radius: 50%;
      background-color: #5082E6;
    }
    &_2 {
      animation-delay: 1s;
    }
    &_3 {
      animation-delay: 2s;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    font-size: 12px;

    @media (min-width: 590px) {
      font-size: 14px;
    }

    @media (min-width: 768px) {
      font-size: 16px;
    }

    @media (min-width: 992px) {
      margin-left: 0;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    @media (min-width: 1440px) {
      font-size: 20px;
      align-items: center;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 1em;
    line-height: 150%;
    color: #0A141E;
    margin-bottom: .8em;
    margin-top: 0;

    @media (min-width: 768px) {
      margin-bottom: 0.4em;
    }

    @media (min-width: 992px) {
      margin-bottom: 0;
      padding-right: 15px;
    }
  }

  .link-arrow {
    align-self: flex-start;
    font-size: 0.9em;
    flex-shrink: 0;
    font-weight: 600;
    @media (min-width: 992px) {
      align-self: unset;
      font-size: 16px;
    }
    @media (min-width: 1440px) {
      font-size: 18px;
      align-self: center;
    }
  }
}

.banner__prices {
  @media (max-width: 767px) {
    align-items: flex-start;
  }
  .banner__img {
    flex: 1 0 auto;
    background-size: 50px;
    width: 50px;
    height: 50px;

    @media (min-width: 768px) {
      display: flex;
      width: 70px;
      height: 70px;
      background-size: 70px;
    }

    @media (min-width: 1240px) {
      width: 100%;
      height: auto;
      background-size: auto;
      max-width: 125px;
    }
    img {
      width: 25px;
      height: 25px;
      @media (min-width: 768px) {
        width: auto;
        height: auto;
      }

      @media (min-width: 1240px) {
        transform: translate(55%, -50%);
      }
    }
    .banner__description {
      margin-left: 15px;
      @media (min-width: 768px) {
        padding-right: 20px;
      }
      @media (min-width: 1440px) {
        margin-left: 32px;
      }
    }
    .banner__title {
      font-weight: normal;
      color: #394149;
      margin-bottom: 0;
    }
  }

  @keyframes wave {
    0% {
      opacity: 0.7;
      width: 100%;
      height: 100%;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      width: 180%;
      height: 180%;
    }
  }
  @media (max-width: 1439px) {
    @keyframes wave {
      0% {
        opacity: 0.7;
        width: 100%;
        height: 100%;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 0;
        width: 160%;
        height: 160%;
      }
    }
  }
  @media (max-width: 991px) {
    @keyframes wave {
      0% {
        opacity: 0.7;
        width: 100%;
        height: 100%;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 0;
        width: 200%;
        height: 200%;
      }
    }
  }}

.link-arrow::after, .link-section::after {
  content: url(/image/arrow-link-menu-blue.svg);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all ease .2s;
}

.banner .link-arrow {
  position: relative;
  padding-right: 22px;
  color: #0d4a75;
}
