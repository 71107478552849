.sinergy-bpm-rpa {
  &__section {
    margin-bottom: 100px;
  }

  &__title-icon-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .title {
      margin: 0;
    }
  }
  &__title-icon {
    flex-shrink: 0;
    width: 68px;
    height: 52px;
    display: flex;
    align-items: center;
    img {
      display: block;
    }
  }

  &__include-block {
    &-img {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-text {
        width: 50%;
        padding-right: 10px;
      }
      &-img {
        padding-left: 10px;
        max-width: 50%;
        margin: 0;
      }

    }
  }

  &__test {
    &-header {
      max-width: 615px;
      margin-bottom: 45px;
    }

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    &-item {
      margin-bottom: 50px;
      @media (min-width: 600px) {
        width: calc(50% - 15px);
      }
      @media (min-width: 768px) {
        width: calc(50% - 55px);
      }
    }

    &-title {
      margin-bottom: 8px;
    }
    &-text {
      color: #808080;
    }
    &-radio-group {
      display: flex;
      align-items: center;
      margin-top: 16px;
    }
    &-radio {
      margin-right: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      input {
        display: none;
      }
      &-icon {
        border: 1px solid #0D4A75;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 13px;
        flex-shrink: 0;
        position: relative;
        &::before {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          background-color: #0D4A75;
          border-radius: 50%;
          transition: 0.2s;
          opacity: 0;
          position: absolute;
          left: calc(50% - 4px);
          top: calc(50% - 4px);
        }
      }
      input:checked + &-icon {
        &::before {
          opacity: 1;
        }
      }
    }
    &-btn-col {
      @media (min-width: 600px) {
        width: calc(50% - 15px);
        margin-left: auto;
        margin-top: -30px;
      }
      @media (min-width: 768px) {
        width: calc(50% - 55px);
      }
      .btn {
        width: 300px;
        max-width: 100%;
        margin: 0;
      }
    }
  }

  &__gif-b {
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.08);
    display: inline-block;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      display: block;
      margin: 0;
    }
    img {
      display: block;
    }
  }

  &__wrapper {
    padding: 0 16px;
    @media (min-width: 1280px) {
      padding: 0;
    }
  }

  .s-story__target-list--blue .s-story__target-mark {
    color: #233255;
  }
}