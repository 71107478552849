.crm {

  &__title {
    padding-left: 35px;
  }

  &__description {
    padding-left: 35px;
    padding-bottom: 28px;
    margin-bottom: 11px;
  }

  &__button-primary {
    margin-left: 35px;
    margin-bottom: 32px;
    padding: 12px 35px
  }
}


.what-elma-crm {
  margin-bottom: 75px;

  &__wrap-text {
    width: 615px;
    @media (min-width: 1400px) {
      width: 725px;
    }
  }

  &__aside-wrap {
    width: 320px;
    @media (min-width: 1400px) {
      width: 380px;
    }
  }

  & &__title {
    margin-bottom: 14px;
  }

  &__bpm-platform &__title {
    width: 425px;
  }

  &__crm-plus {
    margin-bottom: 71px;
  }

  &__aside-wrap {
    margin-top: 5px;
    @media (min-width: 1400px) {
      margin-right: 30px;
    }
  }

  &__help {
    background: white;
    padding: 25px 30px;
    padding-bottom: 10px;
    -webkit-box-shadow: 0 0 48px 2px rgba(190, 190, 190, 0.5);
    box-shadow: 0 0 48px 2px rgba(190, 190, 190, 0.5);
    margin-bottom: 69px;
  }

  &__help-title {
    margin-bottom: 15px;
  }

  &__help-text {
    margin-bottom: 6px;
  }

  &__help-item {
    padding-bottom: 20px;
    margin-bottom: 18px;
  }

  &__article {
    padding: 0 30px;
  }

  &__article .h3 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 7px;
    margin-top: 7px;
  }

  &__article img {
    margin: 0;
  }

  &__article span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &__article a {
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
  }

  &__article h3 {
    margin-bottom: 18px;
  }
}

.long-term-relationships {
  background-image: url('/img/crm/CRM_1290x725_new.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 1290px;
  min-height: 726px;
  margin-left: -35px;
  margin-right: -35px;
  margin-bottom: 73px;
  padding: 55px 67px;

  &__wrap-text {
    width: 675px;
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    color: white;
    font-weight: 400;
    margin-bottom: 12px;
  }

  &__description {
    font-size: 16px;
    line-height: 26px;
    color: white;
    margin-bottom: 15px;
    margin-right: 143px;
  }
}

.example-process-crm {
  margin-bottom: 38px;

  & &__title {
    margin-bottom: 13px;
  }

  &__description {
    width: 805px;
    margin-bottom: 33px;
  }

  &__wrap-text {
    max-width: 825px;
    padding: 53px 67px;
    padding-bottom: 72px;
    padding-right: 157px;
    background-color: #EBE7DD;
    margin: 0 auto;
    min-height: 1283px;
  }

  &__text-container {
    margin-bottom: 17px;
  }

  &__text-container:nth-of-type(3) {
    margin-bottom: 30px;
  }

  &__subtitle {
    margin-bottom: 5px;
  }

  &__word {
    margin-bottom: 5px;
  }

  &__wrap-image {
    background: white;
    width: 1050px;
    margin-left: -180px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    padding-top: 44px;
    @media (min-width: 1400px) {
      width: 1290px;
      margin-left: -300px;
    }
  }

  &__wrap-image figcaption {
    font-size: 14px;
    line-height: 22px;
    color: #636363;
    margin-bottom: 51px;
    margin-top: -12px;
    margin-left: 89px;
    width: 694px;
  }

  &__wrap-image img {
    max-width: 94%;
    margin: 0 auto;
    display: block;
    @media (min-width: 1400px) {
      max-width: 1103px;
    }
  }
}
