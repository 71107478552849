.ecm {
  &__title {
    margin-left: 35px;
  }

  &__description {
    padding-left: 35px;
    padding-bottom: 32px;
  }

  &__button-primary {
    margin-left: 35px;
    margin-bottom: 32px;
    padding: 12px 35px;
  }
}

.what-elma-ecm {
  max-width: 865px;
  margin-bottom: 72px;

  & &__title {
    margin-bottom: 14px;
  }

  &__description {
    margin-bottom: 14px;
  }

  .what-elma-ecm .what-elma-ecm__title {
    margin-bottom: 14px;
  }

  .what-elma-ecm__description {
    margin-bottom: 14px;
  }
}

.classic-task-ecm {
  margin-bottom: 72px;

  &__wrap-text {
    width: 460px;
    @media (min-width: 1400px) {
      width: 596px;
    }
  }

  &__wrap-image {
    max-width: 460px;
    @media (min-width: 1400px) {
      max-width: 590px;
    }
  }

  &__wrap-text &__title {
    line-height: 42px;
    margin-bottom: 11px;
    margin-top: -5px;
  }

  &__description {
    margin-bottom: 18px;
  }
}

.workflow-ecm {

  & &__wrap {
    padding-top: 32px;
    margin-bottom: 61px;
  }

  & &__title {
    margin-bottom: 15px;
  }

  &__description {
    max-width: 839px;
  }

  &__transform {
    top: 50%;
    left: 50%;
    width: 1200px;
    height: 898px;
    margin-left: -629px;
    margin-top: -426px;
    z-index: -1;
  }
}

.workflow-circle__wrap-fill {
  margin-bottom: 104px;
  max-width: 1139px;
  margin-left: 37px;
  min-height: 345px;
  margin-top: 31px;
}

.workflow-ecm__transform-cover {
  background-image: url('/img/ecm/circle_6_2.png ');
  background-size: 72%;
  background-repeat: no-repeat;
  background-position: 50% 58%
}

.advantages-ecm-automatic {
  background-image: url('/img/ecm/Group 367.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 1290px;
  min-height: 726px;
  margin-left: -35px;
  margin-right: -35px;
  margin-bottom: 73px;
  margin-top: 73px;
  padding: 55px 67px;

  &__wrap-blocks {
    max-width: 546px;
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    color: white;
    font-weight: 400;
    margin-bottom: 12px;
  }

  &__description {
    font-size: 16px;
    line-height: 26px;
    color: white;
    margin-bottom: 15px;
    margin-right: 70px;
  }
}

.example-process-ecm {
  margin-bottom: 35px;

  & &__title {
    margin-bottom: 12px;
  }

  &__description {
    width: 805px;
    margin-bottom: 33px;
  }

  &__wrap-text {
    max-width: 825px;
    padding: 53px 67px;
    padding-bottom: 86px;
    padding-right: 157px;
    background-color: #EBE7DD;
    margin: 0 auto;
    min-height: 1314px;
  }

  &__text-container {
    margin-bottom: 17px;
  }

  &__text-container:nth-of-type(3) {
    margin-bottom: 30px;
  }

  &__subtitle {
    margin-bottom: 0;
  }

  &__word {
    margin-bottom: 7px;
  }

  &__wrap-image {
    background: white;
    width: 1050px;
    margin-left: -180px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    padding-top: 26px;
    @media (min-width: 1400px) {
      width: 1290px;
      margin-left: -300px;
    }
  }

  &__wrap-image figcaption {
    font-size: 14px;
    line-height: 22px;
    color: #636363;
    margin-bottom: 32px;
    margin-top: -20px;
    margin-left: 18px;
  }
}

.ecm-link--active {
  color: black;
  border-radius: 3px;
  background-color: rgba(190, 190, 190, 0.2);
}


@media (max-width: $mobile-view) {
  .example-process-ecm__description {
    width: 100%;
  }

  .example-process-ecm__wrap-text {
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -16px;
    margin-right: -16px;
    min-height: 500px;
    padding-bottom: 40px;
  }

  .example-process-ecm__wrap-image {
    width: 100%;
    margin-left: 0;
  }

  .example-process-ecm__wrap-image img {
    max-width: 317px;
  }

  .example-process-ecm__wrap-image figure {
    width: 100%;
  }

  .example-process-ecm__wrap-image figcaption {
    margin-left: 8px;
    margin-bottom: 0;
    margin-top: 0;
  }
}