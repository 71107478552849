.single-webinars {
  min-height: 522px;
  margin-left: -35px;
  margin-right: -35px;
  margin-bottom: 30px;
  padding: 19px 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: rgba(23, 83, 149, 0.7);
  background-image: url("/img/assets/webinar_bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  &__wrap-text {
    width: 570px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    @media (min-width: 1400px) {
      width: 720px;
    }
  }

  &__wrap-form {
    width: 380px;
    background-color: white;
    padding: 22px 29px;
    @media (min-width: 1400px) {
      margin-right: 35px;
    }
  }

  &__wrap-text .crumbs-list {
    margin-top: 6px;
    color: white;
    padding: 0;
    border: none;
  }

  &__wrap-text .crumbs-list a {
    color: white;
  }

  &__type {
    margin-top: auto;
    background-color: #ffcc00;
    color: black;
    padding: 0 8px;
    padding-bottom: 1px;
    margin-bottom: 11px;
  }

  &__title {
    color: white;
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 36px;
    font-weight: bold;
  }

  &__speakers  {
    width: 380px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__date {
    color: white;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 126px;
  }

  &__form-description {
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 12px;
  }

  &__label {
    width: 100%;
    margin-bottom: 12px;
  }

  &__label--checkbox {
    font-size: 14px;
    line-height: 20px;
  }

  &__input {
    border: 1px solid #d1d1d1;
    width: 100%;
    padding: 5px 6px;
  }

  & &__input--checkbox {
    width: 1px;
    margin-top: 15px;
    margin-left: 5px;
  }

  &__submit {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 12px 28px;
    color: white;
    font-weight: bold;
    border: 0;
    margin-top: 7px;
    cursor: pointer;
  }

  &__input--checkbox + .single-webinars__label--icon {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 7px;
    margin-bottom: -2px;
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 0 1px #d1d1d1;
    box-shadow: inset 0 0 0 1px #d1d1d1;
  }

  &__input--checkbox:checked + .single-webinars__label--icon::before {
    position: absolute;
    content: "";
    top: 8px;
    right: 7px;
    width: 7px;
    height: 2px;
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
    background-color: #048ad3;
  }

  &__input--checkbox:checked + .single-webinars__label--icon::after {
    position: absolute;
    content: "";
    top: 6px;
    right: 1px;
    width: 11px;
    height: 2px;
    -webkit-transform: rotate(-59deg);
    -ms-transform: rotate(-59deg);
    transform: rotate(-59deg);
    background-color: #048ad3;
  }

  &__label option {
    color: #000000;
  }

  &__label select:invalid {
    color: #d1d1d1;
  }

  &__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &__content-general {
    width: 555px;
    @media (min-width: 1400px) {
      width: 765px;
    }
  }

  &__content .single-webinars__content-title {
    margin-bottom: 14px;
  }

  &__submit--content {
    width: auto;
  }

  &__content-speakers {
    width: 380px;
  }

  &__content-speakers {
    width: 380px;
  }

  &__content-speakers-list {
    width: 380px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__content-speakers-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }

  &__content-speaker-img-wrap {
    width: 105px;
    height: 105px;
    -webkit-box-shadow: inset 0 0 0 1px #f0523e;
    box-shadow: inset 0 0 0 1px #f0523e;
  }

  &__content-speaker-img {
    width: 100%;
    height: 100%;
  }

  &__content-speakers-info {
    padding-left: 13px;
    width: 150px;
  }

  &__content-speakers-name {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 4px;
    margin-top: -5px;
  }

  &__content-speakers-position {
    font-size: 16px;
    line-height: 22px;
    color: #272727;
  }
}

::-webkit-input-placeholder {
  color: #d1d1d1;
}
::-moz-placeholder {
  color: #d1d1d1;
}
/* Firefox 19+ */
:-moz-placeholder {
  color: #d1d1d1;
}
/* Firefox 18- */
:-ms-input-placeholder {
  color: #d1d1d1;
}

@media (max-width: 768px) {
  .single-webinars {
    width: auto;
  }
}

@media (max-width: $mobile-view) {
  .single-webinars {
    width: auto;
    box-sizing: border-box;
    margin-left: -16px;
    margin-right: -16px;
    padding: 18px 16px;
    padding-bottom: 0;
    background-size: cover;

    &__wrap-text {
      width: 100%;
    }

    &__speakers  {
      width: 100%;
    }

    &__title {
      font-size: 24px;
      line-height: 28px;
      width: 100%;
    }

    &__date {
      margin-bottom: 25px;
    }

    &__wrap-form {
      width: auto;
      padding: 21px 16px;
      margin-right: -16px;
      margin-left: -16px;
    }

    &__content-general {
      width: 100%;
    }

    &__speakers {
      order: 1;
      width: 100%;
    }

    &__content-speakers-list {
      width: 100%;
    }
  }
}

@media (max-width: $mobile-view-320) {
  .single-webinars {
    &__title {
      font-size: 22px;
      line-height: 24px;
    }
  }
}
