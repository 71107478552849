.scrollbar-inner > .scroll-element .scroll-bar {
  background: #E8E8E8;
}
.scrollbar-inner > .scroll-element:hover .scroll-bar {
  background-color: #E8E8E8;
}
.scrollbar-inner > .scroll-element .scroll-element_outer,
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  border-radius: 40px;
}
.scrollbar-inner > .scroll-element .scroll-element_track {
  background-color: transparent;
}
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  opacity: 1;
}
.scrollbar-inner > .scroll-element.scroll-x {
  height: 4px;
  cursor: pointer;
}
.scrollbar-inner > .scroll-element.scroll-x:hover {
  height: 8px;
}
.scrollbar-inner > .scroll-element .scroll-element_track {
  background-color: transparent;
}