.try-dropdown {
  position: relative;
  display: inline-block;

  &__switcher {
    display: inline-block;
    cursor: pointer;
    padding-right: 17px;
    position: relative;

    @media (min-width: 576px) {
      padding-right: 26px;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: calc(50% - 4px);
      background-image: url("../image/try-dropdown-arr.svg");
      width: 10px;
      height: 9px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.2s ease-in-out;

      @media (min-width: 576px) {
        width: 14px;
      }
    }
  }
  &__value {
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #0D4A75;
    margin-left: 5px;

    @media (min-width: 576px) {
      font-size: 30px;
    }
  }
  &__wrap {
    position: absolute;
    width: calc(100% + 48px);
    top: calc(100% + 10px);
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    z-index: 100;
    max-height: 285px;
    overflow: hidden;
    pointer-events: none;
    opacity: 0;
    transform: translateY(-10px);
    transition: 0.3s ease-in-out;
    min-width: 174px;
    left: -24px;
  }

  &__list {
    margin: 0;
    padding: 8px 24px;
    list-style: none;
  }

  &__list-item {
    padding: 8px 0;
    line-height: 19px;
  }

  &__option {
    cursor: pointer;
    position: relative;
    margin: 0;
    display: block;
    &_disable {
      pointer-events: none;
    }
  }

  &__option-value {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    transition: 0.2s;
    display: block;
    font-weight: normal;
    &_disable {
      opacity: 0.4;
    }
  }

  &__input:checked + &__option-value {
    opacity: 0.3;
  }
}

.dd-item_active .try-dropdown__wrap {
  transform: translateY(0);
  opacity: 1;
  pointer-events: inherit;
}

.dd-item_active .try-dropdown__switcher {
  &::after {
    transform: rotate(180deg);
  }
}