.main-search {
  background-color: white;
  padding: 30px;

  &__title {
    margin-bottom: 15px;
  }

  &__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 25px;
  }

  &__input {
    border: 1px solid #e8e8e8;
    border-right: none;
    padding: 10px 10px;
    width: 100%;
    outline: none;
  }

  &__input:focus {
    outline: none;
  }

  &__input:-webkit-autofill {
    -webkit-box-shadow:inset 0 0 0 1000px white;
  }

  &__button {
    border: none;
    font-weight: bold;
    padding: 10px 25px;
  }

  &__count-results {
    color: #cdcdcd;
  }

  &__results-item {
    padding-top: 17px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f4f4f4;
  }

  &__results-title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &__results-text {
    margin-bottom: 2px;
  }
}

@media (max-width: $mobile-view) {
  .main-search {
    padding-left: 16px;
    padding-right: 16px;
    margin-right: -16px;
    margin-left: -16px;

    &__title {
      font-size: 24px;
      line-height: 30px;
    }
  }
}
