$orange-color: #edc14e;
$lnk-btn-color: #0d4a75;
$prg-list-p-color: #212529;
$bg-block-color: #636363;
$block-color: #ebe7dd;
$dmn-download-color: #e6e6e6;
$table-block-color: #e9ebef;
$font-head-w: 700;
$prg-list-ul: square;
$font-head-s: 30px;
$font-all-txt-s: 18px;
$font-blocks-txt-s: 16px;
$indent-58: 58px;

@mixin white-border{
  border-bottom: 2px solid #ffffff;
}

@mixin blue-link-w{
  font-size: 18px;
  font-weight: $font-head-w;
}

@mixin prg-list-decor{
  ul {
    list-style-type: none;
  }
  li{
    color: $orange-color;
  }
  p{
    color: $prg-list-p-color;
  }
}
@mixin bg-block-decor{
  background: $block-color;
  color: $bg-block-color;
  padding-left: 28px;
  padding-right: 30px;
  padding-top: 28px;
}

@mixin font-all-txt-s{
  font-size:$font-all-txt-s;
}

@mixin font-blocks-txt-s{
  font-size: $font-blocks-txt-s;
}
@mixin orng-head{
  color: $orange-color;
  font-size: 24px;
  line-height: 1.2;
}

.blue-link {
  @include blue-link-w;
}

.all-txt-dmn {
  font-size: 14px;
  line-height: 1.7;
  margin-bottom: 12px;
  @media (min-width: 1400px) {
    @include font-all-txt-s;
  }
}

.dmn-nav {
  padding-left: 30px;
}

.banner-dmn__wrap {
  box-shadow: 0 3px 24px 0 rgba(0,0,0,.16);
  margin-left: -20px;
  margin-right: -20px;
}

@media (max-width: 767px) {
  .banner-dmn__wrap{
      width: 360px;
      img {
        box-shadow: 0 3px 24px 0 rgba(0,0,0,.16);
      }
  }
}

.banner__dmn {
  margin-top: 10px;
  background-image: url("/images/img_dmn/banner_web.png");
  background-size: cover;
  //margin-left: 28px;
  padding: 0 0 75px 0;

  li {

    font-size: 20px;
    padding-right: 30px;
    display: inline;
    list-style-type: none;
  }
}

@media (max-width: 767px) {
  .banner__dmn li {
    padding-left: 10px;
  }
}

.dmn-burg{
  font-size: 0;
}

@media(min-width: 768px) {
  .dmn-navbar{
    position: relative;
    display: inline-block;
  }
  .dmn-navbar button{
    display: none;
  }
}
.dmn-navbar span{
  font-size: 0;
}
.btn-nav{
  cursor: pointer;
  margin-left: 15px;
  position: relative;
  display: block;
  width: 18px;
  height: 14px;
  border-left: none;
  border-right: none;
  border-top: 2px solid #edc14e;
  border-bottom: 2px solid #edc14e;
  background: transparent;
  //border: none;
  font-size:0;
}
.btn-nav::after{
  margin-left: -6px;
  display: block;
  width: 100%;
  content: "";
  position: absolute;
  top: 4px;
  border-top: 2px solid #edc14e;
}

@media (max-width: 767px) {
  .dmn-navbar{
    padding-top: 25px;
  }
}
@media (max-width: 767px) {
  .dmn-navbar span{
    position: absolute;
    cursor: pointer;
    line-height: 15px;
  }
}

@media (max-width: 767px) {
  .burger-line{
    position: absolute;
    top: 8px;
    left: 16px;
    width: 18px;
    height: 2px;
    background-color:#edc14e;
    transition: .5s all;
  }
  .first{
    top:70px;
  }
  .second, .third{
    top: 75px;
  }
  .fourth{
    top: 80px;
  }
}

@media (max-width: 767px){
  .banner__dmn{
    margin-left: 16px;
    width: 100%;
    background: url("/images/img_dmn/banner_mobile_1.png") no-repeat;
    background-size: 360px 508px;
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .dmn-nav {
    display: none;
    position: absolute;
    top: -97px;
    transform: translateY(100%);
    //max-height: 0;
    font-size: 0;
    overflow: hidden;
    transition: .5s all;

    ul.dmn-nav{
      margin-left: 6px;
    }
    .nav-item-dmn {
      color: #000;
      font-size: 16px;
      margin-left: -40px;
      display: inline-block;
      width: 360px;
      padding: 10px;
      text-decoration: none;
      border-top: 1px solid rgba(255, 255, 255, .3);
      background-color: #f5f5f5;
    }

    a:hover {
      background-color: rgba(255, 255, 255, 255);
    }
  }

  .dmn-navbar:hover .dmn-nav{
    display: block;
  }
}

.title__block{
  line-height: 1.5;
  margin-bottom: 24px;
  font-weight: $font_head-w;
  font-size: $font-head-s;
}

@media (max-width: 767px) {
  .title__block {

    font-size: 20px;
    margin-bottom: 20px;
  }
  .all-txt-dmn{

    font-size: 16px;
    line-height: 1.5;
  }
}

.head-dmn {
  max-width: 808px;
  margin-left: 30px;

  &__dmn1 {
    margin-top: 64px;
    margin-bottom: 24px;
    font-size: 48px;
    font-weight: $font-head-w;
  }
  &__dmn2 {
    margin-bottom: 44px;
    font-size: 20px;
    line-height: 1.7;

  }
}

@media (max-width: 767px){
  .head-dmn {
    margin-left: 16px;

    &__dmn1 {
      max-width: 230px;
      margin-top: 37px;
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: $font-head-w;
    }

    &__dmn2 {
      max-width: 313px;
      margin-bottom: 36px;
      font-size: 16px;
      line-height: 1.7;
    }
  }
}

.text-blue{
  color: $lnk-btn-color;
}

.btn__dmn{
  padding: 16px 33px;
  font-size: 16px;
  font-weight: $font-head-w;
  border: none;
  cursor: pointer;
  border-radius: 1px;
  background-color: $lnk-btn-color;
}

@media (max-width: 767px){
  .btn__dmn {
    padding: 16px 75px;
  }
}

.dmn-russian {
  width: 800px;
  padding: 0 10px;
  padding-top: 51px;
}


@media (max-width: 767px) {
  .dmn-russian {
    width: 328px;
    padding-top: 20px;
    margin-left: 16px;
  }
}

.dmn-for {
  margin-top: 26px;
  margin-bottom: 88px;
  padding: 0 10px;

  ul {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
  }

  li {
    margin-right: 40px;
    width: 277px;
    @media (min-width: 1400px) {
      margin-right: 100px;
      width: 297px;
    }
  }

  &__head {
    @include orng-head;
    margin-top: 10px;
    margin-bottom: 8px;;
  }
}

@media (max-width: 767px) {
  .dmn-for {
    width: 340px;
    margin-left: 16px;
    margin-bottom: 0;
    margin-top: 36px;

    ul {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      margin-right: 0;
    }

    &__head {
      margin-top: 0;
      font-size: 20px;
    }
  }

}

.access {
  margin-bottom: 120px;
  margin-top: 108px;
  padding: 0 10px;
}
@media (max-width: 767px) {
  .access{
    margin-bottom: 60px;
    margin-top: 60px;
  }
}

.get-access {
  margin: 120px 0 120px 0;
  width: 1249px;
  /*margin-left: -35px;*/
  padding: 25px 0 27px 30px;
  box-shadow: 0 3px 24px 0 rgba(0, 0, 0, .16);

  &__txt {
    padding-right: 30px;
    font-size: 20px;
  }

  &__img {
    img {
      width: 388px;
      height: 219px;
    }
  }
}

.get-access-p {
  p {
    margin-bottom: 5px;
  }

  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .get-access {
    width: 328px;
    margin: 60px 16px 44px 16px;
    padding: 21px 20px 69px 20px;

    &__img {

      img {
        width: 288px;
        height: 162px;
      }
    }

    &__txt {
      padding: 19px 0 0 0;
      font-size: 20px;
    }
  }
}

.bpmn-blocks {
  padding-left: 10px;
}

.diagrams{
  padding-top: 10px;}

@media (max-width: 767px) {
  .bpmn-blocks {
    width: 360px;
    margin-left: 16px;

    .title__block {
      width: 230px;
    }
  }

}

.bg-block {
  @include bg-block-decor;
}

@media (max-width: 767px) {
  .bg-block {
    padding: 26px 20px 0 20px;
  }
}

.left-block-bpmn {
  padding-left: 0;

  &__flow-chart1 {
    margin-top: -109px;
    width: 558px;
    @media (min-width: 1400px) {
      margin-left:20px;
      width: 658px;
    }
  }

  &__prg-list {
    margin-left: -5px;
    width: 488px;
    margin-right: 40px;
    @media (min-width: 1400px) {
      width: 638px;
      margin-right: 80px;
    }

    @include prg-list-decor;

    li {
      margin-bottom: 50px;
    }
    li::before{
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background: #edc14e;
      margin-left: -19px;
      margin-top: 10px;}
  }

  &__float-chart1 {
    &__shema2 {
      padding-top: 50px;
      @include white-border;

      img {
        width: 598px;
        height: 316px;
      }
    }

    &__shema3 {
      padding-top: 98px;
      padding-bottom: 40px;
    }

    &__ps {
      max-width: 660px;
      margin-top: 80px;
    }
  }
}

.left-block-bpmn {
  &__float-chart1 {
    &__shema2::before {
      content: url("/images/img_dmn/MOBILE_strelka_2.svg");
      position: absolute;
      margin-top: 214px;
      margin-left: 281px;
      @media (min-width: 1400px) {
        margin-left: 361px;
      }
    }
  }
}

@media (max-width: 767px) {
  .left-block-bpmn {
    &__flow-chart1 {
      margin-top: 60px;
      margin-left: 16px;
      width: 328px;

      h3 {
        font-size: 16px;
        margin-bottom: 0;

      }

      .left-block-bpmn {
        &__float-chart1 {
          &__shema2 {
            padding-top: 38px;

            img {
              text-align: center;
              width: 292px;
              height: 154px;
            }
          }
        }
      }

      .left-block-bpmn {
        &__float-chart1 {
          &__shema3 {
            padding-top: 48px;
            padding-bottom: 20px;

            img {
              width: 289px;
              height: 127px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .left-block-bpmn {
    &__prg-list {
      width: 344px;
      margin-right: 0;

      ul {
        padding-left: 34px;
      }

      li {
        margin-bottom: 36px;
      }
    }
  }
}


@media (max-width: 767px) {
  .left-block-bpmn {
    &__float-chart1 {
      &__shema2::before {
        content: url("/images/img_dmn/MOBILE_min_strelka_2.svg");
        position: absolute;
        margin-top: 100px;
        margin-left: 225px;

      }
    }
  }
}

@media (max-width: 767px) {
  .left-block-bpmn {
    &__float-chart1 {
      &__ps {
        width: 328px;
        margin-top: 10px;
      }
    }
  }
}

.right-block-bpmn {
  &__flow-chart2::before {
    content: url("/images/img_dmn/MOBILE_strelka_1.svg");
    position: absolute;
    margin-top: 247px;
    margin-left: -340px;
  }
}

@media (max-width: 767px) {
  .right-block-bpmn {
    &__flow-chart2::before {
      content: url("/images/img_dmn/MOBILE_min_strelka_1.svg");
      position: absolute;
      margin-top: 267px;
      margin-left: 202px;
    }
  }
}

.right-block-bpmn {
  &__flow-chart2 {
    margin-left: 46px;
    width: 428px;
  }

  &__float-chart2 {
    &__shema1 {
      text-align: center;
      padding-top: 51px;
      padding-bottom: 109px;
    }
  }

  &__prg-list {
    p {
      width: 415px;
    }
    margin: 120px 0 80px 21px;
    width: 400px;

    @media (min-width: 1400px) {
      margin: 120px 0 80px 65px;
      width: auto;
    }
    @include prg-list-decor;

    ul {
      margin-top: 24px;
      margin-left: -20px;
    }

    li::before{
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background: #edc14e;
      margin-left: -19px;
      margin-top: 10px;}
  }

  &__dwn {
    padding-top: 48px;

    p {
      padding-bottom: 66px;
    }
  }
}

@media (max-width: 767px) {
  .right-block-bpmn {
    &__flow-chart2 {
      margin-left: 16px;
      width: 328px;

      h3 {
        font-size: 16px;
        margin: 0;
      }

      .right-block-bpmn {
        &__float-chart2 {
          &__shema1 {
            padding-top: 36px;
            padding-bottom: 88px;

            img {
              width: 249px;
              height: 411px;
            }
          }
        }
      }
    }

    &__prg-list {
      width: 328px;
      margin: 60px 16px 0 16px;
      padding: 0;

      p {
        width: 310px;
      }

      ul {
        margin-left: -16px;
        padding-left: 34px;
      }
    }

    &__dwn {
      padding-top: 26px;

      p {
        padding-bottom: 34px;
      }
    }
  }
}


.dmn-block {
  padding-left: 10px;

  &__head {
    width: 800px;
    padding-bottom: 36px;
  }

  &__up {
    padding-bottom: 120px;
  }

  &__dwn {
    padding-bottom: 120px;
  }
}

@media (max-width: 767px) {
  .dmn-block {
    margin-left: 16px;
    width: 328px;

    &__head {
      width: 328px;
      padding-bottom: 20px;
    }

    &__up {
      padding-bottom: 60px;
      width: 360px;

      .left-block-dmn {
        &__txt {
          li {
            @media (min-width: 1400px) {
              width: 328px;
            }
          }
        }
      }
    }

    &__dwn {
      width: 360px;
      padding-bottom: 70px;

      .left-block-dmn {
        &__txt {
          li {
            width: 328px;
          }
        }
      }
    }
  }
}

.blocks-txt {
  padding-top: 26px;
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .blocks-txt {
    font-size: 14px;
    padding-top: 24px;
    margin-bottom: 0;
    padding-bottom: 24px;
  }
}

.orng-head {
  @include orng-head;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .orng-head {
    font-size: 20px;

  }
}

@media (max-width: 767px) {
  .left-block-dmn {
    &__txt {

      ul {
        //list-style-type: none;
        //margin-left: -40px;
      }

      li {
        padding-bottom: 4px;
      }

      h3 {
        font-size: 16px;
      }

      p {
        margin: 0;
      }
    }
  }
}

.left-block-dmn {
  &__txt {
    width: 392px;
    @media (min-width: 1400px) {
      width: 502px;
    }

    ul {
      list-style-type: none;
      margin-left: -40px;
    }

    li {
      padding-bottom: 20px;
      @media (min-width: 1400px) {
        width: 490px;
      }
    }
  }
}

.right-block-dmn {
  &__shm {
    padding-right: 82px;
    .flow-chart3 {
      padding: 0 0 70px 0;

      p {
        font-size: 16px;
        padding-bottom: 41px;
      }

      img {

        width: 493px;
        height: 279px;
      }
    }
  }
}

@media (max-width: 767px) {
  .right-block-dmn {
    &__shm {
      margin-left: 16px;

      .flow-chart3 {
        padding-bottom: 40px;

        img {
          width: 288px;
          height: 163px;
        }
      }
    }
  }
}

.left-block-dmn {
  &__shm {
    padding-right: 50px;
    .flow-chart3 {
      padding: 0 0 70px 0;

      p {
        margin-bottom: 52px;
        font-size: 16px;
        padding-bottom: 50px;
      }

      img {

        height: 254px;
        width: 457px;
      }
    }
  }
}

@media (max-width: 767px) {
  .left-block-dmn {
    &__shm {
      margin-left: 16px;

      .flow-chart3 {
        padding: 0 0 40px 0;

        p{
          margin-bottom: 0;
        }

        img {
          height: 161px;
          width: 288px;
        }
      }
    }
  }
}

.hd-alg{
  padding-top: 40px;
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 30px;}

@media(max-width: 767px) {
  .hd-alg{
  font-size: 20px;}
}
.table-block {
  font-size: 20px;
  width: 1042px;
  margin-left: 116px;
  padding-right: 65px;
  padding-left: 47px;
  padding-top: 28px;
  background: $table-block-color;

  &__trs {
  }

  &__tr1, &__tr2, &__tr3, &__tr4, &__tr5 {
    @include white-border;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  &__td-txt {
    font-size: 24px;
  }
}

.td-left {
  padding-top: 48px;
  padding-bottom: 120px;
  padding-right: 100px;
}

.td-right {
  padding-top: 48px;
  padding-bottom: 120px;
}

.case-form {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .case-form {
    margin-top: 16px;
  }
}

.agree-label {
  width: 330px;
}

//@media (max-width: 767px) {
//  width: 276px;
//}

.dmn-download {
  background: $dmn-download-color;
  margin: 120px -20px 120px -20px;
  padding: 55px 0 82px 67px;

  &__form {
    max-width: 58%;

    ul {
      list-style-type: none;
      margin-left: -40px;
    }

    select:invalid {
      color: #fff;
    }
  }

  &__img {
    width: 400px;
    @media (min-width: 1400px) {
      width: 460px;
    }
    img {
      box-shadow: 0 3px 24px 0 rgba(0,0,0,.16);
    }

    .pic-img {
      display: block;
      margin: 0 auto;
    }

  }
}

@media (max-width: 767px) {
  .dmn-download {
    .title__block {
      margin-left: 0;
      margin-bottom: 0;
    }

    width: 360px;
    margin: 0;
    padding: 30px 16px 65px 16px;

    &__img {
      margin-top: 48px;
    }

    &__form {
      max-width: 328px;
    }

    select:invalid {
      color: #cfcfcf;
    }
  }
}

.dmn-form {
  &__input {
    border: none;
    height: 40px;
    width: 413px;
    margin-bottom: 12px;
    padding: 12px 0 12px 15px;
  }

  &__checkbox {
    margin-left: 224px;
    width: 413px;
  }

  &__label-value {
    display: inline-block;
    width: 224px;
  }
}

@media (max-width: 767px) {
  .dmn-form {
    &__label-value {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .dmn-form {
    &__input {
      width: 328px;
      height: 40px;
    }

    &__checkbox {
      font-size: 14px;
      width: 307px;
      margin-left: 0;
    }
  }
}

.dmn-download-btn {
  margin-top: 27px;
}

.select-corp {

  padding-left: 15px;
}

.select-corp option {
  color: #000;
}

.dmn-details {
  width: 80%;
  margin-left: 30px;
}

@media (max-width: 767px) {
  .dmn-details {
    width: 328px;
    margin-left: 16px;
    margin-right: 0;
    margin-top: 60px;
  }
}

.form-p {
  padding-bottom: 16px;
}

.label-p {
  margin: 0;
}

.elma-link--arrow a {
  position: relative;
  cursor: pointer;
  padding-bottom: 53px;
}

.corporation-input {
  border: none;
  height: 40px;
  width: 413px;
  margin-bottom: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.downloads-demo__input option[value=disabled] {
  color: #fff;
  display: none;
  opacity: 1;
}

@media (max-width: 767px) {
  .corporation-input {
    width: 328px;
    height: 40px;
  }
}

.dmn-form-check span::after {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 7px;
  margin-bottom: -2px;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px #d1d1d1;
}

.dmn-arrow3 {
  position: absolute;
  margin-top: 172px;
  margin-left: 795px;
  @media (min-width: 1400px) {
    margin-left: 1035px;
  }

  img {
    height: 385px;
  }
}

@media (max-width: 767px) {
  .dmn-arrow3 {
    position: absolute;
    margin-top: 565px;
    margin-left: 235px;

    img {
      height: 474px;
    }
  }
}

.elma-link-dmn {
  font-size: 18px;
  line-height: 24px;
}

@media (max-width: 767px) {
  .elma-link-dmn {
    font-size: 16px;
    width: 216px;
  }
}

.dmn-alg-end-li li {
  border-bottom: none;
}

.dmn-algorithm {
  margin-left: 58px;
  margin-right: 58px;
  background: #e9ebef;
  padding-left: 47px;
  padding-right: 55px;

  &__list {
    margin-left: 0;
    padding-left: 0;
    font-size: 0;

    li {
      display: flex;
      padding-top: 48px;
      list-style-type: none;
      font-size: 24px;
      border-bottom: 2px solid #ffffff;
    }
  }

  &__list-img {
    margin-bottom: 100px;
    width: 521px;

    &__2, &__4, &__6 {
      padding-left: 57px;
    }

    &__1, &__3, &__5 {
      padding-left: 88px;
    }
  }

  &__list-txt {
    width: 521px;

    &__2, &__4, &__6 {
      padding-left: 88px;
    }

    &__1, &__3, &__5 {
      width: 355px;
    }

    p {
      line-height: 1.7;
      font-size: 20px;
      @media (min-width: 1400px) {
        font-size: 24px;
      }
      //width: 355px;
    }
  }
}

.agree {
  margin-left: 201px;
}

@media (max-width: 767px) {
  .agree {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .dmn-algorithm {
    width: 328px;
    margin-left: 16px;
    padding-left: 20px;
    padding-right: 26px;
    margin-bottom: 64px;

    &__list {
      li {
        padding-top: 36px;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 60px;
      }
    }

    &__list-txt {
      p {
        margin-bottom: 35px;
        font-size: 20px;
      }

      font-size: 20px;

      &__2, &__4, &__5, &__6 {
        padding-left: 0;
      }
    }

    &__list-txt {
      order: 0;

      p {
        &__2 {
          width: 271px;
        }
      }
    }

    &__list-img {
      order: 1;
      margin-bottom: 0;

      &__2, &__4, &__5, &__6 {
        padding-left: 0;
      }

      &__1, &__3 {
        padding-left: 30px;
      }
      &__5{
        padding-left: 0;
      }
    }
  }
}

li.dmn-alg-end-li {
  border-bottom: none;
}

@media (max-width: 767px) {
  p.p-alg-2 {
    width: 271px;
  }
}
@media (max-width: 767px) {
  p.p-alg-3 {
    width: 286px
  }
}
@media (max-width: 767px) {
  p.p-alg-4 {
    width: 271px;
  }
}
@media (max-width: 767px) {
  p.p-alg-5 {
    width: 249px
  }
}
@media (max-width: 767px) {
  p.p-alg-6 {
    width: 260px;
  }
}
