.ia {
  &__title {
    padding-left: 35px;
  }

  &__description {
    padding-left: 35px;
  }

  &__button-primary {
    margin-left: 35px;
    margin-bottom: 32px;
    padding: 12px 35px;
  }
}

.ia-path {
  background-image: url('/upload/bg-ia/png5cbdb437844ab2/68187271/bg-ia.png');
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 1290px;
  margin-left: -35px;
  margin-right: -35px;
  margin-bottom: 35px;
  padding: 55px 67px;

  &__wrap-blocks {
    max-width: 546px;
  }

  &__description {
    margin-bottom: 7px;
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    color: white;
    font-weight: 400;
    margin-bottom: 12px;
  }

  &__description {
    font-size: 16px;
    line-height: 26px;
    color: white;
    margin-bottom: 15px;
    margin-right: 70px;
  }
}

.ia-helpers {
  max-width: 1290px;
  margin-left: -35px;
  margin-right: -35px;
  margin-bottom: 73px;
  padding: 55px 67px;
  background-color: white;
  background-image: url("/upload/Group 517/png5cbf0c201e5019/50969207/Group 517.png"), url("/upload/Group 518/png5cbf0c3bb53169/07675119/Group 518.png");
  background-repeat: no-repeat, no-repeat;
  background-position: 93% 17%, 91% 95%;
}

@media (max-width: $mobile-view) {
  .ia {
    &__title {
      padding-left: 16px;
    }

    &__description {
      padding-left: 16px;
    }

    &__button-primary {
      margin-left: 16px;
      margin-bottom: 32px;
      padding: 12px 35px;
    }
  }

  .ia-path,
  .ia-helpers {
    margin-left: -16px;
    margin-right: -16px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .ia-helpers {
    background-image: none;
  }

  .ia-path {
    &__description {
      width: 100%;
    }
  }
}
