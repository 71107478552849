.block-2-col {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;

    img {
        max-width: 100%;
        height: auto;
    }
}

@media (min-width: 768px) {
    .block-2-col {
        flex-direction: row;
        
        &__col {
            width: 50%;

            &--pl {
                padding-left: 80px;
            }
        }
    }
}

@media (min-width: 1024px) {
    .block-2-col {
        padding: 0;
    }
}