.ce {
  padding-top: 25px;
  margin-bottom: 66px;

  & &__title {
    margin-bottom: 22px;
  }

  &__description:first-of-type {
    margin-top: 22px;
  }

  &__description:last-of-type {
    margin-bottom: 23px;
  }

  &__description {
    margin-bottom: 6px;
    width: 826px;
  }

  &__subnav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1036px;
  }

  &__subnav-item {
    width: 480px;
    height: 198px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin-bottom: 15px;
    overflow: hidden;
    @media (min-width: 1400px) {
      width: 510px;
    }
  }

  &__subnav-item:hover .ce__subnav-layer {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    padding-top: 24px;
    color: white;
  }

  &__subnav-item--forum {
    background-image: url('/img/ce/_com2.png');
  }

  &__subnav-item--knowledge {
    background-image: url('/img/ce/_com3.png');
  }

  &__subnav-item--video {
    background-image: url('/img/ce/_com1.png');
  }

  &__subnav-item--reference {
    background-image: url('/img/ce/_com4.png');
  }

  &__subnav-layer {
    display: block;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding-top: 12px;
    -webkit-transform: translateY(145px);
    -ms-transform: translateY(145px);
    transform: translateY(145px);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  &__subnav-title {
    font-size: 22px;
    margin-left: 36px;
    margin-bottom: 13px;
  }

  &__subnav-description {
    margin-left: 36px;
    width: 428px;
  }
}

.ce-download {
  min-height: 532px;
  margin-left: -35px;
  margin-right: -35px;
  margin-bottom: 61px;
  padding-left: 35px;
  padding-top: 31px;
  background-color: white;
  background-image: url('/img/bg__ce-form.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  -webkit-box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);

  & &__title {
    width: 635px;
    margin-bottom: 14px;
  }

  &__description {
    margin-bottom: 22px;
    width: 644px;
  }

  &__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 535px;
  }

  &__form label {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px;
  }

  &__form label:nth-of-type(3) {
    margin-bottom: 10px;
  }

  &__form .ce-download__form-checkbox {
    display: block;
    position: relative;
    font-size: 13px;
    line-height: 26px;
    cursor: pointer;
    margin-bottom: 21px;
    margin-left: 178px;
  }

  &__form .ce-download__form-input {
    cursor: pointer;
    top: 20px;
    left: 5px;
  }

  &__form &__form-input + &__form-checkbox-icon {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    margin-bottom: -2px;
    -webkit-box-shadow: inset 0 0 0 1px #d1d1d1;
    box-shadow: inset 0 0 0 1px #d1d1d1;
  }

  &__form &__form-input:checked + &__form-checkbox-icon::before {
    position: absolute;
    content: "";
    top: 8px;
    right: 7px;
    width: 7px;
    height: 2px;
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
    background-color: #048ad3;
  }

  &__form &__form-input:checked + &__form-checkbox-icon::after {
    position: absolute;
    content: "";
    top: 6px;
    right: 1px;
    width: 11px;
    height: 2px;
    -webkit-transform: rotate(-59deg);
    -ms-transform: rotate(-59deg);
    transform: rotate(-59deg);
    background-color: #048ad3;
  }

  &__form input {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 4.5px 7px;
    border: none;
    -webkit-box-shadow: 0 0 0 1px #d1d1d1;
    box-shadow: 0 0 0 1px #d1d1d1;
  }

  &__form input::-webkit-input-placeholder {
    color: #d1d1d1;
  }

  &__form input:-ms-input-placeholder {
    color: #d1d1d1;
  }

  &__form input::-ms-input-placeholder {
    color: #d1d1d1;
  }

  &__form input::placeholder {
    color: #d1d1d1;
  }

  &__submit {
    border: none;
    padding: 12px 30px;
    margin-left: 178px;
    cursor: pointer;
    font-weight: bold;
    -webkit-box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
  }
}

.ce-comparison {
  margin-bottom: 5px;
  & &__title {
    width: 703px;
    margin-bottom: 9px;
  }

  &__description {
    width: 792px;
    margin-bottom: 62px;
  }
}

.compare-elma {
  padding-left: 35px;
  padding-right: 35px;
  margin-left: -35px;
  margin-right: -35px;

  &__product {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 0;
    display: block;
  }

  &__list-wrap {
    margin-bottom: 0;
  }

  &__title {
    position: relative;
    background-color: white;
    font-size: 20px;
    line-height: 30px;
    color: black;
    padding: 10px 30px;
    padding-left: 35px;
    margin-left: -35px;
    margin-right: -35px;
    margin-bottom: 0;
    -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
  }

  &__title-arrow {
    position: absolute;
    right: 21px;
    top: 18px;
    width: 12px;
    height: 12px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }

  &__title-arrow--up {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  &__title-arrow::before {
    position: absolute;
    content: "";
    top: 5px;
    right: 4px;
    width: 11px;
    height: 2px;
    background-color: #048ad3;
    border-radius: 1px;
    -webkit-transform: rotate(47deg);
    -ms-transform: rotate(47deg);
    transform: rotate(47deg);
  }

  &__title-arrow::after {
    position: absolute;
    content: "";
    top: 5px;
    right: -3px;
    width: 11px;
    height: 2px;
    background-color: #048ad3;
    border-radius: 1px;
    -webkit-transform: rotate(-47deg);
    -ms-transform: rotate(-47deg);
    transform: rotate(-47deg);
  }
}

.table__row:first-of-type {
  width: 299px;
  padding-left: 35px;
  padding-right: 10px;
  padding-top: 21px;
  padding-bottom: 17px;
}

.table__row:nth-of-type(2) {
  width: 453px;
  background-color: #dee2e6;
  padding-left: 31px;
  padding-right: 10px;
  padding-top: 21px;
  padding-bottom: 17px;
}

.table__row:nth-of-type(3) {
  width: 541px;
  padding-left: 35px;
  padding-top: 21px;
  padding-bottom: 17px;
}


.table--hidden {
  overflow: hidden;
  max-height: 12px;
  -webkit-transition: max-height 0.8s;
  -o-transition: max-height 0.8s;
  transition: max-height 0.8s;
}

.table--show {
  max-height: 1500px;
}

.table {
  margin-bottom: 0;
  overflow: hidden;
}

.table__column {
  margin-left: -35px;
  margin-right: -35px;
  margin-bottom: 0;
}

.table__column:last-of-type .table__row {
  padding-bottom: 27px;
}
.table__description {
  width:97%;
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: #636363;
}

.compare-elma b {
  display: inline-block;
  margin-bottom: 5px;
}

.table__btn {
  display: inline-block;
  padding: 10px 31px;
  font-weight: bold;
  color: white;
  margin: 10px 0;
  margin-top: 15px;
  -webkit-box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
}

.table__row:last-of-type .compare-elma__download-link {
  margin-bottom: 0;
  margin-top: 40px;
}

.table__row {

}

.bpms__item .item-plus {
  padding-right: 10px;
}

.compare-elma b {
  padding-right: 5px;
}

.table__row ul {
  list-style: disc;
  padding-left: 17px;
}

.table__row li {
  margin-bottom: 7px;
  width: 93%;
}

.item-plus {
  list-style: none;
  position: relative;
}

.item-plus::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 2px;
  top: 12px;
  left: -19px;
  background-color: black;
}

.item-plus::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 10px;
  top: 12px;
  left: -19px;
  background-color: black;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

@media (max-width: 420px) {
  .ce {
    width: auto;

    &__title,
    &__description {
      width: 100%;
    }

    &__subnav-list {
      width: auto;
    }

    &__subnav-item {
      width: 100%;
      height: auto;
      padding-top: 34vw;
      background-size: contain;
      background-position: top;
      background-color: rgba(0, 0 ,0, 0.6);
    }

    &__subnav-layer {
      transform: translateY(0);
      padding: 15px 16px;
      background: none;
    }

    &__subnav-title,
    &__subnav-description {
      margin-left: 0;
      margin-right: 0;
    }

    &__subnav-description {
      width: 100%;
    }
  }

  .ce-download {
    width: auto;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 46px;
    background-image: none;

    & &__title,
    &__form {
      width: 100%;
    }

    &__form &__form-checkbox {
      margin-left: 0;
    }

    &__submit {
      margin-left: 0;
      width: 100%;
    }
  }

  .ce-comparison {
    width: auto;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    overflow: scroll;

    & &__title,
    & &__description {
      width: 100%;
    }
  }
}
