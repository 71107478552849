.case-problem {
  margin: auto;

  &__problem {
    display: block;
    font-size: 30px;
    line-height: 22px;
    margin-bottom: 26px;
  }

  &__description {
    font-size: 18px;
    line-height: 30px;
  }

  &__list {
    list-style: none;
    padding-left: 105px;
    padding-right: 130px;
    margin-bottom: 70px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
  }

  &__item {
    border-top: 8px solid #edc14c;
    padding-top: 10px;
    width: 25%;
    margin-right: 12%;
  }

  &__item:last-of-type {
    margin-right: 0;
  }

  &__subtitle {
    font-size: 24px;
    line-height: 30px;
  }

  &__item ul {
    padding-left: 0;
    list-style: none;
  }

  &__item li {
    margin-bottom: 10px;
  }
}

.case-problem--retail {
  margin: auto;
  margin-bottom: 67px;

  .case-problem {
    &__problem {
      margin-bottom: 32px;
    }

    &__decision .case-problem__problem {
      margin-bottom: 22px;
    }

    &__decision .case-problem__description {
      width: 68%;
      margin-bottom: 37px;
    }

    &__list {
      margin-bottom: 114px;
      padding: 0;
      width: 92.5%;
    }

    &__item {
      padding-top: 13px;
    }

    &__subtitle {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.case-problem--retail-demo {
  margin: auto;
  margin-bottom: 101px;

  .case-problem {
    &__title {
      margin-bottom: 14px;
    }

    &__description {
      width: 56%;
      margin-bottom: 39px;
    }
  }
}

.case-problem--travel {
  margin: auto;

  .case-problem {
    &__problem-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 45px;
    }

    &__problem-fill {
      width: 50%;
    }

    &__board {
      margin-top: 18px;
      margin-right: 27px;
      padding: 25px 35px;
      padding-right: 47px;
      background-color: #e6e6e6;
      width: 31%;
      box-sizing: border-box;
    }

    &__board p,
    &__board b {
      font-size: 18px;
      line-height: 30px;
    }

    &__problem {
      margin-bottom: 32px;
    }

    &__problem-fill .case-problem__problem {
      margin-bottom: 22px;
    }

    &__list {
      width: 96%;
      padding: 0;
      justify-content: space-between;
      margin-bottom: 114px;
    }

    &__item {
      width: 236px;
      margin-right: 0;
      padding-top: 13px;
    }

    &__subtitle {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.case-problem--sell {
  margin: auto;
  margin-bottom: 80px;

  .case-problem {
    &__problem-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 45px;
    }

    &__problem-wrap {
      @media (min-width: 1400px) {
        margin-right: -20px;
      }
    }

    &__problem-fill {
      width: 58%;
    }

    &__board {
      margin-top: 18px;
      padding: 25px 35px;
      padding-right: 47px;
      background-color: #e6e6e6;
      width: 36%;
      box-sizing: border-box;
    }

    &__board p,
    &__board b {
      font-size: 18px;
      line-height: 30px;
    }

    &__problem {
      margin-bottom: 32px;
    }

    &__problem-fill .case-problem__problem {
      margin-bottom: 22px;
    }

    &__list {
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 83px;
    }

    &__item {
      width: 290px;
      margin-right: 0;
      padding-top: 13px;
      @media (min-width: 1400px) {
        width: 319px;
      }
    }

    &__item:not(:last-of-type) {
      margin-right: 50px;
      @media (min-width: 1400px) {
        margin-right: 102px;
      }
    }

    &__subtitle {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 24px;
    }

    &__subtitle + p {
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.case-problem--farm {
  padding: 42px 0;
  padding-left: 50px;

  .case-problem {
    &__title {
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 46px;
    }

    &__list {
      padding-left: 0;
    }

    &__item {
      border-top: 5px solid $color-dark-green;
      padding-top: 14px;
    }

    &__subtitle {
      margin-bottom: 33px;
    }
  }
}

.case-problem--mriya {
  .case-problem__item {
    width: 45%;
  }

  .case-problem__item {
    margin-right: 44px;
  }

  .case-problem__item:nth-of-type(even) {
    margin-right: 0;
  }
}

.s-story-quote-a__wrap-img--mriya {
  object-fit: cover;
}


@media (max-width: $mobile-view) {
  .case-problem {
    width: 100%;

    &__problem {
      font-size: 20px;
      line-height: 22px;
      padding-right: 16px;
      padding-left: 16px;
    }

    &__subtitle {
      font-size: 20px;
      line-height: 26px;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
      padding-right: 16px;
      padding-left: 16px;
    }

    &__list {
      padding-left: 16px;
      padding-right: 52px;
      margin-bottom: 0;

    }

    &__item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 35px;
    }

    &__item ul {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .case-problem--retail {
    width: 100%;
    margin-bottom: 36px;

    .case-problem {
      &__subtitle {
        margin-bottom: 0;
      }

      &__list {
        margin-bottom: 36px;
        padding: 0 16px;
      }

      &__item {
        margin-bottom: 24px;
      }

      &__item:nth-of-type(2) {
        width: 100%;
        padding-left: 0;
        border: none;
        padding-top: 0;
      }

      &__decision .case-problem__description {
        width: 100%;
      }
    }
  }

  .case-problem--retail-demo {
    width: 100%;
    margin-bottom: 63px;

    .case-problem {
      &__title {
        font-size: 20px;
        line-height: 30px;
        width: 100%;
        padding: 0 16px;
      }

      &__description {
        width: 100%;
      }
    }
  }

  .case-problem--travel {
    width: 100%;
    margin-bottom: 63px;

    .case-problem {
      &__title {
        font-size: 20px;
        line-height: 30px;
        width: 100%;
        padding: 0 16px;
      }

      &__description {
        width: 100%;
      }

      &__list {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 0;
      }

      &__problem-fill {
        width: 100%;
      }

      &__board {
        width: 100%;
        margin-top: 0;
        margin-right: 16px;
        margin-left: 16px;
        padding: 20px 16px;
      }
    }
  }

  .case-problem--sell {
    width: 100%;
    margin-bottom: 63px;

    .case-problem {
      &__title {
        font-size: 20px;
        line-height: 30px;
        width: 100%;
        padding: 0 16px;
      }

      &__description {
        width: 100%;
      }

      &__list {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 0;
      }

      &__item {
        width: 100%;
      }

      &__item:not(:last-of-type) {
        width: 100%;
        margin-right: 0;
      }

      &__problem-wrap {
        width: 100%;
      }

      &__problem-fill {
        width: 100%;
      }

      &__board {
        width: 100%;
        margin-top: 0;
        margin-right: 16px;
        margin-left: 16px;
        padding: 20px 16px;
      }
    }
  }

  .case-problem--farm {
    padding: 36px 16px;

    .case-problem {
      &__list {
        padding-right: 0;
      }

      &__item {
        width: 100%;
      }
    }
  }
}
