.submit-spinner {
  position: relative;
  &_active {
    pointer-events: none;
  }
  &_active &__wrap {
    opacity: 1;
  }
  &__wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0d4a75;
    transition: 0.3s;
    opacity: 0;
  }
  &__dot {
    margin: 0 4px;
    width: 10px;
    height: 10px;
    background-color: #5082E6;
    border-radius: 50%;

    animation: spinner-bounce 1.4s infinite ease-in-out;

    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    &_1 {
      animation-delay: -0.48s;
      background-color: #E62D32;
    }
    &_2 {
      animation-delay: -0.32s;
      background-color: #FABE00;
    }
    &_3 {
      animation-delay: -0.16s;
      background-color: #5AD2FF;
    }
  }
}

@keyframes spinner-bounce {
  0%, 80%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }
  40% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}