.video-modal {
  .modal-dialog {
    max-width: 800px !important;
    margin: 30px auto;
  }

  .modal-body {
    position:relative;
    padding:0px;
  }
  .close {
    position:absolute;
    right:0px;
    top:-34px;
    z-index:999;
    font-size:2rem;
    font-weight: normal;
    color:#fff;
    opacity:1;

    @media (min-width: 900px) {
      right:-30px;
      top:0;
    }
  }
}