.s-story-quote-a {
  &__wrap {
    position: relative;
    padding: 27px 36px;
    padding-bottom: 40px;
    margin-bottom: 64px;
  }

  &__wrap::before,
  &__wrap::after {
    position: absolute;
    content: '';
    height: 1px;
    right: 16px;
    left: 16px;
    background-color: #d3d9db;
  }

  &__wrap::before {
    top: 0;
  }

  &__wrap::after {
    bottom: 0;
  }

  &__wrap-img img {
    display: none;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    padding-left: 8px;
  }

  &__description:last-of-type {
    margin-bottom: 0;
  }

  &__wrap-author {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
  }

  &__wrap-author-img {
    padding-right: 17px;
  }

  &__wrap-author-img img {
    border-radius: 50%;
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  }

  &__wrap-position b {
    font-size: 14px;
    line-height: 22px;
  }

  &__wrap-position p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
  }
}

@media (min-width: $desktop-view ) {
  .s-story-quote-a {
    &__wrap {
      display: flex;
      justify-content: space-between;
      padding: 65px;
      padding-bottom: 81px;
      margin-bottom: 86px;
    }

    &__wrap-img {
      width: 35%;
    }

    &__wrap-img img {
      display: block;
      border-radius: 50%;
      box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    }

    &__wrap-text {
      width: 80%;
      padding-top: 9px;
    }

    &__description {
      font-size: 20px;
      line-height: 35px;
      padding-left: 0;
    }

    &__wrap-author {
      justify-content: flex-start;
      padding-top: 26px;
    }

    &__wrap-author-img {
      padding-right: 0;
    }

    &__wrap-author-img img {
      display: none;
    }

    &__wrap-position b {
      font-size: 16px;
      line-height: 26px;
    }

    &__wrap-position p {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 0;
    }
  }
}


@media (min-width: 1400px ) {
  .s-story-quote-a {
    &__wrap {
      padding: 65px 126px;
    }
  }
}