.contacts {
  padding-top: 24px;
  margin-left: 16px;
  margin-right: 16px;

  & &__title {
    margin-bottom: 22px;
  }

  &__list-cities {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: -5px;
    margin-bottom: 34px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  &__item-cities {
    padding: 1px 4px;
    margin-right: 22px;
    color: #048ad3;
    cursor: pointer;
  }

  &__item-cities--active {
    color: black;
    background: white;
  }

  &__map {
    margin-bottom: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 325px;
    -webkit-box-shadow: 0 0 40px 1px rgba(120, 120, 120, 0.2);
    box-shadow: 0 0 40px 1px rgba(120, 120, 120, 0.2)
  }

  &__map iframe {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  &__toggle-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: -35px;
    margin-right: -35px;
  }

  &__toggle-items {
    margin: 0;
    padding: 0;
  }
}

.contacts-information {
  line-height:21px;
  background-color: white;
  padding:48px;
  width: 438px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  &__title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }

  &__number-wrap {
    line-height: 21px;
    margin-bottom: 5px;
  }

  &__number {
    font-size: 30px;
    color: black;
  }

  &__address {
    margin-bottom: 0;
  }

  &__email-wrap {
    margin-bottom: 11px;
  }

  &__menu-list {
    list-style: none;
    padding: 0;
    margin:0;
    margin-top: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 310px;
  }
}

@media (max-width: $mobile-view) {
  .contacts__toggle-list {
    width: auto;
    margin-left: -16px;
    margin-right: -16px;
  }

  .contacts__map {
    width: auto;
  }

  .contacts__leadership-list {
    width: auto;
    padding-left: 16px;
  }

  .contacts-information {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .contacts-information__menu-list {
    width: 100%;
  }

  .contacts-information__support-wrap {
    margin-bottom: 15px;
  }
}
