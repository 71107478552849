.news {
  &__list {
    width: 825px;
    list-style: none;
    padding: 0;
  }

  &__item {
    margin-bottom: 40px;
  }

  &__date {
    font-size: 14px;
    line-height: 22px;
    color: #636363;
    margin-bottom: 8px;
  }

  &__link-wrap {
    margin-bottom: 6px;
  }

  & strong {
    color: black;
  }

  &__pseudo-link {
    color: #048ad3;
  }

  &__pseudo-link:hover,
  &__pseudo-link:hover {
    color: #f0523e;
  }
}

@media (max-width: $mobile-view) {
  .news {
    &__list {
      width: 100%;
    }

    &__item {
      width: 100%;
    }
  }
}
