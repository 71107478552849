.about-block {
  margin-bottom: 56px;

  @media (min-width: 768px) {
    margin-bottom: 80px;
  }

  &__text {
    font-size: 12px;
    line-height: 1.8;
    margin-top: 16px;
    margin-bottom: 0;
    @media (min-width: 576px) {
      font-size: 18px;
    }
    @media (min-width: 992px) {
      margin-top: 36px;
    }
  }

  &__bot-text, &__bot-link {
    @media (max-width: 575px) {
      font-size: 12px;
      line-height: 1.8;
    }
  }

  &__deming {
    margin: 36px 0 42px;
    @media (min-width: 768px) {
      margin: 100px 0;
    }
  }

  &__link {
    @media (max-width: 991px) {
      display: none;
    }
  }

  &__video {
    margin-top: 32px;
    @media (min-width: 768px) {
      margin: 0;
    }
  }
}