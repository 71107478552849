.s-story-release {
  &__wrap {
    position: relative;
    padding: 25px 16px;
    padding-bottom: 46px;
    background-color: #e9ebef;
  }

  &__wrap::before {
    position: absolute;
    content: '';
    height: 2px;
    right: 16px;
    left: 16px;
    background-color: #ffffff;
    bottom: 0;
  }

  &__title {
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 23px;
  }

  &__wrap-list {
    padding-top: 26px;
    padding-left: 28px;
  }

  &__partner {
    display: flex;
    flex-direction: column;
    padding: 28px 16px;
    padding-bottom: 30px;
    background-color: #e9ebef;
  }

  &__wrap-partner-img {
    order: 2;
  }

  &__partner-description {
    order: 1;
  }

  &__partner-description {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 23px;
  }

  &__wrap-partner-img img {
    width: 100%;
    height: 39vw;
    margin-bottom: 15px;
  }

  &__wrap-partner-img figcaption {
    font-size: 14px;
    line-height: 22px;
    color: #636363;
  }
}

.s-story-release--sadco {
  .s-story-release {
    &__wrap {
      background: none;
    }

    &__wrap::before {
      display: none;
    }
  }
}

@media (min-width:$desktop-view) {
  .s-story-release {
    &__wrap {
      margin-left: 40px;
      margin-right: 40px;
      padding: 59px 47px;
      padding-bottom: 55px;
    }

    &__wrap::before {
      right: 65px;
      left: 47px;
    }


    &__wrap-content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__wrap-left {
      width: 60%;
    }

    &__title {
      font-size: 30px;
      line-height: 26px;
      margin-bottom: 20px;
    }

    &__description {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 26px;
      width: 75%;
    }

    &__wrap-list {
      padding-top: 0;
      padding-left: 0;
      width: 33.5%;

      &_width {
        width: 23.5%;
      }
    }

    &__wrap-list .s-story__target-item {
      margin-bottom: 12px;
    }

    &__wrap-list .s-story__target-text {
      margin-top: -5px;
      font-size: 16px;
      line-height: 26px;
    }

    &__partner {
      margin-left: 40px;
      margin-right: 40px;
      padding: 59px 47px;
      padding-bottom: 75px;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__partner-description {
      width: 44.6%;
      padding-top: 5px;
      padding-right: 5%;
      order: 2;
    }

    &__wrap-partner-img {
      width: 50%;
      order: 1;
    }

    &__wrap-partner-img img {
      width: 460px;
      height: 198px;
    }

    &__wrap-partner-img figcaption {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .s-story-release--sadco {
    .s-story-release {
      &__wrap {
        width: 100%;
        margin-left: 0;
      }

      &__description {
        width: 95%;
      }
    }

    .s-story__target-item {
      width: 279px;
    }

    .s-story-quote-b__position {
      padding-top: 6px;
      padding-right: 23px;
    }

    .s-story-quote-b__avatar {
      width: 60px;
      height: 60px;
    }

    .s-story-quote-b__author-description {
      padding-top: 0;
    }
  }

  .s-story-release--obninsk {
    margin-bottom: 80px;

    .s-story-about-company__title {
      margin-bottom: 30px;
    }
  }
}
@media (min-width: 1400px) {
  .s-story-release {
    &__wrap {
      margin-left: 68px;
      margin-right: 68px;
    }

    &__partner {
      margin-left: 68px;
      margin-right: 68px;
    }
  }
}