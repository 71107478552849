.crumbs-list {
  list-style: none;
  padding: 0;
  padding-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 80px;
  margin-bottom: 24px;
  border-top: 1px solid #EEEEEE;
  color: #CECECE;
}

.crumbs-item {
  margin-right: 13px;
  position: relative;
}

.crumbs-item a {
  color: inherit;
}

.crumbs-item:not(:last-of-type)::before {
  content: '/';
  position: absolute;
  top: 0;
  right: -9px;
  color: inherit;
}
