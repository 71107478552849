body {
  background: $white;
  -webkit-font-smoothing: antialiased;
}

hr {
	border-top: 1px solid #d2d8db;
}


a {
	text-decoration: none;
    color: #0d4a75;
    transition: 0.2s ease-in-out;
	&:hover{
		text-decoration: none;
        color: #6e91ab;
//		border-bottom: 1px solid rgba(theme-color("primary"), .3);
	}
}

    section.redaction ul li {
        list-style: none;
        display: inline-block;
        background: #048ad3;
        border-radius: 8px;
        padding: 0 0.4em;
        margin-right: 0.3em;
        margin-bottom: 0.3em;
        color: white;
    }
    section.redaction ul {
        padding: 0;
        margin: 0;
    }
    section.redaction .elma-ce li {
        background: #dbdbdb;
    }

    h2, .h2 {
        font-size: 1.875rem;
        line-height: 2.375rem;
    }

    h3, .h3 {
        font-size: 1.125rem;
        line-height: 1.625rem;
    }


.leftcitation:before, .rightcitation:before, .centercitation:before {
  content: "«";
  font-size: 4rem;
  position: absolute;
  margin-left: -2.2rem;
  margin-top: -0.4rem;
}


.elma-link {
     font-weight: bolder;
	&:after {

    }
}

nav.navbar form{
  input {
	border: none;
    padding: 6px 20px;
    padding-right: 2.2rem;
	border-radius: 20px;
	font-size: 0.9em;
    background-color: #E3E3E3;
    outline: none;
  }

  input:focus {
    outline: none;
  }

  input:-webkit-autofill {
    -webkit-box-shadow:inset 0 0 0 1000px #E3E3E3;
  }


  svg.feather-search {
	    position: absolute;
	    margin-left: -2.6rem;
	    width: 1.2rem;
	    height: 2rem;
  }

  a {
    color: $navbar-light-color;

  }

  button {
    position: absolute;
    content: "";
    width: 25px;
    height: 25px;
    top: 50%;
    margin-top: -12.5px;
    right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-repeat: no-repeat;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
  }
}

.bg-secondary.btn:hover {
    background: #cddfed !important;
	box-shadow: 0 0.25rem 0.875rem 0 rgba(0,40,61,.2);
}

.bg-secondary.btn {
    box-shadow: none;
}



.btn{
	min-width: 9.375rem;
	//box-shadow: 0 0.25rem 0.875rem 0 rgba(0, 40, 61, 0.2);
	margin-right: 1rem;
	margin-bottom: 1rem;
	&-primary:hover {
	    color: #fff;
	    background-color: #0d4a75;
	    border-color: #0d4a75;
	}
  &-bordered {
    background-color: transparent;
    color: #0d4a75;
    border: 1px solid #0d4a75;
  }

}
.box-shadow{
	box-shadow: 0px 1.25rem 2.5rem 0 rgba(0, 0, 0, 0.2);
}

* > .btn:last-of-type {
    margin-right: 0;
}


section#header-bannner {
	position: relative;
    padding-top: 44.7%;
    background-size: cover !important;
    > * {
		bottom: 0px;
	}
	h1 {
		line-height: 1.1;
	}

}
h3, .h3{
	font-weight: $font-weight-bold;
}
.h25 {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    line-height: 2rem;
}

#ecm-clircle .text-dark a{
	color: inherit;
}

.navbar-toggler {
    border: none;
}

a.navbar-brand:hover {
	border: 0;
}

.navbar-collapse.show {
    min-height: 40em;
}

//.navbar-collapse.collapse:not(.show) {
//    display: none!important;
//}

/*
      @include media-breakpoint-up(md) {
        .navbar-expand-md .navbar-collapse {
          display: none !important;  // stylelint-disable-line declaration-no-important
        }

       .navbar-expand-md .navbar-toggler {
    		display: inline-block;
		}

       .navbar.navbar-expand-md {
           display: flex;
		   justify-content: space-between;
       }

      }
*/


/*

      @include media-breakpoint-up(wm) {
        flex-flow: row nowrap;
        justify-content: flex-start;


		.navbar-expand-md .navbar-collapse {
//    flex-basis: 100%;
//    flex-grow: 1;
//    align-items: center;
    	    display: block!important;
		    flex-basis: auto;
		}

		.navbar-nav {
          flex-direction: row;

          .dropdown-menu {
            position: absolute;
          }

          .nav-link {
            padding-right: $navbar-nav-link-padding-x;
            padding-left: $navbar-nav-link-padding-x;
          }
        }

        // For nesting containers, have to redeclare for alignment purposes
        > .container,
        > .container-fluid {
          flex-wrap: nowrap;
        }

        .navbar-expand-md .navbar-collapse {
          display: flex !important;  // stylelint-disable-line declaration-no-important

          // Changes flex-bases to auto because of an IE10 bug
          flex-basis: auto;
        }

        .navbar-toggler {
          display: none !important;
        }
      }
*/

form.form-search input[type="search"] {
	mask: url(/img/assets/search.svg) no-repeat 1.1rem center;
    mask-size: 1rem;
    background-color: $secondary !important;

//    background-image: url(/img/assets/search.svg);
//    background-repeat: no-repeat;
//    background-position: calc(100% - 0.6rem);
//    background-size: 1.1rem;
}


.navbar-light .navbar-toggler-icon{
	width: 1rem;
	height: 1rem;
}

.navbar-toggler[aria-expanded="true"]:not(:disabled):not(.disabled):not(.collapsed)  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-close;
    width: 1rem;
    height: 1rem;
  }

a .box-shadow{
	transition: all 0.5s;
}
a:hover .box-shadow {
	transform: translate(0, -0.2rem);
	box-shadow: 0 1.25rem 3rem 0 rgba(0,0,0,.2);
}

@include media-breakpoint-down(xs) {
	.container-fluid {
	    padding-right: 0;
	    padding-left: 0;
	}

	.container-fluid > * {
	    padding-right: calc($grid-gutter-width / 3);
	    padding-left: calc($grid-gutter-width / 3);
	}
}

@include media-breakpoint-up(sm) {
	.fixed-sm-top {
	    position: fixed;
	    top: 0;
	    right: 0;
	    left: 0;
	    z-index: 1030;
	}
	.position-sm-absolute {
  		position: absolute;
  	}
	section#header-bannner {
//	    background-size: contain !important;
	}

	.text-sm-white {
	    color: $white !important;
	}
	.sm-h3 {
	    font-size: $h3-font-size;
	    font-weight: $font-weight-bold;
	}
	.container-fluid > * {
	    padding-left: $grid-gutter-width;
	    padding-right: $grid-gutter-width;
	}
	.w-sm-50 {
	    width: 50%!important;
	}
}

@include media-breakpoint-up(md) {
	.position-md-absolute {
  		position: absolute;
  	}
}

@include media-breakpoint-up(xl) {
	html {
//  		font-size: 1.3rem;
  	}
}

span.currency_name {
    text-transform: initial!important;
}

p {
	line-height: 1.625rem;
}

.animation {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  border-radius: 50%;
  margin: 0 auto;
}
.animation > * {
  position: relative;
  z-index: 1;
}
.animation::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  margin-left: -0.5px;
  margin-top: -0.5px;
  animation-name: scale;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes scale {
  0% {
      box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.7),
              0 0 0 7px rgba(255, 255, 255, 0.6),
              0 0 0 14px rgba(255, 255, 255, 0.5),
              0 0 0 21px rgba(255, 255, 255, 0.4),
              0 0 0 28px rgba(255, 255, 255, 0.3);
  }

  100% {
      box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.6),
                  0 0 0 14px rgba(255, 255, 255, 0.5),
                  0 0 0 21px rgba(255, 255, 255, 0.4),
                  0 0 0 28px rgba(255, 255, 255, 0.3),
                  0 0 0 35px rgba(255, 255, 255, 0);
  }
}

.youtube {
  &__wrap {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 2;
  }

  &__button-play {
    z-index: 2;
    &:hover {
      src: "/image/youtube_play_hover.png";
    }
  }

  &__wrap:hover,
  &__wrap:focus {
    cursor: pointer !important;
  }

  &__wrap.hidden &__button-play {
    position: absolute;
    opacity: 0;
    transition: 0.5s;
  }

  &__wrap.hidden iframe {
    width: 100%;
    height: 100%;
    z-index: 3;
  }

  &__wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 0;
    height: 0;
  }

  &__wrap[data-img=false] {
    background-image: url('/image/youtube_default.png');
  }
}

img[data-name=SK] {
  transition: 0.3s;
}

img[data-name=SK]:hover {
  box-shadow: 0 6px 19px rgba(0, 0, 0, 0.19);
}

.call-to-action-small__button {
  border: 1px solid #0a2c58;
}

.call-to-action-small__button:hover {
  color: #0a2c58 !important;
}
