.partners {
  padding-top: 25px;

  & &__title {
    margin-bottom: 23px;
  }

  &__description {
    margin-top: 31px;
    margin-bottom: 15px;
    width: 760px;
  }

  &__wrap-map {
    background-image: url('/img/map/map_partners_without_citypoints3.svg');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: -7px 0;
    min-height: 653px;
  }

  &__wrap-map .abs-city:hover::before,
  &__wrap-map .abs-city.active::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 11px;
    height: 11px;
    border: 1px solid #3889c7;
    border-radius: 50%;
    top: 4px;
    left: -3px;
  }

  &__wrap-map .abs-city:hover span.cityname,
  &__wrap-map .abs-city.active span.cityname {
    display: inline-block;
  }

  &__wrap-map span.cityname {
    display: none;
  }

  &__wrap-map .abs-city span.point {
    display: inline-block;
    background: #3889c7;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 7px;
    vertical-align: top;
    margin-top: 7px;
  }

  &__wrap-cities {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 31px;
    max-width: 1202px;
  }

  &__city-block:first-of-type {
    width: 100%;
  }

  &__city-block {
    min-width: 197px;
    margin-bottom: 59px;
    @media (min-width: 1400px) {
      min-width: 257px;
    }
  }

  &__city-title {
    font-size: 24px;
    line-height: 34px;
    padding-bottom: 11px;
    margin-bottom: 15px;
    border-bottom: 1px solid #d3d9db;
  }

  &__city-list-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &__city-list {
    min-width: 197px;
    list-style: none;
    padding: 0;
    margin: 0;
    @media (min-width: 1400px) {
      min-width: 257px;
    }
  }

  &__city-item {
    margin-bottom: 12px;
  }
}

@media (max-width: 420px) {
  .partners {
    width: auto;

    &__title,
    &__description {
      width: 100%;
    }

    &__description {
      margin-bottom: 36px;
    }

    &__wrap-link {
      margin-bottom: 36px;
    }

    &__wrap-map {
      display: none;
    }

    &__wrap-cities {
      width: 100%;
    }

    &__city-block {
      width: 100%;
    }
  }
}
