.form-t {
  .form-group {
    margin-bottom: 7px;
    position: relative;
  }

  .required-field {
    padding-top: 12px;
    position: relative;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .form-control-file {
    display: none;
  }

  .form-control_flat {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid;
    padding-bottom: 2px;
    padding-left: 0;
    background: none;
    color: inherit;
  }

  .form-control {
    color: rgba(33,37,41,1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    position: relative;
    z-index: 2;
    font-size: 1rem;
    outline: none;
  }

  .form-control.parsley-error {
    border-bottom-color: red;
  }

  input[type=checkbox].parsley-error + .registration__label--icon {
    box-shadow: 0 0 0 1px red;
  }

  &.form-control:focus {
    border-bottom-color: rgb(13, 74, 117);
    box-shadow: none;
  }

  .form-control:focus + label {
    top: 0;
    font-size: 13px;
    color: rgb(13, 74, 117);
    opacity: 1;
  }


  // form .form-control:valid + label {
  //     top: 0;
  //     font-size: 13px;
  //     color: #5db3e6;
  //     opacity: 1;
  // }

  & .form-control:placeholder-shown + label {
    opacity: 0;
    transform: translateY(1rem);
  }

  .floating_label label {
    display: inline-block;
    position: absolute;
    top: 23px;
    font-size: 13px;
    z-index: 1;
    color: #000;
    opacity: 0.4;
    transition: all 0.4s ease;
    white-space: nowrap;
  }

  .floating_label.filled label {
    color: #000;
    top: 0;
    font-size: 10px;
    opacity: 1;
    z-index: 5;
  }

  .form-control:focus {
    border-bottom-color: rgb(13, 74, 117);
  }

  .form-control:focus + label {
    top: 0;
    font-size: 13px;
    color: rgb(13, 74, 117);
    opacity: 1;
    z-index: 5;
  }

  .form-control:placeholder-shown + label {
    opacity: 0;
    transform: translateY(1rem);
  }

  .floating_label label {
    display: inline-block;
    position: absolute;
    top: 23px;
    font-size: 13px;
    z-index: 1;
    color: #000;
    opacity: 0.4;
    transition: all 0.4s ease;
  }


  .parsley-errors-list {
    display: none;
  }

  .parsley-error .registration__label--icon {
    box-shadow: 0 0 0 1px red;
  }

  & .custom-checkbox {
    padding-top: 19px;
  }

  & .btn {
    padding: 16px 20px;
    font-family: "Inter", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
  }

  & .buttons-r {
    padding-top: 19px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  & .buttons-r p {
    margin: 0;
    display: block;
    width: 42%;
    color: #B2B2B2;
    font-size: 13px;
    line-height: 19px;
  }

  & label[for=trial-modal-agree] span {
    font-size: 16px;
    line-height: 150%;
  }

  & .registration__label--icon {
    display: inline-block;
    position: relative;
    width: 15px;
    height: 15px;
    margin-right: 7px;
    margin-bottom: -2px;
    cursor: pointer;
    box-shadow: inset 0 0 0 1px #d1d1d1;
  }

  .registration__input--checkbox:checked + .registration__label--icon::before {
    position: absolute;
    content: "";
    top: 9px;
    right: 6px;
    width: 8px;
    height: 2px;
    -webkit-transform: rotate(50deg);
    transform: rotate(50deg);
    background-color: #0D4A75;
  }

  .registration__input--checkbox:checked + .registration__label--icon::after {
    position: absolute;
    content: "";
    top: 7px;
    right: 0;
    width: 10px;
    height: 2px;
    -webkit-transform: rotate(-59deg);
    transform: rotate(-59deg);
    background-color: #0D4A75;
  }

  .form-group .filled input {
    -webkit-box-shadow: inset 0 0 0 50px #ffffff;
  }

  input {
    font-size: 15px;
    outline: none;
    // -webkit-box-shadow: inset 0 0 0 50px #ffffff;
  }

  & select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
  }
}

.submenu .form {
  .floating_label {
    position: relative;
    padding-top: 0;
  }

  .custom-checkbox {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .form-control {
    font-size: 14px;
  }

  button {
    padding: 6px 13px;
    font-size: 13px;
  }

  .registration__label--icon {
    width: 15px;
    height: 15px;
    margin-right: 2px;
  }

  .registration__input--checkbox:checked + .registration__label--icon::after {
    top: 6px;
    right: 1px;
    width: 9px;
    height: 2px;
  }

  .registration__input--checkbox:checked + .registration__label--icon::before {
    top: 8px;
    right: 6px;
    width: 7px;
    height: 2px;
  }

  input::placeholder {
    color: #DFDFDF;
  }

  .text-danger {
    color: #fc0016;
  }
}

.form--response form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .form-group {
    width: 47%;
  }

  .form-group:nth-of-type(7) {
    width: 100%;
  }

  textarea[name=question] {
    width: 100%;
    resize: none;
    flex-grow: 1;
    min-height: 130px;
  }
}

@media (max-width: 768px) {
  .form {
    .form-control {
      font-size: 13px;
    }

    .form-group {
      margin-bottom: 5px;
    }

    .floating_label label {
      top: 20px;
    }

    .buttons-r button {
      order: 1;
      width: 100%;
    }

    .buttons-r p {
      order: 0;
      width: 100%;
      margin-bottom: 15px;
    }

    & .btn {
      padding: 12px 20px;
    }
  }
}
