.banner-t1 {
  padding-top: 50px;
  background: #233255;
  position: relative;
  margin-bottom: 56px;
  z-index: 2;
  overflow: hidden;

  @media (min-width: 576px) {
    padding-top: 110px;
  }

  @media (min-width: 768px) {
    margin-bottom: 105px;
  }

  @media (min-width: 1400px) {
    padding-top: 150px;
  }

  &::before {
    content: '';
    position: absolute;
    background-image: url("../image/banner-pixels-top.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 308px;
    height: 113px;
    top: 0;
    right: 0;
    z-index: -1;

    @media (min-width: 992px) {
      height: 140px;
      right: 9px;
    }

    @media (min-width: 1400px) {
      right: 28px;
      width: 428px;
      height: 214px;
    }

    @media (max-width: 575px) {
      width: 308px;
      height: 95px;
      top: 243px;
      right: 93px;
      transform: rotate(90deg);
      transform-origin: right bottom;
    }
  }

  &__content {
    padding: 0 24px;

    @media (min-width: 576px) {
      padding: 0 30px;
    }

    @media (min-width: 992px) {
      padding: 0 57px;
    }
  }

  &__title {
    line-height: 1.4;
    max-width: 240px;
    margin: 0;
    margin-bottom: 14px;

    @media (min-width: 576px) {
      max-width: 710px;
    }
    @media (min-width: 1400px) {
      margin-bottom: 24px;
    }
  }

  &__description {
    font-size: 20px;
    line-height: 180%;
    opacity: .6;
    color: $white;
    @media (min-width: 1400px) {
      font-size: 24px;
    }
  }

  &__links-container {
    margin-top: 42px;
    margin-bottom: 0;

    @media (min-width: 576px) {
      margin-top: 70px;
      display: flex;
      border-top: 1px solid rgba(245, 245, 245, 0.2);
    }
  }

  &__link-item {
    padding: 18px 38px 0 21px;

    @media (min-width: 576px) {
      padding-top: 36px; padding-left:57px; padding-bottom: 43px;
      width: 50%;
    }
  }

  &__link-wrap {
    padding: 20px 24px;
    display: block;
    width: 100%;

    @media (min-width: 576px) {
      min-height: 118px;
      display: flex;
      align-items: center;
      padding: 10px 30px;
      transition: 0.3s ease-in-out;
      &:hover {
        background-color: #011d38;
      }
    }
    @media (min-width: 992px) {
      padding: 10px 57px;
    }
  }

  &__links-container > &__link-item:first-child {
    border-bottom: 1px solid rgba(245, 245, 245, 0.2);

    @media (min-width: 576px) {
      border-right: 1px solid rgba(245, 245, 245, 0.2);
      border-bottom: none;
    }
  }

  &__link-item:last-child {
    border-top: 1px solid rgba(245, 245, 245, 0.2);

    @media (min-width: 576px) {
      border-left:1px solid rgba(245,245,245,.2);
      border-bottom:none
    }
  }

  &__link {
    font-size: 17px;
    line-height: 1.4;
    pointer-events: none;

    @media (min-width: 768px) {
      font-size: 21px;
    }

    @media (min-width: 1400px) {
      font-size: 24px;
    }
  }
}
