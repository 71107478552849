.product-cards {
  margin-bottom: 56px;

  @media (min-width: 768px) {
    margin-bottom: 150px;
  }

  &__wrap {
    margin-top: 26px;
    @media (min-width: 768px) {
      display: flex;
      margin: 58px -10px 0;
    }
  }

  &__item {
    min-height: 255px;
    width: 255px;
    height: 100%;
    @media (min-width: 768px) {
      min-height: 322px;
      width: 33.33%;
      padding: 0 10px;
      height: auto;
    }
  }

  &__subtitle {
    font-size: 12px;
    line-height: 1.8;
    @media (min-width: 576px) {
      font-size: 18px;
    }
    @media (max-width: 991px) {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  .owl-stage {
    display: flex;
  }
  .owl-nav {
    display: none;
  }

  .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 26px;
  }

  .owl-dot {
    width: 8px;
    height: 8px;
    font-size: 0px;
    border-radius: 50%;
    border: none;
    padding: 0;
    background: #C4C4C4;
    transition: 0.2s;
    margin: 0 6px;
    &.active {
      background: #0D4A75;
    }
  }
}