.partners-list {
  padding-top: 25px;

  &__crumbs-list {
    list-style: none;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  &__crumbs-item {
    margin-right: 28px;
    position: relative;
  }

  &__crumbs-item:not(:last-of-type)::before {
    content: '/';
    position: absolute;
    top: 0;
    right: -20px;
  }

  & &__title {
    margin-bottom: 0;
  }

  &__all-city {
    display: inline-block;
    position: relative;
    padding: 13px 30px;
    padding-top: 15px;
    padding-right: 46px;
    z-index: 5;
  }

  &__city-list-all:hover &__all-city {
    background-color: white;
    color: #048ad3;
  }

  &__all-city::before {
    content: '';
    position: absolute;
    width: 9px;
    height: 1px;
    background: #048ad3;
    -webkit-transform: rotate(44deg);
    -ms-transform: rotate(44deg);
    transform: rotate(44deg);
    top: 29px;
    right: 26px;
  }

  &__all-city::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 1px;
    background: #048ad3;
    -webkit-transform: rotate(-44deg);
    -ms-transform: rotate(-44deg);
    transform: rotate(-44deg);
    top: 29px;
    right: 20px;
  }

  &__city-list-all:hover .partners-list__all-city::before {
    -webkit-transform: rotate(-44deg);
    -ms-transform: rotate(-44deg);
    transform: rotate(-44deg);
  }

  &__city-list-all:hover .partners-list__all-city::after {
    -webkit-transform: rotate(44deg);
    -ms-transform: rotate(44deg);
    transform: rotate(44deg);
  }

  &__city-list-all {
    position: relative;
    display: inline-block;
    margin-bottom: 12px;
  }

  &__city-list-all:first-of-type {
    margin-left: -30px;
  }

  &__city-list-all:hover .partners-list__hidden-sublist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  &__hidden-sublist {
    position: absolute;
    left: 0;
    top: 52px;
    width: 926px;
    padding: 25px 30px;
    padding-right: 65px;
    min-height: 545px;
    background-color: white;
    z-index: 1;
    display: none;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  }

  &__city-block {
    width: 167px;
  }

  &__city-block:nth-of-type(1) {
    width: 100%;
  }

  &__city-list-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &__city-list {
    width: 167px;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  &__city-item {
    margin-bottom: 4px;
  }

  &__city-title {
    font-weight: bold;
    padding-bottom: 8px;
    margin-bottom: 5px;
    border-bottom: 1px solid #c4c4c4;
  }

  &__single-partners {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: -30px;
    margin-right: -30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &__single-item {
    width: 510px;
    background-color: white;
    padding: 22px 30px;
    margin-bottom: 33px;
    -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    @media (min-width: 1400px) {
      width: 630px;
    }
  }

  &__single-wrap-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

  }

  &__single-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    width: 270px;
  }

  &__single-logo-wrap {
    position: relative;
    width: 150px;
    height: 40px;
  }

  &__single-logo {
    position: absolute;
    right: 12px;
    top: 3px;
  }

  &__single-status {
    padding: 0 7px;
    line-height: 16px;
    padding-bottom: 3px;
    margin-left: -9px;
    border-radius: 25px;
    margin-bottom: 10px;
    display: inline-block;
  }

  &__single-status {
    &--gold {
      background-color: #ebdb9a;
    }
    &--bronze {
      background-color: #CD7F32;
      color: white;
    }
    &--silver {
      background-color: #C0C0C0;
    }
    &--platinum {
      background-color: #F1ECE1;
    }
    &--partner {
      background-color: #69b8e3;
      color: white;
    }
    &--certificate {
      background-color: #70a9ac;
      color: white;
    }
  }

  &__single-information {
    margin-bottom: 11px;
  }

  &__single-city {
    margin-right: 20px;
  }

  &__single-certification-title {
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 1px;
  }

  &__single-certification-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  &__single-certification-list:last-of-type {
    margin-bottom: 16px;
  }

  &__single-certification-item {
    margin-right: 23px;
    margin-bottom: 10px;
  }

  &__single-number {
    display: inline;
    margin-right: 15px;
  }

  &__single-address {
    margin-bottom: 11px;
  }

  &__single-contact-name {
    margin-left: 7px;
  }
}

@media (max-width: 420px) {
  .partners-list {
    width: auto;

    &__single-partners {
      width: 100%;
      margin-left: 0;
    }

    &__single-item {
      width: 100%;
      padding: 22px 16px;
    }

    &__single-logo-wrap {
      display: none;
    }
  }
}
