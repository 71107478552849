.university {
  padding-top: 25px;
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  & &__title {
    margin-bottom: 23px;
  }

  &__description:first-of-type {
    margin-top: 31px;
  }

  &__description {
    margin-bottom: 15px;
  }

  &__wrap-content {
    width: 760px;
  }

  &__aside {
    width: 381px;
    margin-top: 120px;
  }

  &__aside-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__aside-item {
    margin-bottom: 31px;
  }

  &__aside-info {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 5px;
  }

  &__aside-description {
    font-size: 16px;
    line-height: 22px;
    color: #272727;
  }
}

.university-advantages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 32px;

  & &__title {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 13px;
  }

  &__description:nth-of-type(3) {
    margin-bottom: 29px;
  }

  &__wrap-content {
    width: 755px;
  }

  & .university-advantages__title {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 13px;
  }

  &__wrap-aside {
    width: 380px;
    margin-top: 45px;
    padding-right: 40px;
  }

  &__question {
    margin-bottom: 14px;
  }

  &__specialist {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 2px;;
  }

  &__contact-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__contact-item {
    margin-bottom: 4px;
  }

  &__number {
    color: black;
  }

  &__subtitle {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  &__advantages-list {
    list-style: none;
    padding: 0;
    margin-bottom: 44px;
  }

  &__advantages-item {
    margin-bottom: 8px;
  }

  &__education-list-title {
    margin-bottom: 0;
    margin-top: 25px;
  }

  &__education-list {
    width: 450px;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  &__education-item {
    margin-bottom: 8px;
  }

  &__education-list-title {
    width: 471px;
    margin-bottom: 8px;
    margin-top: 45px;
  }

  &__education-list {
    width: 471px;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  &__education-item {
    margin-bottom: 8px;
  }
}

.university-courses {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 22px;

  &__wrap-content {
    width: 690px;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 13px;
    font-weight: bold;
  }

  &__courses-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__courses-item {
    margin-bottom: 15px;
  }

  &__courses-subtitle {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
  }

  &__aside {
    width: 381px;
  }

  &__aside-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
    font-weight: bold;
  }

  &__aside-wrap-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-right: 40px;
  }

  &__programm-list,
  &__hours-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__hours-list {
    text-align: center;
  }

  &__programm-item,
  &__hours-item {
    margin-bottom: 6px;
  }

  &__programm-item:first-of-type,
  &__hours-item:first-of-type {
    font-weight: bold;
    margin-bottom: 8px;
  }
}

.university-practise {
  margin-bottom: 0;

  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
    font-weight: bold;
  }

  &__list {
    width: 680px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    margin-bottom: 15px;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 0;
  }
}

@media (max-width: 420px) {
  .university {
    width: auto;

    &__title,
    &__description {
      width: 100%;
    }

    &__aside {
      margin-top: 25px;
      width: 100%;
    }
  }

  .university-advantages {
    width: auto;

    &__wrap-content {
      width: 100%;
    }

    &__title,
    &__description {
      width: 100%;
    }

    &__education-list-title {
      width: 100%;
    }

    &__education-list {
      width: 100%;
    }

    &__education-item {
      width: 100%;
    }
  }

  .university-courses {
    width: auto;

    &__title,
    &__description {
      width: 100%;
    }
  }

  .university-practise {
    width: auto;

    &__title {
      width: 100%;
    }

    &__list {
      width: 100%;
    }
  }
}
