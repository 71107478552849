.s-story-result {
  &__wrap {
    position: relative;
    z-index: 5;
    padding: 25px 16px;
    padding-bottom: 48px;
    background-color: #e9ebef;
    margin-bottom: 48px;
  }

  &__title {
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 23px;
  }

  &__list-wrap {
    position: relative;
    padding-top: 7px;
    padding-bottom: 10px;
    margin-bottom: 6px;
  }

  &__list-wrap::before {
    position: absolute;
    content: '';
    height: 1px;
    right: 0;
    left: 0;
    background-color: #636363;
    bottom: 0;
  }

  &__list-wrap .s-story__target-item {
    display: flex;
    margin-bottom: 15px;
  }

  &__list-wrap .s-story__target-mark {
    width: 52%;
    font-size: 30px;
    line-height: 42px;
  }

  &__list-wrap .s-story__target-number {
    font-size: 60px;
    line-height: 82px;
  }

  &__list-wrap .s-story__target-number + span {
    margin-top: 33px;
  }

  &__list-wrap .s-story__target-item:last-of-type .s-story__target-number + span {
    margin-top: -14px;
  }

  &__list-wrap .s-story__target-text {
    width: 37%;
    padding-top: 7px;
  }

  &__wrap small {
    color: #636363;
    font-size: 12px;
    line-height: 30px;
  }
}

@media (min-width:$desktop-view) {
  .s-story-result {
    &__wrap {
      margin-left: 40px;
      margin-right: 40px;
      padding: 36px 47px;
      padding-bottom: 67px;
      margin-bottom: 122px;
    }

    &__title {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 11px;
    }

    &__list-wrap {
      width: 95%;
      padding-bottom: 50px;
      margin-bottom: 7px;
    }

    &__list-wrap .s-story__target-list {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__list-wrap .s-story__target-item {
      width: 27%;
      display: block;
    }

    &__list-wrap .s-story__target-item:nth-of-type(2) {
      width: 20%;
    }

    &__list-wrap .s-story__target-item:last-of-type {
      width: 43%;
      margin-right: 0;
    }

    &__list-wrap .s-story__target-mark {
      width: 100%;
      font-size: 36px;
      line-height: 42px;
    }

    &__list-wrap .s-story__target-number {
      font-size: 70px;
      line-height: 82px;
    }

    &__list-wrap .s-story__target-item:last-of-type .s-story__target-number + span {
      margin-top: 33px;
    }

    &__list-wrap .s-story__target-text {
      width: 100%;
    }

    &__wrap small {
      font-size: 14px;
      line-height: 30px;
    }
  }
}

@media (min-width:1400px) {
  .s-story-result {
    &__wrap {
      margin-left: 68px;
      margin-right: 68px;
    }

    &__list-wrap .s-story__target-item {
      width: 26%;
      display: block;
      margin-right: 112px;
    }

    &__list-wrap .s-story__target-item:nth-of-type(2) {
      width: 20%;
    }

    &__list-wrap .s-story__target-item:last-of-type {
      width: 40%;
      margin-right: 0;
    }
  }
}