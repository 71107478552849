.case-nav {
  margin: auto;
  margin-bottom: 60px;
  background-color: #F5F5F5;
  position: sticky;
  top: 0;
  z-index: 1000;

  &__list {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    padding-right: 31px;
  }

  &__item:not(:last-of-type)::before {
    position: absolute;
    content: '';
    width: 1px;
    height: 55%;
    top: 25%;
    right: 15px;
    background-color: #000000;
  }

  &__link {
    font-size: 18px;
    line-height: 36px;
    color: #048ad3;
  }
}

@media (max-width: $mobile-view) {
  .case-nav {
    width: 100%;
    padding: 0 16px;
    margin-bottom: 40px;

    &__link {
      font-size: 16px;
      line-height: 36px;
    }
  }
}
