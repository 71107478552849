.s-story-target {
  &__wrap {
    padding: 0 16px;
    margin-bottom: 69px;

    &-ul {
      list-style-type: none;
      padding-left: 0;
      span {
        width: 21px;
        height: 21px;
        border-radius: 50%;
        background: #233255;
        color: white;
        font-weight: 600;
        font-size: 14px;
        display: inline-block;
        text-align: center;
        vertical-align: text-bottom;
      }

      p {
        margin-left: 10px;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        display: inline-block;
      }
    }
  }

  .video-ss {
    max-width: 820px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding-bottom: 40px;
    @media (min-width: 768px) {
    padding-bottom: 80px;
  }
    iframe {
      max-height: 460px;
    }
  }

  &__main-title {
    font-size: 36px;
    line-height: 52px;
    font-weight: bold;
    margin-bottom: 40px;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 18px;
  }

  &__wrap-text {
    margin-bottom: 43px;
  }

  &__wrap-text p {
    font-size: 16px;
    line-height: 24px;
  }

  &__wrap-img {
    position: relative;
  }

  &__img {
    width: 100%;
    height: 73vw;
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  }

  &__substrate {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    padding: 35px 47px;
    padding-bottom: 28px;
    min-height: 230px;
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  }

  &__substrate img {
    width: auto;
    height: auto;
    box-shadow: none;
  }

  &__substrate &__img-wrap-description {
    position: static;
    margin-top: auto;
  }

  &__img-wrap-description {
    position: absolute;
    bottom: 6.5vw;
    left: 53px;
  }

  &__img-wrap-description p {
    font-size: 14px;
    line-height: 16px;
    color: #636363;
    margin-bottom: 0;
  }

  &__img-wrap-description a {
    font-size: 14px;
    line-height: 20px;
    color: #048ad3;
  }

  &__process-list {
    list-style: none;
    padding-left: 36px;
    padding-top: 46px;
   // padding-bottom: 70px;
    width: 88%;
    margin-bottom: 0;
  }

  &__process-item {
    margin-bottom: 48px;
  }

  &__process-item p {
    font-size: 16px;
    line-height: 24px;
  }

  &__process-item p:not(:last-of-type) {
    margin-bottom: 0;
  }

  .mb-32 {
    margin-bottom: 1.777777em;
  }
}

@media (min-width: 768px) {
  .s-story-target {
    .display-block {
      display: block;
    }
  }
}


@media (min-width: $desktop-view) {
  .s-story-target {

    &__wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0 10px;
      margin-bottom: 35px;
    }

    &__title {
      font-size: 30px;
      line-height: 44px;
      font-weight: bold;
      margin-bottom: 24px;
    }

    &__wrap-text {
      width: 45%;
      &_width {
        width: 74%;
      }
    }

    &__wrap-text p {
      font-size: 18px;
      line-height: 1.67;
    }

    &__wrap-img {
      width: 40.3%;
      padding-top: 13px;
    }

    &__img {
      width: 440px;
      height: 351px;
    }

    &__img-wrap-description {
      bottom: 38px;
      left: 64px;
    }

    &__img-wrap-description p {
      font-size: 16px;
      line-height: 26px;
    }

    &__img-wrap-description a {
      font-size: 16px;
      line-height: 26px;
    }

    &__substrate {
      width: 440px;
      height: 290px;
    }

    &__substrate &__img-wrap-description {
      position: static;
      margin-top: auto;
      margin-left: 20px;
    }

    &__process-list {
      padding-left: 105px;
      padding-right: 41px;
      padding-top: 140px;
    }

    &__process-item {
      margin-bottom: 100px;
    }
  }

  .s-story-target--nanotech {
    .s-story-target__wrap-text {
      width: 60%;
    }

    h2.s-story-target__title {
      margin-bottom: 33px;
    }

    h3.s-story-target__title {
      font-size: 24px;
      line-height: 29px;
    }
  }
}
