#carousel-webinars {
  margin-top: 20px;
  margin-bottom: 20px;
}

.wrap-star {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  top: 50%;
  margin-top: -25px;
  left: 15px;
}

.star {
  position: relative;
  display: block;
  width: 13px;
  height: 13px;
  background-color: rgb(255, 219, 47);
  border-radius: 50%;
}

.star--one,
.star--two,
.star--three,
.star--fourth,
.star--fives {
  position: absolute;
  width: 13px;
  height: 13px;
  background-color: rgba(255, 219, 47, 0.4);
}

.star--one {
  animation-name: star-round;
  animation-duration: 3s;
  animation-iteration-count: infinite;;
}

.star--two {
  animation-name: star-round;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
}

.star--three {
  animation-name: star-round;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}

.star--fourth {
  animation-name: star-round;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 1.5s;
}

.star--fives {
  animation-name: star-round;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}

.vertical-star,
.horisontal-star {
  display: block;
  position: absolute;
  width: 30px;
  height: 8px;
  background-color: rgb(255, 219, 47);
  border-radius: 15px;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -4px;
  transform: rotate(-25deg);
  z-index: 0;
}

.horisontal-star {
  transform: rotate(65deg);
  z-index: 2;
}

.star--one .horisontal-star,
.star--one .vertical-star,
.star--two .horisontal-star,
.star--two .vertical-star,
.star--three .horisontal-star,
.star--three .vertical-star,
.star--fourth .horisontal-star,
.star--fourth .vertical-star,
.star--fives .horisontal-star,
.star--fives .vertical-star {
  display: block;
  position: absolute;
  width: 30px;
  height: 8px;
  background-color: rgba(255, 219, 47, 0.4);
  border-radius: 15px;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -4px;
  transform: rotate(-25deg);
  z-index: 0;
}

.star--one .horisontal-star,
.star--two .horisontal-star,
.star--three .horisontal-star,
.star--fourth .horisontal-star,
.star--fives .horisontal-star {
  transform: rotate(65deg);
  z-index: 2;
}

.star--one .horisontal-star,
.star--one .vertical-star {
  animation-name: star;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.star--two .horisontal-star,
.star--two .vertical-star {
  animation-name: star;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
}

.star--three .horisontal-star,
.star--three .vertical-star {
  animation-name: star;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}

.star--fourth .horisontal-star,
.star--fourth .vertical-star {
  animation-name: star;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 1.5s;
}

.star--fives .horisontal-star,
.star--fives .vertical-star {
  animation-name: star;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}

@keyframes star {
  0% {
    width: 30px;
    height: 8px;
    background-color: rgba(255, 219, 47, 0.3);
    border-radius: 15px;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -4px;
  }

  100% {
    width: 80px;
    height: 15px;
    background-color: rgba(255, 219, 47, 0.01);
    border-radius: 15px;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -7.5px;
  }
}

@keyframes star-round {
  0% {
    width: 13px;
    height: 13px;
    background-color: rgba(255, 219, 47, 0.3);
  }

  100% {
    width: 35px;
    height: 35px;
    background-color: rgba(255, 219, 47, 0.01);
  }
}

.business-process-for-big {
  position: relative;
  height: 26px;
  overflow: hidden;
}

.business-process-for-big__description {
  position: absolute;
  left: 16px;
  top: 1px;
  color: #000;
  font-weight: normal;
  background-color: #dee2e6;
  transition: 0.2s;
  transform: translateY(-24px);
  animation-name: business-process-for-big;
  animation-duration: 11s;
  animation-iteration-count: infinite;

}

.btn:hover .business-process-for-big__description {
  background-color: #cddfed;
}

@keyframes business-process-for-big {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(0);
  }

  60% {
    transform: translateY(-25px);
  }

  80% {
    transform: translateY(-25px);
  }

  90% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

.elma-day__wrap {
  overflow: hidden;
}

.elma-day__background {
  background-color: #000000;
  background-image: url('/upload/elmaday2020.jpg');
  background-repeat: no-repeat;
  padding: 8px 24px;
  padding-bottom: 8.1px;
  width: 1290px;
  height: 60.133px;

}

.elma-day__title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 18px;
  line-height: 127.4%;
  padding-left: 179px;
  text-align: left;
  width: 503px;
  padding-right: 27px;
  box-sizing: content-box;
}

.elma-day__place {
  position: relative;
  font-family: 'Proxima', Arial, sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 0;
  margin-right: 44px;
}

.elma-day__place svg {
  position: absolute;
}

.elma-day__place.square svg {
  width: 15px;
  height: 15px;
  left: -23px;
  top: 3px;
}

.elma-day__place.rectangle svg {
  width: 12px;
  height: 15px;
  left: -22px;
  top: 4px;
}

.elma-day__place:not(:last-of-type) {
  margin-right: 43px;
}

.elma-day__link {
  font-family: 'Proxima', Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 9px 19px;
  border: 1px solid #8EFCFF;
  color: #8EFCFF;
  margin-bottom: 0;
}

@media (max-width: 425px) {
  .elma-day__background {
    background-image: none;
  }

  .elma-day__title {
    padding-left: 5px;
  }
}

.elma-day__pin::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background-image: url('/elmaday2019/img/icons/pin_place.svg');
  background-repeat: no-repeat;
}
