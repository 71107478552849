.dual-popup {

  .height-adaptive {
    @media (max-width: 1600px) {
      .dual-popup__main,
      .dual-popup__info {
        padding: 36px 56px;
      }

      .dual-popup__info-flex {
        height: 100%;
        position: relative;

        .dual-popup__info-arrows {
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      .dual-popup__text,
      .dual-popup__title {
        margin-bottom: 20px;
      }

      .dual-popup__info-content-wrap {
        margin-top: 30px;
      }

      .form-t .form-control {
        height: calc(1em + .75rem + 2px);
      }

      .form-t .floating_label label {
        top: 9px;
      }

      .form-t .form-control:focus+label {
        top: 0;
      }
    }
    @media (max-width: 576px) {
      .dual-popup__main,
      .dual-popup__info {
        padding: 0 15px 35px;
      }
    }
  }

  &__dialog.modal-dialog {
    background: #FFFFFF;
    max-width: 1220px;
  }

  &__wrap {
    display: flex;
  }

  &__main {
    padding: 0px 15px 35px;

    @media (min-width: 576px) {
      padding: 56px;
    }
    @media (min-width: 1024px) {
      width: 58%;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 16px;

    @media (min-width: 576px) {
      font-size: 30px;
      margin-bottom: 37px;
    }
  }

  &__text {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 1.4;

    @media (min-width: 576px) {
      font-size: 16px;
      margin-bottom: 50px;
    }
  }

  &__info {
    display: none;

    @media (min-width: 1024px) {
      display: block;
      background: #062543;
      padding: 56px;
      width: 42%;
      color: #FFFFFF;
      background-image: url("../image/try-pop-bg.png");
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: 100% auto;

      &-flex {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
      }

      &-title {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 16px;
        line-height: 1.2;
      }
      &-subtitle {
        font-size: 16px;
        line-height: 1.75;
      }
      &-content-wrap {
        margin-top: 70px;
        margin-bottom: auto;
        padding-bottom: 40px;
        &_flex {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }
      &-content-item {
        margin-bottom: 33px;
        &_flex {
          width: calc(50% - 10px);
        }
      }
      &-content-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 16px;
      }
      &-content-text {
        line-height: 1.6;
        font-size: 14px;
      }
    }

  }

  &__labels {
    margin-bottom: 25px;
    margin-top: 25px;
    .form-popup__label--checkbox {
      margin-bottom: 8px;
    }
  }

  .form-popup__label--checkbox {
    margin-left: 0;
  }
  .form-popup__submit {
    margin-left: 0;
  }
}
.dual-popup__dialog.modal-dialog {
  background: #FFFFFF;
  max-width: 600px !important;

  @media (min-width: 1024px) {
    max-width: 1220px !important;
  }
}
