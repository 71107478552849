.main-banner {
  margin-bottom: 35px;
  height: 523px;
  z-index: 1;

  &__wrap {
    max-width: 1290px;
    margin-bottom: 9px;
  }

  &__background {
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    bottom: 0;
    left: -35px;
    right: -35px;
    z-index: -1;
  }

  &__background--main {
    background-image: url('/img/index/home_banner.jpg');
  }

  &__background--bpm {
    background-image: url('/img/bpm/BPM_banner_new.jpg');
  }

  &__background--projects {
    background-image: url('/upload/projects/main-banner-projects.png');
  }

  &__background--products {
    background-image: url('/img/products/products_main.jpg');
  }

  &__background--crm {
    background-image: url('/img/crm/CRM_main-banner.jpg');
  }

  &__background--ecm {
    background-image: url('/img/ecm/ECM_banner.jpg');
  }

  &__background--kpi {
    background-image: url("/img/kpi/KPI_banner.jpg");
  }

  &__background--cases {
    background-image: url("/upload/bg-cases/png5cb6faa219e891/85003703/bg-cases.png");
  }

  &__background--ia {
    background-image: url("/upload/bg-ia/png5cbf0e266f6ab6/27896679/bg-ia.png");
  }

  &__background-banner--ia {
    background-image: url("/upload/bg-main-ia/png5cc0413e2929f1/40558110/bg-main-ia.png");
  }

  &__background-banner--change-easy {
    background-image: url("/upload/bg-main/bg_main-change.png");
  }

  &__background-banner--elma4 {
    background-image: url("/upload/elma4-page/main-banner.jpg");
  }


  &__title-fragment {
    padding: 0;
    padding-left: 35px;
    margin: 0;
    margin-top: 8px;
  }

  &__description {
    padding-left: 35px;
  }

  &__wrap-webinar {
    padding-top: 13px;
    padding-left: 33px;
    margin-left: -35px;
    margin-right: -35px;
  }

  &__title-box {
    margin-left: -35px;
    max-width: 800px;
    padding-bottom: 6px;

    &--projects {
      max-width: 705px;
    }
  }
}

.text-background {
  background-color: rgba(20, 29, 45, 0.6);
}

.text-background--ia {
  background-color: rgba(20, 29, 45, 0.9);
}

.font-banner-title {
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
  padding-left: 35px;
}

@media (max-width: $mobile-view) {
  .main-banner {
    min-height: 523px;
    height: auto;
  }

  .main-banner__wrap {
    margin-right: -16px;
    margin-left: -16px;
  }

  .main-banner__wrap-webinar {
    margin-left: -16px;
    margin-right: -16px;
  }

  .work-elma-bpm__wrap-fill {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .main-banner__background {
    right: 0;
    left: 0;
  }

  .main-banner__title-box {
    margin-left: -16px;
    margin-right: -16px;
    padding-bottom: 0;
    background-color: rgba(20, 29, 45, 0.6);

    &--projects {
      margin-left: 0;
    }

    &--projects.text-background {
      background-color: rgba(20,29,45,.6);
    }
  }

  .font-banner-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    padding-left: 16px;
  }

  .text-background {
    background-color: transparent;
  }

  .main-banner__wrap-webinar {
    padding-left: 16px;
  }

  .main-banner__title-fragment {
    padding-left: 16px;
  }

  .main-banner__description {
    padding-left: 16px;
  }

  .main-banner__background--ecm {
    background-size: 160%;
    background-position: -170px 0;
    background-color: #515151;
  }

  .main-banner__background--kpi {
    background-size: 173%;
    background-position: -178px 0;
    background-color: #515151;
  }

  .main-banner__background--projects {
    background-size: 178%;
    background-position: -186px 0;
    background-color: #515151;
  }
}
