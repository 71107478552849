// second-nav styles
.second-nav {
  list-style-type: none;
  padding: 0;
  margin-bottom: 16px;
  margin-left: -13px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  border: none!important;


  &__item {
    margin-right: 4px;
    margin-bottom: 0;
  }

  & &__item &__link {
    display: inline-block;
    padding: 0 13px;
    padding-bottom: 2px;
    color: #0D4A75;
    cursor: pointer;
    border: none;
    border-radius: 0;
  }

  & &__item &__link--active {
    background-color: white;
    color: black;
  }

  & &__item &__link.active {
    border: none;
    color: rgb(73, 80, 87);
  }

  & &__item &__link.active:hover {
    color: rgb(73, 80, 87);
  }

  &__item &__link:focus,
  &__item &__link:hover {
    text-decoration: none;
    color: #6e91ab;
  }
}

@media (max-width: 420px) {
  .second-mobile-nav__list {
    width: auto;
    margin-left: -16px;
    margin-right: -16px;
  }

  .second-mobile-nav__list .second-mobile-nav__item {
    width: 100%;
    margin-bottom: 0;
    margin-right: 0;
  }

  .second-mobile-nav__item .second-mobile-nav__link {
    width: 100%;
    background-color: white;
    color: #048ad3;
    border-bottom: 1px solid #dee2e6;
    padding: 10px 16px!important;
    margin-right: 0;
    transition: 0.2s;
  }

  .second-mobile-nav__item:last-of-type,
  .contacts__item-cities:last-of-type {
    border-bottom: none!important;
  }

  .second-mobile-nav__link--active {
    position: relative;
    display: block;
    background-color: white;
  }

  .second-mobile-nav__list .second-mobile-nav__item .second-mobile-nav__link {
    position: relative;
    transition: 0.2s;
    border-bottom: 1px solid #cfcfcf;
  }

  .second-mobile-nav__list .second-mobile-nav__item:last-of-type .second-mobile-nav__link {
    border-bottom: none;
  }

  .second-mobile-nav__list .second-mobile-nav__item .second-mobile-nav__link:hover,
  .second-mobile-nav__list .second-mobile-nav__item .second-mobile-nav__link:focus {
    border-bottom: 1px solid #cfcfcf;
    color: #048ad3;
  }

  .second-mobile-nav__list .second-mobile-nav__item:last-of-type .second-mobile-nav__link:hover,
  .second-mobile-nav__list .second-mobile-nav__item:last-of-type .second-mobile-nav__link:focus {
    border-bottom: none;
  }

  .second-nav .second-nav__item .second-nav__link--active {
    color: #1b8ac7;
  }

  .second-mobile-nav__link--active::before {
    content: '';
    position: absolute;
    right: 22px;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: #048ad3;
    transform: rotate(-45deg);
    transition: 0.2s;
  }

  .second-mobile-nav__link--active::after {
    content: '';
    position: absolute;
    right: 16px;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: #048ad3;
    transform: rotate(45deg);
    transition: 0.2s;
  }

  .second-mobile-nav__list--closed .second-mobile-nav__item:not(:first-of-type) {
    overflow: hidden;
  }

  .second-mobile-nav__list--closed .second-mobile-nav__item:not(:first-of-type) .second-mobile-nav__link {
    display: block;
    margin-top: -50px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .second-mobile-nav__list--closed .second-mobile-nav__link--active::before {
    transform: rotate(45deg);
  }

  .second-mobile-nav__list--closed .second-mobile-nav__link--active::after {
    transform: rotate(-45deg);
  }
}
