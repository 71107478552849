.bpm-block {
  background-image: url('/img/index/home-bpm.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 1290px;
  min-height: 726px;
  margin-left: -35px;
  margin-bottom: 35px;
  padding: 55px 67px;

  &__wrap-blocks {
    max-width: 473px;
  }

  &__title {
    font-size: 36px;
    line-height: 50px;
    color: white;
    font-weight: 400;
    margin-bottom: 12px;
  }

  &__description {
    font-size: 16px;
    line-height: 26px;
    color: white;
    margin-bottom: 15px;
  }

  &__faster-competitors {
    margin-bottom: 87px;
  }
}

.work-elma-bpm {
  &__wrap-fill {
    margin-bottom: 87px;
    max-width: 1187px;
    margin-left: 58px;
    min-height: 345px;
    margin-top: 31px;
  }

  &__link {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -77px;
    margin-top: -12px;
    width: 109px;
    text-align: center;
    font-weight: bold;
  }

  &__section-wrap {
    padding-top: 32px;
    margin-bottom: 92px;
  }
}

.elma-vista {
  margin-bottom: 72px;

  &__wrap-text {
    max-width: 805px;
  }

  &__vista {
    margin-bottom: 52px;
  }

  &__wrap-webinar {
    width: 380px;
    margin-top: 47px;
    padding-right: 0;
  }

  &__wrap-webinar iframe {
    height: 211px;
    margin-bottom: 11px;
  }
}

.elma-add-apps {
  margin-bottom: 70px;

  &__image-wrap {
    width: 590px;
    height: 331px;
    overflow: hidden;
  }

  &__first-container {
    margin-bottom: 70px;
  }

  &__second-container {
    margin-top: 68px;
    margin-bottom: 68px;
  }
}

.five-reasons-to-introduce {
  margin-bottom: 37px;
  min-height: 793px;
}

.products-background__bpm-transform {
  top: 50%;
  left: 50%;
  width: 1200px;
  height: 898px;
  margin-left: -638px;
  margin-top: -415px;
  z-index: -1;
}


@media (max-width: $mobile-view) {
  .elma-apps {
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: 36px;
  }

  .elma-apps__fill {
    padding: 43px 16px;
  }

  .main-apps {
    margin-bottom: 36px;
    margin-top: 36px;
  }

  .main-apps__list-apps {
    margin-left: -16px;
    margin-right: -16px;
  }

  .products-background__bpm-transform {
    width: 360px;
  }

  .bpm-block {
    margin-left: -16px;
    margin-right: -16px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 1px;
  }

  .work-elma-bpm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 40px;
  }

  .work-elma-bpm__link {
    position: relative;
    left: 0;
    top: 0;
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    width: 202px;
    margin-left: 0;
  }

  .elma-add-apps__image-wrap {
    height: auto;
    margin-bottom: 25px;
  }

  .elma-link--arrow-bpm::before {
    right: -7px;
    top: 5px;
  }

  .elma-link--arrow-bpm::after {
    right: -7px;
    bottom: 3px;
  }

  .work-elma-bpm__section-wrap {
    padding-top: 0;
    margin-bottom: 0;
    padding-left: 20px;
  }

  .work-elma-bpm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 40px;
  }

  .work-elma-bpm__section-wrap {
    padding-top: 0;
    margin-bottom: 0;
    padding-left: 20px;
  }

  .work-elma-bpm__wrap-fill {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .work-elma-bpm__link {
    position: relative;
    left: 0;
    top: 0;
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    width: 202px;
    margin-left: 0;
  }

  .elma-link--arrow-bpm::before {
    right: -7px;
    top: 5px;
  }

  .elma-link--arrow-bpm::after {
    right: -7px;
    bottom: 3px;
  }

  .work-elma-bpm__section-wrap {
    padding-top: 0;
    margin-bottom: 0;
    padding-left: 20px;
  }

  .products-background__general-transform-cover {
    background-size: 187%;
    background-position: 49% 58%;
  }

  .products-background__bpm-transform {
    margin-left: -181px;
    margin-top: -650px;
    width: 360px;
  }

  .work-elma-bpm__section-wrap {
    padding-top: 0;
    margin-bottom: 0;
    padding-left: 20px;
  }
}
