.vacancy {
  padding-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  &__header {
    font-size: 35px;
  }

  &__header-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__offer-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__offer-item {
    width: 30%;
    display: flex;
    align-items: center;
    margin-right: 30px;
    margin-bottom: 20px;
  }

  &__icon {
    margin-right: 7px;
  }

  &__offer-item:nth-child(3n) {
    margin-right: 0;
  }

  &__contact {
    color: #0d4975;
    margin: 2px;
  }

  &__contact-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 20px 50px 40px;
    width: 320px;
    height: 253px;
    background: #FFFFFF;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }

  &__contact {
    margin-left: 2px;
  }

  &__contact-item-container {
    margin-bottom: 24px;
    margin-top: 15px;
  }

  &__button-block {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__description > ul {
    list-style-image: url("/image/list-item.svg");
    margin-bottom: 30px;
  }

  &__open-vacancy-list {
    margin-top: 70px;
  }

  &__modal{
    padding: 30px 30px 10px 30px;
  }

  &__modal-title {
    background-color: #fff;
    margin-bottom: 35px;
  }

  &__policies{
    margin-top: 35px;
    margin-bottom: 35px;
  }

  &__file-input-label {
    color: #1e6399;
    cursor: pointer;
  }

  &__floating-label{
    font-size: 13px;
    color: #000;
  }

  &__file-info{
    position: inherit!important;
  }

  &__file-container{
    display: flex;
    align-items: center;
  }

  &__date{
    padding-top: 20px;
    opacity: 0.4;
    font-size: 15px;
  }

  &__left-column {
    width: 690px;
    @media (min-width: 1400px) {
      width: 760px;
    }
  }

  & &__title {
    margin-bottom: 23px;
  }

  &__description {
    margin-bottom: 14px;
  }

  &__description:first-of-type {
    margin-top: 31px;
  }

  &__aside {
    padding-top: 69px;
    width: 230px;
    @media (min-width: 1400px) {
      width: 381px;
    }
  }

  &__leadership-avatar {
    width: 160px;
    height: 160px;
    margin-bottom: 10px;
  }

  &__leadership-avatar img {
    width: 100%;
    height: 100%;
  }

  &__leadership-name {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 1px;
  }

  &__leadership-position {
    margin-bottom: 5px;
  }

  &__leadership-contacts-wrap {
    margin-bottom: 17px;
  }

  &__button {
    padding: 12px 30px;
    border: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}

.open-vacancy {
  &__title {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 18px;
    margin-top: -7px;
    font-weight: bold;
  }

  &__menu-list {
    list-style: none;
    padding: 0;
    margin-bottom: 18px;
    margin-left: -10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  &__menu-item {
    color: #048ad3;
    padding: 0 10px;
    cursor: pointer;
    margin-right: 3px;
  }

  &__menu-item--active {
    background-color: white;
    color: black;
  }

  &__vacancy-list {
    width: 840px;
    list-style: none;
    padding: 25px 35px;
    padding-bottom: 34px;
    padding-right: 80px;
    margin-left: -35px;
    margin-bottom: 48px;
    background-color: white;
    -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  }

  &__vacancy-item {
    display: block;
    min-height: 48px;
  }

  &__position {
    position: relative;
    color: #048ad3;
    cursor: pointer;
  }

  &__arrow {
    position: absolute;
    right: -27px;
    top: 5px;
    width: 12px;
    height: 12px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
  }

  &__arrow--active {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  &__arrow::before,
  &__arrow::after {
    position: absolute;
    content: "";
    width: 9px;
    height: 2px;
    background-color: #048ad3;
    border-radius: 1px;

  }

  &__arrow::before {
    top: 5px;
    right: 4px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &__arrow::after {
    top: 5px;
    right: -1px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &__vacancy-text {
    overflow: hidden;
  }

  &__vacancy-toggle {
    margin-top: 24px;
    margin-bottom: 40px;
    color: black;
    -webkit-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
  }

  &__vacancy-toggle--hidden {
    margin-bottom: -1000px;
  }

  &__vacancy-toggle p {
    margin-bottom: 0;
  }

  &__vacancy-toggle b {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__vacancy-toggle ul {
    list-style: none;
    padding: 0;
    margin-bottom: 17px;
  }

  &__vacancy-toggle ul li {
    margin-bottom: 0;
    line-height: 26px;
  }
}

.advantages-work-elma {
  padding-top: 24px;
  padding-bottom: 72px;

  & &__title {
    margin-bottom: 23px;
  }

  &__wrap-slider {
    margin-right: -20px;
    margin-left: -20px;
  }

  &__wrap-slider .carousel {
    min-height: 421px;
    margin-bottom: 14px;
    @media (min-width: 1400px) {
      min-height: 581px;
    }
  }

  &__wrap-slider .carousel-inner {
    background-color: #084F74;
    color: #ffffff;
  }

  &__wrap-slider .carousel-indicators {
    bottom: 5px;
    left: 0;
    right: auto;
    margin-left: 45px;
    @media (min-width: 1400px) {
      bottom: 75px;
      margin-left: 70px;
    }
  }

  &__wrap-slider .carousel-indicators li {
    background-color: transparent;
    border: 1px solid #ffffff;
  }

  &__wrap-slider .carousel-indicators li:hover {
    background-color: #ffffff;
    border: 1px solid #ffffff;
  }

  &__wrap-slider .carousel-indicators .active {
    background-color: #ffffff;
  }

  &__wrap-slider .carousel-control-next,
  &__wrap-slider .carousel-control-prev {
    width: 4%;
    color: #ffffff;
    opacity: 1;
  }

  &__wrap-slider .carousel-control-next-icon,
  &__wrap-slider .carousel-control-prev-icon {
    color: #ffffff;
  }

  &__wrap-text {
    padding-top: 35px;
    padding-left: 45px;
    padding-right: 30px;
    padding-bottom: 0px;
    width: 646px;
    @media (min-width: 1400px) {
      padding-top: 75px;
      padding-left: 70px;
      padding-right: 170px;
      padding-bottom: 100px;
      width: 746px;
    }
  }

  &__subtitle {
    font-size: 36px;
    line-height: 50px;
    font-weight: 400;
    margin-bottom: 19px;
  }

  &__subscription {
    font-size: 14px;
    line-height: 1.5;
    @media (min-width: 1400px) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__wrap-img {
    width: 374px;
    @media (min-width: 1400px) {
      width: 514px;
    }
  }

  &__wrap-img img {
    width: 514px;
  }
}

@media (max-width: $mobile-view) {
  .vacancy {
    width: auto;

    &__title,
    &__description {
      width: 100%;
    }

    &__aside {
      padding-top: 15px;
      margin-bottom: 45px;
    }
  }

  .open-vacancy {
    width: auto;

    &__vacancy-list {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      margin-left: 0;
      margin-right: 0;
    }

    &__vacancy-item ul {
      margin-left: 0;
    }

    &__vacancy-toggle--hidden {
      margin-bottom: -2000px;
    }

    &__position {
      display: block;
      padding-right: 20px;
    }

    &__arrow {
      top: 7px;
      right: 2px;
    }
  }

  .advantages-work-elma {
    width: 100%;
    padding-top: 24px;
    padding-bottom: 72px;

    &__wrap-slider {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      box-sizing: content-box;
    }

    &__wrap-slider #carouselVacancy {
      min-height: 997px;
      height: 273vw;
    }

    &__subtitle {
      margin-bottom: 33px;
    }

    &__subscription {
      margin-bottom: 23px;
    }

    &__wrap-text {
      padding-top: 35px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 25px;
      width: 100%;
      order: 1;
    }

    &__wrap-slider .carousel-control-next,
    &__wrap-slider .carousel-control-prev {
      opacity: 0;
    }

    &__wrap-slider .carousel-control-prev {
      left: -16px;
    }

    &__wrap-slider .carousel-control-next {
      right: -16px;
    }

    &__wrap-img {
      width: 100%;
    }

    &__wrap-img img {
      width: 100%;
      height: auto;
    }

    &__wrap-slider .carousel-indicators {
      bottom: 18px;
      left: 0;
      right: auto;
      margin-left: 16px;
    }
  }
}

@media (max-width: $mobile-view-320) {
  .advantages-work-elma {
    &__wrap-slider #carouselVacancy {
      min-height: 1067px;
      height: 327vw;
    }
  }
}
