.burger-menu {
  border: 0;
  display: block;
  position: relative;
  width: 24px;
  height: 12px;
  padding: 0;
  background-color: transparent;
  appearance: none;
  outline: none!important;

  &:before {
    content: "";
    position: absolute;
    top: -12px;
    bottom: -12px;
    left: -10px;
    right: -10px;
  }

  &__item {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 24px;
    height: 2px;
    background: #272727;
    transition: all .2s ease;
  }

  &__item:first-of-type {
    top: 0;
  }

  &__item:nth-of-type(2) {
    top: 50%;
    margin-top: -1px;
  }

  &__item:last-of-type {
    bottom: 0;
  }

  &_opened &__item:first-of-type {
    transform: translate(2px,5px)rotate(45deg);
  }

  &_opened &__item:nth-of-type(2) {
    opacity: 0;
  }

  &_opened &__item:last-of-type {
    transform: translate(2px,-5px) rotate(-45deg);
  }
}