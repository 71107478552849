.case-help {
  &__wrap {
    padding-bottom: 36px;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__main-content {
    width: 65%;
    padding-right: 6%;
    box-sizing: border-box;
  }

  &__title {
    font-size: 30px;
    line-height: 46px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  &__description {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  &__description:last-of-type {
    margin-bottom: 38px;
  }

  &__list {
    list-style: none;
    width: 100%;
    padding-left: 17px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__list li {
    position :relative;
    font-size: 18px;
    line-height: 30px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding-right: 5%;
  }

  &__list li::before {
    position: absolute;
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    left: -17px;
    top: 13px;
    background-color: #000000;
  }

  &__list.orange li::before {
    background-color: #edc14c;
  }

  &__list.blue li::before {
    background-color: #048ad3;
  }

  &__list.gray li::before {
    background-color: #bcbcbc;
  }

  &__list.lh-26 li {
    line-height: 26px;
  }

  &__list.for-lh-26 li::before {
    top: 10px;
  }

  &__border-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__border-list li {
    position: relative;
    padding-left: 17px;
    width: 42%;
    margin-bottom: 37px;
    font-size: 18px;
    line-height: 28px;
  }

  &__border-list li:nth-of-type(odd) {
    margin-right: 40px;
  }

  &__border-list li::before {
    position: absolute;
    content: '';
    top: 5px;
    left: 0;
    bottom: 3px;
    width: 8px;
    background-color: #000000;
  }

  &__border-list.gold li::before {
    background-color: #edc14c;
  }

  &__aside {
    width: 34%;
    padding-right: 3.5%;
    padding-top: 62px;
    box-sizing: border-box;
  }

  &__aside--personnel-reserve {
    .case-help__aside-list {
      background-color: transparent;
    }
  }

  &__aside-list {
    background-color: #e6e6e6;
    list-style: none;
    margin-bottom: 0;
    padding: 27px 31px;
    padding-right: 27px;
    padding-bottom: 60px;
    box-sizing: border-box;
  }

  &__aside-list li:not(:last-of-type) {
    margin-bottom: 23px;
  }

  &__aside-list b {
    display: block;
    font-size: 18px;
    line-height: 30px;
  }

  &__aside-list p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 30px;
  }

  &__aside--personnel-reserve {
    .case-help__aside-list {
      padding-top: 0;
      padding-bottom: 0;
    }

    .case-help__aside-list b {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 16px;
      color: #048ad3;
      font-weight: normal;
    }

    .case-help__aside-list p {
      font-size: 18px;
      line-height: 30px;
    }
  }
}


.case-help--meeting {
  .case-help {
    &__wrap {
      padding-bottom: 50px;
    }

    &__main-content {
      width: 530px;
      padding-right: 0;
      @media (min-width: 1400px) {
        width: 600px;
      }
    }

    &__description {
      margin-bottom: 22px;
    }

    &__aside {
      width: 40.3%;
      padding-top: 6px;
    }

    &__aside-list {
      background-color: transparent;
      width: 100%;
      padding: 0;
      padding-right: 22%;
    }

    &__aside-list li {
     margin-bottom: 16px;
    }

    &__aside-list li div {
      color: #edc14c;
    }

    &__aside-list li div mark {
      display: inline-block;
      background-color: transparent;
      font-size: 48px;
      line-height: 48px;
      padding: 0;
      color: inherit;
      margin-bottom: 6px;
    }

    &__aside-list li div p {
      font-size: 20px;
      line-height: 24px;
      display: inline-block;
      margin: 0;
    }

    &__aside-list li div span {
      font-size: 20px;
      line-height: 24px;
      display: inline-block;
    }

    &__aside-list li p {
      font-size: 16px;
      line-height: 26px;
      display: inline-block;
    }
  }
}

.case-help--meeting-decision {
  .case-help {
    &__wrap {
      padding-bottom: 50px;
      margin-bottom: 50px;
    }

    &__main-content {
      width: 540px;
      padding-right: 0;
      @media (min-width: 1400px) {
        width: 670px;
      }
    }

    &__description {
      margin-bottom: 39px;
    }

    &__aside {
      width: 40.3%;
      padding-top: 9px;
      padding-right: 0;
      @media (min-width: 1400px) {
        padding-right: 6.3%;
      }
    }

    &__aside-list {
      padding: 23px 27px;
    }

    &__aside-list b {
      margin-bottom: 9px;
    }

    &__aside-list > li p {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 22px;
    }

    &__aside-list .case-help__list li:not(:last-of-type) {
      margin-bottom: 12px;
    }

    &__aside-list .case-help__list li {
      font-size: 16px;
      line-height: 26px;
    }
  }
}

.case-help--personnel-reserve {
  .case-help {
    &__wrap {
      padding-bottom: 50px;
      margin-bottom: 50px;
    }

    &__main-content {
      width: 570px;
      padding-right: 0;
      @media (min-width: 1400px) {
        width: 601px;
      }
    }

    &__description {
      margin-bottom: 39px;
    }

    &__aside {
      width: 38%;
      padding-top: 30px;
      @media (min-width: 1400px) {
        width: 43.3%;
        padding-right: 6.3%;
      }
    }

    &__aside-list {
      padding: 23px 27px;
    }
  }
}

@media (max-width: $mobile-view) {
  .case-help {
    & &__wrap {
      width: 100%;
      margin-bottom: 20px;
    }

    & &__title {
      font-size: 20px;
      line-height: 22px;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
    }

    & &__main-content {
      width: 100%;
      padding: 0 16px;
    }

    &__list li {
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }

    &__aside--personnel-reserve {
      .case-help__aside-list {
        padding-top: 0;
        padding-bottom: 0;
      }

      .case-help__aside-list b {
        font-size: 20px;
        line-height: 30px;
        color: #048ad3;
        font-weight: normal;
      }

      .case-help__aside-list p {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__list li::before {
      top: 9px;
    }

    & &__list li:nth-of-type(even) {
      width: 100%;
    }

    &__border-list li {
      width: 80%;
    }

    & &__aside {
      width: 100%;
      padding: 0;
    }

    & &__aside-list {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}
