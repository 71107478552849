.product-card {
  padding: 25px 23px 35px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid #D6D6D6;
  background-color: #FFFFFF;
  transition: 0.3s ease-in-out;
  height: 100%;
  flex-direction: column;
  color: inherit;
  z-index: 2;
  position: relative;

  @media (min-width: 992px) {
    padding: 30px;
  }

  @media (min-width: 1200px) {
    padding: 40px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 6px;
    top: 6px;
    width: 92px;
    height: 104px;
    background-image: url("../image/product-card-pixels.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: -1;
    @media (min-width: 992px) {
      right: 19px;
      top: 11px;
      width: 119px;
      height: 127px;
    }
  }

  &_interactive {
    &:hover {
      box-shadow: 0 20px 34px -10px rgba(0, 0, 0, 0.1);
    }
  }

  &__content {
    max-width: 285px;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.2;
    color: #1E6599;
    margin-bottom: 13px;
    @media (min-width: 575px) {
      font-size: 20px;
    }
    @media (min-width: 768px) {
      margin-bottom: 24px;
      font-size: 24px;
    }
  }

  &__release-soon {
    top: 8px;
    right: 11px;
    position: absolute;
    display: block;
    width: 80px;
    @media (min-width: 1024px) {
      width: 110px;
    }
    @media (min-width: 1200px) {
      top: 8px;
      right: 11px;
    }
  }

  &__text {
    color: #212529;

    @media (max-width: 767px) {
      font-size: 14px;
    }
    @media (max-width: 575px) {
      font-size: 12px;
      line-height: 1.8;
    }
  }

  &__link {
    @media (max-width: 575px) {
      font-size: 12px;
      line-height: 1.8;
    }
  }
}