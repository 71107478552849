.b-2-col {
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    margin-left: -10px;
    margin-right: -10px;
    flex-direction: row;
    align-items: center;
    &_align_top {
      align-items: flex-start;
    }
  }

  &__col {
    line-height: 1.75;

    @media (min-width: 768px) {
      padding: 0 10px;
      width: 50%;
      &_align_top {
        padding-top: 20px;
        align-self: flex-start;
      }
    }

    @media (min-width: 992px) {
      &_text-left {
        padding-left: 114px;
      }
      &_img-right {
        padding-left: 64px;
      }

      &_text-right {
        padding-right: 114px;
      }
      &_img-left {
        padding-right: 64px;
      }
    }
    @media (max-width: 767px) {
      &_text-right {
        order: 2;
        margin-top: 30px;
      }
      &_text-left {
        order: 2;
        margin-top: 30px;
      }
    }
  }

  &__img {
    max-width: 100%;
    display: block;
    margin: auto;
  }

}