.community-advanced {
  min-height: auto;
  padding-bottom: 52px;
  background-image: url('/img/assets/download_ce.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

@media (max-width: 420px) {
  .community-advanced {
    background-image: none;
  }
}
