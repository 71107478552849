.form-popup {
  &__wrap {
    position: relative;
    background-color: white;
    -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    padding: 22px 30px;
    padding-bottom: 80px;
  }

  &__wrap--standard {
    width: 640px;
    max-width: none;
  }

  &__button-close {
    position: absolute;
    background: transparent;
    border: none;
    right: 25px;
    top: 31px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    outline: none;
  }

  &__button-close:active,
  &__button-close:hover,
  &__button-close:focus {
    outline: none;
  }

  &__button-close::before {
    content: '';
    position: absolute;
    top: 8px;
    left: -4px;
    width: 23px;
    height: 2px;
    background-color: #272727;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &__button-close::after {
    content: '';
    position: absolute;
    top: 8px;
    left: -4px;
    width: 23px;
    height: 2px;
    background-color: #272727;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &__description {
    padding-right: 45px;
    margin-bottom: 27px;
  }

  &__title {
    font-size: 24px;
    line-height: 34px;
    font-weight: bold;
    margin-bottom: 13px;
  }

  &__title--no-description {
    margin-bottom: 18px;
  }

  &__label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    flex-wrap: wrap;
    padding-right: 45px;
    margin-bottom: 12px;
  }

  &__input-wrap {
    flex-grow: 1;
  }

  &__label--login {
    padding-right: 139px;
  }

  &__label--checkbox {
    position: relative;
    display: block;
    margin-left: 178px;
    margin-bottom: 19px;
    padding-right: 0;
    font-size: 14px;
    line-height: 24px;
  }

  &__label--checkbox-login {
    margin-left: 89px;
  }

  &__label--textarea {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  &__label--drop-file {
    position: relative;
    width: 100%;
    height: 159px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-bottom: 41px;
    margin-bottom: 32px;
    background-color: #f5f5f5;

  }

  &__label--drop-file-active {
    border: 1px solid #cfcfcf;
    background-color: transparent;
  }

  &__label--drop-file::before {
    position: absolute;
    content: '';
    width: 40px;
    height: 40px;
    left: 50%;
    top: 40px;
    margin-left: -25px;
    background-image: url('/img/assets/ic_download.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__label-value {
    width: 178px;
    display: inline-block;
    padding-top: 5px;
  }

  &__label-value--login {
    width: 84px;
  }

  &__label--drop-file span {
    width: auto;
  }

  &__label--drop-file .form-popup__input-link {
    color: #048ad3;
  }

  &__input {
    top: 15px;
    left: 9px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 5px 15px;
    border: 1px solid #cfcfcf;
    background-color: transparent;
  }

  &__input.error {
    border: 1px solid #dc143c;
  }

  &__input.error + .invalid-feedback {
    display: block;
  }

  &__input-description {
    width: 375px;
    box-sizing: border-box;
    border: none;
    font-size: 14px;
    line-height: 21px;
    color: #868686;
  }

  &__label select:invalid {
    color: transparent;
  }

  &__label select option[value=""][disabled] {
    display: none;
  }

  &__label select option {
    color: black;
  }

  &__label textarea::-webkit-input-placeholder {opacity: 0;}
  &__label textarea::-moz-placeholder          {opacity: 0;}/* Firefox 19+ */
  &__label textarea:-moz-placeholder           {opacity: 0;}/* Firefox 18- */
  &__label textarea:-ms-input-placeholder      {opacity: 0;}

  &__input::-webkit-input-placeholder {opacity: 0}
  &__input::-moz-placeholder          {opacity: 0}/* Firefox 19+ */
  &__input:-moz-placeholder           {opacity: 0}/* Firefox 18- */
  &__input:-ms-input-placeholder      {opacity: 0}

  &__input--mobile-only::-webkit-input-placeholder {opacity: 1}
  &__input--mobile-only::-moz-placeholder          {opacity: 1}/* Firefox 19+ */
  &__input--mobile-only:-moz-placeholder           {opacity: 1}/* Firefox 18- */
  &__input--mobile-only:-ms-input-placeholder      {opacity: 1}

  &__input--select {
    padding: 6px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('/img/assets/drop_down.svg');
    background-repeat: no-repeat;
    background-position: 96% 15px;
    background-size: 13px;
  }

  &__input--file {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
  }

  &__file-info {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }

  &__input--checkbox + .form-popup__label--icon {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 7px;
    margin-bottom: -2px;
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 0 1px #d1d1d1;
    box-shadow: inset 0 0 0 1px #d1d1d1;
  }

  &__input--checkbox.error + .form-popup__label--icon {
    box-shadow: inset 0 0 0 1px crimson;
  }

  &__input--checkbox:checked + .form-popup__label--icon::before {
    position: absolute;
    content: "";
    top: 8px;
    right: 7px;
    width: 7px;
    height: 2px;
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
    background-color: #048ad3;
  }

  &__input--checkbox:checked + .form-popup__label--icon::after {
    position: absolute;
    content: "";
    top: 6px;
    right: 1px;
    width: 11px;
    height: 2px;
    -webkit-transform: rotate(-59deg);
    -ms-transform: rotate(-59deg);
    transform: rotate(-59deg);
    background-color: #048ad3;
  }

  &__submit {
    position: relative;
    display: inline-block;
    margin-left: 178px;
    padding: 11px 48px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #0d4a75;
    transition: .2s;
  }

  &__submit--login {
    margin-left: 84px;
  }

  &__submit--drop-file {
    margin-left: 0;
  }

  &__wrap textarea {
    height: 108px;
    resize: none;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 5px 15px;
    border: 1px solid #cfcfcf;
  }

  &__wrap textarea.error {
   border: 1px solid #dc143c;
  }

  &__wrap-interactive {
    position: relative;
    width: 960px;
    min-height: 600px;
    max-width: none;
    padding: 24px 30px;
    background-color: white;
    -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  }

  &__wrap-interactive--demo-version {
    background-image: url('/img/assets/interactive-demo.jpg');
    background-repeat: no-repeat;
    background-position: 202px 0;
  }

  &__wrap-interactive--question {
    background-image: url('/img/assets/question.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    padding-right: 350px;
    min-height: 638px;
  }

  &__wrap-interactive--download-ce {
    background-image: url('/img/assets/download_ce.jpg');
    background-repeat: no-repeat;
    background-position: 328px 0;
    background-size: 66%;
  }

  &__wrap-interactive .downloads-demo__form-wrap {
    width: 349px;
  }

  &__wrap-interactive .downloads-demo__products-wrap {
    width: 545px;
  }

  &__wrap-interactive .downloads-demo__label--checkbox {
    margin-left: 0;
    margin-bottom: 10px;
  }

  &__wrap-interactive .downloads-demo__submit {
    margin-left: 0;
  }

  &__wrap-interactive textarea {
    height: 108px;
    resize: none;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 5px 15px;
    border: 1px solid #cfcfcf;
    background-color: transparent;
  }

  &__wrap-interactive textarea.error {
    border: 1px solid #dc143c;
  }

  &__wrap-interactive textarea.error + .invalid-feedback {
    display: block;
  }

  &__wrap-interactive .form-popup__label--checkbox {
    margin-bottom: 10px;
  }

  &__wrap-interactive--free-version {
    padding-bottom: 91px;
  }

  &__wrap-interactive--download-ce {
    min-height: 489px;
  }

  &__wrap-interactive--download-ce .downloads-demo__submit-unclosed {
    margin-left: 0;
  }

  &__wrap-interactive--download-ce .form-popup__button-close::before,
  &__wrap-interactive--download-ce .form-popup__button-close::after {
    background-color: white;
  }

  &__wrap-interactive--download-ce .downloads-demo__ce-description {
    padding-right: 275px;
  }

  &__wrap-interactive--download-ce .downloads-demo__submit {
    padding-left: 28px;
  }

  &__wrap-interactive--download-ce .downloads-demo__submit::before {
    display: none;
  }
}

.downloads-demo--download-ce .downloads-demo__products-wrap {
  padding-left: 25px;
}

@media (max-width: $mobile-view) {
  .form-popup__wrap-interactive,
  .form-popup__wrap--standard {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 0;
  }

  .form-popup__wrap-interactive--demo-version,
  .form-popup__wrap-interactive--question,
  .form-popup__wrap-interactive--download-ce {
    background-image: none;
  }

  .form-popup__wrap-interactive--download-ce .downloads-demo__ce-description {
    padding-right: 0;
  }

  .form-popup__label {
    padding-right: 0;
  }

  .form-popup__label-value {
    display: none;
  }

  .form-popup__wrap-interactive--question .form-popup__label-value {
    display: none;
  }

  .form-popup__wrap-interactive--question .form-popup__label--checkbox,
  .form-popup__label--checkbox {
    margin-left: 0;
    margin-top: 25px;
  }

  .form-popup__wrap-interactive--question .form-popup__submit,
  .form-popup__submit {
    margin-left: 0;
  }

  .form-popup__wrap-interactive .downloads-demo__form-wrap {
    width: 100%;
  }

  .form-popup__button-close {
    right: 6px;
    top: 12px;
  }

  .form-popup__label select:invalid {
    color: #cfcfcf;
  }

  .form-popup__wrap-interactive .downloads-demo__products-wrap {
    width: 100%;
    padding-top: 25px;
  }

  .form-popup__wrap-interactive--download-ce .form-popup__button-close::before,
  .form-popup__wrap-interactive--download-ce .form-popup__button-close::after {
    background-color: black;
  }

  .form-popup__label textarea::-webkit-input-placeholder {opacity: 1;}
  .form-popup__label textarea::-moz-placeholder          {opacity: 1;}/* Firefox 19+ */
  .form-popup__label textarea:-moz-placeholder           {opacity: 1;}/* Firefox 18- */
  .form-popup__label textarea:-ms-input-placeholder      {opacity: 1;}

  .form-popup__input::-webkit-input-placeholder {opacity: 1;}
  .form-popup__input::-moz-placeholder          {opacity: 1;}/* Firefox 19+ */
  .form-popup__input:-moz-placeholder           {opacity: 1;}/* Firefox 18- */
  .form-popup__input:-ms-input-placeholder      {opacity: 1;}
}
