.case-banner {
  min-height: 523px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 9px;
  margin-bottom: 42px;
  padding: 28px 30px;
  background-color: #636363;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  box-sizing: border-box;
  box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);

  &__position {
    display: block;
    font-size: 20px;
    line-height: 26px;
    color: #edc14c;
    margin-bottom: 28px;
  }

  &__title {
    font-size: 48px;
    line-height: 60px;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 13px;
  }

  &__description {
    font-size: 20px;
    line-height: 35px;
    color: #ffffff;
    margin-bottom: 38px;
  }

  &__button {
    display: inline-block;
    padding: 12px 40px;
  }
}

.case-banner--purchases {
  background-image: url('/upload/purh-bg/png5cff8118e2d4e4/93170401/purh-bg.png');

  .case-banner__title {
    width: 60%;
  }

  .case-banner__description {
    width: 59%;
  }
}

.case-banner--contracts {
  background-image: url('/upload/contracts-bg/jpg5d10661b3c2870/48329068/contracts-bg.jpg');

  .case-banner__title {
    width: 83%;
  }

  .case-banner__description {
    width: 64%;
  }
}

.case-banner--b2b {
  background-image: url("/upload/bg-sale/jpg5d15b6fe294117/91715708/bg-sale.jpg");
  .case-banner__title {
    width: 55%;
  }

  .case-banner__description {
    width: 60%;
  }
}

.case-banner--meeting {
  background-image: url("/upload/bg-meeting/png5d316ce878d1f2/35810597/bg-meeting.png");
  .case-banner__title {
    width: 80%;
  }

  .case-banner__description {
    width: 75%;
  }
}

.case-banner--case-hub {
  background-image: url("/upload/bg_case-hub/png5d70e9db4dd970/24037979/bg_case-hub.png");
  .case-banner__position {
    margin-bottom: 80px;
  }

  .case-banner__description {
    width: 70%;
  }
}


.case-banner--ivoice {
  margin-bottom: 54px;

  background-image: url("/upload/Group1270/jpg5d80b05e2c9003/30711756/Group1270.jpg");
  .case-banner__title {
    width: 100%;
  }

  .case-banner__description {
    width: 70%;
  }
}

.case-banner--travel {
  margin-bottom: 54px;

  background-image: url("/upload/web_banner/png5d8b08267a9ef8/10170969/web_banner.png");
  .case-banner__title {
    width: 80%;
  }

  .case-banner__description {
    width: 65%;
  }
}

.case-banner--pay {
  margin-bottom: 54px;

  background-image: url("/upload/case/case_pay_bg.jpg");
  .case-banner__title {
    width: 100%;
  }

  .case-banner__description {
    width: 65%;
  }
}

.case-banner--up-sell {
  margin-bottom: 54px;

  background-image: url("/upload/Artboard – 1/png5db14cd7b9e208/13984254/Artboard – 1.png");
  .case-banner__title {
    width: 100%;
  }

  .case-banner__description {
    width: 65%;
  }
}

.case-banner--personnel-reserve {
  margin-bottom: 54px;

  background-image: url("/upload/cases/hr/bg_personnel.jpg");
  .case-banner__title {
    width: 100%;
  }

  .case-banner__description {
    width: 65%;
  }
}

.case-banner--farm {
  background-image: url("/upload/cases/farm/bg_farm.jpg");
  background-color: #eeeeee;
  padding: 28px 77px;
  min-height: 779px;
  background-size: contain;

  .case-banner__position {
    margin-bottom: 80px;
  }

  .case-banner__wrap {
    padding-bottom: 64px;
  }

  .case-banner__title {
    width: 85%;
    color: #000000;
  }

  .case-banner__description {
    width: 60%;
    color: #000000;
  }

  .case-banner__position {
    color: $color-dark-green;
    margin-bottom: 23px;
  }

  .case-banner__button {
    background-color: $color-dark-green;
  }

  .case-banner__button:active,
  .case-banner__button:hover,
  .case-banner__button:focus {
    background-color: $color-dark-green!important;
  }

  .case-banner__add {
    padding: 30px 0;
    width: 700px;
  }

  .case-banner__add p {
    font-size: 20px;
    line-height: 160%;
    margin-bottom: 23px;
  }

  .case-banner__add a {
    font-size: 20px;
    line-height: 32px;
    text-decoration: none;
    padding: 6px 35px;
    color: $color-dark-green;
    border: 2px solid $color-dark-green;
    transition: 0.1s;
  }

  .case-banner__add a:hover {
    background-color: $color-dark-green;
    color: white;
  }

  .case-banner__groupe-link {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

@media (max-width: $mobile-view) {
  .case-banner {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 16px;

    &__position {
      font-size: 16px;
      line-height: 26px;
    }

    & &__title {
      font-size: 26px;
      line-height: 30px;
      width: 100%;
    }

    & &__description {
      font-size: 16px;
      line-height: 26px;
      width: 100%;
    }

    &__button {
      font-size: 16px;
      line-height: 30px;
      padding: 9px 40px;
    }
  }

  .case-banner--farm {
    background-size: cover;

    .case-banner {
      &__add {
        width: 100%;
        padding: 0;
      }

      &__add a:not(:last-of-type) {
        margin-bottom: 10px;
      }

      &__add a {
        width: 100%;
        font-size: 16px;
      }
    }
  }

  .case-banner--contracts {
    .case-banner {
      &__title {
        width: 100%;
      }

      &__description {
        width: 100%;
      }
    }
  }

  .case-banner--case-hub {
    .case-banner__position {
      margin-bottom: 28px;
    }
  }
}
