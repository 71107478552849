.visually-hidden {
  position: absolute;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
.line-height--title {
  line-height: 28px;
}

.line-height--small {
  line-height: 16px;
}

.ecm-link--active {
  color: black;
  border-radius: 3px;
  background-color: rgba(190, 190, 190, 0.2);
}

.text-background {
  background-color: rgba(20, 29, 45, 0.6);
}

.title {
  line-height: 1.2;
  font-weight: 600;
  &_h1 {
    font-size: 28px;
  }
  &_h2 {
    font-size: 24px;
  }
  &_h2-5 {
    font-size: 20px;
  }
  &_h3 {
    font-size: 16px;
  }
  &_h4 {
    font-size: 16px;
    margin-bottom: 11px;
  }

  @media (min-width: 576px) {
    &_h1 {
      font-size: 40px;
    }
    &_h2 {
      font-size: 35px;
    }
    &_h2-5 {
      font-size: 26px;
    }
    &_h3 {
      font-size: 20px;
    }
    &_h4 {
      font-size: 18px;
      margin-bottom: 11px;
    }
  }

  @media (min-width: 1400px) {
    &_h1 {
      font-size: 50px;
    }
    &_h2 {
      font-size: 45px;
    }
    &_h2-5 {
      font-size: 30px;
    }
    &_h3 {
      font-size: 24px;
    }
    &_h4 {
      font-size: 20px;
      margin-bottom: 11px;
    }
  }
}

.base-wrapper {
  overflow: hidden;
  position: relative;
}

.font-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
  margin-bottom: 21px;
}

.font-title-bold {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}

.font-link {
  font-size: 16px;
  line-height: 22px;
}

.font-description {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.font-description--figure {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #636363;
}

.elma-link--arrow {
  position: relative;
  cursor: pointer;
  padding-right: 24px;
  transition: 0.2s ease-in-out;
  color: #0d4a75;
  display: inline-block;

  &:hover {
    opacity: 0.6;
    color: #0d4a75;
    &::after {
      left: 29px;
    }
  }

  &::after {
    content: "";
    width: 16px;
    height: 7px;
    background-image: url("../image/link-arr.svg");
    background-position: center right;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    margin-left: -16px;
    position: relative;
    left: 24px;
    transition: 0.2s ease-in-out;
  }

  &_yellow {
    color: #FABE00;
    &:hover {
      color: #FABE00;
    }
    &::after {
      background-image: url("../image/link-arr-yellow.svg");
    }
  }

  &_wide {
    padding-right: 34px;

    &::after {
      width: 28px;
      margin-left: -28px;
      left: 34px;
    }

    &:hover {
      &::after {
        left: 39px;
      }
    }
  }

  @media (min-width: 768px) {
    &_wide {
      padding-right: 52px;

      &::after {
        width: 38px;
        margin-left: -38px;
        left: 52px;
      }

      &:hover {
        &::after {
          left: 57px;
        }
      }
    }
  }
}


.elma-link--arrow-bpm {
  cursor: pointer;
}

.elma-link--arrow-bpm::before {
  position: absolute;
  content: "";
  top: 26px;
  right: 1px;
  width: 2px;
  height: 10px;
  background-color: #048ad3;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.elma-link--arrow-bpm::after {
  position: absolute;
  content: "";
  bottom: 2px;
  right: 1px;
  width: 2px;
  height: 10px;
  background-color: #048ad3;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.elma-link--no-border {
  border-bottom: none;
}

.cover-img {
  position: relative;
  overflow: hidden;
  &__item {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    position: absolute;
    object-fit: cover;
    object-position: center;
  }
}

.contain-img {
  position: relative;
  overflow: hidden;
  &__item {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    position: absolute;
    object-fit: contain;
    object-position: center;
  }
}

.btn-primary:hover {
  color: #0d4a75;
  background-color: transparent;
  border-color: #0d4a75;
}

.btn-primary.focus, .btn-primary:focus, .form-control:focus {
  box-shadow: none;
}

@media (max-width: $mobile-view) {
  .font-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
  }

  .font-title-bold {
    font-size: 16px;
    line-height: 26px;
  }
}

body {
  @media (max-width: 1399px) {
    font-size: 0.9rem;
  }
}

.ss-card-row {
  width: 300px;
  @media (min-width: 1400px) {
    width: 380px;
  }
}

.video-courses {
  iframe {
    max-width: 100%;
  }
}