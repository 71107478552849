.play-button {
  background: #1E6599;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: block;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  @media (min-width: 576px) {
    width: 68px;
    height: 68px;
  }

  &_center {
    position: absolute;
    left: calc(50% - 27px);
    top: calc(50% - 27px);
    @media (min-width: 576px) {
      left: calc(50% - 34px);
      top: calc(50% - 34px);
    }
  }

  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 0 9px 16px;
    border-color: transparent transparent transparent #ffffff;
    position: absolute;
    left: 55%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.2s ease-in-out;
    @media (min-width: 576px) {
      border-width: 12px 0 12px 21px;
    }
  }

  &:hover {
    background: #FFFFFF;
    &::before {
      border-color: transparent transparent transparent #1E6599;
    }
  }
}