.case-rules {
  &__wrap {
    padding: 59px 43px;
    margin-left: 40px;
    margin-right: 40px;
    @media (min-width: 1400px) {
      margin-left: 60px;
      margin-right: 60px;
    }
  }

  &__title {
    font-size: 35px;
    line-height: 42px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__description {
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 50px;
  }

  &__subtitle-wrap {
    display: flex;
  }

  &__subtitle-wrap p:first-of-type {
    width: 50%;
    padding-left: 64px;
  }

  &__subtitle-wrap p:last-of-type {
    width: 50%;
    padding-left: 64px;
  }

  &__subtitle-wrap p {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
  }

  &__list {
    padding-left: 0;

    &.slider-projects {
      font-size: 18px;
      line-height: 30px;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__item:not(:last-of-type) {
    margin-bottom: 40px;
  }

  &__item-left {
    position: relative;
    padding-left: 64px;
    width: 50%;
    padding-right: 50px;
    @media (min-width: 1400px) {
      padding-right: 162px;
    }
  }

  &__item-right {
    position: relative;
    width: 50%;
    padding-left: 64px;
    padding-right: 30px;
  }

  &__success,
  &__error {
    position: absolute;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    left: 10px;
    top: -5px;
  }

  &__success::after,
  &__success::before {
    content: '';
    position: absolute;
    background-color: #ffffff;
    height: 3px;
    border-radius: 2px;
  }

  &__success::after {
    width: 9px;
    left: 10px;
    top: 21px;
    transform: rotate(45deg);
  }

  &__success::before {
    width: 17px;
    left: 14px;
    top: 19px;
    transform: rotate(-45deg);
  }

  &__success {
    background-color: $color-dark-green;
  }

  &__error::after,
  &__error::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 3px;
    background-color: #ffffff;
    top: 17px;
    left: 9.2px;
    border-radius: 2px;
  }

  &__error::after {
    transform: rotate(45deg);
  }

  &__error::before {
    transform: rotate(-45deg);
  }

  &__error {
    background-color: $color-dark-orange;
  }

  &__item p + ul {
    margin-top: -15px;
  }

  &__item ul {
    list-style-type: disc;
    padding-left: 19px;
  }
}

.case-rules--gray {
 .case-rules {
   &__wrap {
     background-color: rgba(192, 192, 192, 0.15);
   }
 }
}

.case-rules--light-green {
  .case-rules {
    &__wrap {
      background-color: #DDEEEC;
    }
  }
}

.case-rules--mint {
  .case-rules {
    &__wrap {
      background-color: #E1E8EC;
    }
  }
}

.case-rules--pt {
  padding-top: 50px;
}

.case-rules--projects {
  .case-rules__item-left {
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      width: 38px;
      height: 38px;
      background: url("/upload/projects/icon_problem.svg") no-repeat center center;
      background-size: cover;
    }
  }

  .case-rules__item-right {
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      width: 38px;
      height: 38px;
      background: url("/upload/projects/icon_decision.svg") no-repeat center center;
      background-size: cover;
    }
  }
}

@media (max-width: $mobile-view) {
  .case-rules {
    &__wrap {
      width: 100%;
      margin-left: 0;
      padding-left: 16px;
      padding-right: 16px;
    }

    &__item {
      flex-wrap: wrap;
    }

    &__item-left,
    &__item-right {
      width: 100%;
      padding-left: 40px;
      padding-right: 0;
      margin-bottom: 25px;
    }

    &__error,
    &__success {
      left: -8px;
    }

    &__subtitle-wrap p:first-of-type {
      width: 39%;
      padding-left: 39px;
    }
  }

  .case-rules--projects {
    .case-rules__item-left,
    .case-rules__item-right {
      padding: 29px 23px;
      background-color: rgba(255, 255, 255, 0.5);
    }

    .case-rules__item-left {
      border: 1.5px solid #EE736D;

      &::before {
        width: 28px;
        height: 28px;
        top: -14px;
        left: auto;
        right: 34px;
      }
    }

    .case-rules__item-right {
      border: 1.5px solid #69AB42;

      &::before {
        width: 28px;
        height: 28px;
        top: -14px;
        left: auto;
        right: 34px;
      }
    }

    .owl-stage {
      padding-top: 20px;
    }
  }

  .case-rules__list--desk {
    display: none;
  }
}
