
.universal-block__chairs {
  background-image: url('/img/assets/bg_chairs.jpg');
  background-repeat: no-repeat;
  background-position: 576px 38px;
  background-size: 52%;
  background-color: rgba(86, 99, 115, 1);
  padding: 51px 73px;
  max-width: 1290px;
  margin-left: -35px;
  margin-right: -35px;
}

@media (max-width: $mobile-view) {
  .universal-block__chairs {
    margin-left: -16px;
    margin-right: -16px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 254px;
    background-size: 87%;
    background-position: 57px 926px;
  }

  .universal-block__chairs--crm {
    background-position: 57px 1166px;
  }

  .universal-block__chairs--project {
    background-position: 57px 840px;
  }

  .universal-block__chairs--kpi {
    background-position: 56px 705px;
  }
}
