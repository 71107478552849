.system-requirements-enterprise {
  position: relative;
  background: url('/img/enterprise.png');
  background-repeat: no-repeat;
  background-size: 83%;
  width: 100%;
  height: 487px;
  margin-bottom: 50px;

  &__wrap-link a {
    position: absolute;
  }

  &__link-one {
    top: 49.5%;
    left: 0;
  }

  &__link-three {
    top: 45%;
    left: 143px;
    width: 60px;
  }

  &__link-four {
    top: 87%;
    left: 221px;
    width: 162px;
  }

  &__link-five {
    top: 87%;
    left: 398px;
    width: 140px;
  }

  &__link-six {
    top: 30%;
    left: 617px;
    width: 160px;
  }

  &__link-seven {
    bottom: 0;
    right: 0;
  }
}

@media (max-width: $mobile-view) {
  .system-requirements-enterprise {
    background-image: url("/upload/system_requirements/png5cdcfc6b0d2d24/18617365/system_requirements.png");
    background-size: contain;
    background-position: center;
    margin-bottom: 120px;

    &__link-one {
      top: 6.5%;
      left: 48%;
    }

    &__link-three {
      top: 24%;
      left: 48%;
      width: 165px;
    }

    &__link-four {
      top: 58%;
      left: 42%;
      width: 162px;
    }

    &__link-five {
      top: 70%;
      left: 7%;
      width: 140px;
    }

    &__link-six {
      top: 96%;
      left: 172px;
      width: 166px;
    }

    &__link-seven {
      bottom: -10%;
      right: 71%;
      width: 100px;
    }
  }
}
