.courses {
  padding-top: 25px;

  & &__title {
    margin-bottom: 22px;
  }

  &__description {
    margin-top: 22px;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 19px;
  }

  &__general-text {
    width: 735px;
  }

  &__entry,
  &__education,
  &__attestation {
    width: 735px;
    margin-top: 15px;
  }

  &__wrap-table {
    padding: 15px 7px;
    background-color: white;
    width: 855px;
    margin-left: -27px;
    -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  }

  &__table {
    width: 841px;
  }

  &__table thead {
    background-color: #f2f2f2;
  }

  &__table thead th {
    font-size: 16px;
    line-height: 22px;
    font-weight: normal;
    color: #272727;
    padding: 12px 0;
    padding-left: 6px;
  }

  &__table th:first-of-type {
    width: 104px;
    padding-left: 20px;
  }

  &__table td:nth-of-type(2) {
    width: 183px;
    vertical-align: top;
  }

  &__table td:nth-of-type(3) {
    width: 69px;
    padding-left: 14px;
    vertical-align: top;
  }

  &__table td:nth-of-type(4) {
    width: 120px;
    vertical-align: top;
  }

  &__table td:nth-of-type(5) {
    width: 154px;
    vertical-align: top;
  }

  &__table td {
    padding-top: 12px;
    padding-left: 6px;
  }

  &__table td:first-of-type {
    width: 104px;
    padding-left: 20px;
    vertical-align: top;
  }

  &__table td:nth-of-type(2) {
    width: 183px;
  }

  &__table tr:nth-of-type(2) td {
    padding-top: 22px;
  }

  &__table tr:nth-of-type(7) td {
    padding-bottom: 21px;
  }

  &__table tr:nth-of-type(9) td {
    padding-top: 22px;
    padding-bottom: 21px;
  }

  &__table tr:nth-of-type(11) td {
    padding-top: 22px;
  }

  &__table tr:nth-of-type(13) td {
    padding-top: 22px;
    padding-bottom: 21px;
  }

  &__table .courses__table-border {
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
    border-bottom: 1px solid #dee2e6;
    padding: 9px 0;
  }

  &__value {
    font-size: 16px;
    line-height: 26px;
    color: #272727;
    margin-bottom: 28px;
  }

  &__button {
    display: inline-block;
    font-weight: bold;
    padding: 12px 20px;
    margin-bottom: 17px;
    cursor: pointer;
  }
}

.courses-price {
  margin-bottom: 29px;
}

.courses-price__title {
  margin-bottom: 19px;
}

@media (max-width: 420px) {
  .courses {
    width: auto;

    &__title,
    &__description,
    &__general-text {
      width: 100%;
    }

    &__entry,
    &__entry-title,
    &__entry-description {
      width: 100%;
    }

    &__education,
    &__education-title,
    &__education-description {
      width: 100%;
    }

    &__attestation,
    &__attestation-title,
    &__attestation-description {
      width: 100%;
    }

    &__wrap-table {
      width: auto;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
      overflow: scroll;
    }
  }
}
