.journal {
  position: relative;
  padding-top: 25px;

  &__page-description {
    margin-bottom: 32px;
    @include md-lg {
      width: 66.666%;
    }
  }

  &__additional {
    display: flex;
    margin-bottom: 32px;
    span {
      min-width: 121px;
    }
    span, a {
      margin-right: 16px;
    }
    &-links {
      display: flex;
      flex-wrap: wrap;
      margin-right: -16px;
      margin-bottom: -8px;
      a {
        margin-bottom: 8px;
      }
    }

  }


  &__section {
    margin-bottom: 40px;
    @include md-lg {
      margin-bottom: 60px;
    }
  }

  & &__title {
    margin-bottom: 19px;
  }
  &__title-block {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__menu-list {
    padding: 0;
    margin: 0;
    margin-left: -7px;
    margin-bottom: 22px;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  &__menu-items {
    margin-right: 9px;
    padding: 0 8px;
  }

  &__menu-items--active {
    background: white;
  }

  &__menu-items--active .journal__menu-link {
    color: black;
  }

  &__description {
    width: 800px;
    margin-bottom: 27px;
  }

  &__item {
    margin-bottom: 44px;
  }

  &__news-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &__news-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 23px;
  }

  &__news-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    &:not(&--one) {
      margin: 0 -10px;
    }
  }
  &__news-list--last {
    @include md-lg {
      width: 66%;
    }
  }

  &__news-items {
    align-items: flex-start;
    width: 33.333%;
    margin-bottom: 20px;
    &:not(&--first) {
      padding: 0 10px;
    }
  }
  &__news-container {
    height: 100%;
    background-color: #fff;
    border: 1px solid #E1E1E1;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  &__news-items-content {
    flex-grow: 1;
    padding: 24px;
    display: flex;
    flex-direction: column;
    .author__content-wrap {
      margin-top: auto;
      flex-grow: 0;
    }
  }

  &__news-img-wrap {
    position: relative;
    width: 100%;
    height: 170px;
    overflow: hidden;
    @include md {
      height: 170px;
    }
    @include lg {
      height: 226px;
    }
  }

  &__news-img-wrap::before {
    content: '';
    display: block;
    padding-bottom: 57%;
  }

  &__news-img-wrap.active a::before {
    position: absolute;
    content: "Открыта запись";
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    top: 50%;
    left: 50%;
    opacity: .93;
    background-color: #fc0;
    margin-left: -37px;
    margin-top: -37px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000;
    animation-name: scale;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: 1;
  }

  &__news-list:not(&__news-list--simple) &__news-items:nth-of-type(4),
  &__news-list:not(&__news-list--simple) &__news-items:nth-of-type(5) {
    @include md {
      width: 50%;
      .journal__news-img-wrap {
        height: 265px;
      }
    }
    @include lg {
      width: 50%;
      .journal__news-img-wrap {
        height: 344px;
      }
    }


  }

  /*&__news-items:nth-of-type(4) {
    margin-right: 3%;
  }

  &__news-items:nth-of-type(1),
  &__news-items:nth-of-type(2),
  &__news-items:nth-of-type(6),
  &__news-items:nth-of-type(7) {
    margin-right: 1.9%;
  }*/

  &__news-items .journal__news-img-wrap img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    transition: .3s;
  }

  &__news-items .journal__news-img-wrap img:hover {
    transform: scale(1.05);
  }

  & &__news-items--first {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 56px;
    border: 1px solid #E1E1E1;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 0;
    .journal__news-img-wrap {
      width: 50%;
      height: 100%;
      margin-bottom: 0;
    }

    .journal__news-items img {
      width: 100%;
      height: 100%;
    }

    .journal__news-link-wrap {
      margin-bottom: 8px;
    }

    .journal__news-items-content {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 26px 70px 26px 40px;
      height: 100%;
      box-sizing: border-box;
    }
  }

  &__news-list--simple {
    .journal__news-items:nth-of-type(*) {
      width: 33.333%;
    }
  }

  &__news-link-wrap {
    font-size: 16px;
    line-height: 140%;
    color: #1E6599;
    margin-bottom: 10px;
    @include lg {
      font-size: 18px;
    }
  }

  &__news-link-wrap a {
    font: inherit;
    //color: inherit;
  }

  &__collection-link {
    display: block;
    text-align: center;
    width: 100%;
    color: #0d4a75;
    padding: 14px 20px;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #E1E1E1;
    border-radius: 6px;
    margin-bottom: 45px;
    @include md {
      margin-bottom: 70px;
    }
    @include lg {
      font-size: 16px;
    }
  }

  &__news-type {
    font-weight: bold;
  }

  &__news-description {
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 19px;
    @include lg {
      font-size: 16px;
    }
  }

  &__news-date {
    font-size: 14px;
    line-height: 150%;
    color: #BABABA;
    margin-bottom: 16px;
  }

  &__news-author {
    margin-top: auto;
    font-size: 14px;
  }

  &__webinars {
    margin-bottom: 72px;
    background-color: #F3F5F6;
    padding: 46px;
    margin-left: -46px;
    margin-right: -46px;
    position: relative;
  }

  &__webinars-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 33px;
  }

  &__webinars-list {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__webinars-items {
    margin-bottom: 33px;
    width: 32%;
  }

  &__webinars-video-wrap {
    margin-bottom: 10px;
  }

  &__webinars-video-wrap {
    margin-bottom: 16px;
  }

  &__webinars-link-wrap {
    margin-bottom: 7px;
  }

  &__webinars-date {
    font-size: 14px;
    color: rgb(120, 120, 120);
  }

  &__webinars-root {
    position: absolute;
    right: 27px;
    top: 52px;
  }

  &__root {
    list-style: none;
    margin: 0;
    margin-top: 46px;
    padding: 0;
    width: 800px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &__root-wrap {
    padding-top: 18px;
    width: 30%;
  }

  &__anons p {
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
    margin-bottom: 23px;
    @include lg {
      font-size: 16px;
    }
  }

  &__anons .link__wrap {
    margin-bottom: 10px;
  }

  &__anons .link__wrap {
    padding-top: 13px;
  }

  &__podcasts {
    position: absolute;
    right: 0;
    top: 30px;
    display: flex;
    align-items: center;
  }

  &__podcasts a {
    margin-left: 5px;
  }
}

.bpm-large {
  margin-bottom: 78px;

  &__title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 17px;
  }

  &__wrap-preview {
    width: 300px;
    height: 168px;
    margin-bottom: 15px;
  }

  &__wrap-preview img {
    width: 100%;
    height: 100%;
  }
}

.social {
  padding-top: 25px;
  width: 100%;

  &__title {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
  }

  &__list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
  }

  &__item:not(:last-of-type) {
    margin-right: 10px;
  }

  &__item {
    cursor: pointer;
  }

  &__item svg rect {
    transition: .1s;
  }

  &__item svg {
    fill: #C7C7C7;
  }

  &__item[aria-label=VK]:hover svg,
  &__item[aria-label=VK]:focus svg {
    fill: #4C75A3;
  }

  &__item[aria-label=Facebook]:hover svg,
  &__item[aria-label=Facebook]:focus svg {
    fill: #395398;
  }

  &__item[aria-label=Twitter]:hover svg,
  &__item[aria-label=Twitter]:focus svg {
    fill: #7BC5F8;
  }

  &__item[aria-label=Telegram]:hover svg,
  &__item[aria-label=Telegram]:focus svg {
    fill: #0095D4;
  }

  &__item[aria-label=Skype]:hover svg,
  &__item[aria-label=Skype]:focus svg {
    fill: #0095D4;
  }

  &__item[aria-label=VK].active svg {
    fill: #4C75A3;
  }

  &__item[aria-label=Facebook].active svg {
    fill: #395398;
  }

  &__item[aria-label=Twitter].active svg {
    fill: #7BC5F8;
  }

  &__item[aria-label=Telegram]:active svg {
    fill: #0095D4;
  }

  &__item[aria-label=Skype].active svg {
    fill: #0095D4;
  }
}

.category {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 60px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &__item {
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 5px;
    transition: .2s;
  }

  &__item:not(:last-of-type) {
    margin-right: 8px;
    margin-bottom: 8px;
    color: $black;
  }

  &__item:hover,
  &__item:focus {
    background-color: #124B74;
    color: $white;
  }

  &__item.active {
    background-color: #0D4A75;
    color: $white;
    border-color: #0D4A75;
  }

  &__link {
    color: inherit;
    font-size: 16px;
    line-height: 28px;
    padding: 2px 8px;
    transition: none;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: white;
    }
  }
}

.author {
  display: flex;
  flex-wrap: wrap;

  &__img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
  }

  &__img {
    width: 100%;
    height: 100%;
    margin-right: 0;
  }

  &__content-wrap {
    flex-grow: 1;
    padding-left: 15px;
  }

  &__content-wrap--simple {
    padding-left: 0;
  }

  &__content-wrap p {
    margin-bottom: 0;
    color: #272727;
  }

  &__content-wrap p:first-of-type {
    color: #272727;
    font-weight: 600;
  }

  &__content-wrap--simple p:first-of-type {
    color: #BABABA;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
  }
}


@media (max-width: $mobile-view) {
  .journal {
    &__title {
      width: 100%;
    }

    &__description {
      width: 100%;
    }

    &__menu-items {
      width: 100%;
      margin-right: 0;
    }

    &__menu-link {
      padding-left: 16px;
      padding-right: 16px;
    }

    &__news {
      width: 100%;
    }

    &__news-items:first-of-type {
      width: 100%;
    }

    &__news-items {
      width: 100%;
    }

    &__news-items:first-of-type &__news-img-wrap {
      width: 100%;
      height: auto;
    }

    &__news-img-wrap {
      height: auto;
    }

    &__root {
      width: 100%;
      margin-bottom: 46px;
    }

    &__root-wrap {
      padding-bottom: 18px;
    }

    &__root-wrap:last-of-type {
      border-bottom: 1px solid rgb(222, 226, 230);
    }

    &__right-side {
      width: 100%;
      margin-right: 0;
    }

    &__webinars-video-wrap {
      height: auto;
    }

    &__webinars-video-wrap iframe {
      height: 50vw;
      width: 100%;
    }

    & &__news-items--first {
      .journal__news-img-wrap {
        width: 100%;
      }

      .journal__news-items-content {
        width: 100%;
        padding: 24px;
        height: auto;
      }
    }

    &__news-list {
      .journal__news-items:nth-of-type(1),
      .journal__news-items:nth-of-type(2) {
        width: 100%;
      }
    }

    &__webinars-root {
      position: absolute;
      right: auto;
      left: 45px;
      top: auto;
      bottom: 45px;
    }
  }
}

.journal__podcasts {
  background: #7ba1ec;
  border-radius: 6px;
  flex-shrink: 0;
  width: 169px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: 0;
  padding-right: 14px;
  padding-left: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;

  a {
    color: #FFFFFF;
    z-index: 9;
  }

  p {
    margin-bottom: 0;
    color: #FFFFFF;
  }

  &:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 10px;
    width: 19px;
    height: 19px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../image/podcast-triangle.svg");
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../image/podcast-link-bg.png");
  }
}

.category-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;


}

@media (max-width: 400px) {
  .category__link {
    height: auto;
  }

  .category {
    margin-bottom: 16px;
  }

  .category-wrap {
    margin-bottom: 60px;
    flex-direction: column;
  }
}
