.grecaptcha-badge {
  opacity: 0;
  pointer-events: none;
}

.g-recaptcha-wrap__error {
  color: red !important;
  width: 100% !important;
  margin: 0;
  margin-top: 5px;
}

.g-recaptcha-wrap {
  &_v2-active &__item {
    display: block;
  }
  &_v2-active &__button {
    display: none;
  }

  &__button {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  &__item {
    display: none;
  }
}
