.input {
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  padding: 4px 0;
  border: none;
  border-bottom: 1px solid #DFDFDF;
  &:focus {
    border-color: #0D4A75;
  }
  &::placeholder {
    color: #D3D3D3;
  }
}