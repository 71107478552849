$header-breakpoint-max: 1084px;
$header-breakpoint-min: 1085px;

.dropdown {
  position: relative;
  &_static {
    position: static;
  }
  @media (min-width: $header-breakpoint-min) {
    &_static-desc {
      position: static;
    }
  }
  &__wrap {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 100%;
    transform: translateY(10px);
    transition: all .375s .1s ease-in-out;
  }
  &:hover &__wrap {
    opacity: 1;
    pointer-events: inherit;
    visibility: visible;
    transform: translateY(0px);
  }
  &__mobile-btn {
    display: none;
  }
  @media (max-width: $header-breakpoint-max) {
    &__mobile-btn {
      appearance: none;
      -webkit-appearance: none;
      transition: 0.2s;
      outline: none;
      width: 20px;
      height: 54px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      &::before, &::after {
        content: "";
        position: relative;
        height: 2px;
        width: 50%;
        border-radius: 2px;
        background-color: #0d4a75;
      }
      &::before {
        transform-origin: left;
        left: 3px;
        transform: rotate(45deg);
      }
      &::after {
        transform-origin: right;
        right: 3px;
        transform: rotate(-45deg);
      }
      &:checked {
        transform: rotate(180deg);
      }
    }
    &__mobile-btn + &__wrap {
      position: unset;
      opacity: 1;
      pointer-events: inherit;
      visibility: visible;
      transform: translateY(0px);
    }
    &__mobile-btn:not(:checked) + &__wrap {
      display: none;
    }
    &__mobile-btn:checked + &__wrap {
      display: block;
    }
  }
}