.case-process {
  background-color: #FFFFFF;
  min-height: 852px;
  margin-left: -35px;
  margin-right: -35px;
  padding: 35px 80px;
  box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.1);

  &__title {
    font-size: 30px;
    line-height: 46px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  &__subtitle {
    font-size: 24px;
    line-height: 42px;
    margin-bottom: 12px;
    font-weight: bold;
  }

  &__wrap-text ul {
    font-size: 14px;
    line-height: 1.5;
    @media (min-width: 1400px) {
      line-height: 30px;
      font-size: 18px;
    }
  }

  &__wrap-text b {
    font-size: 14px;
    line-height: 1.5;
    @media (min-width: 1400px) {
      line-height: 26px;
      font-size: 18px;
    }
  }

  &__wrap-text p {
    font-size: 14px;
    line-height: 1.5;
    @media (min-width: 1400px) {
      line-height: 30px;
      font-size: 18px;
    }
  }

  &__description {
    margin-bottom: 25px;
  }

  &__wrap-text .case-help__list li {
    line-height: 26px;
    margin-bottom: 12px;
  }

  &__wrap-text .case-help__list li::before {
    top: 9px;
  }

  &__link-list {
    margin-left: -10px;
    margin-bottom: 39px;
  }

  &__link-item {
    margin-right: 12px;
  }

  &__link {
    padding: 5px 10px;
    font-size: 15px;
    @media (min-width: 1400px) {
      font-size: 18px;
    }
  }

  &__link.active {
    background-color: #e6e6e6;
    color: #000000;
  }

  &__content-item-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

  }

  &__wrap-text {
    width: 46%;
    box-sizing: border-box;
    padding-right: 20px;
    @media (min-width: 1400px) {
      width: 50%;
      padding-right: 5.7%;
    }
  }

  &__slide-wrap {
    width: 54%;
    padding-top: 10px;
    padding-left: 1.3%;
    box-sizing: border-box;
    @media (min-width: 1400px) {
      width: 50%;
    }
  }

  &__slide-wrap img {
    margin-bottom: 12px;
    border: 1px solid #dee2e6;
    height: auto;

    .tab-pane & {
      max-width: 100%;
      @media (min-width: 1400px) {
        max-width: 550px;
      }
    }
  }

  &__slide-wrap figcaption {
    font-size: 16px;
    line-height: 26px;
    color: #636363;
  }

  &__slide-wrap .carousel .carousel-indicators {
    top: 370px;
    height: 50px;
  }

  &__slide-wrap .carousel-indicators li {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px #bcbcbc;
    margin: 0 10px;
  }

  &__slide-wrap .carousel-indicators li:hover,
  &__slide-wrap .carousel-indicators li:focus {
    background-color: #bcbcbc;
    box-shadow: inset 0 0 0 1px #bcbcbc;
  }

  &__slide-wrap .carousel-indicators li.active {
    background-color: #bcbcbc;
    box-shadow: inset 0 0 0 1px #bcbcbc;
  }

  &__carousel-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/upload/cases/mockup_d.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 445px;
    height: 339px;
    padding: 65px 65px;
    padding-bottom: 77px;
    margin-bottom: 65px;
    @media (min-width: 1400px) {
      background-size: cover;
      width: 595px;
      height: 339px;
      padding: 21px 76px;
      padding-bottom: 35px;
    }
  }

  &__mockup {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &--gray {
    background-color: #e6e6e6;
    min-height: 500px;
  }

  &--digital {
    background-color: #e6e6e6;
    background-image: url('/upload/round-bg/png5cfa0d46340ea5/76808474/round-bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: right;
    padding-bottom: 80px;
    min-height: 599px;
    margin-bottom: 50px;
  }

  &--digital  &__link-list {
    width: 100%;
  }

  &--digital  &__link-item {
    margin-right: 16px;
  }

  &--digital  &__link.active {
    background-color: #FFFFFF;
  }

  &--digital &__wrap-text {
    padding-right: 7%;
  }

  &.elma-projects-img .what-elma-project__aside-wrap {
    margin-top: 120px;
  }
}

.case-process--contracts {
  min-height: 656px;

  .case-process {
    &__wrap-text {
      padding-right: 6.7%;
    }
  }
}

.case-process--b2b {
  min-height: 764px;

  .case-process {
    &__wrap-text {
      padding-right: 6.7%;
    }
  }
}

.case-process--retail {
  min-height: 744px;
}

.case-process--meeting {
  min-height: 500px;
  padding-bottom: 54px;
}

.case-process--ivoice {
  min-height: 809px;
  margin-bottom: 75px;

  .case-process {
    &__slide-wrap {
      padding-top: 25px;
    }

    &__wrap-text .case-help__list li {
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.case-process--travel {
  min-height: 614px;
}

.case-process--personnel {
  .case-process {

    &__wrap-text {
      @media (min-width: 1400px) {
        width: 515px;
        padding-right: 0;
      }
    }

    &__slide-wrap {
      width: 51%;
      padding-left: 0;
    }

    &__slide-wrap img {
      width: 100%;
    }

    &__slide-wrap figcaption {
      padding: 0 60px;
    }

    &__link-list {
      margin-bottom: 52px;
    }
  }
}

.case-process--farm {
  padding: 34px 81px;

  .case-process {
    &__link {
      color: $color-dark-green;
    }

    &__link.active {
      color: #000000;
    }
  }
}

.case-process-action {
  &__wrap {
    padding: 38px 58px;
    background-image: url("/upload/cases/farm/bg_action.jpg");
    background-size: cover;
  }

  &__title {
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 9px;
  }

  &__description {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 21px;
  }

  &__button {
    display: inline-block;
    padding: 12px 40px;
    background-color: $color-dark-green;
    font-weight: bold;
  }

  &__button:active,
  &__button:hover,
  &__button:focus {
    background-color: $color-dark-green!important;
  }
}

.case-process.case-process--projects {
  background-color: transparent;
  padding: 0;
  box-shadow: none;

  .case-process__wrap-text {
    width: 65%;

    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
}

@media (max-width: $mobile-view) {
  .case-process {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding-left: 16px;
    padding-right: 16px;

    &__wrap-text,
    &__slide-wrap {
      width: 100%;
    }

    &__slide-wrap .carousel {
      height: auto;
      margin-top: 0;
      margin-bottom: 0;
    }

    &__slide-wrap .carousel .carousel-indicators {
      top: 58vw;
    }

    &__carousel-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url('/upload/cases/mockup_s.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      padding: 5% 11%;
      width: 100%;
      height: 54vw;
      margin-bottom: 50px;
    }

    &__mockup {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__slide-wrap figure img,
    &__slide-wrap img {
      width: 100%;
      height: auto;
    }

    &__slide-wrap figcaption {
      font-size: 14px;
      line-height: 22px;
      color: #646363;
    }

    &__title {
      font-size: 20px;
      line-height: 30px;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 22px;
    }

    &__link-list {
      width: 100%;
    }

    &__link-item {
      width: 100%;
      margin-bottom: 5px;
    }

    &__link {
      font-size: 16px;
      line-height: 22px;
    }

    &__wrap-text p,
    &__wrap-text ul {
      font-size: 16px;
      line-height: 24px;
    }


    &--gray.margin {
      margin-bottom: 36px;
    }
  }

  .case-process-action {
    &__wrap {
      padding: 26px 16px;
    }

    &__title {
      font-size: 26px;
    }
  }

  .case-process--digital {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 30px;
    padding-bottom: 10px;

    .case-process {
      &__link-list {
        width: 100%;
      }
    }
  }

  .case-process--meeting {
    .case-process__link-list {
      margin-bottom: 25px;
      padding-bottom: 13px;
      border-bottom: 1px solid #e9ebef;
    }
  }

  .case-process--personnel {
    .case-process {
      &__slide-wrap {
        width: 100%;
        padding-left: 0;
      }

      &__slide-wrap figcaption {
        padding: 0 16px;
      }
    }
  }

  .case-process.case-process--projects {
    min-height: auto;
    .case-process__wrap-text {
      width: 100%;
    }
  }
}
