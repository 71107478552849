.elma-projects-about {
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__item {
    margin-right: 40px;
    margin-bottom: 40px;
    @media (min-width: 1400px) {
      margin-right: 80px;
    }

    &:nth-of-type(5n) {
      margin-right: 0;
    }
  }

  &__link {
    display: block;
    position: relative;
    width: 156px;
    padding-top: 75px;

    font-weight: normal;
    font-size: 18px;
    line-height: 30px;

    color: #048AD3;

    &--1 {
      background: url("/upload/projects/about-icon-1.svg") no-repeat top left;
      background-size: 61px auto;
    }

    &--2 {
      background: url("/upload/projects/about-icon-2.svg") no-repeat top left;
      background-size: 74px auto;
    }

    &--3 {
      background: url("/upload/projects/about-icon-3.svg") no-repeat top left;
      background-size: 74px auto;
    }

    &--4 {
      background: url("/upload/projects/about-icon-4.svg") no-repeat top left;
      background-size: 56px auto;
    }

    &--5 {
      background: url("/upload/projects/about-icon-5.svg") no-repeat top left;
      background-size: 98px auto;
    }

    &--6 {
      background: url("/upload/projects/about-icon-6.svg") no-repeat top left;
      background-size: 67px auto;
    }

    &--7 {
      background: url("/upload/projects/about-icon-7.svg") no-repeat top left;
      background-size: 111px auto;
    }

    &--8 {
      background: url("/upload/projects/about-icon-8.svg") no-repeat top left;
      background-size: 77px auto;
    }

    &--9 {
      background: url("/upload/projects/about-icon-9.svg") no-repeat top left;
      background-size: 84px auto;
    }

    &--10 {
      background: url("/upload/projects/about-icon-10.svg") no-repeat top left;
      background-size: 71px auto;
    }
  }
}

@media (max-width: $mobile-view) {
  .elma-projects-about {
    &__item {
      margin-right: 30px;

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &:nth-of-type(5) {
        margin-right: 30px;
      }
    }

    &__link {
      width: 135px;
      font-size: 14px;
      line-height: 20px;

      &--1 {
        background-size: 54px auto;
      }

      &--2 {
        background-size: 65px auto;
      }

      &--3 {
        background-size: 62px auto;
      }

      &--4 {
        background-size: 49px auto;
      }

      &--5 {
        background-size: 86px auto;
      }

      &--6 {
        background-size: 59px auto;
      }

      &--7 {
        background-size: 98px auto;
      }

      &--8 {
        background-size: 68px auto;
      }

      &--9 {
        background-size: 74px auto;
      }

      &--10 {
        background-size: 63px auto;
      }
    }
  }
}