img {
    max-width: 100%;
}

.drop-shadow-img {
    filter: drop-shadow(0px 14px 44px rgba(0, 0, 0, 0.14));
    border: none !important;
}

.drop-shadow-section {
    filter: drop-shadow(0px 3px 24px rgba(0, 0, 0, 0.102));
}

.video-img {
    position: relative;
    display: inline-block;
    line-height: 1;
    cursor: pointer;
    padding-left: 20px;
    @media (max-width: 767px) {
        padding-left: 0;
    }

    &:before {
        z-index: 1;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        margin: -30px 0 0 -30px;
        background: #188CD1 56% 50% url("data:image/svg+xml,%3Csvg width='17' height='24' viewBox='0 0 17 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.9092 12.0227L0.0341786 23.537L0.0341796 0.508547L16.9092 12.0227Z' fill='white'/%3E%3C/svg%3E") no-repeat;
        content: "";
    }

    & > img {
        display: block;
        max-width: 550px;
        width: 100%;
        margin: 0;
    }
}

//.main-text {
//}

@media (min-width: 768px) {
    .main-text {
        font-size: 16px;
        line-height: 1.4;
        color: #272727;
    }
}

@media (min-width: 1400px) {
    .main-text {
        font-size: 18px;
        line-height: 30px;
    }
}

//.main-subtitle {
//}

@media (min-width: 768px) {
    .main-subtitle {
        font-size: 30px;
        line-height: 36px;
        padding-right: 50px;
    }
}

.section-light-blue {
    padding: 20px;
    background-color: #DFECF3;
}

@media (min-width: 768px) {
    .section-light-blue {
        max-width: 1158px;
        margin: 0 auto;
        padding: 50px 64px;
    }
}

.main-banner--elma4 {
    margin-bottom: 40px;
    min-height: auto;

    .main-banner__title-box {
        margin: 0;
    }
}

.main-banner--elma4 {
    @media (min-width: 768px) {
        margin-bottom: 80px !important;
        min-height: 523px;
    }

    .main-banner__title-box {
        @media (min-width: 768px) {
            margin-bottom: 40px;
        }
        @media (min-width: 992px) {
            margin-left: -35px;
            margin-right: -16px;
        }
    }
}

@media (min-width: 992px) {}

.list-line {
    &__item {
        padding-left: 32px;
        position: relative;
        
        &::before {
            position: absolute;
            content: "";
            width: 8px;
            height: 50px;
            left: 0;
        }
    }
}

@media (min-width: 768px) {
    .list-line {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: -20px;
        margin-right: -20px;

        &__item {
            width: 30%;
            padding-left: 52px;
            padding-right: 20px;
            position: relative;
            
            &::before {
                position: absolute;
                content: "";
                width: 8px;
                height: 50px;
                left: 20px;
                background-color: #188CD1;
            }
        }
    }
}

.list-card {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
        border: 1px solid rgba(211, 217, 219, 1);

        img {
            max-width: 100%;
            height: auto;
        }
    }

    &__link-wrap {
        display: block;
        text-decoration: none;
    }

    &__info {
        padding: 25px 20px 40px 24px;
    }

    &__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
    }

    &__description {
        font-size: 16px;
        line-height: 24px;
        color: #272727;
    }
}

@media (min-width: 768px) {
    .list-card {
        display: flex;
        justify-content: space-between;

        &__item {
            width: 33%;

            & + & {
                margin-left: 15px;
            }

            
        }
    }
}

.checkbox-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__item {
        padding-left: 40px;
        position: relative;
        width: 50%;

        &::before {
            content: url('/upload/elma4-page/checkbox.svg');
            position: absolute;
            left: 0;
        }
    }
}

.case-process--elma4 {
    width: auto;
}

.elma4-tab {
    flex-direction: column;
}

@media (min-width: 1024px) {
    .elma4-tab {
        flex-direction: row;
    }
    .case-process--elma4 {
        padding-top: 60px;
        padding-bottom: 120px;
    }
}

.products-banner {
    &__wrap {
        display: block;
        text-decoration: none;
    }

    &__text {
        color: #fff;
    }

    &__list {
        margin-left: 0;
        margin-right: 0;
    }

    &__item {
        a {
            padding-bottom: 10px;
        }
    }

    &__text {
        padding-left: 15px;
    }

    &__wrap {
        span {
            padding-left: 15px;
        }
    }

    &__title {
        display: flex;
        align-items: center;

    }

    &__new {
        display: inline-block;
        padding: 3px 5px !important;
        color: #FABD05;
        font-size: 18px;
        line-height: 26px;
        border: 1px solid #FABD05;
    }
}

.carousel--main .carousel-item--products {
    padding: 0;
}

@media (min-width: 768px) {
    .products-banner {
        &__title {
            padding-top: 80px;
            margin-bottom: 200px;

            .font-banner-title {
                // padding-top: 85px;
            }
        }

        &__text {
            font-size: 18px;
            line-height: 26px;
            padding-left: 35px;
            padding-right: 20px;
        }

        &__wrap {
            span {
                padding-left: 35px;
            }
        }

        &__list {
            margin-left: -15px;
            margin-right: -15px;
        }

        &__item {
            
            a {
                padding-bottom: 63px;
            }

                
            &:hover a {
                position: relative;
                // background-image: url('/upload/elma4-page/elma4-dark.jpg') !important;
                &::after {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.14);
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
            }

            // &--elma365 {
            //     &:hover {
            //         background-image: url('/upload/elma4-page/dark365.jpg') !important;
            //     }
            // }

            // &--rpa {
            //     &:hover {
            //         background-image: url('/upload/elma4-page/rpa-dark.jpg') !important;
            //     }
            // }
        }
    }

    

    .pl-60 {
        padding-left: 60px !important;
    }
}

@media (min-width: 576px) {
    .navbar-expand-md .navbar-nav .nav-link {
        padding: 0.8rem !important;
    }
}