.downloads {
  padding-top: 25px;

  & &__title {
    margin-bottom: 22px;
  }

  & &__menu-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    border: none;
  }

  &__menu-item {
    margin-right: 4px;
  }

  & &__menu-link {
    display: inline-block;
    padding: 0 13px!important;
    padding-bottom: 2px!important;
    color: #048ad3;
    cursor: pointer;
    border: none!important;
  }

  & &__menu-item &__menu-link.active {
    background-color: white;
    border: none;
    color: #048ad3;
  }

  & &__mebu-link:hover {
    border: none!important;
  }

  &__menu-item--active {
    background-color: white;
  }

  &__list {
    max-width: 1290px;
    min-height: 633px;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: -35px;
    margin-right: -35px;
  }

  &__item {
    max-width: 1290px;
    min-height: 633px;
    background-color: white;
    -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    padding: 24px 30px;
  }

  &__item--interactive-demo {
    background-image: url('/img/assets/interactive-demo.jpg');
    background-repeat: no-repeat;
    background-position: 444px 0;
  }

  &__item--trial {
    background-image: url('/img/assets/download-trial.jpg');
    background-repeat: no-repeat;
    background-position: 327px 0;
  }

  &__item--community {
    background-image: url('/img/assets/download_ce.jpg');
    background-repeat: no-repeat;
    background-position: 660px 0;
    background-size: 49%;
    min-height: 489px;
  }

  &__subtitle {
    font-size: 24px;
    line-height: 34px;
    font-weight: bold;
    margin-bottom: 13px;
  }
}

.downloads-demo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;

  &__form-wrap {
    position: relative;
    width: 526px;
  }

  &__form-description {
    margin-bottom: 27px;
  }

  &__label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 26px;
    margin-bottom: 12px;
  }

  &__label--checkbox {
    position: relative;
    display: block;
    margin-left: 178px;
    padding-right: 0;
    font-size: 14px;
    line-height: 24px;
  }

  &__label select:invalid {
    color: transparent;
  }

  &__label select option[value=""][disabled] {
    display: none;
  }

  &__label select option {
    color: black;
  }

  &__label-value {
    width: 178px;
    display: inline-block;
  }

  &__input {
    top: 15px;
    left: 9px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 4px 15px;
    border: 1px solid #cfcfcf;
  }

  &__input.error {
    border: 1px solid #dc143c;
  }

  &__input.error + .invalid-feedback {
    display: block;
  }

  & &__label &__input--mobile-only:invalid {
    color: #cfcfcf;
  }

  &__label textarea::-webkit-input-placeholder {opacity: 0}
  &__label textarea::-moz-placeholder          {opacity: 0}/* Firefox 19+ */
  &__label textarea:-moz-placeholder           {opacity: 0}/* Firefox 18- */
  &__label textarea:-ms-input-placeholder      {opacity: 0}

  &__input::-webkit-input-placeholder {opacity: 0}
  &__input::-moz-placeholder          {opacity: 0}/* Firefox 19+ */
  &__input:-moz-placeholder           {opacity: 0}/* Firefox 18- */
  &__input:-ms-input-placeholder      {opacity: 0}

  &__input--mobile-only::-webkit-input-placeholder {opacity: 1}
  &__input--mobile-only::-moz-placeholder          {opacity: 1}/* Firefox 19+ */
  &__input--mobile-only:-moz-placeholder           {opacity: 1}/* Firefox 18- */
  &__input--mobile-only:-ms-input-placeholder      {opacity: 1}

  &__input--select {
    padding: 6px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    background-image: url('/img/assets/drop_down.svg');
    background-repeat: no-repeat;
    background-position: 96% 15px;
    background-size: 13px;
  }

  &__input--select::-ms-expand {
    display: none;
  }

  &__input--checkbox + .downloads-demo__label--icon {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 7px;
    margin-bottom: -2px;
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 0 1px #d1d1d1;
    box-shadow: inset 0 0 0 1px #d1d1d1;
  }

  &__input--checkbox:checked + &__label--icon::before {
    position: absolute;
    content: "";
    top: 8px;
    right: 7px;
    width: 7px;
    height: 2px;
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
    background-color: #048ad3;
  }

  &__input--checkbox:checked + &__label--icon::after {
    position: absolute;
    content: "";
    top: 6px;
    right: 1px;
    width: 11px;
    height: 2px;
    -webkit-transform: rotate(-59deg);
    -ms-transform: rotate(-59deg);
    transform: rotate(-59deg);
    background-color: #048ad3;
  }

  &__submit {
    position: relative;
    display: inline-block;
    margin-left: 178px;
    padding: 10px 28px;
    padding-left: 45px;
    color: white;
    font-weight: bold;
    border: 0;
    cursor: pointer;
    -webkit-box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
  }

  &__submit-unclosed {
    position: relative;
    display: inline-block;
    margin-left: 178px;
    padding: 10px 28px;
    color: white;
    font-weight: bold;
    border: 0;
    cursor: pointer;
  }

  &__submit::before {
    position: absolute;
    content: '';
    top: 15px;
    left: 18px;
    width: 16px;
    height: 13px;
    background-image: url('/img/assets/unlock-button.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  &__products-wrap {
    width: 340px;
    position: relative;
    padding-left: 30px;
  }

  &__overlay {
    position: absolute;
    width: 300px;
    height: 280px;
    left: 0;
    top: 0;
    z-index: 5;
  }

  &__products-title {
    position: relative;
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
    padding-top: 3px;
    padding-left: 22px;
    margin-bottom: 12px;
  }

  &__icon-title {
    position: absolute;
    display: inline-block;
    top: 9px;
    left: -1px;
    width: 13px;
    height: 14px;
    background-image: url("/img/assets/lock.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  &__product-list {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 227px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    opacity: 0.5;
  }

  &__product-list:before {
    content: "";
    position: absolute;
    width: 300px;
    height: 280px;
    left: 0;
    top: 0;
  }

  &__product-item {
    width: 95px;
    margin-bottom: 6px;
  }

  &__product-link {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin-bottom: 0;
    color: #048ad3;
  }

  &__product-subscription {
    font-size: 16px;
    line-height: 22px;
  }

  &__thanks {
    display: none;
    width: 526px;
  }
}

//Активный класс для поведения формы

.open .downloads-demo__thanks {
  display: block;
  width: 355px;
}

.open .downloads-demo__form-wrap {
  display: none;
}

.open .downloads-demo__overlay {
  display: none;
}

.open .downloads-demo__product-list {
  opacity: 1;
}

.open .downloads-demo__product-list::before {
  display: none;
}

.open .downloads-demo__icon-title {
  background-image: url('/img/assets/unlock-button-primary.svg');
  width: 25px;
  left: -9px;
}

.downloads-demo__thanks-text {
  font-size: 18px;
  margin-bottom: 6px;
}

.downloads-demo__form--projects {
  background-color: #e1e8ec;
  width: 100%;

  .downloads-demo__product-list {
    width: 280px;
  }

  .downloads-demo__products-wrap {
    width: 360px;
    @media (min-width: 1400px) {
      width: 600px;
    }
  }
}

@media (max-width: $mobile-view) {
  .downloads {
    &__list {
      width: auto;
      min-height: auto;
      margin-left: -16px;
      margin-right: -16px;
    }

    &__item {
      width: 100%;
      min-height: auto;
      padding-right: 16px;
      padding-left: 16px;
      padding-bottom: 45px;
    }

    & &__menu-list {
      margin-left: -16px;
      margin-right: -16px;
    }

    &__menu-list &__menu-item {
      margin-bottom: 0;
      margin-right: 0;
    }

    & &__menu-link {
      padding-left: 0!important;
    }
  }


  .downloads-demo {
    &__label-value {
      display: none;
    }

    &__label--checkbox,
    &__submit,
    &__submit-unclosed,
    &__submit-unclosed {
      margin-left: 0;
    }

    &__submit,
    &__submit-unclosed,
    &__submit-unclosed {
      width: 100%;
    }

    &__label {
      padding-right: 0;
    }

    &__label select:invalid {
      color: #cfcfcf;
    }

    &__form {
      width: 100%;
      flex-wrap: wrap;
    }

    &__products-wrap {
      width: 100%;
      padding-left: 0;
    }

    &__form-wrap {
      order: 2;
    }

    &__ce-description,
    &__trial-description {
      padding-right: 0;
      order: 1;
    }

    .open &__thanks {
      width: 100%;
    }

    &__label textarea::-webkit-input-placeholder {
      opacity: 1
    }
    &__label textarea::-moz-placeholder {
      opacity: 1
    }
    /* Firefox 19+ */
    &__label textarea:-moz-placeholder {
      opacity: 1
    }
    /* Firefox 18- */
    &__label textarea:-ms-input-placeholder {
      opacity: 1
    }

    &__input::-webkit-input-placeholder {
      opacity: 1;
    }
    &__input::-moz-placeholder {
      opacity: 1;
    }
    /* Firefox 19+ */
    &__input:-moz-placeholder {
      opacity: 1;
    }
    /* Firefox 18- */
    &__input:-ms-input-placeholder {
      opacity: 1;
    }
  }
}
