.deming {
  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.2;
    color: #272727;
    margin-bottom: 12px;
    @media (min-width: 576px) {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }
  &__text {
    line-height: 1.8;
    margin: 0;
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }
  &__img-wrap {
    position: relative;
    order: 5;
    padding: 0 15px;
    @media (min-width: 768px) {
      margin: 55px 0;
      order: inherit;
    }
  }
  &__img {
    display: block;
    max-width: 100%;
    width: 460px;
    margin: auto;

    @media (min-width: 768px) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-54%, -50%);
      z-index: -1;
    }
    @media (min-width: 1200px) {
      width: 580px;
    }
  }
  &__content-col {
    margin-bottom: 24px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
}