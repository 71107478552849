.list-problem-container {
  padding: 32px 50px;
  background-color: #E9EBEF;
  @media (min-width: $tablet-view) {
    padding: 64px 100px;
  }
}
.list-problem-title {
  font-weight: bold;
  margin-bottom: 24px;
  @media (min-width: $tablet-view) {
    margin-bottom: 48px;
  }
}
.list-problem {
  list-style: none;
  padding: 0;
  p {
    margin: 12px 0 0;
    
    @media (min-width: $tablet-view) {
      margin: 24px 0 0;
    }
  }
  &__group {
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet-view) {
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 40px;
      padding-right: 40px;
      margin-left: -40px;
      margin-right: -40px;
      &:not(:last-child)::after {
        content: "";
        width: 100%;
        border-bottom: 2px solid #FFFFFF;
      }
    }

    &:first-child {
      .list-problem {
        &__item {
          padding-top: 20px;
        }
      }
    }

    &:last-child {
      .list-problem {
        &__item {
          .list-problem__text {
            @media (min-width: $tablet-view) {
              border: 0;
            }
          }
        }
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px;

    @media (min-width: $tablet-view) {
      width: 50%;
      padding: 50px 48px;

    }

    &.--problem {
      &:before {
        @media (min-width: $tablet-view) {
          left: 48px;
        }
      }
      @media (min-width: $tablet-view) {
        &::after {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          bottom: 0;
          right: 0;
          width: 1px;
          height: 100%;
          background-color: #FFFFFF;
        }
      }
    }


    &.--desicion {

      &:before {
        @media (min-width: $tablet-view) {
          right: 48px;
        }
      }
      @media (min-width: $tablet-view) {
        padding-left: 98px;
        padding-right: 0;
        &::after {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          bottom: 0;
          left: 0;
          width: 1px;
          height: 100%;
          background-color: #FFFFFF;
        }
      }
      @at-root .list-problem__group:last-child & {
        .list-problem__text {
          border: 0;
        }
      }

      .h3-title {
        &::before {
          content: url('/static/images/decision.svg');
        }
      }
    }
  }

  &__text {
    flex: 1 1;
    margin: 0;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(177, 179, 192, 0.5);

    @media (min-width: $tablet-view) {
      border: 0;
      padding-bottom: 60px;
    }
  }

  .h3-title {
    position: relative;
    display: inline-block;
    margin: -16px 0;

    @media (min-width: $tablet-view) {
      margin-top: 0;
    }

    &::before {
      display: block;
      content: url('/static/images/problem.svg');

      @media (min-width: $tablet-view) {
        position: absolute;
        top: -0.1em;
        left: -48px;
      }
    }
  }
}

.ss .list-problem {
  margin: 0 -16px;
  @media (min-width: $tablet-view) {
    margin: 0 -48px;
  }
  .h3-title {
    margin-bottom: 0;
    font-size: 16px;
    @media (min-width: $tablet-view) {
      font-size: 20px;
    }
    @media (min-width: $desktop-view)  {
      font-size: 24px;
    }
  }
  &__group {
    padding: 0;
    &:last-of-type {
      .list-problem__item {
        padding-bottom: 36px;
      }
    }
  }
  &__item {
    padding: 16px;
    @media (min-width: $tablet-view) {
      padding: 50px 48px;
    }
  }
  &__item.--problem {
    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: #FFFFFF;
    }
  }
  &__item.--desicion {
      padding-left: 84px;
      &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: #FFFFFF;
      }
  }
  .list-problem__group:last-of-type .list-problem__item::before {
    display: none;
  }

}