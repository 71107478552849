// List
.s-story__list {
  list-style: none;
  padding-left: 17px;
  padding-right: 0;
  margin-bottom: 0;
}

.s-story__item {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
}

.s-story__item:last-of-type {
  margin-bottom: 0;
}

.s-story__item::before {
  position: absolute;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  left: -17px;
  top: 8px;
  background-color: #000;
}

.s-story__list--gold .s-story__item::before {
  background-color: #edc14c;
}

.s-story__list--gray .s-story__item::before {
  background-color: #bcbcbc;
}

.s-story__list--blue .s-story__item::before {
  background-color: #048ad3;
}

// Target list

.s-story__target-list {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}

.s-story__target-item {
  margin-bottom: 21px;
}

.s-story__target-item:last-of-type {
  margin-bottom: 0;
}

.s-story__target-mark {
  font-size: 20px;
  line-height: 42px;
  margin-bottom: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.s-story__target-mark--sub {
  display: block;
}

.s-story__target-number {
  font-size: 40px;
  line-height: 55px;
  margin-right: 8px;
  margin-left: 8px;
}

.s-story__target-number + span {
  margin-top: 12px;
  padding-right: 12px;
}

.s-story__target-mark .before {
  margin-top: 12px;
}

.s-story__target-text {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}

.s-story__target-list--blue .s-story__target-mark {
  color: #048ad3;
}

.s-story__target-list--gold .s-story__target-mark {
  color: #edc14c;
}

.s-story__hr {
  border-top: 1px solid #d2d8db;
  margin-top: 1.1rem;
  margin-bottom: 1.1rem;
}

@media (min-width: $desktop-view) {
  // list
  .s-story__list {
    list-style: none;
    padding-left: 17px;
    padding-right: 0;
    margin-bottom: 0;
  }

  .s-story__item {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .s-story__item::before {
    top: 12px;
  }

  // Target list

  .s-story__target-mark {
    font-size: 22px;
    line-height: 42px;
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .s-story__target-mark--sub {
    display: block;
  }

  .s-story__target-number {
    font-size: 48px;
    line-height: 64px;
    margin-right: 8px;
  }

  .s-story__target-number + span {
    margin-top: 15px;
  }

  .s-story__target-mark .before {
    margin-top: 15px;
  }

  .s-story__target-text {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0;
  }
}


@media (min-width: 1400px) {
  .s-story__target-mark {
    font-size: 24px;
  }
}