.modal-adaptive {

  @media (max-width: 575px) {

    &.fade .modal-dialog {
      transform: translate(0,100%);
      transition: transform .5s ease-in-out;
    }

    &.show .modal-dialog {
      transform: translate(0,0);
    }

    &__dialog {
      margin: 0;
      max-height: 89vh;
      overflow: hidden;
      display: flex;
      position: absolute;
      bottom: 0;
      padding-top: 40px;
      border-radius: 12px 12px 0 0;
    }
    &__inner {
      display: block;
      overflow: auto;
    }
    &__close {
      padding: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 40px;
      &:after {
        display: none;
      }
      &:before {
        height: 4px;
        width: 40px;
        border-radius: 4px;
        opacity: 0.23;
        transform: none;
        top: 16px;
        left: calc(50% - 20px);
      }
    }
  }
}
