.elma-apps {
  max-width: 1290px;
  margin-left: -35px;
  margin-top: 39px;
  margin-right: -35px;
  margin-bottom: 69px;
}

.elma-apps__fill {
  padding: 43px 59px;
}

.main-apps {
  margin-bottom: 37px;
  margin-top: 74px;
}

.main-apps__description {
  max-width: 973px;
}

.main-apps__list-apps {
  max-width: 1283px;
  margin-left: -23px;
  margin-right: -23px;
  padding: 0 16px;
}

.main-apps__list-apps-card {
  width: 320px;
  @media (min-width: 1400px) {
    width: 404px;
  }
}

.main-apps__item {
  width: 324px;
  min-height: 243px;
  @media (min-width: 1400px) {
    width: 404px;
  }
}

.wrap-elma-apps {
  margin-bottom: 37px;
  margin-top: 32px;
}

@media (max-width: $mobile-view) {
  .main-apps__item {
    width: 100%;
  }
}
