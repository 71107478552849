.elma-ce__main-banner {
  padding: 0;
  padding-top: 31px;
  min-height: 523px;
}

.elma-ce__title {
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
  padding: 0;
  margin-bottom: 19px;
  max-width: 700px;
}

.elma-ce__description {
  padding: 0;
  margin-bottom: 18px;
  max-width: 700px;
}

.elma-ce-model {
  padding-bottom: 34px;
}

.elma-ce-auto,
.elma-ce-control,
.elma-ce-improve,
.elma-ce-integration {
  padding-bottom: 34px;
  padding-top: 46px;
}

.elma-ce-model__wrap,
.elma-ce-auto__wrap,
.elma-ce-control__wrap,
.elma-ce-improve__wrap,
.elma-ce-integration__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.elma-ce-model__text-container,
.elma-ce-auto__text-container,
.elma-ce-control__text-container,
.elma-ce-improve__text-container,
.elma-ce-integration__text-container {
  max-width: 450px;
}

.elma-ce-model__img-container,
.elma-ce-auto__img-container,
.elma-ce-control__img-container,
.elma-ce-improve__img-container,
.elma-ce-integration__img-container {
  max-width: 450px;
  img {
    height: auto;
  }
}

@media (min-width: 1400px) {

  .elma-ce-model__img-container,
  .elma-ce-auto__img-container,
  .elma-ce-control__img-container,
  .elma-ce-improve__img-container,
  .elma-ce-integration__img-container {
    max-width: inherit;
  }

  .elma-ce-model__text-container,
  .elma-ce-auto__text-container,
  .elma-ce-control__text-container,
  .elma-ce-improve__text-container,
  .elma-ce-integration__text-container {
    max-width: 584px;
  }
}

.elma-ce-model__title,
.elma-ce-auto__title,
.elma-ce-control__title,
.elma-ce-improve__title,
.elma-ce-integration__title {
  margin-bottom: 12px;
}

.elma-ce-model__advantages-list,
.elma-ce-auto__advantages-list,
.elma-ce-control__advantages-list,
.elma-ce-improve__advantages-list {
  padding-left: 18px;
}

.elma-ce .ce-download {
  margin-bottom: 80px;
}

.elma-ce .classic-task-ecm {
  margin-bottom: 59px;
}

.elma-ce-module {
  padding-top: 60px;
  padding-bottom: 50px;
}

.elma-ce-module__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 49px
}

.elma-ce-module__text-container {
  max-width: 450px;
}

.elma-ce-module__img-container {
  max-width: 450px;
  img {
    height: auto;
  }
}

@media (min-width: 1400px) {
  .elma-ce-module__text-container {
    max-width: 590px;
  }
  .elma-ce-module__img-container {
    max-width: inherit;
  }
}

.elma-ce-module__title {
  margin-bottom: 14px;
}

.elma-ce-module__advantages-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.elma-ce-module__advantages-item {
  width: 300px;
}
@media (min-width: 1400px) {
  .elma-ce-module__advantages-item {
    width: 379px;
  }
}
