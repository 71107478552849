$gray : $gray-200;
$light-blue : #048ad3;

.hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #dbdbdb;

  &--blue {
    border-top: 1px solid #2c5f7b;
  }
}

.bg-warning {
  background-color: #f8d638 !important
}

.badge-redaction {
  margin-left: 10px;
  font-size: 0.6875rem;
}

.redaction {
  &__title-inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
  }
}

.text-decoration {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #048ad3;
  }

  &--white::before {
    background-color: #fff;
  }
  &--linght-blue::before {
    background-color: #b3dcf2;
  }

  &--blue::before {
    background-color: #4d7a93;
  }
}

.link {

  &--white {
    color: #fff;

    &:hover,
    &:active {
      color: #048ad3;
    }
  }
}

.h1 {
  font-weight: 800;
}

.price-value {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 1.875rem;
  line-height: 2.375rem;

  .price-cards__item & {
    margin-left: 4px;
  }

  &--transparent {
    .price-cards__item & {
      margin-left: 0;
    }
  }

  span {
    position: relative;
    z-index: 10;
  }

  h2 + & {
    margin-bottom: .8rem;

    @media (min-width: 576px) {
      margin-left: 5%;
      margin-bottom: .5rem;
    }
  }

  &::before {
    content: '';
    position: absolute;
    background-color: $yellow;
    width: 110%;
    height: 30px;
    left: -5%;
    top: 40%;
    border-radius: 15px;
  }

  &--transparent::before {
    background-color: transparent;
    margin-bottom: 0;
  }
}

.price-cards {
  &__item {
    font-size: 0.875rem;
    line-height: 1.375rem;

    & + & {
      margin-top: 24px;

      @media (min-width: 576px) {
        margin-top: 0;
      }
    }
  }

}

.calc-example {
  &__specs {
    border: 2px dashed $gray;
    max-width: 710px;
    width: 100%;

  }

  &__tbody {
    padding: 20px 45px 40px 25px;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;

    @media (min-width: 576px) {
      padding-bottom: 85px;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__places {
    width: 100%;
    flex: 1 1 100%;
    margin-bottom: 1rem;

    @media (min-width: 576px) {
      width: auto;
      flex: 0 1 190px;
      margin-bottom: 0;
    }
  }

  &__places-value {
    font-size: 2.8125rem;
    line-height: 2.875rem;
  }

  &__places-prop {
    font-weight: 800;

  }

  &__list-props {
    width: 100%;
    flex: 1 1 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;

    @media (min-width: 576px) {
      width: auto;
      flex: 0 1 445px;
    }
  }

  &__list-item {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    & + & {
      margin-top: 1.2rem;
    }
  }

  &__prop {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    flex: 0 1 50%;

    span {
      white-space: nowrap;
    }

    @media (min-width: 576px) {
      flex: 0 1 250px;
    }
  }

  &__value {
    font-size: 1.875rem;
    line-height: 2.375rem;
    text-align: right;
    white-space: nowrap;
    flex: 0 1 50%;

    @media (min-width: 576px) {
      flex: 0 1 165px;
    }
  }

  &__footer {
    border-top: 2px dashed $gray;
    padding: 8px 45px 16px;
  }

  &__total-sum {
    text-align: right;
  }
}

.preview-wide {
  &__img {

    @media (min-width: 576px) {
      width: 75%;
    }
  }

  &__descr {
    @media (min-width: 576px) {
      width: 25%;
    }
  }

  &--70perc {
    .preview-wide {
      &__img {

        @media (min-width: 576px) {
          width: 70%;
        }
      }

      &__descr {

        @media (min-width: 576px) {
          width: 30%;
          font-size: 1.5rem;
          line-height: 2.125rem;
        }
      }
    }
  }
}

.news-card {
  &::before {
    position: absolute;
    content: '';
    width: calc(100% - 30px);
    height: 1px;
    top: 0;
    left: 15px;
    background-color: $gray;
    z-index: 1;
  }

  a + a {
    margin-left: 33px;
  }
}

.news-wide {
  position: relative;

  &__img {
    width: 100%;

    @media (min-width: 576px) {
      width: 65%;
    }
  }

  &__descr-img {
    @media (min-width: 576px) {
      width: 35%;
    }
  }
}

.img-wide {
  position: relative;
  padding: 0;

  &__img {
    width: 100%;
    height: auto;
  }

  .badge {
    position: absolute;
    font-weight: normal;
    font-size: 1rem;
    top: 10px;
    left: 10px;

    @media (min-width: 576px) {
      top: 29px;
      left: 30px;
    }
  }
  //height: 300px;

  //@media (min-width: 576px) {
  //  height: 430px;
  //  width: 100%;
  //}
  //img {
  //  width: 1px;
  //  height: 1px;
  //  visibility: hidden;
  //  opacity: 0;
  //  left: 0;
  //  top: 0;
  //}
}

.breadcrumb {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  &__item {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.625rem;

    & + &::before {
      content: '/';
    }

    a {
      color: $black;
    }
  }
}

.skills-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  max-width: 54rem;
  font-size: 1.5rem;
  line-height: 2.125rem;

  &__item {
    flex: 0 1 265px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
    line-height: inherit;
    margin-bottom: .5rem;
  }
}

.preview-text {
  position: relative;
  padding-bottom: 2.3rem;
  font-size: 1.125rem;
  line-height: 1.75rem;

  p {
    margin-bottom: 0;
    font-size: inherit;
    line-height: inherit;
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    max-width: 565px;
    width: 100%;
    height: 1px;
    background-color: $gray;
    margin-left: 30px;
  }

  &--576px {

    @media (min-width: 576px) {
      padding-bottom: 127px;
    }
    &::before {
      max-width: 576px;
      margin-left: 0;
    }
  }

}

.text-article {
  font-size: 1rem;
  line-height: 1.625rem;
}

.blockquote {
  font-size: 1.5rem;
  line-height: 2.125rem;
  text-align: center;
}

.image2qty {

  .col-sm-6 + .col-sm-6 {
    margin-top: 2rem;

    @media (min-width: 576px) {
      margin-top: 0;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

}

.img-text {
  width: 100%;
  height: auto;
  float: none;
  margin-bottom: 1rem;

  &.float-right {
    @media (min-width: 576px) {
      float: right;
      max-width: 378px;
      margin-left: 1rem;
    }
  }

  &.float-left {
    @media (min-width: 576px) {
      float: left;
      max-width: 378px;
      margin-right: 1rem;
    }
  }
}

.blockquots {
  padding: 30px;
  background-color: #fff;
  width: 100%;

  @media (min-width: 576px) {
    max-width: 70%;
  }

  p {
    max-width: 100%;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.footer-menu {
  &__link {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}

.logo-icon {

  &--naked-bpm {
    margin-right: 10px;
    svg {
      width: 39px;
      height: 37px;
    }
  }

  &--bis {
    margin-right: 10px;

    img {
      width: 38px;
      height: 40px;
    }
  }
}

.article-nav {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 16px;
    width: calc(100% - 32px);
    height: 1px;
    background-color: $gray;
  }

  h3 {
    margin-bottom: 1rem;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__link {
    padding: 2px;
    font-size: 1rem;
    line-height: 1.25rem;

    @media (min-width: 576px) {
      font-size: 0.8125rem;
    }

  }

  @at-root .link-active {
    background-color: #fff;
    color: $black;
  }

  h4 {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: bold;

    @media (min-width: 576px) {
      font-size: 0.8125rem;
    }
  }

  li + li {
    margin-top: 5px;
  }

  .col-sm-6 {
    margin-top: 1rem;
  }
}

.insert-text {
  padding: 20px;
  background-color: #fff;
  font-size: 1.5rem;
  line-height: 2rem;

  &__link {
    font-size: 1rem;
    line-height: 1.625rem;
  }

  &__img {
    width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    float: right;
    padding-bottom: 1rem;
    padding-left: 1rem;

    @media (min-width: 576px) {
      max-width: 22%;
      min-width: 130px;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: 576px) {
    padding: 40px;
  }
}

.note-preview {

  & + & {
    margin-top: 1rem;

    @media (min-width: 576px) {
      margin-top: 145px;

    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 16px;
    width: calc(100% - 32px);
    height: 1px;
    background-color: #e5e5e5;
  }

  &__type {
    margin-bottom: .5rem;
    font-weight: bold;
    font-size: 0.8125rem;
    line-height: 1.25rem;
  }

  &__link {
    font-weight: bold;
    margin-bottom: .5rem;
    display: inline-block;
    vertical-align: middle;
  }
}

.author {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  &__img {
    margin-right: 10px;
    overflow: hidden;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .1);

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__name {
    font-size: 1rem;
    line-height: 1.375rem;
    color: $black;
  }
}

.article-insert {
  padding: 0;

  &__inner {
    padding: 28px;
  }

  &__img {
    height: 30px;
    width: auto;
  }

  p {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }

  &__link {
    svg {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      width: 9px;
      height: 9px;
    }
  }
}

.marker-text {
  position: relative;
  z-index: 2;
  @extend .price-value;
  font-size: 1.5rem;
  line-height: 2.125rem;
  vertical-align: top;

  span {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }

  &::before {
    height: 30%;
    top: 55%;
    left: -2%;
    width: 104%;
  }
}

.img-text {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: auto;
}

.link-more {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1.5rem;
  line-height: 1.875rem;

  &__icon {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 26px;
    margin-right: 10px;
  }
}

.contacts-info {

  &__inner-list {

    @media (min-width: 576px) {
      padding-left: 30px;
    }

  }

  &__list-links {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  p {
    margin-bottom: .6rem;
  }

  &__inner-list {
    margin-top: 1rem;
  }

  &__list-links {
    li + li {
      margin-top: 15px;

    }
  }

}

.person {

  &-list {
    .row  + .row {
      margin-top: 1rem;
    }
  }

  &-card {

    & + & {
      margin-top: 1rem;

      @media (min-width: 576px) {
        margin-top: 0;
      }
    }

    &__img {
      margin-bottom: 10px;
      display: block;
      width: 100%;
      height: auto;

      @media (min-width: 576px) {
        max-width: 122px;
      }
    }

    &__contacts {
      margin-bottom: 0;
    }
  }

}

.footer-menu  {
  padding-top: 100px;
}

.partners-list {
  &__head {
    padding-top: 5px;
    padding-right: 10px;
    border-top: 1px solid $gray;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 1.125rem ;
    line-height: 1.5rem;

  }

  &__flag {
    width: 21px;
    height: auto;
    display: block;
  }

  &__body {
    display: flex;
    align-items: flex-start;

    @media (min-width: 576px) {
      justify-content: space-between;
    }
  }

  &__col {
    list-style-type: none;
    margin: 0;

    @media (min-width: 576px) {
      //max-width: 45%;
      //flex: 0 0 45%;
    }

  }

  &__item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    font-size: 1rem;
    height: 1.875rem;
    justify-content: space-between;
    padding-right: 10px;

    @media (min-width: 576px) {
      //max-width: 185px;
    }

    .partners-list__col:last-of-type &{
      margin-left: auto;
    }
  }

  &__prop {
    padding-right: 15px;
  }

  &__inner {
    margin-top: 10px;
  }
}

.vacancies {
  a {
    display: block;
    text-decoration: none;
    max-width: 100%;
  }
  h3 {
    font-weight: normal;
    position: relative;
    display: inline-block;
    font-size: 25px;
    line-height: 28px;
    margin: 0 0 10px -3px;
    padding: 0 0 15px;
    text-align: left;
    width: calc(100% - 80px);
    max-width: calc(100% - 80px);

    &::after {
      position: absolute;
      content: '';
      width: 33px;
      height: 34px;
      right: 1px;
      bottom: 0;
      display: block;
      background: #ffffff url(../images/icon-go.jpg) no-repeat center;
    }
  }

  img {
    display: inline-block;
    margin: -8px 20px 0 0;
    vertical-align: middle;
    min-width: 57px;
  }
}

.ptr {
  border-bottom: 1px dotted #1f6aa7;
  margin-top: 20px;
}

.list-dashed {
  list-style-type: none;
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding: 0;
  padding-top: 10px;
  margin: 0;
  border-top: 1px solid #e5e5e5;

  li + li {
    margin-top: 10px;
  }

  a {
    text-decoration: none;
    border-bottom: 1px dashed #1f6aa7;
  }
}

.infographic {
  &__circle-inner {

  }

  &__col {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    @media (min-width: 576px) {
      display: block;
    }

    &:last-of-type {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      @media (min-width: 576px) {
        align-items: flex-start;
        align-content: flex-start;
      }

      small {
        flex: 1 1 100%;
        text-align: center;
        margin-bottom: 15px;

        @media (min-width: 576px) {
          //margin-bottom: 40px;
        }
      }
    }

  }

  &__col-inner {

  }

  &__circle {
    display: inline-block;
    vertical-align: middle;



    &--1 {
      padding: 2rem 1.5rem;
      margin-left: 9rem;

    }

    &--2 {
      padding: 3rem 1.5rem;
      margin-top: -5rem;
      margin-left: 0;
      text-align: center;
    }

    &--3 {
      padding: 1.7rem 1.5rem;
      margin-top: -5rem;
      margin-left: 9rem;
      z-index: -1;
      position: relative;

    }
  }

  &__arrows {
    transform: rotate(90deg);
    @media (min-width: 576px) {
      transform: none;
    }
  }

  &__target {
    font-size: 2.25rem;
    color: #1b8ac7;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12rem; height: 12rem;
    border: 3px dashed $gray;
    border-radius: 50%;

  }

}

.media {

  &__inner {
    position: relative;
  }

  &__control {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 5;
    width: 50px;
    height: 50px;
  }

  &__button-play {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $light-blue;
    border: none;
    font-size: 0;
    cursor: pointer;
    padding: 0;

    &:hover,
    &:active {
      background-color: #045F9D;
    }

    svg {
      display: block;
      fill: #fff;
      margin-left: 4px;
    }
  }

  &__caption {
    font-size: .875rem;
    line-height: 1.375rem;
  }

}

.figure {
  &__caption {
    font-size: .875rem;
    line-height: 1.375rem;
  }
}

.icon-arrow-more {
  display: inline-block;
  vertical-align: middle;
  width: 7px;
  height: 12px;
  fill: #048ad3;
  fill-rule: evenodd;
  margin-left: 20px;
}

.call-to-action.call-to-action_trial {
  padding: 50px 0 0 78px;
  background: #253252;
  margin-right: 0;
  margin-left: 0;
}

.btn-yellow {
  background: #FABE00;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #253252;
  border: 1px solid #FABE00;
  align-items: center;
  justify-content: center;
  width: 257px;
  height: 48px;
  transition: 0.2s;

  &:hover {
    color: #FABE00;
    background: transparent;
  }
}

.call-to-action_trial {
  position: relative;
  z-index: 1;
  overflow: hidden;

  input {
    box-shadow: none !important;
  }

  .btn-yellow {
    margin-left: 0;
    margin-top: 27px;
  }

  &:after {
    content: '';
    background-image: url("../image/section-trial_after.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: -39px;
    width: 229px;
    height: 100%;
    z-index: -1;
  }

  .block-2-col__col-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 150%;
    color: #FFFFFF;
    margin-bottom: 17px;
    max-width: 419px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
    margin-bottom: 46px;
    max-width: 409px;
  }

  .form-t {
    padding-bottom: 67px;
    max-width: 409px;
    .form-control {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: #fff;
      border-bottom: 1px solid rgba(255,255,255,.2);
      position: relative;
      z-index: 2;
      outline: 0;

    }

    .floating_label label {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: rgba(255, 255, 255, 0.3);
    }
  }
}

@media (max-width: 1399px) {
  .call-to-action.call-to-action_trial {
    .block-2-col__col {
      position: relative;
    }

    img {
      width: 379px;
      position: absolute;
      bottom: 0;
    }
  }
}

@media (max-width: 992px) {
  .call-to-action.call-to-action_trial {
    padding: 50px 0 0 38px;
    .block-2-col__col {
      padding: 0 10px;

      p {
        font-size: .9rem;
        margin-bottom: 15px;
      }

      h2 {
        font-size: 1.875rem;
        line-height: 2.375rem;
        br {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .call-to-action.call-to-action_trial {
    img {
      position: static;
    }
  }

  .call-to-action_trial:after {
    top: 0;
    right: 0;
    width: 140px;
    height: 261px;
  }

  .call-to-action.call-to-action_trial {
    padding: 30px 10px 0 10px;
  }

  .btn-yellow {
    width: 100%;
  }

  .block-2-col {
    padding-left: 0;
    padding-right: 0;
  }

  .call-to-action.call-to-action_trial .block-2-col__col h2 {
    font-size: 1.475rem;
    line-height: 2.375rem;
  }
}
