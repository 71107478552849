.ny-2020-present {
  &_mrg {
    margin: 20px 0;

    @media (min-width: 768px) {
      margin: 20px -20px;
    }
  }
  &__plag {
    background: #E62D32;
    min-height: 86px;
    display: block;
    color: #FFFFFF;
    padding: 15px;
    overflow: hidden;
    position: relative;
    @media (min-width: 1024px) {
      &::after {
        content: '';
        display: block;
        background-image: url("/upload/ny-present/ny-pix.svg");
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 67px;
        height: 67px;
        right: 0;
        bottom: 0;
        pointer-events: none;
      }
    }
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
    }
    @media (min-width: 1024px) {
      padding: 14px 50px;
    }
  }
  &__info {
    width: 100%;
    margin: 0 20px 0 0px;
    font-size: 21px;
    line-height: 1.3;
    color: #FFFFFF;
    span {
      font-weight: 700;
    }
    @media (min-width: 1024px) {
      margin: 0 20px 0 20px;
      max-width: 550px;
    }
    @media (min-width: 1200px) {
      margin: 0 60px 0 70px;
    }
  }
  &__link {
    color: #FFFFFF;
    flex-shrink: 0;
    margin-top: 10px;
    display: inline-block;
    position: relative;
    padding-right: 22px;
    &::after {
      content: url("/upload/ny-present/arrow-link-menu-white.svg");
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease .2s;
    }
    &:hover &::after {
      right: -4px;
    }
    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
  &__img {
    display: none;
    @media (min-width: 1024px) {
      display: block;
    }
  }
}
