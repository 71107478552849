.kpi {
  &__title {
    padding-left: 35px;
  }

  &__description {
    padding-left: 35px;
    padding-bottom: 28px;
  }

  &__button-primary {
    margin-left: 35px;
    margin-bottom: 32px;
    padding: 12px 35px;
  }
}

.what-elma-kpi {
  margin-bottom: 72px;
  width: 779px;

  & &__title {
    margin-bottom: 12px;
  }
}

.kpi-target {
  margin-bottom: 72px;
}

.kpi-target figure {
  margin-left: -12px;
  margin-bottom: 32px;
  position: relative;
  z-index: 0;
  margin-right: 300px;
}

.kpi-target figcaption,
.kpi-result figcaption,
.kpi-motivation figcaption{
  font-size: 14px;
  line-height: 22px;
  color: #636363;
}

.kpi-target .kpi-target__title {
  margin-bottom: 12px;
}

.kpi-target__description {
  margin-bottom: 5px;
}

.kpi-target__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.kpi-target__column-text {
  width: 630px;
  @media (min-width: 1400px) {
    width: 720px;
  }
}

.kpi-target__column-webinar {
  width: 290px;
  margin-right: 30px;
  margin-top: 53px;
  margin-bottom: -100px;
  position: relative;
  z-index: 1;
  @media (min-width: 1400px) {
    width: 380px;
    margin-bottom: -150px;
  }
}

.kpi-target__column-webinar iframe {
  margin-bottom: 7px;
}

.kpi-target figcaption {
  margin-left: 14px;
  margin-top: 28px;
  width: 718px;
}

.kpi-result {
  margin-bottom: 72px;
}

.kpi-result .kpi-result__title {
  margin-bottom: 12px;
}

.kpi-result__description {
  margin-bottom: 5px;
  width: 756px;
}

.kpi-result figcaption {
  margin-left: -2px;
  margin-top: 11px;
}

.kpi-motivation {
  margin-bottom: 77px;
}

.kpi-motivation .kpi-motivation__title {
  margin-bottom: 12px;
}

.kpi-motivation__description {
  margin-bottom: 5px;
  width: 780px;
}

.kpi-motivation__wrap-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 15px;
}

.kpi-motivation__left-image,
.kpi-motivation__right-image {
  width: 490px;
  @media (min-width: 1400px) {
    width: 610px;
  }
}

.kpi-motivation__left-image img {
  margin-left: -35px;
  margin-top: -10px;
}

.kpi-motivation__right-image img {
  margin-left: -35px;
  margin-top: -10px;
}

.kpi-motivation__wrap-image figcaption {
  margin-top: -18px;
}

@media (max-width: $mobile-view) {
  .kpi__title {
    padding-left: 16px;
  }

  .kpi__description {
    padding-left: 16px;
    padding-bottom: 10px;
  }

  .kpi__button-primary {
    margin-left: 16px;
    margin-bottom: 32px;
  }

  .what-elma-kpi {
    width: 100%;
    margin-bottom: 32px;
  }

  .kpi-target {
    margin-bottom: 32px;
  }

  .kpi-target__column-webinar {
    margin-right: 0;
    margin-bottom: 0;
  }

  .kpi-target img {
    width: 100%;
  }

  .kpi-target figure {
    margin-left: -12px;
    margin-bottom: 32px;
    margin-right: 0;
    position: relative;
    z-index: 0;
    width: 100%;
  }

  .kpi-target figcaption {
    width: 100%;
  }

  .kpi-result__description {
    width: 100%;
  }

  .kpi-result__wrap-img {
    width: 100%;
  }

  .kpi-result__wrap-img figure {
    width: 100%;
  }

  .kpi-result__wrap-img img {
    width: 100%;
  }

  .kpi-result__wrap-img figcaption {
    width: 100%;
  }

  .kpi-motivation__left-image img,
  .kpi-motivation__right-image img {
    width: 100%;
    margin-left: 0;
  }

  .kpi-motivation__left-image img {
    margin-bottom: 15px;
  }

  .kpi-motivation__left-image {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .kpi-motivation__wrap-image figcaption {
    margin-left: 15px;
  }

  .kpi-target__column-webinar {
    width: 100%;
    margin-bottom: 25px;
  }

  .kpi-target__column-webinar iframe {
    width: 100%;
  }

  .kpi-motivation__description {
    width: 100%;
  }

  .kpi-result {
    margin-bottom: 32px;
  }

  .kpi-motivation {
    margin-bottom: 32px;
  }
}
