.standard-price {
  padding-top: 25px;

  &__block-wrap {
    margin-bottom: 54px;
  }

  & &__title {
    margin-bottom: 22px;
  }

  &__description {
    margin-top: 22px;
    margin-bottom: 19px;
    width: 776px;
  }

  &__calculator-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &__block-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 14px;
    font-weight: bold;
  }

  &__calculator {
    width: 737px;
    margin-left: -35px;
    background-color: white;
    padding: 30px 35px;
    padding-top: 43px;
    -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  }

  &__calculator-label {
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
  }

  &__label input[type=number]::-webkit-outer-spin-button,
  .standard-price__label input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &__label input[type=number] {
    -moz-appearance: textfield;
  }

  &__subscription {
    font-size: 14px;
    line-height: 22px;
    color: #636363;
    font-weight: normal;
    position: absolute;
    display: block;
    width: 447px;
    top: 22px;
    left: 56px;
  }

  &__change-currency {
    display: inline-block;
    height: 33px;
    -webkit-box-shadow: inset 0 0 0 1px #cdcdcd;
    box-shadow: inset 0 0 0 1px #cdcdcd;
    border-radius: 15px;
    margin-bottom: 28px;
    overflow: hidden;
  }

  &__currency-label {
    margin-bottom: 0;
    margin-right: -5px;
  }

  &__currency-label:hover,
  &__currency-label:focus {
    background-color: #048ad3;
  }

  &__currency-input + &__currency-icon {
    color: #048ad3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 64px;
    height: 33px;
    font-size: 18px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  &__currency-input + &__currency-icon:hover,
  &__currency-input + &__currency-icon:focus {
    color: white;
  }

  &__currency-input:checked + &__currency-icon {
    background-color: #048ad3;
    color: white;
  }

  &__currency-label:nth-of-type(1) &__currency-input + &__currency-icon {
    padding-left: 6px;
    border-right: 1px solid #cdcdcd;
  }

  &__currency-label:nth-of-type(2) &__currency-input + &__currency-icon {
    border-right: 1px solid #cdcdcd;
  }

  &__currency-label:nth-of-type(3) &__currency-input + &__currency-icon {
    padding-right: 6px;
  }

  &__summ {
    display: inline-block;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 32px;
    margin-left: auto;
    margin-right: 3px;
    margin-top: -3px;
  }

  &__summ span {
    text-transform: uppercase;
  }

  &__checkbox {
    display: none;
  }

  &__checkbox + &__checkbox-icon {
    position: relative;
    display: inline-block;
    width: 49px;
    height: 25px;
    border-radius: 15px;
    background-color: #e0e0e0;
    cursor: pointer;
    margin-bottom: -5px;
    margin-right: 2px;
  }

  &__checkbox + &__checkbox-icon::before {
    position: absolute;
    content: '';
    left: 3px;
    top: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ffffff;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
  }

  &__checkbox:checked + &__checkbox-icon::before {
    left: 25px;
  }

  &__checkbox:checked + &__checkbox-icon {
    background-color: #048ad3;
  }

  &__checkbox:checked &__summ {
    color: red;
  }

  &__wrap-bpm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 37px;
  }

  &__wrap-crm,
  &__wrap-project,
  &__wrap-kpi {
    padding-top: 13px;
    padding-bottom: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;
  }

  &__wrap-project &__count-license,
  &__wrap-kpi &__count-license {
    margin-top: 15px;
    width: 666px;
  }

  &__range-max {
    position: absolute;
    right: -2px;
    bottom: -22px;
    font-size: 14px;
    line-height: 22px;
    color: #636363;
  }

  &__range-min {
    position: absolute;
    left: 0;
    bottom: -22px;
    font-size: 14px;
    line-height: 22px;
    color: #636363;
  }

  &__count-license {
    margin-bottom: 40px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  &__count-license input {
    width: 58px;
    margin-left: auto;
    font-size: 24px;
    line-height: 25px;
    text-transform: uppercase;
    text-align: right;
    padding-right: 4px;
  }

  &__count-license input:disabled {
    background-color: transparent;
    border: none;
    color: black;
    box-shadow: 0 0 0 1px #abaaaa;
  }

  &__count-license label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
  }

  &__aside {
    width: 490px;
    margin-top: 12px;
    padding-right: 32px;
  }

  &__count-license--hidden {
    margin-bottom: -160px;
  }

  &__total {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &__total-count {
    text-transform: uppercase;
    font-size: 24px;
    line-height: 32px;
  }

  &__current {
    text-transform: lowercase;
  }

  &__button {
    padding: 12px 30px;
    font-weight: bold;
    border: none;
  }
}

#bpm-lic-name-range,
#bpm-lic-con-range,
#prj-lic-name-range,
#kpi-lic-name-range,
#bpm-lic-mob-range {
  -webkit-appearance: none;
  position: relative;
}

#bpm-lic-name-range::-webkit-slider-runnable-track,
#bpm-lic-con-range::-webkit-slider-runnable-track,
#prj-lic-name-range::-webkit-slider-runnable-track,
#kpi-lic-name-range::-webkit-slider-runnable-track,
#bpm-lic-mob-range::-webkit-slider-runnable-track {
  height: 5px;
  background-color: #cfcfcf;
  display: flex;
  align-items: center;
}

#bpm-lic-name-range::-webkit-slider-thumb,
#bpm-lic-con-range::-webkit-slider-thumb,
#prj-lic-name-range::-webkit-slider-thumb,
#kpi-lic-name-range::-webkit-slider-thumb,
#bpm-lic-mob-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #048ad3;
  top: -7px;
}

#bpm-lic-name-range::-moz-range-track,
#bpm-lic-con-range::-moz-range-track,
#prj-lic-name-range::-moz-range-track,
#kpi-lic-name-range::-moz-range-track,
#bpm-lic-mob-range::-moz-range-track {
  height: 5px;
  background-color: #cfcfcf;
  display: flex;
  align-items: center;
}

#bpm-lic-name-range::-moz-range-progress,
#bpm-lic-con-range::-moz-range-progress,
#prj-lic-name-range::-moz-range-progress,
#kpi-lic-name-range::-moz-range-progress,
#bpm-lic-mob-range::-moz-range-progress {
  background-color: #1b8ac7;
}

#bpm-lic-name-range::-moz-range-thumb,
#bpm-lic-con-range::-moz-range-thumb,
#prj-lic-name-range::-moz-range-thumb,
#kpi-lic-name-range::-moz-range-thumb,
#bpm-lic-mob-range::-moz-range-thumb {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #048ad3;
  border-color: transparent;
  box-shadow: none;
}

#bpm-lic-name-range::-ms-track,
#bpm-lic-con-range::-ms-track,
#prj-lic-name-range::-ms-track,
#kpi-lic-name-range::-ms-track,
#bpm-lic-mob-range::-ms-track {
  overflow: auto;
  display: flex;
  height: 20px;
  align-items: center;
  background-color: transparent;
  color: transparent;
  border: none;
}

#bpm-lic-name-range::-ms-fill-lower,
#bpm-lic-con-range::-ms-fill-lower,
#prj-lic-name-range::-ms-fill-lower,
#kpi-lic-name-range::-ms-fill-lower,
#bpm-lic-mob-range::-ms-fill-lower {
  background-color: #1b8ac7;
  height: 5px;
}

#bpm-lic-name-range::-ms-fill-upper,
#bpm-lic-con-range::-ms-fill-upper,
#prj-lic-name-range::-ms-fill-upper,
#kpi-lic-name-range::-ms-fill-upper,
#bpm-lic-mob-range::-ms-fill-upper {
  background-color: #cfcfcf;
  height: 5px;
}

#bpm-lic-name-range::-ms-thumb,
#bpm-lic-con-range::-ms-thumb,
#prj-lic-name-range::-ms-thumb,
#kpi-lic-name-range::-ms-thumb,
#bpm-lic-mob-range::-ms-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #048ad3;
}

.text-color--disable {
  color: #e0e0e0;
}

.count__license {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.table-platform-bpm {
  width: 840px;
  margin-left: -35px;
  box-shadow: 0 3px 24px 0 rgba(0,0,0,.16);

  &__wrap {
    margin-bottom: 15px;
  }

  &__wrap-bg {
    padding: 35px;
    padding-top: 20px;
    padding-bottom: 15px;
    padding-right: 50px;
    background-color: #ecf4f8;
  }

  &__title {
    display: inline-block;
    font-size: 24px;
    margin-right: 25px;
    font-weight: bold;
  }

  &__price {
    color: #fff;
    background-color: #0496d7;
    font-size: 24px;
    padding: 2px 3px;
  }

  &__lic-wrap {
    background-color: white;
    padding: 35px;
    padding-top: 15px;
  }

  &__lic-item {
    width: 236px;
    margin-right: 50px;
    border-right: 1px solid #f3f3f3;
  }

  &__lic-item:last-of-type {
    border: none;
  }

  &__lic-item b {
    display: block;
  }

  &__lic-item span {
    color: #0496d7;
    font-size: 24px;
  }

  &__table-price tr:nth-of-type(1) td:nth-of-type(2),
  .table-platform-bpm__table-price tr:nth-of-type(1) td:nth-of-type(3) {
    color: #cfcfcf;
    font-size: 14px;
  }

  &__table-price tr td:first-of-type {
    font-size: 18px;
    width: 200px;
  }

  &__table-price tr td:nth-of-type(2) {
    width: 240px;
  }

  &__table-price tr td {
    padding: 10px 10px;
  }

  &__table-price tr td span {
    color: #0496d7;
    font-size: 24px;
  }

  &__table-price tr:nth-of-type(2) td:not(:first-of-type),
  &__table-price tr:nth-of-type(3) td:not(:first-of-type),
  &__table-price tr:nth-of-type(4) td:not(:first-of-type) {
    border-bottom: 1px solid #f3f3f3;
  }

  &__text {
    padding-left: -35px;
    width: 840px;
  }
}

@media (max-width: $mobile-view) {
  .standard-price__description {
    width: auto;
  }

  .standard-price__calculator {
    width: auto;
    padding: 30px 16px;
    margin-right: -16px;
    margin-left: -16px;
  }

  .standard-price__count-license {
    width: auto;
  }

  .standard-price__subscription {
    width: 160px;
    position: static;
  }

  .standard-price__wrap-kpi .standard-price__count-license,
  .standard-price__wrap-project .standard-price__count-license {
    width: auto;
  }

  .count__license {
    width: 100%;
    margin-right: 1px;
  }

  .table-platform-bpm {
    margin-left: 0;

    &__wrap {
      margin: 0 -16px;
      margin-bottom: 15px;
      overflow: scroll;
    }

    &__text {
      width: 100%;
      padding: 0 16px;
    }
  }
}

@media (max-width: $mobile-view-320) {
  .prices__wrap-link .prices__link {
    width: 280px;
  }
}


