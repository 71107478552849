.s-story-banner {
  &__wrap {
    position: relative;
    width: 100%;
    height: 56.3vw;
    margin-bottom: 30px;
    overflow: hidden;
    background-color: silver;
  }

  &__wrap.active::before {
    opacity: 0;
    z-index: -1;
  }

  &__wrap.active .s-story-banner__wrap-text {
    opacity: 0;
    z-index: -1;
  }

  &__wrap.active img {
    display: none;
  }

  &__wrap:not(.not-video)::before {
    position: absolute;
    content: '';
    width: 110px;
    height: 110px;
    top: 50%;
    left: 50%;
    margin-left: -55px;
    margin-top: -55px;
    background-image: url("/upload/play.svg");
    background-position: center center;
    z-index: 100;
    cursor: pointer;
    transition: 0.5s;
  }

  &__wrap iframe {
    width: 100%;
    height: 56vw;
  }

  &__wrap img {
    width: 100%;
    height: 56vw;
    transition: 0.5s;
    object-fit: cover;
  }

  &__wrap-title-block {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding-bottom: 62px;
    padding-left: 30px;
    width: 80%;
    z-index: 10;

    &_width-full {
      width: 100%;
      margin-left: 16px;

      @media (max-width: 1200px) {
        padding-right: 16px;
        padding-left: 16px;
        margin-left: 0;
      }
    }
  }

  &__wrap-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 125%;
    color: #fff;
    @media (min-width: $desktop-view) {
      font-size: 48px;
    }
    &_width-70 {
      @media (min-width: $desktop-view) {
        width: 70%;
      }
    }
    &_mbottom {
      margin-bottom: 0.563em;
    }
  }

  &__wrap-subtitle {
    font-size: 16px;
    line-height: 144%;
    color: white;
    @media (min-width: $desktop-view) {
      font-size: 36px;
    }
    &_width {
      @media (min-width: 1200px) {
        width: 80%;
      }
    }
    &_sm-dnone {
      @media (max-width: 450px) {
        display: none;
      }
    }
  }

  &__wrap-text {
    &:not(.not-video) {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.59);
    }
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transition: 0.5s;
  }

  &__type {
    font-size: 16px;
    line-height: 26px;
    color: #edc14c;
    margin-top: 20px;
    margin-left: 16px;
    margin-bottom: 19vw;
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    margin-left: 16px;
    font-weight: bold;
    padding-right: 25px;
  }
}

@media (min-width: $desktop-view) {
  .s-story-banner {
    &__wrap {
      width: auto;
      height: 635px;
      margin-right: -20px;
      margin-left: -20px;
      margin-bottom: 55px;
      box-sizing: border-box;
      position: relative;
    }

    &__wrap iframe {
      width: 1290px;
      height: 635px;
    }

    &__wrap img {
      position: absolute;
      left: 0;
      top: 0;
      width: 1290px;
      height: 635px;
      z-index: 0;
    }

    &__wrap-text {
      display: flex;
      flex-direction: column;
      z-index: 10;
    }

    &__type {
      font-size: 20px;
      line-height: 36px;
      margin-top: 22px;
      margin-left: 30px;
      margin-bottom: 0;
    }

    &__title {
      margin-top: auto;
      margin-bottom: 62px;
      font-size: 41px;
      line-height: 54px;
      color: #ffffff;
      margin-left: 30px;
      width: 80%;
    }

    &--nanotech {
      .s-story-banner__wrap {
        background: linear-gradient(90deg, #040A10 0%, rgba(4, 10, 16, 0) 100%);
      }
    }
  }
}


@media (min-width: 1400px) {
  .s-story-banner {
    &__title {
      font-size: 48px;
      line-height: 60px;
    }
  }
}

/*@media (min-width: 1600px) {
  .s-story-banner {
    &__wrap {
      height: 726px;
    }

    &__wrap iframe {
      width: 1290px;
      height: 726px;
    }
  }
}*/
