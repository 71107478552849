.single-news {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  &__wrap {
    width: 640px;
    padding-top: 24px;
    @media (min-width: 1400px) {
      width: 750px;
    }
  }

  &__title {
    font-size: 30px;
    line-height: 42px;
    font-weight: bold;
    margin-bottom: 9px;
  }

  & h2 {
    margin-top: 27px;
  }

  & &__date {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
  }

  & &__description {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 54px;
  }

  & img {
    max-width: 100%;
    margin-bottom: 8px;
  }

  & figcaption {
    font-size: 14px;
    line-height: 22px;
    color: #636363;
    margin-bottom: 27px;
  }

  & p {
    margin-bottom: 14px;
  }

  &__aside {
    width: 310px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    @media (min-width: 1400px) {
      width: 379px;
    }
  }

  &__aside-block:first-of-type {
    margin-top: 415px;
    margin-bottom: 50px;
  }
  &__aside-block:empty {
    display: none;
  }

  &__aside-block:nth-of-type(3) {
    margin-top: auto;
  }

  &__aside-title {
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 14px;
  }

  &__list {
    list-style: none;
    padding: 0;
  }

  &__item {
    padding: 12px 0;
  }

  &__item:first-of-type {
    border-bottom: 1px solid #c4c4c4;
  }

  &__link {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
  }

  & .regular-container__fill {
    right: -325px;
    @media (min-width: 1400px) {
      right: -455px;
    }
  }
}

@media (max-width: $mobile-view) {
  .single-news__wrap {
    width: 100%;
  }

  .single-news__aside {
    .single-news__aside-block {
      &:first-child {
        display: none;
      }
    }
  }

  .single-news .regular-container__article {
    display: block;
    margin-bottom: 32px;
  }

  .single-news .regular-container__article .regular-container__fill {
    position: static;
    width: 100%;
    height: auto;
    min-height: auto;
  }
}
