// subnav styles

.navbar-subnav {
  max-width: 1000px;
  width: 100%;
  z-index: 1000;
  left: 0;
  top: 85%;
  position: absolute;
  padding: 25px 50px;
  background-color: #fff;
  display: none;

  @media (min-width: 576px) {
    display: none;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .nav-item:hover & {
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }
  }

  &__group {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;

    &:first-of-type {
      @media (min-width: 576px) {
        flex: 0 1 620px;
      }
    }

    &:last-of-type {
      @media (min-width: 576px) {
        flex: 0 1 270px;
      }
    }

    & + & {
      margin-left: 33px;
    }

    &-title {
      position: relative;
      flex: 1 1 100%;
      text-transform: uppercase;
      margin-bottom: 1rem;
      font-size: .875rem;
      color: #7c7c7c;

      span {
        display: inline-block;
        vertical-align: middle;
        background-color: #fff;
        padding-right: 10px;
      }

      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #e5e5e5;
        bottom: .4rem;
        z-index: -1;
      }

      .navbar-subnav__col--second + & {
        margin-top: 2rem;
      }
    }
  }

  &__inner-col {
    flex: 1 1 100%;
    display: flex;

  }

  &__col {
    flex: 0 1 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;

    @media (min-width: 576px) {
      flex: 0 1 310px;
      min-height: 330px;
    }

    &--second {
      @media (min-width: 576px) {
        min-height: 0;
        flex: 0 1 240px;
      }
    }

    & + & {

      @media (min-width: 576px) {
        //margin-left: 32px;
      }
    }
  }

  &__section {
    & + & {
      margin-top:7px;
    }
  }

  &__section-title {
    font-weight: 800;
    font-size: 1rem;
    line-height: 1.75rem;
    color: #262626;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    font-size: 0.875rem;
    line-height: 1.25rem;

    & + & {
      margin-top: 7px;
    }
  }

  &__link {
    font-size: inherit;
    line-height: inherit;
    color: $black;

    .navbar-subnav__list--second & {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .navbar-subnav__list--second .navbar-subnav__item:first-of-type & {
      color: $black;
    }

    .navbar-subnav__item:first-of-type & {
      font-size: 1rem;
      line-height: 1.5rem;
      color: #7d7d7d;
    }

  }

  &__total {
    margin-top: auto;
    position: relative;

    &::before {
      max-width: 245px;
      width: 100%;
      position: absolute;
      content: '';
      top: -5px;
      height: 1px;
      background-color: $gray;
    }
  }
}

.navbar-subnav--col {
  min-width: 340px;

  .subnav-right-col {
    display: none;
  }

  .navbar-subnav__link--col {
    display: block;
  }
}

@media (min-width: 768px) {
  .navbar-subnav--col {
    min-width: auto;
    width: auto;
    padding: 13px 25px;
    padding-right: 0;
    padding-bottom: 13px !important;
    left: auto;

    .navbar-subnav__wrap-col {
      width: auto;
    }

    .subnav-right-col {
      display: block;
    }

    .navbar-subnav__link {
      display: inline-block;
      line-height: 20px;
    }

    .navbar-subnav__link--col {
      width: 40%;
    }

    .navbar-subnav__list + .navbar-subnav__list {
      margin-top: 10px;
    }
  }
}