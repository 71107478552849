.s-story-about-company {
  &__wrap {
    position: relative;
    padding: 45px 16px;
    background-color: #e9ebef;
    padding-bottom: 45px;
  }

  &__wrap::before {
    position: absolute;
    content: '';
    height: 2px;
    right: 16px;
    left: 16px;
    background-color: #ffffff;
    bottom: 0;
  }

  &__wrap-img {
    margin-bottom: 18px;
  }

  &__wrap-img img {
    width: 100%;
    height: 51vw;
  }

  &__title {
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }

  &__list-wrap {
    padding-top: 22px;
    padding-left: 28px;
    padding-right: 50px;
  }
}

.s-story-about-company--sadco {
  .s-story-about-company {
    &__wrap::before {
      display: none;
    }
  }
}

@media (min-width: $desktop-view) {
  .s-story-about-company {
    &__wrap {
      margin-left: 40px;
      margin-right: 40px;
      padding: 51px 47px;
      padding-bottom: 45px;
    }

    &__wrap-content {
      display: flex;
      justify-content: space-between;
    }

    &__wrap-img {
      width: 51%;
    }

    &__wrap-img img  {
      width: 460px;
      height: 257px;
    }

    &__about {
      width: 44.7%;
    }

    &__title {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 11px;
    }

    &__description {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 20px;
    }

    &__wrap::before {
      right: 65px;
      left: 47px;
    }

    &__list-wrap {
      padding-top: 20px;
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;
      width: 95%;
    }

    &__list-wrap .s-story__target-list {
      display: flex;
      justify-content: space-between;
    }

    &__list-wrap .s-story__target-item {
      width: 31%;
    }
  }

  .s-story-about-company--sadco {
    .s-story-about-company {
      &__wrap {
        width: 1290px;
        margin-left: -20px;
        margin-right: -20px;
        padding: 56px 67px;
      }

      &__wrap-img img {
        width: 590px;
        height: 331px;
      }

      &__about {
        width: 42%;
        padding-right: 96px;
      }

      &__wrap-img {
        width: 53%;
      }
    }
  }
}

@media (min-width: 1400px) {
  .s-story-about-company {
    &__wrap {
      margin-left: 68px;
      margin-right: 68px;
    }

    &__description {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 20px;
    }
    &__wrap-img {
      width: 55%;
    }

    &__list-wrap .s-story__target-item {
      width: 30%;
    }
  }
}