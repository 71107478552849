.become-a-partners {
  padding-top: 25px;
  margin-bottom: 39px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: flex-start;

  &__wrap-text {
    width: 767px;
  }

  & &__title {
    margin-bottom: 23px;
  }

  &__description {
    margin-top: 31px;
    margin-bottom: 35px;
    width: 767px;
  }

  &__wrap-aside {
    width: 380px;
    margin-top: 117px;
    padding-right: 40px;
  }

  &__info {
    margin-top: 15px;
    background: #fff;
    padding: 25px 30px;
    box-shadow: 0 0 48px 2px rgba(190,190,190,.5);
  }

  &__description {
    margin-top: 31px;
    margin-bottom: 35px;
    width: 767px;
  }

  &__advantages-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 14px;
  }

  &__advantages-list {
    width: 383px;
    padding-left: 17px;
    margin: 0;
  }

  &__advantages-item {
    margin-bottom: 16px;
  }

  &__question {
    margin-bottom: 14px;
  }

  &__specialist {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 2px;;
  }

  &__contact-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__contact-item {
    margin-bottom: 4px;
  }

  &__number {
    color: black;
  }
}

.package-support {
  width: 1290px;
  margin-left: -35px;
  padding: 51px 67px;
  padding-bottom: 65px;
  margin-bottom: 65px;
  background-image: url('/img/assets/bg_chairs.jpg');
  background-repeat: no-repeat;
  background-position: 417px 4px;
  background-size: 67%;
  background-color: rgba(86, 99, 115, 1);
  color: white;

  &__title {
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;
    width: 360px;
    margin-bottom: 31px;
  }

  &__package-list {
    width: 538px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__package-item {
    margin-bottom: 24px;
  }

  &__subtitle {
    font-size: 18px;
    margin-bottom: 0;
  }

  &__subscription {
    margin-bottom: 0;
  }
}

.partners-level {
  margin-bottom: 45px;

  &__title {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  &__description {
    width: 770px;
    margin-bottom: 14px;
  }

  &__list-description {
    margin-bottom: 0;
  }

  &__level-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__level-item {
    margin-bottom: 2px;
  }

  &__illustration-list {
    width: 952px;
    list-style: none;
    padding: 0;
    margin-top: -86px;
    margin-left: -22px;
    margin-bottom: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  &__illustration-item {
    position: relative;
    padding: 0 22px;
    padding-top: 36px;
    width: 220px;
    background-color: white;
    -webkit-box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
  }

  &__illustration-item::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 220px;
    height: 18px;
  }

  &__illustration-item:nth-of-type(1) {
    height: 300px;
    padding-right: 39px;
  }

  &__illustration-item:nth-of-type(2) {
    height: 370px;
  }

  &__illustration-item:nth-of-type(3) {
    height: 440px;
  }

  &__illustration-item:nth-of-type(4) {
    height: 510px;
  }

  &__illustration-item:nth-of-type(1)::before {
    background-color: #69b8e3;
  }

  &__illustration-item:nth-of-type(2)::before {
    background-color: #70a9ac;
  }

  &__illustration-item:nth-of-type(3)::before {
    background-color: #718499;
  }

  &__illustration-item:nth-of-type(4)::before {
    background-color: #ebdb9a;
  }

  &__illustration-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 12px;
  }

  &__illustration-description {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 17px;
  }

  &__grade {
    font-size: 14px;
    line-height: 22px;
    color: #636363;
  }
}

@media (max-width: 420px) {
  .become-a-partners {
    width: auto;

    &__title,
    &__description {
      width: 100%;
    }

    &__advantages-title,
    &__advantages-list {
      width: 100%;
    }

    &__wrap-aside {
      width: auto;
      margin-top: 25px;
      margin-left: -16px;
      margin-right: -16px;
      padding: 25px 16px;
      background: white;
    }
  }

  .package-support {
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -16px;
    margin-right: -16px;

    &__title,
    &__package-list,
    &__package-item {
      width: 100%;
    }
  }

  .partners-level {
    width: auto;

    &__title,
    &__description {
      width: 100%;
    }

    &__illustration-list {
      width: 100%;
      margin-top: 25px;
      margin-left: 0;
    }

    &__illustration-item {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 50px;
      width: 100%;
      margin-bottom: 25px;
    }

    &__illustration-item::before {
      width: 100%;
      height: 35px;
    }
  }
}


