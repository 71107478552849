
.select-custom {
  position: relative;

  &__scroll {
    height: 100%;
    max-height: 285px;
  }

  &__frame {
    border: 1px solid #DCDCDC;
    padding: 10px 40px 10px 17px;
    position: relative;
    cursor: pointer;
    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 15px;
      top: calc(50% - 4px);
      width: 14px;
      height: 9px;
      transition: 0.2s ease-in-out;
      background-image: url("../image/select-arr.svg");
    }
  }

  &__value {
    font-size: 16px;
    line-height: 19px;
    overflow: hidden;
    white-space: nowrap;
    color: #000000;
    width: 100%;
    display: block;
    text-overflow: ellipsis;
  }

  &__dropdown {
    position: absolute;
    left: 0;
    width: 100%;
    top: calc(100% + 10px);
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    transform: translateY(-10px);
    transition: 0.3s ease-in-out;
  }

  &__dropdown-list {
    margin: 0;
    padding: 12px 30px 12px 18px;
    list-style: none;
  }

  &__dropdown-item-wrap {
    padding: 12px 0;
  }

  &__option {
    cursor: pointer;
    position: relative;
    margin: 0;
  }

  &__option-value {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    transition: 0.2s
  }

  &__input:checked + &__option-value {
    opacity: 0.3;
  }

  &_active &__frame {
    &::before {
      transform: rotate(180deg);
    }
  }
}

.dd-item_active .select-custom__dropdown {
  transform: translateY(0);
  opacity: 1;
  pointer-events: inherit;
}

.dd-item_active .select-custom__frame {
  &::before {
    transform: rotate(180deg);
  }
}