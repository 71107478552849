.trigger {
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 150px;
  }

  &__title {
    font-weight: 300;
    font-size: 36px;
    line-height: 1.2;
    letter-spacing: -0.04em;
    color:#fabe00;

    @media (min-width: 576px) {
      font-weight: normal;
      line-height: 48px;
    }

    @media (min-width: 992px) {
      font-size: 60px;
    }
  }
  &__txt {
    line-height: 1.5;
    max-width: 202px;
    margin-top: 14px;
    margin-bottom: 36px;
    font-size: 14px;

    @media (min-width: 576px) {
      font-size: 16px;
    }

    @media (min-width: 768px) {
      margin-top: 24px;
      margin-bottom: 0;
    }
  }

  &__icon-link {
    display: inline-block;
    margin-left: 6px;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../image/link-icon-yellow.svg");
    transition: .2s ease-in-out;
    &:hover {
      opacity: 0.6;
    }
  }
}