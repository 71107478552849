.slider-ss {
  position: relative;
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 25%;
    width: 35px;
    height: 35px;
    outline: none!important;
    border-radius: 50%!important;
    background: #ffffff!important;
  }

  .owl-prev {
    left: -50px;

    &:hover {
      background: #ffffff!important;
    }
  }

  .owl-next {
    right: -50px;

    &:hover {
      background: #ffffff!important;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    box-shadow: 0 3px 64px rgba(0, 0, 0, 0.102);
    border-radius: 50%;
    margin: 0!important;
    padding: 0;
  }

  button:first-of-type span {
    transform: scale(-1, 1);
  }

  button span {
    font-size: 0;
  }

  button span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .owl-prev span::before,
  .owl-next span::before {
    position: absolute;
    content: "";
    top: 50%;
    margin-top: -8px;
    margin-right: -2px;
    right: 50%;
    width: 2px;
    height: 10px;
    background-color: #048ad3;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .owl-next span::after,
  .owl-prev span::after {
    position: absolute;
    content: "";
    bottom: 50%;
    margin-bottom: -8px;
    margin-right: -2px;
    right: 50%;
    width: 2px;
    height: 10px;
    background-color: #048ad3;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.slider-ss.owl-theme {
  .owl-nav:hover {
    background: none!important;
  }
}