.widgit-news {
  position: relative;
  &__title{
    margin-bottom: 20px;
    @media (min-width: 576px) {
      margin-bottom: 30px;
    }
  }

  &__item {
    width: 100%;
    height: 213px;
    margin-bottom: 20px;
  }

  &__preview {
    width: 100%;
  }

  &__wrap-link {
    width: 100%;
    font-size: 12px;
    @media (min-width: 576px) {
      font-size: 16px;
    }
  }

  &__date{
    font-size: 10px;
    color: #BABABA;
    line-height: 150%;
    margin-bottom: 8px;
    @media (min-width: 576px) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  &__wrap-item {
    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
  }

  &__link-all {
    position: absolute;
    top: 10px;
    right: 0;
  }
}


@media (max-width: $mobile-view) {
  .widgit-news {
    &__wrap-item {
      width: 100%;
    }
  }
}

.advertising {
  &__elmaday2023 {
    position: absolute;
    top: 18px;
    right: 18px;
    padding: 2px 0;
    z-index: 10;
    @media (max-width: 1399px) {
      top: 16px;
      right: 16px;
    }
  }

  &__default {
    border-radius: 9999px;
    @media (min-width: 1400px) {
      right: 14px !important;
    }
  }

  &__position_bottom {
    top: auto;
    bottom: 3px;
    @media (min-width: 640px) {
      bottom: 5px;
    }
    @media (min-width: 1400px) {
      bottom: 13px;
    }
  }

  &__main {
    background:#FFFFFF4D;
    border-radius: 48px;
    width: 63px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    line-height: 12.1px;
    color: #000000;
    z-index: 10;
  }
  &__info {
    display: none;
    background: #FFFFFF;
    border-radius: 13px;
    padding: 10px;
    color: #000;
    p {
      font-size: 10px;
      color: #000000;
      line-height: 12.1px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}
.advertising__elmaday2023:hover {
  .advertising__info {
    z-index: 10;
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.overflow-show{
  overflow: visible !important;
}
