.wrap__call-to-action {
  margin-left: -35px;
  margin-bottom: 40px;
  width: 840px;
}

.call-to-action-small {
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 23px 23px;
  padding-left: 29px;
  padding-bottom: 39px;

  &__wrap-text {
    width: 445px;
    @media (min-width: 1400px) {
      width: 495px;
    }
  }

  & &__title {
    font-size: 17px;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 18px;
    @media (min-width: 1400px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__wrap-image {
    width: 225px;
    height: 129px;
    @media (min-width: 1400px) {
      width: 265px;
      height: 149px;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
  }

  & &__button {
    padding: 12px 31px;
    margin-right: 19px;
    margin-bottom: 15px;
    display: inline-block;
    font-weight: bold;
    color: white;
    cursor: pointer;
  }

  &__wrap {
    margin-left: -35px;
    margin-right: -73px;
    margin-bottom: 25px;
    margin-top: 25px;
    padding: 0;
  }

  .news-detail &__wrap img {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (max-width: $mobile-view) {
  .wrap__call-to-action {
    width: 100%;
    margin-left: -16px;
    margin-right: -16px;
  }
}
