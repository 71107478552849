.video-card {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: block;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    padding-top: 61.5%;
  }
  &__img {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
  &:hover &__play {
    transform: scale(0.85);
  }
}