.widgit-journal {
  background: #F3F5F6;
  margin-bottom: 56px;

  @media (min-width: 768px) {
    margin-bottom: 80px;
  }

  &__xs-link {
    margin-top: 34px;
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }
  &__header {
    margin-bottom: 28px;
  }
  &__header-title{
    margin-bottom: 16px;
    @media (min-width: 992px) {
      margin-bottom: 24px;
    }
  }
  &__header-text {
    font-size: 12px;
    line-height: 1.8;
    margin-bottom: 0;
    @media (min-width: 576px) {
      font-size: 18px;
    }
  }
  &__info{
    margin-bottom:20px;
  }
  &__right {
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.article-card{
  &__content {
    position: relative;
  }
  &__img-wrap {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    &::before {
      content: '';
      display: block;
      padding-top: 56.5%;
    }
  }
  &__img {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    &:hover{
      transform: scale(1.05);
      transition: 0.4s;
    }
    transition: 0.3s;
  }
  &_size_big > &__img-wrap{
    margin-bottom: 15px;
  }
  &_size_small > &__block{
    margin-bottom: 20px;
  }
  &_size_small > &__img-wrap{
    margin-bottom: 15px;
  }
  &__title{
    &_big{
      font-size: 12px;
      @media (min-width: 576px) {
        font-size: 18px;
      }
    }
    @media (max-width: 575px) {
      font-size: 12px;
    }
    line-height: 180%;
  }
  &__description{
    line-height: 180%;
    font-size: 12px;
    margin-top: 8px;
    margin-bottom:15px;
    font-size: 12px;
    @media (min-width: 576px) {
      font-size: 14px;
      margin-top: 15px;
    }
  }
  &__author{
    font-size: 14px;
    margin-top: 15px;
  }

  &__date{
    font-size: 10px;
    color: #BABABA;
    line-height: 150%;
    position: absolute;
    right: 0;
    bottom: 0;

    @media (min-width: 576px) {
      position: static;
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
}