.s-story-link {
  &__wrap {
    padding: 42px 16px;
    padding-bottom: 6px;
  }

  &__text  {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 25px;
  }

  &__link {
    font-size: 16px;
    line-height: 24px;
  }

  &__text-link a {
    font: inherit;
  }
}

@media (min-width: $desktop-view) {
  .s-story-link {
    &__wrap {
      padding: 110px 10px;
      padding-bottom: 6px;
      width: 75%;
      margin-bottom: 127px;
    }

    &__text  {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 29px;
    }

    &__link {
      font-size: 18px;
      line-height: 30px;
    }
  }
}
