.case-voice-robots {
  &__wrap {
    width: 1230px;
    margin: auto;
  }

  &__title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 16px;
    font-weight: bold;
  }

  &__description {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 34px;
  }

  &__description:last-of-type {
    margin-bottom: 80px;
  }

  &__advantages-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 58px;
    margin-top: 0;
    padding-left: 7%;
    width: 100%;
  }

  &__advantages-item {
    width: 84.5%;
    display: flex;
  }

  &__advantages-item:not(:last-of-type) {
    border-bottom: 2px solid #ffffff;
    margin-bottom: 29px;
    padding-bottom: 15px;
  }

  &__advantages-item p {
    width: 61%;
    font-size: 18px;
    line-height: 28px;
  }

  &__wrap-img {
    width: 37%;
  }

  &__advantages-item &__wrap-img {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &__bpms {
    border-top: 1px solid #d3d9db;
    border-bottom: 1px solid #d3d9db;
    padding-top: 76px;
    padding-left: 105px;
    padding-right: 100px;
    padding-bottom: 69px;
    width: 1230px;
    margin: auto;
    margin-bottom: 80px;
  }

  &__bpms-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__bpms-list > li {
    width: 32.5%;
  }

  &__bpms-list > li:first-of-type {
    margin-right: 191px;
  }

  &__bpms-list .case-help__list li {
    margin-bottom: 23px;
    padding-right: 0;
  }

  &__bpms-list b {
    display: block;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 27px;
  }

  &__bpms .three-columns__item:first-of-type {
    margin-right: 182px;
  }
}

@media (max-width: $mobile-view) {
  .case-voice-robots {
    &__wrap {
      width: 100%;
    }

    & &__title {
      width: 100%;
      font-size: 20px;
      line-height: 30px;
    }

    & &__description {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
    }

    & &__advantages-list {
      width: 100%;
      margin-bottom: 55px;
      padding-left: 36px;
    }

    &__advantages-item {
      justify-content: space-between;
      width: 93%;
    }

    &__advantages-item p {
      width: 54%;
      font-size: 16px;
      line-height: 24px;
    }

    &__advantages-item:not(:last-of-type) {
      margin-bottom: 40px;
    }

    &__bpms {
      width: 100%;
      padding-top: 42px;
      padding-bottom: 62px;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 42px;
    }

    &__bpms-list > li {
      width: 100%;
    }

    &__bpms-list > li:first-of-type {
      margin-right: 0;
    }

    &__bpms-list b {
      font-size: 20px;
      line-height: 30px;
    }

    &__bpms-list .case-help__list li {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
