.case-demonstration {
  &__wrap {
    margin-left: -20px;
    margin-right: -20px;
    padding: 34px 67px;
    background-color: #e6e6e6;
    margin-bottom: 72px;
  }

  &__title {
    width: 62%;
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 11px;
    font-weight: bold;
  }

  &__description {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 48px;
  }

  &__wrap-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__wrap-video {
    position: relative;
    width: 330px;
   // background-image: url("/upload/purchases/png5cff5d3291cde0/07278855/purchases.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    @media (max-width: 1399px) {
      .youtube__wrap {
        width: 100% !important;
      }
    }
    @media (min-width: 1400px) {
      width: 380px;
    }
  }

  &__wrap-video::before {
    position: absolute;
    content: 'Видео будет доступно после заполнения формы';
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0 , 0, 0.5);
    color: #FFFFFF;
    font-size: 18px;
    line-height: 26px;
    z-index: 5;
  }

  &__wrap-video::after {
    position: absolute;
    content: '';
    width: 110px;
    height: 110px;
    top: 50%;
    margin-top: -55px;
    left: 50%;
    margin-left: -55px;
    background-image: url('/upload/play_button/png5cff66f8bdd124/20504150/play_button.png');
    z-index: 0;
  }

  &__wrap-video.open::before,
  &__wrap-video.open::after {
    display: none;
  }

  &__wrap-video.active {
    background-image: none;
  }

  &__wrap-video.active::before,
  &__wrap-video.active::after {
    display: none;
  }

  &__cover-close {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__wrap-video &__cover-close b {
    display: inline-block;
    font-size: 24px;
    line-height: 34px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  &__wrap-video &__cover-close p {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    width: 272px;
    text-align: center;
  }

  &__wrap-content.active &__cover-close {
    display: none;
  }

  &__wrap-form {
    width: 58%;
    box-sizing: border-box;
  }

  &__wrap-form .downloads-demo__label-value {
    width: 202px;
  }

  &__wrap-video iframe {
    width: 100%;
    height: 215px;
    margin-bottom: 16px;
  }

  &__wrap-video b {
    display: block;
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 26px;
  }

  &__wrap-video p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 26px;
    color: #636363;
  }

  &__wrap-form .downloads-demo__form-title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 17px;
    margin-top: -6px;
  }

  &__wrap-form .downloads-demo__label {
    padding-right: 0;
  }

  &__wrap-form .downloads-demo__input {
    background-color: #ffffff;
    padding-top: 6px;
    padding-bottom: 5px;
    font-size: 16px;
    line-height: 22px;
  }

  &__wrap-form .downloads-demo__label:nth-last-of-type(2) {
    margin-bottom: 28px;
  }

  &__wrap-form .downloads-demo__label--checkbox,
  &__wrap-form .downloads-demo__submit-unclosed {
    margin-left: 202px;
    margin-bottom: 33px;
  }

  &__wrap-form .downloads-demo__label--checkbox {
    font-size: 14px;
    line-height: 20px;
  }

  &__wrap-form .downloads-demo__submit-unclosed {
    padding: 11px 38px;
  }
}

.case-demonstration--contracts {
  .case-demonstration__title {
    width: 100%;
  }

  .case-demonstration__wrap-video {
    background-image: url("/upload/preview-contracts/png5d12105ca86f85/92354073/preview-contracts.png");

  }
}

.case-demonstration--stand {
  .case-demonstration {
    &__wrap {
      width: 1230px;
      padding: 23px 0;
      margin-bottom: 52px;
      margin-left: auto;
      margin-right: auto;
      background-color: transparent;
    }

    &__wrap-form {
      width: 35%;
      padding-left: 2.8%;
     // padding-right: 5.8%;
      box-sizing: border-box;
    }

    &__wrap-form .downloads-demo__label {
      padding-right: 22%;
    }

    &__wrap-form .downloads-demo__input {
      background-color: transparent;
      color: #636363;
    }

    &__wrap-video {
      position: relative;
      width: 799px;
      height: 452px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;

    }

    &__wrap-form .downloads-demo__label select:invalid {
      color: #cfcfcf;
    }

    &__wrap-video iframe {
      height: 100%;
    }

    &__wrap-form .downloads-demo__label--checkbox,
    &__wrap-form .downloads-demo__submit-unclosed {
      margin-left: 0;
    }
  }
}

.case-demonstration--timing {
  .case-demonstration {
    &__wrap {
      background: none;
      padding-left: 0;
      padding-right: 0;
      margin: auto;
      padding-top: 99px;
      padding-bottom: 82px;
    }

    &__title {
      margin-bottom: 8px;
    }

    &__description {
      margin-bottom: 30px;
    }

    &__wrap-content {
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &__wrap-form {
      width: 640px;
      margin-right: 40px;
      @media (min-width: 1400px) {
        width: 800px;
      }
    }

    &__wrap-form iframe {
      width: 100%;
      height: 450px;
      margin-bottom: 9px;
    }

    &__wrap-form p {
      font-size: 16px;
      line-height: 26px;
      color: #636363;
    }

    &__wrap-timing {
      width: 300px;
    }

    &__wrap-timing b {
      display: block;
      margin-bottom: 10px;
      line-height: 14px;
    }

    &__timing-list {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    &__timing-item {
      display: flex;
      margin-bottom: 10px;
    }

    &__timing-item p {
      margin-bottom: 0;
      width: 253px;
    }

    &__timing-item span {
      display: block;
      width: 35px;
      color: #048ad3;
      margin-right: 15px;
      padding-top: 2px;
      cursor: pointer;
    }
  }
}

.case-demonstration--farm {
  .case-demonstration {
    &__wrap {
      padding: 34px 78px;
    }

    &__wrap-form {
      width: 53%;
    }

    &__wrap-video {
      width: 420px;
      padding-top: 101px;
    }

    &__submit {
      background-color: $color-dark-green;
    }

    &__submit:hover,
    &__submit:focus,
    &__submit:active {
      background-color: $color-dark-green!important;
    }

    &__group-submit {
      padding-top: 20px;
    }
  }

  .downloads-demo__input--checkbox[data-type=choose] + .downloads-demo__label--icon {
    background-color: #ffffff;
  }

  .downloads-demo__input--checkbox + .downloads-demo__label--icon::after,
  .downloads-demo__input--checkbox + .downloads-demo__label--icon::before{
    background-color: $color-dark-green;
  }

  .downloads-demo__accept-terms,
  .downloads-demo__policy-confident {
    color: $color-dark-green;
  }
}

.elma-project--form-bottom .case-demonstration__wrap {
  background-color: transparent;
}

.elma-project--form-bottom {
  background: #E1E8EC;

  .case-demonstration__wrap {
    width: 100%;
  }

  .case-demonstration__wrap-form {
    width: 55%;
  }

  .downloads-demo__products-wrap {
    width: 35%;
    margin-top: 0;
  }

  .case-demonstration__title {
    width: 100%;
  }

  .downloads-demo__submit {
    margin-left: 203px;
  }
}

@media (max-width: $mobile-view) {
  .case-demonstration {
    &__wrap {
      width: 100%;
      padding-right: 16px;
      padding-left: 16px;
      margin-bottom: 25px;
      margin-left: 0;
      margin-right: 0;
    }

    &__wrap-video {
      margin-bottom: 35px;
    }

    &__wrap-form {
      width: 100%;
    }

    &__title {
      width: 100%;
      font-size: 20px;
      line-height: 26px;
    }

    &__description {
      width: 100%;
      font-size: 14px;
      line-height: 22px;
    }

    &__wrap-video {
      width: 100%;
      // height: 51.5vw;
      background-size: cover;
    }

    &__wrap-form .downloads-demo__label select:invalid {
      color: #cfcfcf;
    }

    &__wrap-form .downloads-demo__label--checkbox,
    &__wrap-form .downloads-demo__submit-unclosed {
      margin-left: 0;
    }

    &__cover-close svg {
      width: 45px;
      height: 45px;
    }

    &__wrap-video &__cover-close b {
      display: inline-block;
      font-size: 20px;
      line-height: 26px;
    }

    &__wrap-video &__cover-close p {
      display: inline-block;
      font-size: 16px;
      line-height: 18px;
    }
  }

  .case-demonstration--stand {
    .case-demonstration {
      &__wrap {
        width: 100%;
        padding: 0 16px;
      }

      &__wrap-video {
        width: 100%;
        height: auto;
      }

      &__wrap-form {
        width: 100%;
        padding: 0;
      }

      &__wrap-form .downloads-demo__label {
        padding-right: 0;
      }
    }
  }

  .case-demonstration--timing {
    .case-demonstration {
      &__wrap {
        width: 100%;
        padding: 0 16px;
        padding-top: 54px;
        padding-bottom: 40px;
      }

      &__wrap-form {
        width: 100%;
        margin-right: 0;
      }

      &__wrap-form iframe {
        width: 100%;
        height: 52vw;
        margin-right: 0;
      }

      &__wrap-timing {
        display: none;
      }
    }
  }

  .case-demonstration--farm {
    .case-demonstration {
      &__wrap {
        padding: 32px 16px;
      }

      &__wrap-form {
        width: 100%;
      }

      &__wrap-content {
        width: 100%;
      }
    }
  }

  .elma-project--form-bottom {
    background: transparent;

    .downloads-demo__products-wrap {
      display: none;
    }

    .case-demonstration__wrap {
      width: 100%;
    }

    .downloads-demo__submit {
      margin-left: 0;
    }

    .case-demonstration__wrap-form {
      width: 100%;
    }
  }
}

.modal_case {
  z-index: 1501;
}
