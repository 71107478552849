.news-banner {
  padding: 50px 16px;
  color: #FFFFFF;
  background-color: #233255;

  @media (min-width: 992px) {
    padding: 80px 86px;
  }

  &__title {
    margin-bottom: 15px;
  }

  &__description {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.36;
    @media (min-width: 576px) {
      font-size: 23px;
    }
    @media (min-width: 992px) {
      font-size: 32px;
    }
  }

  &__author {
    margin-top: 50px;
    align-items: center;
  }

  .face-block__photo-wrap {
    width: 75px;
  }
  .face-block__content {
    font-size: 16px;
    @media (min-width: 992px) {
      font-size: 24px;
    }
  }

  &__inside {
    max-width: 670px;
  }
}