.success-story__main-info-wrap {
  position: relative;
}

.success-story__main-info {
  max-width: 574px;
  @media (min-width: 1400px) {
    max-width: 764px;
  }
}

.success-story__main-info-item {
  width: 74%;
}

.main-banner--success-story {
  height: 370px;
  @media (min-width: 1400px) {
    height: 427px;
  }
}

.success-story p {
  max-width: 620px;
  @media (min-width: 1400px) {
    max-width: 741px;
  }
}

.success-story .success-story__img {
  max-width: 641px;
  height: auto;
  margin-bottom: 10px;
  @media (min-width: 1400px) {
    max-width: 741px;
  }
}

.success-story .success-story__figcaption {
  max-width: 741px;
}

.success-story__quote {
  font-size: 30px;
  line-height: 35px;
  font-weight: normal;
  text-align: center;
  width: 100%;
  padding: 25px 15px;
  margin-top: 72px;
  margin-bottom: 46px;
  border-bottom: 1px solid #D9D9D9;
  border-top: 1px solid #D9D9D9;

  &_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .reset {
    text-align: start;
    h2 {
      font-weight: revert;
    }
    p {
      font-size: 18px;
      margin-bottom: 1.5rem;
    }
    h4 {
      font-weight: revert;
      font-size: 16px;
    }
    a {
      font-size: 16px;
      color: #272727;
    }
  }


  &_margin {
    margin-top: 3.625rem;
    margin-bottom: 4.688rem;
  }
}

.success-story__aside-info {
  position: absolute;
  right: 0;
  top: 0;
  width: 295px;
  @media (min-width: 1400px) {
    width: 380px;
  }
}

.success-story__aside-info-item {
  width: 23.5%;
}

.success-story__aside-element {
  padding: 30px 35px;
  margin-bottom: 21px;
  background-color: white;
}

.success-story__aside-item-wrap {

}

.success-story__aside-item-title {
  line-height: 44px;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 27px;
}

.success-story__aside-wrap-img {
  max-width: 240px;
  width: 100%;
  margin-bottom: 18px;
}

.success-story__aside-subtitle {
  line-height: 30px;
  span {
    color: #0D4A75;
  }
}

.success-story__aside-subtitle a {
  color: rgba(13, 74, 117, 1);
}



@media (min-width: 1200px) {
  .success-story__aside-item_mobile {
    display: none;
  }
}

.success-story__aside-item_desktop {
  display: none;
}

@media (min-width: 1200px) {
  .success-story__aside-item_desktop {
    display: block;
  }
}

.success-story__aside-title {
  margin-bottom: 17px;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
}

.success-story__aside-industry,
.success-story__aside-text {
  margin-bottom: 5px;
}

.success-story__aside-industry-link {
  display: block;
}

.success-story__aside-advantages-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.success-story__aside-advantages-item {
  margin-bottom: 15px;
}

.success-story__aside-subtitle {
  //font-size: 18px;
  line-height: 26px;
  font-weight: normal;
  margin-bottom: 3px;
}

.success-story__intro {
  font-size: 20px;
  line-height: 30px;
}

.success-story__img {
  width: 813px;
}

.success-story__figcaption {
  color: #636363;
  max-width: 813px;
}

.success-story__figcaption {
  color: #636363;
  max-width: 813px;
  margin-bottom: 32px;
}

.success-story__aside-img-wrap {
  position: relative;
}

.success-story__aside-figure {
  position: absolute;
  right: 0;
  top: 16px;
}

.success-story__aside-figure img {
  width: 280px;
  height: auto;
  margin-bottom: 10px;
  @media (min-width: 1400px) {
    width: 380px;
  }
}

.success-story__aside-figcaption {
  max-width: 290px;
  color: #636363;
  @media (min-width: 1400px) {
    max-width: 400px;
  }
}

.success-story__quote-aside-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 29px 0;
}

.success-story__quote-img {
  width: 553px;
  height: auto;
  @media (min-width: 1400px) {
    width: 813px;
  }
}

.success-story__quote-wrap {
  width: 407px;
  padding: 27px;
  margin-top: 28px;
  margin-right: -35px;
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  @media (min-width: 1400px) {
    font-size: 30px;
    line-height: 42px;
  }
}

.success-story__quote-wrap--content-wide {
  max-width: 740px;
  width: auto;

  p {
    line-height: 42px;
  }
}

.success-story__two-image-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 29px 0;
}

.success-story__two-image {
  width: 465px;
  @media (max-width: 1399px) {
    height: auto;
  }
  @media (min-width: 1400px) {
    width: 585px;
  }
}

.success-story__two-image-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.success-story__list {
  max-width: 741px;
}

.success-story p strong {
  display: inline-block;
  margin-top: 22px;
}

@media (max-width: $mobile-view) {
  .success-story .main-banner--success-story {
    min-height: 34vw;
    height: 34vw;
  }

  .success-story .wrap-call-to-action {
    margin-bottom: 0;
  }

  .success-story__aside-info {
    position: static;
    width: 380px;
  }

  .success-story .success-story__title {
    font-size: 20px;
    line-height: 32px;
  }

  .success-story__main-info-wrap {
    flex-wrap: wrap;
  }

  .success-story .success-story__img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  .success-story__quote-img-wrap .success-story__quote-img {
    width: 100%;
    height: auto;
  }

  .success-story__quote-img-wrap {
    width: 100%;
  }

  .success-story__two-image-wrap .success-story__two-image {
    width: 100%;
    height: auto;
  }

  .success-story__two-image-wrap .success-story__two-image:first-of-type {
    margin-bottom: 25px;
  }

  .success-story__aside-figure {
    position: static;
  }

  .success-story__aside-figure img {
    width: 100%;
    height: auto;
  }

  .success-story__quote,
  .success-story__quote-wrap {
    font-size: 18px;
    line-height: 24px;
    margin-top: 47px;
  }

  .success-story__quote-wrap {
    width: 100%;
  }

  .success-story__aside-title {
    font-size: 20px;
    line-height: 28px;
  }

  .success-story__aside-subtitle {
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
  }

  .success-story__aside-info {
    width: 100%;
  }

  .success-story__aside-element {
    padding-left: 16px;
    padding-right: 16px;
  }
}
